import React from 'react'
import { ExpandableCard } from '../../ExpandableCard'
import { Typography } from '@mui/material'

interface Props {
  title: string
  content: string
  animalName: string
}

export const CollapsibleBlogArticle: React.FC<Props> = ({ title, content, animalName }) => {
  return (
    <ExpandableCard title={title}>
      <Typography
        variant="body2"
        sx={{ mt: 2, lineHeight: '19px' }}
        dangerouslySetInnerHTML={{ __html: content.replaceAll('votre animal', animalName) }}
      />
    </ExpandableCard>
  )
}
