import { AlertColor, SnackbarOrigin } from '@mui/material'
import create from 'zustand'

interface GlobalSnackbarStore {
  isSnackbarOpen: boolean
  openSnackbar: () => void
  closeSnackbar: () => void
  triggerNotification: ({ message, action }: { message: string; action: React.ReactNode | undefined }) => void
  triggerSuccessMessage: (message: string) => void
  triggerErrorMessage: (message: string) => void
  triggerInfoMessage: (message: string) => void
  setMessage: (message: string) => void
  message: string
  severity: AlertColor
  autoHideDuration: number | null
  position: SnackbarOrigin | undefined
  action: React.ReactNode | undefined
}

export const useGlobalSnackbarStore = create<GlobalSnackbarStore>()((set, get) => ({
  isSnackbarOpen: false,
  message: '',
  severity: 'success' as AlertColor,
  autoHideDuration: 6000,
  position: undefined,
  action: undefined,
  openSnackbar: () => {
    set({ isSnackbarOpen: true })
  },
  closeSnackbar: () => {
    set({ isSnackbarOpen: false })
  },
  setMessage: (message: string) => {
    set({ message })
  },
  triggerNotification: ({ message, action }) => {
    set({ action: action })
    set({ autoHideDuration: null })
    set({ position: { vertical: 'top', horizontal: 'center' } })
    set({ severity: 'info' })
    get().setMessage(message)
    get().openSnackbar()
  },
  triggerSuccessMessage: (message: string) => {
    set({ severity: 'success' })
    get().setMessage(message)
    get().openSnackbar()
  },
  triggerInfoMessage: (message: string) => {
    set({ severity: 'info' })
    get().setMessage(message)
    get().openSnackbar()
  },
  triggerErrorMessage: (message: string) => {
    set({ severity: 'error' })
    get().setMessage(message)
    get().openSnackbar()
  },
}))
