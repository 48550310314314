import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Avatar, Box, Button, Card, CardContent, IconButton, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { AnimalDto, CustomerDto } from '../../interactors/gen/backendClient'
import { getAnimalProfilePhotoSrc } from '../../utils/S3-links'
import { findGoodAnimalProfileURL } from '../../utils/findGoodAnimalProfilURL'
import { ReactComponent as CrossIcon } from '../icons/CrossIcon.svg'
import { AdoptionAssociationCard } from './AdoptionAssociationCard'
import { Categories } from './AdoptionDetailsScreen'

interface Props {
  animal: AnimalDto
  association: CustomerDto
  handleCategoryChange: (category: Categories) => void
}

export const AnimalCard: React.FC<Props> = ({ animal, association, handleCategoryChange }) => {
  const [open, setOpen] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const profileImageKey = animal.images?.profileImageKey

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const pictures = [profileImageKey, ...(animal.images?.otherImagesKeys || [])].filter(Boolean)

  const nextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % pictures.length)
  }

  const previousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + pictures.length) % pictures.length)
  }

  return (
    <Card sx={{ p: { xs: 1, md: 2 } }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'row' }, gap: '24px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Box
              sx={{
                position: 'relative',
                height: { md: '330px', xs: '100px' },
                maxWidth: '100%',
                width: { xs: '100px', md: '300px' },
              }}
            >
              {profileImageKey ? (
                <img
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '11.25px',
                    cursor: 'pointer',
                  }}
                  key={profileImageKey}
                  src={profileImageKey ? findGoodAnimalProfileURL(profileImageKey) : ''}
                  onClick={() => handleImageClick(0)}
                />
              ) : (
                <Avatar
                  alt={animal.name}
                  sx={{
                    width: '300px',
                    maxWidth: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '11.25px',
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                display: { md: 'flex', xs: 'none' },
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: '8px',
              }}
            >
              {pictures?.slice(1, 4).map((picture, index) => (
                <img
                  key={picture}
                  style={{
                    width: '300px',
                    maxWidth: '20%',
                    height: '63px',
                    objectFit: 'cover',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  src={getAnimalProfilePhotoSrc(picture || '')}
                  onClick={() => handleImageClick(index + 1)}
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', color: '#f5f5f5' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="h1" sx={{ fontSize: { md: '24px', xs: '22px' }, color: 'black' }}>
                    {animal.name} {`n'attend que vous !`}
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                color="inherit"
                sx={{ fontSize: 12.5, color: 'black', width: 'fit-content', p: '8px', py: '4px' }}
                onClick={() => handleCategoryChange(Categories.Documents)}
              >
                Voir le profil
              </Button>
            </Box>
          </Box>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              borderRadius: '8px',
              p: '32px',
              gap: '24px',
              border: 'none',
              position: 'relative',
            }}
          >
            <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '0px', right: '0px' }}>
              <CrossIcon />
            </IconButton>
            <IconButton
              onClick={previousImage}
              sx={{
                position: 'absolute',
                left: '0px',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }} />
            </IconButton>
            <IconButton
              onClick={nextImage}
              sx={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)' }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <img
              style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
              src={getAnimalProfilePhotoSrc(pictures[selectedImageIndex] || '')}
            />
          </Box>
        </Modal>
        <AdoptionAssociationCard association={association} />
      </CardContent>
    </Card>
  )
}
