import { Upload } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { AnimalProfileHealthObservationsDto } from '../../interactors/gen/backendClient'
import { LoadingButton } from '@mui/lab'

interface Props {
  animalId: string
  observationId: string
  setObservation: (observation: AnimalProfileHealthObservationsDto) => void
}

export const AddFileToObservation: React.FC<Props> = ({ animalId, observationId, setObservation }) => {
  const [buttonClicked, setButtonClicked] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone()

  const onSubmit = async () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('file', acceptedFiles[0])
    try {
      const observation = await AnimalProfilesClient.addFileToObservation(animalId, observationId, formData)
      setObservation(observation)
      setButtonClicked(false)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  if (buttonClicked) {
    return (
      <Box>
        {acceptedFiles[0] ? (
          acceptedFiles[0].type.startsWith('video') ? (
            <video
              style={{
                width: '100%',
                height: '330px',
                objectFit: 'contain',
                borderRadius: '11.25px',
              }}
              src={acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0])}
              controls
            />
          ) : (
            <img
              style={{
                width: '100%',
                height: '330px',
                objectFit: 'contain',
                borderRadius: '11.25px',
              }}
              src={acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0])}
            />
          )
        ) : (
          <Box
            {...getRootProps()}
            sx={{
              width: '100%',
              height: '330px',
              objectFit: 'cover',
              borderRadius: '11.25px',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'lightgrey',
            }}
          >
            <input {...getInputProps()} />
            <Typography variant="body1" component="p">
              Ajouter une photo/vidéo <Upload />
            </Typography>
          </Box>
        )}
        {acceptedFiles[0] && (
          <LoadingButton variant="contained" onClick={onSubmit} sx={{ width: '100%', mt: 2 }} loading={isLoading}>
            Ajouter
          </LoadingButton>
        )}
      </Box>
    )
  }
  return (
    <>
      <Typography sx={{ fontSize: '16px' }}>{`Pas de photo ou vidéo pour cette observation`}</Typography>
      <Button variant="contained" onClick={() => setButtonClicked(true)}>
        Ajouter une photo/vidéo
      </Button>
    </>
  )
}
