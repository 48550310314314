import { Autocomplete, BaseTextFieldProps, Chip, SxProps, TextField } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { blueSky } from '../theme'

interface Option {
  label: string
  value: string | number
}

type Props<T extends FieldValues> = {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Option[]
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  freeSolo?: boolean
  color?: string
  sx?: SxProps
}

export function ControlledMultipleAutoComplete<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  options,
  size,
  disabled,
  freeSolo,
  requiredRule,
  color = blueSky,
  sx,
}: PropsWithChildren<Props<T>>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={[] as any} // TODO: check this
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          sx={{ width: '100%', ...sx }}
          onChange={(_, data) => onChange(data.map((option: Option) => option.value))}
          freeSolo={freeSolo}
          id={fieldName}
          options={options}
          getOptionLabel={(option) => option.label}
          value={value.map((val: string | number) => options.find((option) => option.value === val)).filter(Boolean)}
          size={size}
          disabled={disabled}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...restTagProps } = getTagProps({ index })
              return (
                // Destructure getTagProps to remove the key
                <Chip
                  key={option.value}
                  label={option.label}
                  {...restTagProps}
                  // Customize the chip style here
                  style={{ backgroundColor: color, color: 'white' }}
                />
              )
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              required={requiredRule ? value.length === 0 : false}
              label={label}
              placeholder={label}
              error={!!error}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '100%',
                },
              }}
            />
          )}
        />
      )}
    />
  )
}
