import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField, Box, Typography } from '@mui/material'
import { z } from 'zod'
import { PromoCodesClient } from '../../interactors/clients/PromoCodesClient'
import { PromoCodeDto } from '../../interactors/gen/backendClient'
import { LoadingButton } from '@mui/lab'

// Define the schema using zod
const promoCodeSchema = z.object({
  promoCode: z.string().optional(),
})

// Define the form data type
type PromoCodeFormData = z.infer<typeof promoCodeSchema>

interface Props {
  setPromoCode: (promoCode: PromoCodeDto) => void
  orderPrice: number
  promoCode?: PromoCodeDto
  invisible?: boolean
}

export const PromoCode: React.FC<Props> = ({ promoCode, setPromoCode, orderPrice, invisible = false }) => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const queryParams = new URLSearchParams(window.location.search)
  const promoCodeParam = queryParams.get('promoCode')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PromoCodeFormData>({
    resolver: zodResolver(promoCodeSchema),
    defaultValues: {
      promoCode: promoCode?.code || promoCodeParam || '',
    },
  })

  useEffect(() => {
    if (promoCodeParam) {
      onSubmit({ promoCode: promoCodeParam })
    }
  }, [promoCodeParam])

  const onSubmit = async (data: PromoCodeFormData) => {
    if (!data.promoCode) {
      return
    }
    try {
      setLoading(true)
      const verifiedPromoCode = await PromoCodesClient.checkPromoCode(data.promoCode)
      if (verifiedPromoCode.minimalOrderAmount && verifiedPromoCode.minimalOrderAmount > orderPrice) {
        setError(`Le montant de la commande doit être supérieur à ${verifiedPromoCode.minimalOrderAmount} €`)
        setLoading(false)
        return
      }
      setPromoCode(verifiedPromoCode)
      setError(null) // Clear any previous errors
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError("Ce code promo n'existe pas") // Set custom error message
      console.error('Error verifying promo code', error)
    }
  }

  if (invisible) return null

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label="Code Promo"
        {...register('promoCode')}
        error={!!errors.promoCode}
        helperText={errors.promoCode ? errors.promoCode.message : ''}
      />
      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
      <LoadingButton type="submit" variant="contained" color="primary" loading={loading}>
        Appliquer le Code Promo
      </LoadingButton>
    </Box>
  )
}
