import { AdoptionAttemptDtoStatus } from '../../interactors/gen/backendClient'

export const getReadableAdoptionStatus = (status: AdoptionAttemptDtoStatus): string => {
  const map: Record<AdoptionAttemptDtoStatus, string> = {
    [AdoptionAttemptDtoStatus.AwaitingCertificate]: 'En cours',
    [AdoptionAttemptDtoStatus.AwaitingContract]: 'En cours',
    [AdoptionAttemptDtoStatus.AwaitingPayment]: 'À initier',
    [AdoptionAttemptDtoStatus.AwaitingSignedCertificate]: 'En cours',
    [AdoptionAttemptDtoStatus.AwaitingSignedContract]: 'En cours',
    [AdoptionAttemptDtoStatus.AwaitingCertificateValidation]: 'En cours',
    [AdoptionAttemptDtoStatus.AwaitingContractValidation]: 'En cours',
    [AdoptionAttemptDtoStatus.AwaitingPaymentValidation]: 'En cours',
    [AdoptionAttemptDtoStatus.Done]: 'Terminée',
    [AdoptionAttemptDtoStatus.PendingCustomer]: 'En attente',
    [AdoptionAttemptDtoStatus.PendingUser]: 'À initier',
    [AdoptionAttemptDtoStatus.SuspendedByCustomer]: 'Suspendue',
    [AdoptionAttemptDtoStatus.SuspendedByUser]: 'Suspendue',
  }

  return map[status]
}

export const getTextForValidateButtonOfStatus = (status: AdoptionAttemptDtoStatus): string => {
  const map: Record<AdoptionAttemptDtoStatus, string> = {
    [AdoptionAttemptDtoStatus.AwaitingCertificate]: 'Envoyer mon certificat signé',
    [AdoptionAttemptDtoStatus.AwaitingContract]: "J'envoie le contrat signé",
    [AdoptionAttemptDtoStatus.AwaitingPayment]: "J'ai effectué le paiement",
    [AdoptionAttemptDtoStatus.AwaitingSignedCertificate]: 'Envoyer mon certificat signé',
    [AdoptionAttemptDtoStatus.AwaitingSignedContract]: "J'envoie le contrat signé",
    [AdoptionAttemptDtoStatus.AwaitingCertificateValidation]: 'En attente de validation',
    [AdoptionAttemptDtoStatus.AwaitingContractValidation]: 'En attente de validation',
    [AdoptionAttemptDtoStatus.AwaitingPaymentValidation]: 'En attente de validation',
    [AdoptionAttemptDtoStatus.Done]: '',
    [AdoptionAttemptDtoStatus.PendingCustomer]: '',
    [AdoptionAttemptDtoStatus.PendingUser]: "Poursuivre l'adoption",
    [AdoptionAttemptDtoStatus.SuspendedByCustomer]: '',
    [AdoptionAttemptDtoStatus.SuspendedByUser]: '',
  }

  return map[status]
}
