import { AnimalProfileHealthObservationsDtoType } from '../interactors/gen/backendClient'

export const getAdviceForHealthObservationsType = (issue: AnimalProfileHealthObservationsDtoType): string => {
  const map: Record<AnimalProfileHealthObservationsDtoType, string> = {
    // Cat health observations
    [AnimalProfileHealthObservationsDtoType.DiarrheaAndStoolIssues]:
      "Si votre chat a une diarrhée persistante, des excréments noirs, sanglants, ou mucus, ou s'il montre des signes de vomissements, léthargie, perte d'appétit ou de poids, consultez un vétérinaire. De même, l'incapacité à déféquer ou des excréments durs indiquent des problèmes de santé potentiels. Un bilan vétérinaire est conseillé pour écarter tout problème de santé.",
    [AnimalProfileHealthObservationsDtoType.Vomiting]:
      "Les vomissements occasionnels, comme des boules de poils ou de l'herbe, peuvent être normaux pour les chats. Cependant, si vous remarquez une augmentation de la fréquence, des vomissements de nourriture ou de sang, des difficultés à avaler, ou des haut-le-cœur, consultez un vétérinaire. Les vomissements accompagnés de léthargie, de diarrhée, ou de perte d'appétit peuvent indiquer des maladies graves, incluant des troubles rénaux ou hépatiques. Un examen vétérinaire est crucial.",
    [AnimalProfileHealthObservationsDtoType.MeowingInLitter]:
      "Si votre chat va souvent à sa litière, s'accroupit sans uriner, miaule, ou a du sang dans ses selles, il peut souffrir d'une infection urinaire. Consultez un vétérinaire pour un diagnostic et un traitement appropriés.",
    [AnimalProfileHealthObservationsDtoType.AppetiteChange]:
      "Si votre chat mange moins, cela peut être dû à la chaleur ou parce qu’il chasse dehors. Mais chez les chats, surtout les seniors, cela peut signaler un problème de santé. Si la perte d'appétit s'accompagne de léthargie, vomissements, diarrhée, ou perte de poids, consultez un vétérinaire. Une augmentation de l'appétit pourrait indiquer une hyperthyroïdie, du diabète, ou une maladie intestinale.",
    [AnimalProfileHealthObservationsDtoType.ExcessiveWeightGain]:
      "Si votre chat prend rapidement du poids, cela peut le rendre obèse, risquant le diabète et des problèmes de mobilité. Vous devriez sentir ses côtes facilement ; si ce n'est pas le cas, il pourrait être en surpoids. Consultez votre vétérinaire pour des conseils sur comment aider votre chat à perdre du poids sainement. Assurez-vous de changer son régime alimentaire graduellement pour éviter des troubles digestifs.",
    [AnimalProfileHealthObservationsDtoType.IncreasedWaterConsumption]:
      "Une augmentation de la consommation d'eau est normale si vous changez l'alimentation de votre chat de nourriture humide à sèche. Cependant, si votre chat boit plus sans changement de régime, cela pourrait indiquer une maladie comme le diabète ou des problèmes rénaux. Si vous observez votre chat boire fréquemment ou si sa litière est anormalement mouillée, consultez votre vétérinaire pour évaluer sa santé.",
    [AnimalProfileHealthObservationsDtoType.SkinCrusts]:
      'Les croûtes sur la peau de votre chat, causées par un grattage excessif ou une toilette intense, peuvent signaler une maladie cutanée. Un pelage terne ou avec des peaux mortes peut aussi indiquer un problème de santé plus profond. Si votre chat se gratte beaucoup et présente des points noirs dans son pelage, il pourrait avoir des puces, ce qui est généralement simple à traiter. Par ailleurs, des sécrétions brunâtres ou des rougeurs autour des oreilles nécessitent une consultation vétérinaire.',
    [AnimalProfileHealthObservationsDtoType.RedAndSwollenGums]:
      "Une rougeur autour de la gueule, des gencives enflées, ou une mauvaise haleine peuvent indiquer une maladie buccale chez votre chat. Ces symptômes peuvent être douloureux, entraînant une perte d'appétit, des difficultés à manger, ou une perte de poids. Si vous observez ces signes, consultez un vétérinaire pour un examen et un traitement appropriés.",
    [AnimalProfileHealthObservationsDtoType.Panting]:
      "Les chats ne respirent normalement pas la gueule ouverte. Si votre chat halète, respire rapidement, tousse, ou émet des bruits en respirant, c'est une urgence vétérinaire. Contactez immédiatement votre vétérinaire pour une évaluation, car cela peut indiquer une détresse respiratoire ou d'autres problèmes de santé graves.",
    [AnimalProfileHealthObservationsDtoType.Sneezing]:
      "L'éternuement, un nez qui coule, et des yeux rouges chez votre chat peuvent être le signe d'un problème dentaire, d'une infection virale ou d'une autre maladie affectant le système respiratoire supérieur. Ces symptômes méritent une attention vétérinaire pour diagnostiquer la cause sous-jacente et démarrer un traitement adapté.",
    [AnimalProfileHealthObservationsDtoType.NoseBleeding]:
      'Des sécrétions visqueuses persistantes, un saignement de nez, un clignement excessif ou des rougeurs autour des yeux sont des signes alarmants chez votre chat. Ces symptômes peuvent indiquer une maladie sous-jacente sérieuse. Il est important de consulter un vétérinaire rapidement pour un diagnostic précis et un traitement approprié.',
    [AnimalProfileHealthObservationsDtoType.ExcessiveEyeBlinking]:
      'Des sécrétions visqueuses persistantes, un saignement de nez, un clignement excessif ou des rougeurs autour des yeux sont des signes alarmants chez votre chat. Ces symptômes peuvent indiquer une maladie sous-jacente sérieuse. Il est important de consulter un vétérinaire rapidement pour un diagnostic précis et un traitement approprié.',
    [AnimalProfileHealthObservationsDtoType.MobilityIssues]:
      "Si votre chat boîte ou montre des difficultés à se lever, à sauter, ou à utiliser les escaliers, il peut souffrir de douleurs articulaires ou d'une blessure. Ces signes indiquent potentiellement un problème osseux ou articulaire. Consultez rapidement un vétérinaire pour évaluer son état et assurer que votre chat ne souffre pas inutilement.",
    [AnimalProfileHealthObservationsDtoType.InappropriateScratching]:
      "Pour prévenir les griffades des chats, placez des griffoirs visibles et accessibles dans votre maison, car les chats marquent leur territoire avec des griffes pour communiquer, pas seulement pour les aiguiser. Lorsque le problème survient, veillez à l'orienter vers un support approprié  et à le recompenser d’une caresse Si vous avez plusieurs chats, assurez-vous d'avoir un griffoir par chat plus un supplémentaire, car ils préfèrent ne pas partager. Si le problème persiste, envisagez de consulter un comportementaliste",
    [AnimalProfileHealthObservationsDtoType.NocturnalMeowingOrAgitation]:
      "Le chat est naturellement plus actif au crépuscule et à l'aube, correspondant à son instinct de chasseur nocturne. Si un chat est agité la nuit, cela peut indiquer du stress ou du malaise, souvent manifesté par des miaulements excessifs, grattage prolongé de la litière, alimentation nocturne ou léchage compulsif. Ces comportements sont des signaux d'alerte. Il est crucial de ne pas punir le chat mais de chercher les causes de son anxiété. Pour réduire son agitation nocturne, assurez-vous qu'il ait suffisamment d'activités pendant la journée et un environnement stimulant (des jeux).",
    [AnimalProfileHealthObservationsDtoType.InappropriateElimination]:
      "Votre chat marque hors de la litière, ce qui peut être causé par plusieurs facteurs. Assurez-vous que son bac est propre, éloigné de sa zone d'alimentation, et que la litière est fréquemment renouvelée. Si le problème persiste, cela peut indiquer un stress. Envisagez de consulter un comportementaliste ou un vétérinaire pour exclure des problèmes de santé.",
    [AnimalProfileHealthObservationsDtoType.FearfulBehavior]:
      "Si votre chat est soudainement craintif, il peut être stressé ou malade. Les chats peuvent être sensibles aux changements dans leur environnement, comme un déménagement, un nouvel animal de compagnie, ou des travaux de construction. Si votre chat est craintif, assurez-vous qu'il ait un endroit sûr pour se cacher et se reposer. Si le comportement persiste, consultez un vétérinaire pour exclure des problèmes de santé.",

    // Dog health observations
    [AnimalProfileHealthObservationsDtoType.ExcessiveObjectChewing]:
      "Surveillez le mâchonnement excessif de votre chien et proposez-lui des jouets à mâcher pour protéger vos objets domestiques. Si le comportement persiste, évaluez les jouets et l'environnement pour s'assurer qu'ils sont stimulants. En cas de mâchonnement continu, consultez un vétérinaire pour écarter des problèmes de santé, comme des soucis dentaires ou comportementaux.",
    [AnimalProfileHealthObservationsDtoType.ExcessiveScratching]:
      'Surveillez attentivement le grattage excessif de votre animal, car cela peut indiquer des problèmes sous-jacents tels que des allergies ou des parasites. Utilisez des traitements anti-parasitaires et si le grattage persiste, consultez un vétérinaire pour un diagnostic précis et un traitement approprié. Une évaluation vétérinaire est essentielle pour adresser efficacement ce comportement.',
    [AnimalProfileHealthObservationsDtoType.UnusualFrequentUrination]:
      "Notez les cas d'urination fréquente de votre animal dans des endroits inhabituels, ce qui peut indiquer un stress ou un problème médical. Augmentez les balades et minimisez le stress environnemental. Si l'urination inappropriée persiste, consultez un vétérinaire rapidement pour diagnostiquer et traiter tout problème sous-jacent.",
    [AnimalProfileHealthObservationsDtoType.AppetiteLoss]:
      "Observez si votre animal montre un désintérêt pour sa nourriture, ce qui peut indiquer un problème de santé. Variez son alimentation, assurez-vous que la nourriture est fraîche et servie à température ambiante, et proposez-lui des aliments attractifs pour stimuler son appétit. Si la perte d'appétit persiste, il est essentiel de consulter un vétérinaire, car cela peut signaler une condition médicale plus sérieuse nécessitant un diagnostic précis.",
    [AnimalProfileHealthObservationsDtoType.ExcessiveLicking]:
      "Surveillez les zones que votre animal lèche excessivement, ce comportement peut être dû à une irritation ou à de l'anxiété. Éliminez les irritants potentiels et offrez des distractions telles que des jouets ou davantage d'exercice pour diminuer son stress. Si le léchage excessif persiste, consultez un vétérinaire. Un léchage continu peut indiquer des problèmes de santé qui nécessitent un traitement pour soulager votre animal.",
    [AnimalProfileHealthObservationsDtoType.UnexplainedAggressiveness]:
      "Notez les déclencheurs potentiels d'agressivité chez votre animal, car ce comportement peut être causé par la peur, la douleur ou le stress. Évaluez et minimisez les sources de stress dans son environnement et travaillez à sa désensibilisation de manière sécurisée. Encouragez également les interactions positives. Si l'agressivité persiste, il est recommandé de consulter un vétérinaire ou un comportementaliste. Une agressivité continue peut nécessiter l'intervention d'un professionnel pour déterminer la cause et élaborer un plan de traitement approprié.",
    [AnimalProfileHealthObservationsDtoType.SuddenWeightChange]:
      "Surveillez de près tout changement soudain de poids chez votre animal, car cela peut indiquer des déséquilibres nutritionnels, des troubles métaboliques, ou d'autres problèmes de santé. Assurez-vous que son alimentation est appropriée, équilibrée et adaptée à son âge, poids, et niveau d'activité. Augmentez son exercice physique et veillez à ce que de l'eau fraîche et propre soit toujours disponible. Si le poids continue de fluctuer sans raison apparente, observez d'autres symptômes comme des changements d'appétit ou de comportement. Une variation de poids inexpliquée et persistante nécessite une consultation vétérinaire pour un diagnostic et un traitement appropriés.",
    [AnimalProfileHealthObservationsDtoType.VomitingDog]:
      "Surveillez la fréquence et les circonstances des vomissements de votre animal. S'ils sont occasionnels, cela peut être bénin, mais s'ils sont répétés, ils requièrent une attention particulière. Diminuez les portions alimentaires et privilégiez une nourriture facile à digérer. Veillez également à ce que votre animal reste bien hydraté. Si les vomissements persistent, une visite chez le vétérinaire est impérative. Des vomissements répétés peuvent signaler une affection sérieuse nécessitant un diagnostic et un traitement approprié.",
    [AnimalProfileHealthObservationsDtoType.Diarrhea]:
      "Surveillez la fréquence et la consistance des selles de votre animal, car une diarrhée occasionnelle peut survenir. En cas de persistance ou d'aggravation, optez pour une diète facile à digérer, comme du riz bouilli avec du poulet, et assurez-vous de maintenir une bonne hydratation. Évitez les changements brusques dans l'alimentation. Si la diarrhée persiste plus de 48 heures, il est crucial de consulter un vétérinaire, car une diarrhée prolongée peut être le signe de problèmes de santé plus sérieux nécessitant un diagnostic précis et un traitement approprié.",
    [AnimalProfileHealthObservationsDtoType.EscapeAttempts]:
      "Notez les moments et circonstances des tentatives d'évasion de votre animal pour identifier les déclencheurs et mieux comprendre son comportement. Renforcez la sécurité de son environnement et proposez davantage d'exercices et d'activités stimulantes pour limiter l'ennui. Considérez des options de socialisation sécurisée. Si les tentatives d'évasion persistent, l'intervention d'un comportementaliste animalier peut être nécessaire. Les évasions répétées peuvent indiquer un stress ou des besoins non satisfaits, et une consultation professionnelle est recommandée pour traiter le comportement de manière efficace.",
    [AnimalProfileHealthObservationsDtoType.SleepHabitsChange]:
      "Surveillez les changements dans les habitudes de sommeil de votre animal, car des modifications soudaines peuvent indiquer du stress, de l'ennui, ou des problèmes de santé. Pour favoriser un sommeil de qualité, assurez un environnement calme et confortable et maintenez une routine quotidienne régulière. Si les changements persistent, cela peut signaler une condition médicale ou de l'anxiété, nécessitant une consultation vétérinaire. Des modifications durables dans le comportement de sommeil sont un signe qu'il est essentiel de consulter un vétérinaire pour exclure des troubles sous-jacents et garantir le bien-être de votre animal.",
    [AnimalProfileHealthObservationsDtoType.ConstantBarking]:
      "Observez les déclencheurs des aboiements constants de votre chien pour identifier les causes sous-jacentes, comme l'ennui ou la peur. Pour atténuer ce comportement, proposez plus d'activités physiques et mentales et envisagez des jouets interactifs pour l'occuper. Maintenir un environnement calme et une routine stable peut également aider à réduire les aboiements. Si le comportement persiste malgré ces mesures, il pourrait être nécessaire d'entreprendre une formation comportementale. Si les aboiements continus ne s'améliorent pas, consultez un comportementaliste ou un vétérinaire pour déterminer les causes profondes et adapter le plan de traitement.",
    [AnimalProfileHealthObservationsDtoType.NoBarking]:
      "Si votre chien, qui est habituellement vocal, cesse soudainement d'aboyer, cela peut signaler un malaise ou une condition médicale. Il est important de s'assurer qu'il ne souffre pas physiquement. Si l'absence d'aboiements se prolonge, une consultation vétérinaire est cruciale pour écarter les problèmes de santé.",
    [AnimalProfileHealthObservationsDtoType.ConstantRestlessness]:
      "Observez les périodes d'agitation de votre animal et identifiez les déclencheurs possibles, tels que l'ennui, l'anxiété, ou un manque d'exercice. Augmentez son activité physique et offrez des jeux stimulants pour gérer son énergie. Assurez un environnement calme et des routines régulières. Si l'agitation persiste, envisagez un dressage professionnel ou des techniques de relaxation. En cas d'agitation continue, une consultation avec un vétérinaire ou un comportementaliste est recommandée pour identifier d'éventuels problèmes de santé ou troubles comportementaux.",
    [AnimalProfileHealthObservationsDtoType.Lethargy]:
      "Si votre animal semble manquer d'énergie ou est souvent fatigué, notez les moments de fatigue intense et les activités qui l'épuisent. Assurez-vous que son alimentation est nutritive et équilibrée, et veillez à ce qu'il bénéficie d'un sommeil de qualité et de périodes de repos calmes. Si la fatigue persiste sans raison apparente, envisagez d'ajuster sa routine, comme en réduisant les exercices intensifs. Un manque d'énergie chronique ou une fatigue persistante malgré un repos adéquat peuvent indiquer des problèmes de santé. Dans ce cas, une consultation vétérinaire est recommandée pour une évaluation complète et des conseils adaptés.",
    [AnimalProfileHealthObservationsDtoType.RefusalToEat]:
      "Surveillez attentivement le comportement alimentaire de votre animal. Un refus de manger occasionnel peut arriver, mais un suivi est nécessaire si cela persiste ou s'accompagne d'autres symptômes. Assurez-vous que la nourriture est fraîche et variez les aliments pour stimuler son appétit. Créez un environnement calme pour les repas. Si votre animal refuse de manger pendant plus de 24 heures, proposez des aliments appétissants et faciles à digérer, comme du poulet bouilli ou du riz. Un refus persistant de manger peut signaler un problème de santé sérieux, nécessitant une consultation vétérinaire pour un diagnostic et un traitement appropriés.",
    [AnimalProfileHealthObservationsDtoType.HydrationRefusal]:
      "Observez la consommation d'eau de votre animal et soyez attentif à tout manque d'intérêt soudain pour l'hydratation. Offrez de l'eau fraîche à plusieurs endroits accessibles et envisagez d'ajouter du bouillon sans oignon ni ail à sa nourriture pour l'encourager à s'hydrater. Des fontaines à eau peuvent aussi inciter certains animaux à boire plus. Si le refus persiste, essayez des glaçons aromatisés ou donnez de l'eau à la main par petites quantités fréquentes. Un manque prolongé d'hydratation ou un refus de boire est grave et peut signaler des problèmes de santé comme des troubles rénaux. Consultez un vétérinaire immédiatement si aucune amélioration n'est constatée.",
    [AnimalProfileHealthObservationsDtoType.BadBreath]:
      "La mauvaise haleine chez les chiens peut être causée par des problèmes dentaires, des infections, ou des troubles digestifs. Si l'halitose persiste malgré un brossage régulier des dents, consultez un vétérinaire pour un examen dentaire approfondi et un traitement approprié.",
    [AnimalProfileHealthObservationsDtoType.IncreasedTerritorialMarking]:
      "Les marquages territoriaux excessifs peuvent être causés par des changements dans l'environnement, des problèmes de santé, ou des troubles comportementaux. Pour réduire ce comportement, assurez-vous que votre animal se sent en sécurité et proposez-lui des activités stimulantes. Si le marquage persiste, consultez un vétérinaire pour évaluer la santé de votre animal et envisager des solutions comportementales.",
    [AnimalProfileHealthObservationsDtoType.Itching]:
      'Les démangeaisons excessives peuvent être causées par des allergies, des parasites, ou des problèmes de peau. Surveillez les zones que votre animal gratte fréquemment et consultez un vétérinaire pour un diagnostic précis et un traitement approprié. Les démangeaisons prolongées peuvent entraîner des lésions cutanées et des infections, il est donc important de les traiter rapidement.',
    [AnimalProfileHealthObservationsDtoType.SelfMutilation]:
      "Les comportements d'automutilation peuvent être causés par des troubles comportementaux, des allergies, ou des problèmes de peau. Surveillez les zones que votre animal mord, lèche, ou mâche excessivement et consultez un vétérinaire pour un diagnostic précis et un traitement approprié. L'automutilation peut entraîner des lésions cutanées et des infections, il est donc important de les traiter rapidement.",
    [AnimalProfileHealthObservationsDtoType.WalkingDifficulty]:
      'Les difficultés à marcher peuvent être causées par des douleurs articulaires, des blessures, ou des troubles neurologiques. Surveillez les signes de boiterie, de raideur, ou de difficulté à se lever et consultez un vétérinaire pour un examen approfondi et un traitement approprié. Les problèmes de mobilité peuvent affecter la qualité de vie de votre animal, il est donc important de les traiter rapidement.',
    [AnimalProfileHealthObservationsDtoType.PawPadsIssues]:
      'Les problèmes de coussinets peuvent être causés par des coupures, des brûlures, des infections, ou des allergies. Surveillez les signes de boiterie, de léchage excessif, ou de rougeur et consultez un vétérinaire pour un diagnostic précis et un traitement approprié. Les problèmes de coussinets peuvent entraîner des douleurs et des infections, il est donc important de les traiter rapidement.',
    [AnimalProfileHealthObservationsDtoType.SkinProblems]:
      'Les problèmes de peau peuvent être causés par des allergies, des infections, des parasites, ou des troubles dermatologiques. Surveillez les signes de rougeur, de démangeaisons, de lésions cutanées, ou de perte de poils et consultez un vétérinaire pour un diagnostic précis et un traitement approprié. Les problèmes de peau peuvent entraîner des infections et des complications, il est donc important de les traiter rapidement.',
    [AnimalProfileHealthObservationsDtoType.RedEyes]:
      'Les yeux rouges peuvent être causés par des infections, des allergies, des irritations, ou des troubles oculaires. Surveillez les signes de rougeur, de larmoiement, ou de clignements excessifs et consultez un vétérinaire pour un diagnostic précis et un traitement approprié. Les yeux rouges peuvent entraîner des douleurs et des complications, il est donc important de les traiter rapidement.',
    [AnimalProfileHealthObservationsDtoType.Drooling]:
      'La salivation excessive peut être causée par des problèmes dentaires, des infections, des troubles digestifs, ou des troubles neurologiques. Surveillez les signes de bave excessive, de mauvaise haleine, ou de difficulté à manger et consultez un vétérinaire pour un diagnostic précis et un traitement approprié. La salivation excessive peut entraîner des infections et des complications, il est donc important de les traiter rapidement.',
    [AnimalProfileHealthObservationsDtoType.SkinIrritation]:
      'Les irritations cutanées peuvent être causées par des allergies, des infections, des parasites, ou des troubles dermatologiques. Surveillez les signes de rougeur, de démangeaisons, ou de lésions cutanées et consultez un vétérinaire pour un diagnostic précis et un traitement approprié. Les irritations cutanées peuvent entraîner des infections et des complications, il est donc important de les traiter rapidement.',
  }

  return map[issue]
}
