import { Box, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import {
  AdoptionAttemptDocumentDtoType,
  AdoptionAttemptDto,
  AnimalDto,
  AnimalProfileDto,
} from '../../interactors/gen/backendClient'
import { DocumentItem } from './DocumentItem'
import { lightBlue } from '../theme'
import { BrandItem } from '../Shopping/BrandItem'

interface Props {
  attempt: AdoptionAttemptDto
  animal: AnimalDto | AnimalProfileDto
}

export const DocumentsTab: React.FC<Props> = ({ attempt, animal }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1 }}>
        {attempt.certificate && attempt.certificate.signed && attempt.certificate.signed !== 'NOT_PROVIDED.pdf' && (
          <DocumentItem
            documentKey={attempt.certificate.signed}
            type="certificate"
            uploadDate={attempt.certificate.signedDate}
          />
        )}
        {attempt.contract && attempt.contract.signed && attempt.contract.signed !== 'NOT_PROVIDED.pdf' && (
          <DocumentItem
            documentKey={attempt.contract.signed}
            type="contract"
            uploadDate={attempt.contract.signedDate}
          />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 2, my: 2 }}>
          <Card sx={{ backgroundColor: lightBlue }}>
            <CardContent>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: '14px', lineHeight: '19.2px', fontWeight: 400 }}
              >
                {`Le certificat d’engagement rappelle qu’un budget annuel de 50 à 200 € est nécessaire pour assurer les soins courants d’un animal en bonne santé.`}
                <br />
                {`Avec Kozoo, estimez gratuitement le coût d’une assurance personnalisable pour couvrir la santé de ${animal.name}`}
              </Typography>
            </CardContent>
          </Card>
          <BrandItem
            animalProfile={animal}
            title="Assurance Kozoo"
            description={`Remboursement de 50% à 100%\n
        Plafond de 1000€ à 3000€`}
            logoUrl="https://www.kozoo.eu/wp-content/themes/kozoo/assets/images/kozoo-assurance-chien-chat-digitale.svg"
            imgSrc="https://animals-photos.s3.amazonaws.com/2023-04-05+(1).jpg"
            brandName="kozoo"
            catchPhrase="Dès 5,44€/mois"
            redirectUrl="https://bit.ly/4a6FFAJ"
          />
        </Box>
        {attempt.documents &&
          attempt.documents.length > 0 &&
          attempt.documents
            .filter(
              (doc) =>
                doc.type !== AdoptionAttemptDocumentDtoType.AdoptionContract &&
                doc.type !== AdoptionAttemptDocumentDtoType.KnowledgeCertificate
            )
            .map((document) => (
              <DocumentItem
                key={document.id}
                documentKey={document.documentKey}
                type={document.type}
                uploadDate={document.uploadedAt}
              />
            ))}
      </Box>
    </>
  )
}
