import { getAnimalProfilePhotoSrc, getAnimalProfileProfilePhotoSrc } from './S3-links'

export const findGoodAnimalProfileURL = (profilImageKey: string | undefined) => {
  if (!profilImageKey) {
    return ''
  }
  const path = getAnimalProfilePhotoSrc(profilImageKey)
  const fallBackPath = getAnimalProfileProfilePhotoSrc(profilImageKey)

  return profilImageKey.startsWith('P') ? path : fallBackPath
}
