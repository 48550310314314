import { Assignment } from '@mui/icons-material'
import { Box, Button, Chip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { getAdoptionAttemptsDocumentsSrc } from '../../utils/S3-links'
import { colors, lightBlue, theme } from '../theme'
import { AdoptionAttemptDocumentDtoType } from '../../interactors/gen/backendClient'

type DocumentType = AdoptionAttemptDocumentDtoType | 'certificate' | 'contract'

type Props = {
  documentKey: string
  type: DocumentType
  uploadDate?: string
}

export const DocumentItem: React.FC<Props> = ({ documentKey, type, uploadDate }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: '8px',
        px: '16px',
        borderRadius: '5px',
        gap: '10px',
        border: '1px solid',
        borderColor: colors.lightGrey,
        backgroundColor: colors.lightGrey,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
          color: colors.black,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            color: colors.black,
          }}
        >
          <Assignment sx={{ color: theme.palette.secondary.main }} />
          <Chip sx={{ backgroundColor: lightBlue }} label={translateDocumentType(type)} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {uploadDate && (
          <Typography sx={{ fontStyle: 'italic', fontSize: '14px' }}>{`${dayjs(uploadDate).format(
            'DD/MM/YY'
          )}`}</Typography>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          gap: '16px',
          color: colors.black,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          component="a"
          href={getAdoptionAttemptsDocumentsSrc(documentKey)}
          target="_blank"
        >
          Consulter
        </Button>
      </Box>
    </Box>
  )
}

const translateDocumentType = (type: DocumentType) => {
  switch (type) {
    case 'certificate':
      return 'Certificat signé'
    case 'contract':
      return "Contrat d'adoption signé"
    case 'adoption-contract':
      return "Contrat d'adoption"
    case 'health-book':
      return 'Livret de santé'
    case 'information-document':
      return 'Document d’information'
    case 'knowledge-certificate':
      return 'Certificat de connaissance'
    case 'sell-certificate':
      return 'Certificat de cession'
    case 'veterinary-certificate':
      return 'Certificat vétérinaire'
    case 'others':
      return 'Autre'
  }
}
