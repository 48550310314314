import { Lock, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material'
import React, { FormEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { LoadingButton } from '@mui/lab'
import { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { useMutation } from 'react-query'
import { AuthClient } from '../../interactors/clients/AuthClient'
import { AuthService } from '../../interactors/services/AuthService'
import { useAccountsStore } from '../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useProgressiveImage } from '../../utils/hooks/useProgressiveImage'
import placeholder from '../Login/login-bg-compressed.png'
import Background from '../Login/login-bg.png'
import { PATHS } from '../PATHS'
import { blue } from '../theme'

interface ErrorResponse {
  message: string
}

export const CreatePasswordPage: React.FC = () => {
  const accountsStore = useAccountsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [serverError, setServerError] = useState('')
  const [isPasswordField, setIsPasswordField] = useState(true)

  const createPasswordMutation = useMutation<string, AxiosError<ErrorResponse>, { token: string; newPassword: string }>(
    async (params: { token: string; newPassword: string }) => {
      return await AuthClient.createPassword(params.token, params.newPassword)
    },
    {
      onSuccess: (email) => {
        globalSnackbarStore.triggerSuccessMessage(`Votre accès a bien été créé !`)
        AuthService.loginWithPassword(email.trim(), password.trim())
          .then(() => {
            if (AuthService.account) {
              accountsStore.changeConnectedAccount(AuthService.account)
              navigate('/')
            }
          })
          .catch((error) => {
            console.error({ error })
            if (error.response.status === StatusCodes.UNAUTHORIZED) {
              setServerError('Mot de passe incorrect.')
            } else {
              setServerError('Une erreur est survenue.')
            }
          })
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        console.error({ error })
        setServerError(
          `Une erreur est survenue lors de la création de votre mot de passe : ${
            error.response?.data?.message ?? 'An unknown error occurred'
          }`
        )
      },
    }
  )

  const handleLogin = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setServerError('')
    if (password !== passwordConfirm) {
      return setServerError('Les mots de passe ne correspondent pas.')
    }
    return createPasswordMutation.mutate({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      token: new URLSearchParams(window.location.search).get('token')!,
      newPassword: password,
    })
  }

  const loadedBackground = useProgressiveImage(Background)

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        '@media orientation: landscape': {
          overflow: 'auto',
        },
      }}
    >
      <Box sx={{ height: { md: 0, sm: 100, xs: 100 } }}></Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: { md: '100%', xs: '50%' },
          width: { md: '50%', xs: '100%' },
          mt: { md: '40vh', xs: 0 },
        }}
      >
        <Box flexGrow="1" margin="auto" padding="0px 2%" boxSizing="border-box" paddingLeft={{ lg: 10 }}>
          <div>
            <Box
              sx={{
                margin: 'auto',
                '@media orientation: portrait': {
                  maxWidth: { md: '600px', sm: '80vh' },
                },
                '@media orientation: landscape': {
                  maxWidth: { md: '600px', sm: '80vw' },
                },
                maxWidth: { md: '600px' },
                color: 'white',
                marginTop: { md: 0, xs: 5 },
                width: { md: '100%', sm: '600px' },
              }}
            >
              <Typography
                variant="h1"
                sx={{ color: blue, fontFamily: 'Insaniburger', fontSize: { xs: '40px', sm: '55px' } }}
              >
                Bienvenue sur Petso !
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: blue, fontWeight: 600, fontSize: { xs: '1rem', sm: '1.4rem' }, marginBottom: 2 }}
              >
                {"Créez votre mot de passe pour accéder à votre parcours d'adoption."}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: { md: '100%', xs: '600px' }, maxWidth: { md: '400px', xs: '95vw' } }}>
                <form onSubmit={handleLogin}>
                  <div>
                    <Box marginTop="0.5rem">
                      <TextField
                        fullWidth
                        type={isPasswordField ? 'password' : 'text'}
                        label="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <div style={{ marginRight: '8px' }}>
                              <Lock />
                            </div>
                          ),
                          endAdornment: (
                            <IconButton
                              color="default"
                              size="small"
                              edge="end"
                              onClick={() => setIsPasswordField(!isPasswordField)}
                            >
                              {isPasswordField ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                  </div>

                  <div>
                    <Box marginTop="1.5rem">
                      <TextField
                        fullWidth
                        type={isPasswordField ? 'password' : 'text'}
                        label="Confirmation du mot de passe"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <div style={{ marginRight: '8px' }}>
                              <Lock />
                            </div>
                          ),
                          endAdornment: (
                            <IconButton
                              color="default"
                              size="small"
                              edge="end"
                              onClick={() => setIsPasswordField(!isPasswordField)}
                            >
                              {isPasswordField ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                  </div>

                  <div>
                    {serverError && (
                      <Grid item>
                        <Typography color="error">{serverError}</Typography>
                      </Grid>
                    )}
                    <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
                      <Box position="relative" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography>
                          J&#39;ai déjà créé mon mot de passe: <Link to={PATHS.login.absolute}>Me connecter</Link>
                        </Typography>
                        <LoadingButton
                          variant="contained"
                          id="login-button"
                          type="submit"
                          loading={createPasswordMutation.isLoading}
                          sx={{ py: 2, px: 4 }}
                        >
                          Créer mon compte
                        </LoadingButton>
                      </Box>
                    </Box>
                  </div>
                </form>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          zIndex: 1,
          pointerEvents: 'none',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: { md: '100vh', xs: '100%' },
          width: { md: '50%', xs: '100%' },
          backgroundImage: `url(${loadedBackground || placeholder})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: { md: 'left', xs: 'top' },
        }}
      ></Box>
    </Box>
  )
}
