import {
  FormControl,
  FormHelperText,
  SxProps,
  Theme,
  InputLabel,
  MenuItem,
  Select,
  BaseTextFieldProps,
} from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'

interface Option {
  label: string
  value: string | number
}

type Props<T extends FieldValues> = {
  sx?: SxProps<Theme>
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Array<Option>
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  fullWidth?: boolean
  onKeyUp?: () => void
}

export function ControlledSelectField<T extends FieldValues>({
  sx,
  control,
  options,
  error,
  fieldName,
  label,
  requiredRule,
  size,
  disabled,
  onKeyUp,
  fullWidth = false,
}: PropsWithChildren<Props<T>>) {
  const labelId = `${fieldName}_label`

  return (
    <FormControl fullWidth={fullWidth} variant="outlined" sx={{ width: '100%', ...sx }}>
      <InputLabel htmlFor={labelId} error={!!error} size={size === 'small' ? 'small' : 'normal'}>
        {label}
      </InputLabel>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={'' as any} // TODO: check this
        rules={{ required: requiredRule }}
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            onChange={onChange}
            onKeyUp={onKeyUp}
            label={label}
            labelId={labelId}
            error={!!error}
            required={Boolean(requiredRule)}
            size={size}
            style={{ height: '48px' }}
            disabled={disabled}
          >
            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
    </FormControl>
  )
}
