import { AxiosRequestConfig } from 'axios'
import { ExternalVeterinaryType, VeterinariesForCareExportType } from '../../domain/Veterinaries/ExternalVeterinaryType'
import { AccountDto } from '../gen/backendClient'
import { backendClient } from './client'

export class VeterinaryClient {
  public static async getVeterinaryById(veterinaryId: string): Promise<AccountDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/veterinaries/' + veterinaryId,
    }

    const response = await backendClient<AccountDto>(config)

    return response.data
  }

  public static async searchForVeterinaryByFirstName(firstName: string): Promise<ExternalVeterinaryType[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/veterinaries/external/firstName/' + firstName,
    }

    const response = await backendClient<ExternalVeterinaryType[]>(config)

    return response.data
  }

  public static async searchForVeterinaryByLastName(lastName: string): Promise<ExternalVeterinaryType[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/veterinaries/external/lastName/' + lastName,
    }

    const response = await backendClient<ExternalVeterinaryType[]>(config)

    return response.data
  }

  public static async getVeterinaryByOrdinalNumber(ordinalNumber: string): Promise<VeterinariesForCareExportType> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/veterinaries/external/ordinalNumber/' + ordinalNumber,
    }

    const response = await backendClient<VeterinariesForCareExportType>(config)

    return response.data
  }
}
