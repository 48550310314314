import { Box, Button, SxProps, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as InfoIcon } from '../icons/InfoIcon.svg'
import { ReactComponent as SuccessIcon } from '../icons/SuccessIcon.svg'
import { ReactComponent as ErrorIcon } from '../icons/ErrorIcon.svg'
import { colors, lightBlue, lightGreen, lightRed, theme } from '../theme'

interface Props {
  message: string
  type?: 'info' | 'success' | 'error'
  sx?: SxProps
  buttonText?: string
  action?: () => void
}

export const AdoptionInfoBox: React.FC<Props> = ({ message, type = 'info', sx, buttonText, action }) => {
  const getColorForType = (type: Props['type']) => {
    switch (type) {
      case 'info':
        return lightBlue
      case 'success':
        return lightGreen
      case 'error':
        return lightRed
    }
  }
  const getTextColorForType = (type: Props['type']) => {
    switch (type) {
      case 'info':
        return theme.palette.secondary.main
      case 'success':
        return theme.palette.success.main
      case 'error':
        return '#696b6d'
    }
  }
  const getIconForType = (type: Props['type']) => {
    switch (type) {
      case 'info':
        return <InfoIcon />
      case 'success':
        return <SuccessIcon />
      case 'error':
        return <ErrorIcon fill={colors.punch} />
    }
  }
  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        alignItems: 'center',
        gap: '12px',
        borderRadius: '8px',
        backgroundColor: getColorForType(type),
        p: '16px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        {getIconForType(type)}
        <Typography
          variant="body1"
          component="p"
          sx={{
            fontSize: '14px',
            lineHeight: '16.8px',
            color: getTextColorForType(type),
            fontWeight: 600,
          }}
        >
          {message}
        </Typography>
      </Box>
      {buttonText && action && (
        <Button variant="outlined" color="inherit" sx={{ textTransform: 'none' }} onClick={action}>
          {buttonText}
        </Button>
      )}
    </Box>
  )
}
