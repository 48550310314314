import { Box, Button, Grow, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { UseMutationResult } from 'react-query'
import {
  getQuestionsForBehavior,
  getReadableCatBehaviorQuestions,
  getReadableOftenQuestions,
} from '../../../domain/AnimalProfileDisplay/HealthQuestionsDisplay'
import {
  AnimalProfileDto,
  BehaviorQuestionsDto,
  BehaviorQuestionsDtoCatSocialization,
  BehaviorQuestionsDtoInappropriateElimination,
} from '../../../interactors/gen/backendClient'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { ControlledMultipleToggleButton } from '../../common/ControlledMutipleToggleButton'
import { Field } from '../../common/FormField'

interface Props {
  animalProfile: AnimalProfileDto
  editMutation: UseMutationResult<AnimalProfileDto, unknown, AnimalProfileDto, unknown>
}

export const EditBehaviorQuestions: React.FC<Props> = ({ animalProfile, editMutation }) => {
  const mostRecentBehaviorQuestions = animalProfile.health.score?.behaviorQuestions?.sort((a, b) =>
    dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
  )[0]
  if (!mostRecentBehaviorQuestions) return null

  const [selectedQuestion, setSelectedQuestion] = React.useState<string | null>(null)

  const getDefaultValues = (behaviorQuestions: BehaviorQuestionsDto) => {
    return {
      ...behaviorQuestions,
      date: dayjs().toISOString(),
    }
  }

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormExtended(mostRecentBehaviorQuestions, getDefaultValues)

  useEffect(() => {
    const scrollDown = () => {
      const chatContainer = document.querySelector('.scrollable-content') as HTMLElement
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
    if (selectedQuestion === null && isDirty) scrollDown()
  }, [selectedQuestion])

  const onSubmit = (data: BehaviorQuestionsDto) => {
    const behaviorQuestionsArray = animalProfile.health.score?.behaviorQuestions || []
    const inputDataDate = dayjs(data.date).format('YYYY-MM-DD') // Normalize input date to 'YYYY-MM-DD'

    // Find index based on date comparison (ignoring time)
    const index = behaviorQuestionsArray.findIndex(
      (question) => dayjs(question.date).format('YYYY-MM-DD') === inputDataDate
    )

    if (index !== -1) {
      // If found, replace the existing entry
      behaviorQuestionsArray[index] = data
    } else {
      // If not found, push the new data
      behaviorQuestionsArray.push(data)
    }

    const newAnimalProfile: AnimalProfileDto = {
      ...animalProfile,
      health: {
        ...animalProfile.health,
        score: {
          ...animalProfile.health.score,
          healthScore: animalProfile.health.score?.healthScore || 0,
          behaviorQuestions: behaviorQuestionsArray,
        },
      },
    }
    editMutation.mutate(newAnimalProfile) // Assuming editMutation is a mutation hook from react-query
  }

  const displaySelectedQuestions = () => {
    switch (selectedQuestion) {
      case 'inappropriateElimination':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForBehavior('inappropriateElimination')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.inappropriateElimination}
                  fieldName="inappropriateElimination"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'nocturnalVocalization':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForBehavior('nocturnalVocalization')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.nocturnalVocalization}
                  fieldName="nocturnalVocalization"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'fearfulBehavior':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForBehavior('fearfulBehavior')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.fearfulBehavior}
                  fieldName="fearfulBehavior"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'humanInteraction':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForBehavior('humanInteraction')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.humanInteraction}
                  fieldName="humanInteraction"
                  options={Object.values(BehaviorQuestionsDtoCatSocialization).map((value) => ({
                    label: getReadableCatBehaviorQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'catSocialization':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForBehavior('catSocialization')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.catSocialization}
                  fieldName="catSocialization"
                  options={Object.values(BehaviorQuestionsDtoCatSocialization).map((value) => ({
                    label: getReadableCatBehaviorQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'otherAnimalsSocialization':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForBehavior('otherAnimalsSocialization')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.otherAnimalsSocialization}
                  fieldName="otherAnimalsSocialization"
                  options={Object.values(BehaviorQuestionsDtoCatSocialization).map((value) => ({
                    label: getReadableCatBehaviorQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'playBehavior':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForBehavior('playBehavior')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.playBehavior}
                  fieldName="playBehavior"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'inappropriateScratching':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForBehavior('inappropriateScratching')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.inappropriateScratching}
                  fieldName="inappropriateScratching"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      default:
        return null
    }
  }

  if (selectedQuestion) return displaySelectedQuestions()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('inappropriateElimination')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForBehavior('inappropriateElimination')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableOftenQuestions(watch('inappropriateElimination'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('nocturnalVocalization')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForBehavior('nocturnalVocalization')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableOftenQuestions(watch('nocturnalVocalization'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('fearfulBehavior')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForBehavior('fearfulBehavior')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableOftenQuestions(watch('fearfulBehavior'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('humanInteraction')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForBehavior('humanInteraction')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableCatBehaviorQuestions(watch('humanInteraction'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('catSocialization')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForBehavior('catSocialization')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableCatBehaviorQuestions(watch('catSocialization'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('otherAnimalsSocialization')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForBehavior('otherAnimalsSocialization')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableCatBehaviorQuestions(watch('otherAnimalsSocialization'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('playBehavior')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForBehavior('playBehavior')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableOftenQuestions(watch('playBehavior'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('inappropriateScratching')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForBehavior('inappropriateScratching')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableOftenQuestions(watch('inappropriateScratching'))}
        </Typography>
      </Box>
      {isDirty && (
        <Button variant="contained" onClick={handleSubmit(onSubmit)} fullWidth>
          Enregistrer mes modifications
        </Button>
      )}
    </Box>
  )
}
