import { Box, Chip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AnimalDto, AnimalProfileDto } from '../../../interactors/gen/backendClient'
import { AccessoriesTab } from '../../Adoptions/AccessoriesTab'
import { lightBlue, theme } from '../../theme'
import { AccessoriesCategory } from './AccessoriesCategory'
import { FoodCategory } from './FoodCategory'
import { WellbeingCategory } from './WellbeingCategory'

export enum Categories {
  Food = 'food',
  Wellbeing = 'wellbeing',
  Accessories = 'accessories',
  Fidelami = 'fidelami',
}

interface Props {
  animalProfile: AnimalProfileDto | AnimalDto
  adoptionMode?: boolean
}

export const ShoppingCategory: React.FC<Props> = ({ animalProfile, adoptionMode }) => {
  const [selectedCategory, setSelectedCategory] = React.useState<Categories>(
    animalProfile.species === 'cat' || animalProfile.species === 'dog' ? Categories.Fidelami : Categories.Wellbeing
  )

  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search)
  const handleCategoryChange = (category: Categories) => {
    setSelectedCategory(category)
    navigate(
      `${location.pathname}?${
        adoptionMode
          ? `shopping=true&shoppingCategory=${category}`
          : `tab=shopping&category=recommandations&shoppingCategory=${category}`
      }`
    )
  }

  useEffect(() => {
    const tab = queryParams.get('shoppingCategory') as Categories
    if (tab) {
      setSelectedCategory(tab)
    } else {
      setSelectedCategory(
        animalProfile.species === 'cat' || animalProfile.species === 'dog' ? Categories.Fidelami : Categories.Wellbeing
      )
    }
  }, [queryParams])

  const renderCategory = () => {
    switch (selectedCategory) {
      case Categories.Food:
        return <FoodCategory animalProfile={animalProfile} />
      case Categories.Wellbeing:
        return <WellbeingCategory animalProfile={animalProfile} />
      case Categories.Accessories:
        return <AccessoriesCategory animalProfile={animalProfile} />
      case Categories.Fidelami:
        return <AccessoriesTab animalProfile={animalProfile} alreadyAdoptedAnimal />
      default:
        return <Box />
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 3, mx: { xs: 0, md: 0 } }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          overflowX: 'auto',
          mx: { xs: -2, md: 0 },
          px: { xs: 1, md: 0 },
        }}
      >
        {Object.values(Categories)
          .filter((c) => {
            if (animalProfile.species !== 'cat') {
              if (animalProfile.species === 'dog') {
                return c !== Categories.Food
              }
              return c !== Categories.Food && c !== Categories.Fidelami
            }
            return true
          })

          .map((category) => (
            <Chip
              key={category}
              label={translateCategories(category)}
              clickable
              onClick={() => handleCategoryChange(category)}
              sx={{
                backgroundColor: selectedCategory === category ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: selectedCategory === category ? 'white' : theme.palette.secondary.main,
                fontSize: '14px',
                px: '12px',
                py: '20px',
                borderRadius: '20px',
                fontWeight: 'bold',
                '&:focus': {
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                },
              }}
            />
          ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: lightBlue,
          borderRadius: '12px',
          p: 1.5,
          gap: '8px',
          width: '100%',
          border: `1px solid ${theme.palette.secondary.main}`,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Typography
            sx={{ fontSize: '15px', fontWeight: 600 }}
          >{`10% des revenus Petso sont alloués pour soutenir les refuges et associations de protection animale.`}</Typography>
        </Box>
      </Box>
      {renderCategory()}
    </Box>
  )
}

function translateCategories(category: Categories): string {
  switch (category) {
    case Categories.Accessories:
      return 'Accessoires'
    case Categories.Food:
      return 'Alimentation'
    case Categories.Wellbeing:
      return 'Bien-être'
    case Categories.Fidelami:
      return 'Les kits par Fidelami'
    default:
      return ''
  }
}
