import { LocalPharmacy, Pets } from '@mui/icons-material'
import { Typography, Button, Box } from '@mui/material'
import dayjs from 'dayjs'
import {
  AnimalProfileDto,
  AnimalProfileHealthPestControlDto,
  BehaviorQuestionsDto,
  EnvironmentQuestionsDto,
  FeedingQuestionsDto,
} from '../../../interactors/gen/backendClient'
import { Categories } from './AnimalHealthTab'

export type Goal = {
  title: string
  subtitle: string
  description: React.ReactNode
  icon: React.ReactNode
  achieved: boolean
}

export function getGoals(animal: AnimalProfileDto, setSelectedCategory: (category: Categories) => void): Goal[] {
  let goals: Goal[] = []

  goals.push(getDewormingGoal(animal.health.deworming))
  goals.push(getFleaControlGoal(animal.health.fleaControl))
  goals = goals.concat(
    getEnvironmentGoals(
      animal.health.score?.environmentQuestions?.sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1))[0]
    )
  )
  goals = goals.concat(
    getBehaviorGoals(
      animal.health.score?.behaviorQuestions?.sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1))[0]
    )
  )
  goals = goals.concat(
    getFeedingGoals(
      animal.health.score?.feedingQuestions?.sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1))[0]
    )
  )

  return goals

  function getDewormingGoal(deworming: AnimalProfileHealthPestControlDto | undefined): Goal {
    const lastDewormingDate = deworming?.dates.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? 1 : -1))[0]
    if (!lastDewormingDate) {
      return {
        title: 'Vermifuge',
        subtitle: 'Objectifs non atteints',
        description: (
          <>
            <Typography variant="body2">
              {`Aucune info n'a été recueillie concernant l'environnement de votre animal`}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={() => setSelectedCategory(Categories.Deworming)}
            >
              Remplir la date du dernier vermifuge
            </Button>
          </>
        ),
        icon: <LocalPharmacy sx={{ color: 'lightSalmon' }} />,
        achieved: false,
      }
    }
    if (lastDewormingDate && dayjs(lastDewormingDate).add(deworming.frequency, 'weeks').isAfter(dayjs())) {
      return {
        title: 'Vermifuge',
        subtitle: 'Super',
        description: (
          <>
            <Typography variant="body2">{`Le vermifuge de votre chat est à jour ! Cela aide à le protéger contre les parasites internes et contribue à maintenir son bien-être général.`}</Typography>
          </>
        ),
        icon: <LocalPharmacy sx={{ color: 'lightGreen' }} />,
        achieved: true,
      }
    }

    return {
      title: 'Vermifuge',
      subtitle: 'Objectifs non atteints',
      description: (
        <>
          <Typography variant="body2">
            {`Le rappel du vermifuge était à faire avant le ${dayjs(lastDewormingDate).format(
              'DD/MM/YYYY'
            )} ! Faites un rappel dès que possible pour protéger sa santé contre les parasites internes. Cela est crucial pour son bien-être.`}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => setSelectedCategory(Categories.Deworming)}
          >
            Mettre à jour la date du dernier vermifuge
          </Button>
        </>
      ),
      icon: <LocalPharmacy sx={{ color: 'lightSalmon' }} />,
      achieved: false,
    }
  }

  function getFleaControlGoal(deworming: AnimalProfileHealthPestControlDto | undefined): Goal {
    const lastDewormingDate = deworming?.dates.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? 1 : -1))[0]
    if (!lastDewormingDate) {
      return {
        title: 'Insectifuge',
        subtitle: 'Objectifs non atteints',
        description: (
          <>
            <Typography variant="body2">
              {`Aucune info n'a été recueillie concernant l'insectifuge de votre animal`}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={() => setSelectedCategory(Categories.FleaControl)}
            >
              Remplir la date du dernier insectifuge
            </Button>
          </>
        ),
        icon: <LocalPharmacy sx={{ color: 'lightSalmon' }} />,
        achieved: false,
      }
    }
    if (lastDewormingDate && dayjs(lastDewormingDate).add(deworming.frequency, 'weeks').isAfter(dayjs())) {
      return {
        title: 'Insectifuge',
        subtitle: 'Super',
        description: (
          <>
            <Typography variant="body2">{`Le insectifuge de votre chat est à jour ! Cela aide à le protéger contre les parasites externes (puces & tiques) et contribue à maintenir son bien-être général.`}</Typography>
          </>
        ),
        icon: <LocalPharmacy sx={{ color: 'lightGreen' }} />,
        achieved: true,
      }
    }

    return {
      title: 'Insectifuge',
      subtitle: 'Objectifs non atteints',
      description: (
        <>
          <Typography variant="body2">
            {`Vous êtes en retard sur le dernier insectifuge qui devait être fait avant le ${dayjs(
              lastDewormingDate
            ).format('DD/MM/YYYY')}`}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
            onClick={() => setSelectedCategory(Categories.FleaControl)}
          >
            Mettre à jour la date du dernier insectifuge
          </Button>
        </>
      ),
      icon: <LocalPharmacy sx={{ color: 'lightSalmon' }} />,
      achieved: false,
    }
  }

  function getEnvironmentGoals(environment: EnvironmentQuestionsDto | undefined): Goal[] {
    const goals: Goal[] = []
    const achieved = []
    if (!environment) {
      goals.push({
        title: 'Environnement',
        subtitle: 'Objectifs non atteints',
        description: (
          <>
            <Typography variant="body2">
              {`Aucune info n'a été recueillie concernant l'environnement de votre animal`}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={() => setSelectedCategory(Categories.Environment)}
            >
              Remplir le questionnaire environnement
            </Button>
          </>
        ),
        icon: <Pets sx={{ color: 'lightSalmon' }} />,
        achieved: false,
      })
      return goals
    }
    switch (environment.hasAccessToSecuredWindows) {
      case 'yes':
        achieved.push(
          `Formidable ! Votre chat bénéficie déjà d'une vue extérieure. Cela stimule sa curiosité et réduit son stress, améliorant son bien-être mental.`
        )
        break
      case 'no':
        goals.push({
          title: 'Vue vers l’extérieur',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Offrez à votre chat une vue vers l'extérieur. Permettre à votre chat d'observer l'extérieur peut enrichir son environnement et diminuer son ennui.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Environment)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
    }

    switch (environment.hasAnAdaptedHidingPlace) {
      case 'yes':
        achieved.push(
          `Magnifique ! Votre chat a sa propre cachette, un signe de sécurité et de confort. C'est crucial pour son sentiment de sécurité.`
        )
        break
      case 'no':
        goals.push({
          title: 'Zone de repos',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Créez une cachette pour votre chat. Cela lui offre un refuge, essentiel pour son bien-être et son sentiment de sécurité.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Environment)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
    }

    switch (environment.hasPerches) {
      case 'yes':
        achieved.push(
          `Super ! Votre chat profite d'une zone de perchoir, lui permettant de surveiller son territoire. Cela nourrit son instinct naturel d'observation.`
        )
        break
      case 'no':
        goals.push({
          title: "Zone d'exploration",
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Mettez en place une zone de perchoir pour votre chat. Avoir un point de vue en hauteur renforce leur sécurité et leur confiance.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Environment)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
    }

    switch (environment.hasToys) {
      case 'yes':
        achieved.push(
          `Bravo ! Votre chat a des jouets, enrichissant son quotidien. Jouer stimule son esprit et entretient sa forme physique.`
        )
        break
      case 'no':
        goals.push({
          title: 'Eveil & Jeux',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Introduisez des jouets dans la vie de votre chat. Ils sont essentiels pour stimuler son esprit et maintenir sa forme physique.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Environment)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
    }

    switch (environment.hasScratchingPosts) {
      case 'yes':
        achieved.push(
          `Bien joué ! votre chat dispose d'un griffoir, ce qui est essentiel pour sa santé des griffes et pour éviter qu'il griffe les meubles.`
        )
        break
      case 'no':
        goals.push({
          title: 'Griffoir',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Offrez à votre chat un griffoir. Cela protégera vos meubles et répondra au besoin naturel de votre chat de faire ses griffes.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Environment)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
    }

    switch (environment.hasOneMoreLitterBoxThanNumberOfCats) {
      case 'yes':
        achieved.push(
          `Remarquable ! Vous avez une litière supplémentaire, ce qui réduit le stress et les conflits entre chats. C'est une pratique exemplaire pour leur bien-être.`
        )
        break
      case 'no':
        goals.push({
          title: 'Litière',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Ajoutez une litière supplémentaire. Avoir plus de litières que de chats prévient le stress et favorise une bonne hygiène.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Environment)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
    }

    switch (environment.LitterIsFarFromFood) {
      case 'yes':
        achieved.push(
          `Magnifique ! La litière loin de l'espace d'alimentation respecte les instincts naturels du chat, favorisant son hygiène et son bien-être.`
        )
        break
      case 'no':
        goals.push({
          title: "Zone d'alimentation",
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Placez la litière loin de l'espace d'alimentation. Cela suit l'instinct du chat pour la propreté et améliore son confort.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Environment)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
    }

    if (achieved.length > 0) {
      goals.push({
        title: 'Environnement',
        subtitle: `${achieved.length}/7`,
        description: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {achieved.map((achieved, index) => (
              <Typography key={`${achieved.slice(0, 10)}-${index}`} variant="body2">
                {achieved}
              </Typography>
            ))}
          </Box>
        ),
        icon: <Pets sx={{ color: 'lightGreen' }} />,
        achieved: true,
      })
    }

    return goals
  }

  function getBehaviorGoals(behavior: BehaviorQuestionsDto | undefined): Goal[] {
    const goals: Goal[] = []
    const achieved = []
    if (!behavior) {
      goals.push({
        title: 'Comportement',
        subtitle: 'Objectifs non atteints',
        description: (
          <>
            <Typography variant="body2">
              {`Aucune info n'a été recueillie concernant le comportement de votre animal`}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={() => setSelectedCategory(Categories.Behavior)}
            >
              Remplir le questionnaire comportement
            </Button>
          </>
        ),
        icon: <Pets sx={{ color: 'lightSalmon' }} />,
        achieved: false,
      })
      return goals
    }
    switch (behavior.inappropriateElimination) {
      case 'always':
        goals.push({
          title: 'Marquages inappropriés',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat marque constamment son territoire hors de la litière. Consultez un vétérinaire pour exclure des problèmes de santé.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'often':
        goals.push({
          title: 'Marquages fréquents',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat marque souvent hors de la litière. Cela peut indiquer un stress ou un malaise. Envisagez de consulter un comportementaliste.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'sometimes':
        goals.push({
          title: 'Marquages occasionnels',
          subtitle: 'Objectifs à surveiller',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat marque parfois hors de la litière, signe d'un stress occasionnel. Consulter un comportementaliste peut aider. Est-ce que sa litière est changée et lavée fréquemment ?`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'rarely':
        achieved.push(
          `Votre chat marque rarement hors de sa litière, ce qui peut être un signe de stress temporaire. Est-ce que sa litière est changée et lavée fréquemment ?`
        )
        break
      case 'never':
        achieved.push(
          `Magnifique ! Votre chat ne marque jamais hors de sa litière, signe de son bien-être et de son confort avec son environnement.`
        )
        break
    }

    switch (behavior.nocturnalVocalization) {
      case 'always':
        goals.push({
          title: 'Miaulements & agitions',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat miaule constamment ou montre de l'agitation nocturne. Cela peut indiquer un besoin non satisfait ou un problème de santé. Avez-vous consulté un vétérinaire ?`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'often':
        goals.push({
          title: 'Miaulements & agitions',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat vocalise souvent la nuit. Cela peut indiquer un malaise ou un besoin d'attention. Consultez un comportementaliste.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'sometimes':
        goals.push({
          title: 'Miaulements & agitions',
          subtitle: 'Objectifs à surveiller',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat miaule parfois ou manifeste de l'agitation nocturne. Cela peut être dû à un changement dans son environnement. Est-ce que quelque chose a changé récemment ?`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'rarely':
        achieved.push(
          `Louable ! Votre chat miaule rarement ou est agité la nuit, ce qui peut être une réaction à un événement spécifique. Est-ce lié à un événement récent (déménagement...) ou à des changements dans son environnement ?`
        )
        break
      case 'never':
        achieved.push(
          `Impressionnant ! Votre chat ne miaule jamais de manière excessive ni ne montre d'agitation nocturne, ce qui est un bon indicateur de son bien-être général.`
        )
        break
    }

    switch (behavior.fearfulBehavior) {
      case 'always':
        goals.push({
          title: 'Comportements craintifs',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat montre constamment des signes de peur ou un comportement craintif. Un comportementaliste peut aider à identifier les causes profondes et proposer des solutions adaptées.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'often':
        goals.push({
          title: 'Comportements craintifs',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat est souvent craintif, cela peut indiquer un malaise. Un comportementaliste peut pointer des éléments dans son environnement qui contribuent à ce comportement.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'sometimes':
        goals.push({
          title: 'Comportements craintifs',
          subtitle: 'Objectifs à surveiller',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat se montre parfois peureux ou craintif. Consultez un comportementaliste peut aider à déterminer des éléments ou des déclencheurs spécifiques à son environnement.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'rarely':
        achieved.push(
          `Votre chat est rarement craintif. Un comportementaliste pourrait aider à identifier des déclencheurs spécifiques et le rassurer.`
        )
        break
      case 'never':
        achieved.push(
          `Remarquable ! Votre chat ne montre jamais de comportement craintif, ce qui est un signe positif de son bien-être. Continuer à surveiller son comportement pour maintenir cet état.`
        )
        break
    }

    switch (behavior.humanInteraction) {
      case 'aggressive':
        goals.push({
          title: 'Interaction humaine',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat se montre agressif envers les humains, cela peut signaler du stress ou de la peur. Un comportementaliste peut aider à comprendre et à modifier ce comportement.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'curious':
        achieved.push(
          `Propice ! La curiosité de votre chat envers les humains indique une bonne socialisation et un intérêt pour son environnement. C'est un trait positif à encourager.`
        )
        break
      case 'shy':
        goals.push({
          title: 'Interaction humaine',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Un chat timide avec les humains peut se sentir insécurisé. Travailler avec un comportementaliste peut aider à augmenter sa confiance.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'emotional':
        achieved.push(
          `Sublime ! Un chat affectif avec les humains montre un lien fort et de la confiance. C'est le résultat d'une bonne socialisation et d'un environnement sécurisant.`
        )
        break
      case 'indifferent':
        achieved.push(
          `Pas affolant ! Un chat indifférent envers les humains peut simplement préférer son espace. Observer son comportement pour des signes de stress ou d'ennui est utile.`
        )
        break
    }

    switch (behavior.catSocialization) {
      case 'aggressive':
        goals.push({
          title: 'Socialisation avec d’autres chats',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat est agressif envers les autres chats, cela peut indiquer un problème de territoire ou de socialisation. Un comportementaliste pourrait aider à résoudre ces conflits.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'curious':
        achieved.push(
          `La curiosité de votre chat envers d'autres chats est bon signe de sa volonté d'interagir et d'explorer. Encouragez ces interactions sous supervision.`
        )
        break
      case 'shy':
        goals.push({
          title: 'Socialisation avec d’autres chats',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat est timide face à d'autres chats. Il/Elle peut manquer de confiance ou avoir peur. Un professionnel peut conseiller sur des stratégies pour améliorer ses interactions sociales.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'emotional':
        achieved.push(
          `Incroyable ! Votre chat est affectif avec d'autres chats se qui montre une bonne capacité de socialisation. Assurez-vous qu'ils aient assez d'espace et de ressources pour tous`
        )
        break
      case 'indifferent':
        achieved.push(
          `Convenable, votre chat indifférent aux autres chats. Il/Elle peut préférer la solitude ou simplement coexister sans conflit. Veillez à ce que chaque chat ait son propre espace.`
        )
        break
    }

    switch (behavior.otherAnimalsSocialization) {
      case 'aggressive':
        goals.push({
          title: 'Socialisation avec d’autres animaux',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat montre de l'agressivité envers d'autres animaux, ce qui peut être le signe de stress ou d'une socialisation insuffisante. Consulter un comportementaliste pourrait aider.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'curious':
        achieved.push(
          `Bonne nouvelle ! votre chat fait preuve de curiosité envers d'autres animaux, signe d'une bonne ouverture et d'une volonté d'explorer. Assurez-vous que les premières interactions soient supervisées.`
        )
        break
      case 'shy':
        goals.push({
          title: 'Socialisation avec d’autres animaux',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat se montre timide autour d'autres animaux, indiquant une possible insécurité. Des introductions graduelles avec l'aide d'un professionnel pourraient renforcer sa confiance.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'emotional':
        achieved.push(
          `Superbe ! Votre chat est affectif avec d'autres animaux, reflétant une socialisation réussie. Continuez de veiller sur leurs interactions pour garder une atmosphère harmonieuse.`
        )
        break
      case 'indifferent':
        achieved.push(
          `Votre chat reste indifférent face à d'autres animaux, ce qui peut faciliter une cohabitation paisible, à condition qu'il dispose de son propre espace`
        )
        break
    }

    switch (behavior.playBehavior) {
      case 'never':
        goals.push({
          title: "Manque d'intérêt au jeu",
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat ne joue jamais, ce qui peut indiquer un malaise ou un manque de stimulation. Encouragez le jeu avec des jouets adaptés.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'rarely':
        goals.push({
          title: 'Comportement de jeu',
          subtitle: 'Objectifs à encourager',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat joue rarement, ce qui peut indiquer un manque d'intérêt ou d'énergie. Encouragez les activités ludiques ! Un comportementaliste peut aider à identifier des jeux plus stimulants.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'sometimes':
        achieved.push(
          `Votre chat joue parfois, ce qui est bon. Pour augmenter sa fréquence de jeu, un comportementaliste peut pointer des jeux ou des jouets spécifiques.`
        )
        break
      case 'often':
        achieved.push(
          `Grandiose ! Votre chat joue souvent, c'est une bonne indication qu'il est actif et en bonne santé. Un comportementaliste peut suggérer plus de façons de stimuler son jeu.`
        )
        break
      case 'always':
        achieved.push(
          "Votre chat joue constamment, un signe excellent de bien-être et de santé. Cela montre qu'il est stimulé et heureux dans son environnement. Attention pour autant à ce qu'il/elle ne soit pas trop excité(e)"
        )
        break
    }

    switch (behavior.inappropriateScratching) {
      case 'always':
        goals.push({
          title: 'Griffades inappropriées',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat fait constamment ses griffes sur des endroits non appropriés. Un comportementaliste peut identifier des solutions et vous conseiller sur des griffoirs attractifs.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'often':
        goals.push({
          title: 'Griffades fréquentes',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat utilise souvent des endroits non appropriés pour faire ses griffes, consulter un comportementaliste peut aider à rediriger ce comportement.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'sometimes':
        goals.push({
          title: 'Griffades occasionnelles',
          subtitle: 'Objectifs à surveiller',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat griffe parfois des objets inappropriés. Encouragez le griffoir et détournez son attention des meubles. Un comportementaliste pourrait suggérer comment rendre les griffoirs plus attrayants`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Behavior)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
      case 'rarely':
        achieved.push(
          `C'est prometteur ! Votre chat gratte rarement des endroits non appropriés. Renforcez l'usage des griffoirs appropriés avec l'aide d'un comportementaliste peut prévenir ce comportement.`
        )
        break
      case 'never':
        achieved.push(
          `Phénoménal ! Votre chat ne fait jamais ses griffes sur des endroits non appropriés, signe qu'il est bien adapté à son environnement et aux griffoirs mis à sa disposition.`
        )
        break
    }

    if (achieved.length > 0) {
      goals.push({
        title: 'Comportement',
        subtitle: `${achieved.length}/8`,
        description: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {achieved.map((achieved, index) => (
              <Typography key={`${achieved.slice(0, 10)}-${index}`} variant="body2">
                {achieved}
              </Typography>
            ))}
          </Box>
        ),
        icon: <Pets sx={{ color: 'lightGreen' }} />,
        achieved: true,
      })
    }

    return goals
  }

  function getFeedingGoals(feeding: FeedingQuestionsDto | undefined): Goal[] {
    const goals: Goal[] = []
    const achieved = []
    if (!feeding) {
      goals.push({
        title: 'Nutrition',
        subtitle: 'Objectifs non atteints',
        description: (
          <>
            <Typography variant="body2">
              {`Aucune info n'a été recueillie concernant la nutrition de votre animal`}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={() => setSelectedCategory(Categories.Feeding)}
            >
              Remplir le questionnaire nutrition
            </Button>
          </>
        ),
        icon: <Pets sx={{ color: 'lightSalmon' }} />,
        achieved: false,
      })
      return goals
    }
    switch (feeding.catHydration) {
      case 'yes':
        achieved.push(
          `Votre chat boit régulièrement de l'eau chaque jour, c'est un bon signe d'hydratation et de santé. Continuez à fournir de l'eau fraîche constamment.`
        )
        break
      case 'no':
        goals.push({
          title: "Manque d'hydratation",
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Votre chat ne boit pas régulièrement de l'eau, cela peut affecter sa santé. Assurez-vous que l'eau est toujours disponible et fraîche pour encourager sa consommation.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Feeding)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
    }

    switch (feeding.hasWaterFountain) {
      case 'yes':
        achieved.push(
          `Disposer d'une fontaine à eau ou maintenir la gamelle d'eau propre encourage votre chat à boire régulièrement, favorisant une bonne hydratation et santé.`
        )
        break
      case 'no':
        goals.push({
          title: 'Fontaine à eau ou eau propre',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Envisagez d'acquérir une fontaine à eau ou de veiller à la propreté de sa gamelle. Cela peut encourager votre chat à boire plus souvent, améliorant son hydratation.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Feeding)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
        break
    }

    if (feeding.energeticalNeeds && feeding.nutritionalIntake) {
      const energyNeeds = feeding.energeticalNeeds
      const nutritionalIntakeInKcalPerDay =
        (feeding.nutritionalIntake.metabolicEnergy / 100) * feeding.nutritionalIntake.dailyPortion
      const difference = nutritionalIntakeInKcalPerDay / energyNeeds

      if (difference > 0.95 && difference < 1.05) {
        achieved.push(
          `Bravo ! La ration quotidienne de votre chat correspond à ses besoins énergétiques. Cela contribue à son bien-être général et à sa santé.`
        )
      } else if (difference < 0.95 && difference > 0.85) {
        goals.push({
          title: 'Sous-alimentation',
          subtitle: 'Objectifs à surveiller',
          description: (
            <>
              <Typography variant="body2">
                {`Vous ne donnez pas assez de nourriture à votre chat, ce qui peut être insuffisant pour ses besoins. Augmenter légèrement les portions peut soutenir son énergie et sa santé.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Feeding)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
      } else if (difference > 1.05 && difference < 1.15) {
        goals.push({
          title: 'Suralimentation',
          subtitle: 'Objectifs à surveiller',
          description: (
            <>
              <Typography variant="body2">
                {`Vous donnez trop de nourriture à votre chat, augmentant légèrement le risque de surpoids. Un petit ajustement des portions peut améliorer son bien-être.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Feeding)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
      } else if (difference < 0.85) {
        goals.push({
          title: 'Sous-alimentation extrême',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Vous ne donnez vraiment pas assez de nourriture à votre chat, risquant la sous-nutrition. Ajuster sa ration est crucial pour répondre à ses besoins énergétiques.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Feeding)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
      } else if (difference > 1.15) {
        goals.push({
          title: 'Suralimentation extrême',
          subtitle: 'Objectifs non atteints',
          description: (
            <>
              <Typography variant="body2">
                {`Vous donnez beaucoup trop de nourriture à votre chat, ce qui peut conduire à un surpoids. Réajuster ses portions favorisera une meilleure santé.`}{' '}
                <a href="#" style={{ color: 'black' }} onClick={() => setSelectedCategory(Categories.Feeding)}>
                  Mettre à jour
                </a>
              </Typography>
            </>
          ),
          icon: <Pets sx={{ color: 'lightSalmon' }} />,
          achieved: false,
        })
      }
    }

    if (achieved.length > 0) {
      goals.push({
        title: 'Nutrition',
        subtitle: `${achieved.length}/3`,
        description: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {achieved.map((achieved, index) => (
              <Typography key={`${achieved.slice(0, 10)}-${index}`} variant="body2">
                {achieved}
              </Typography>
            ))}
          </Box>
        ),
        icon: <Pets sx={{ color: 'lightGreen' }} />,
        achieved: true,
      })
    }

    return goals
  }
}
