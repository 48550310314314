import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../domain/Animal/calculateAge'
import { AnimalHealthDtoSterilizedOrNeutered, AnimalProfileDto } from '../../interactors/gen/backendClient'
import { findGoodAnimalProfileURL } from '../../utils/findGoodAnimalProfilURL'

interface Props {
  animalProfile: AnimalProfileDto
}

export const AnimalItem: React.FC<Props> = ({ animalProfile }) => {
  const navigate = useNavigate()

  const handleItemClick = () => {
    navigate(`/mes-animaux/${animalProfile.id}`)
  }

  const profileImageKey = animalProfile?.images?.profileImageKey
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '98px',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '8px',
        p: '16px',
        cursor: 'pointer',
      }}
      onClick={handleItemClick}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: 1, width: '100%' }}>
        <Avatar
          src={profileImageKey ? findGoodAnimalProfileURL(profileImageKey) : ''}
          alt={animalProfile?.name}
          sx={{ height: '66px', width: '66px' }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'row', md: 'column' },
              gap: '10px',
              width: { xs: '100%', md: '33%' },
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}>
              {animalProfile?.name}
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              {`${animalProfile?.species ? getReadableSpecies(animalProfile.species) : ''}, ${
                animalProfile?.birthday?.date ? calculateAge(animalProfile.birthday.date) : 'âge inconnu'
              }, ${
                animalProfile?.health.sterilizedOrNeutered === AnimalHealthDtoSterilizedOrNeutered.True
                  ? 'stérilisé'
                  : animalProfile?.health.sterilizedOrNeutered === AnimalHealthDtoSterilizedOrNeutered.False
                  ? 'non stérilisé'
                  : 'stérilisation inconnue'
              }`}
            </Typography>
          </Box>
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '33%' }}>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}>
              Type de l&#39;offre
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              {animal?.adoption.priceInEuros ? `Compensation ${animal?.adoption.priceInEuros}€` : 'Inconnu'}
            </Typography>
          </Box> */}
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '33%' }}>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}>
              Interlocuteur
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 400 }}>
              {adoption.customerName}
            </Typography>
          </Box> */}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>{/* <TrashIcon /> */}</Box>
      </Box>
    </Box>
  )
}
