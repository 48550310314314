import { AxiosRequestConfig } from 'axios'
import { backendClient } from './client'
import { AnimalProfileDto, AnimalProfileHealthObservationsDto } from '../gen/backendClient'

export class AnimalProfilesClient {
  public static async getAnimalById(animalId: string): Promise<AnimalProfileDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/animalprofiles/' + animalId,
    }

    const response = await backendClient<AnimalProfileDto>(config)

    return response.data
  }

  public static async getAnimalByMongoId(animalId: string): Promise<AnimalProfileDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/animalprofiles/mongo/' + animalId,
    }

    const response = await backendClient<AnimalProfileDto>(config)

    return response.data
  }

  public static async getShareLink(animalId: string): Promise<string> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/animalprofiles/share-link/' + animalId,
    }

    const response = await backendClient<string>(config)

    return response.data
  }

  public static async addCommentToObservationOrOperation(
    animalId: string,
    id: string,
    type: 'observation' | 'operation',
    data: { name: string; comment: string }
  ): Promise<AnimalProfileDto> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/adopters-platform/animalprofiles/${animalId}/${type}s/${id}/add-comment`,
      data,
    }

    const response = await backendClient<AnimalProfileDto>(config)

    return response.data
  }

  public static async getAllAnimalProfilesByAdopterId(): Promise<AnimalProfileDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/animalprofiles',
    }

    const response = await backendClient<AnimalProfileDto[]>(config)

    return response.data
  }

  public static async editAnimalProfile(animal: AnimalProfileDto): Promise<AnimalProfileDto> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: '/adopters-platform/animalprofiles/edit',
      data: animal,
    }

    const response = await backendClient<AnimalProfileDto>(config)

    return response.data
  }

  public static async createAnimalProfile(animal: Omit<AnimalProfileDto, 'id'>): Promise<AnimalProfileDto> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/adopters-platform/animalprofiles/create',
      data: animal,
    }

    const response = await backendClient<AnimalProfileDto>(config)

    return response.data
  }

  public static async uploadProfileImage(animalId: string, image: File): Promise<string> {
    const formData = new FormData()

    formData.append('file', image)

    const config: AxiosRequestConfig<FormData> = {
      method: 'POST',
      url: `/adopters-platform/animalprofiles/${animalId}/upload-profile-photos`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'image/',
      },
    }

    const response = await backendClient<string>(config)

    return response.data
  }

  public static async addObservation(
    animalId: string,
    formData: FormData
  ): Promise<AnimalProfileHealthObservationsDto> {
    const config: AxiosRequestConfig<FormData> = {
      method: 'POST',
      url: `/adopters-platform/animalprofiles/${animalId}/observations`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'image/',
      },
    }

    const response = await backendClient<AnimalProfileHealthObservationsDto>(config)

    return response.data
  }

  public static async addFileToObservation(
    animalId: string,
    observationId: string,
    formData: FormData
  ): Promise<AnimalProfileHealthObservationsDto> {
    const config: AxiosRequestConfig<FormData> = {
      method: 'POST',
      url: `/adopters-platform/animalprofiles/${animalId}/observations/${observationId}/add-file`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'image/',
      },
    }

    const response = await backendClient<AnimalProfileHealthObservationsDto>(config)

    return response.data
  }
}
