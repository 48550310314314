import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { AnimalDto } from '../../../../interactors/gen/backendClient'
import { getAnimalProfilePhotoSrc } from '../../../../utils/S3-links'
import { colors, theme } from '../../../theme'

interface StepsProps {
  animal: AnimalDto
}

export const Zero: React.FC<StepsProps> = ({ animal }) => {
  const profileImageKey = animal?.images?.profileImageKey || ''
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 3,
        height: '100%',
        backgroundColor: colors.blueSky,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Avatar
          src={profileImageKey ? getAnimalProfilePhotoSrc(profileImageKey) : ''}
          alt={animal?.name}
          sx={{ height: '140px', width: '140px' }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          fontSize: '22px',
          fontWeight: 700,
          width: 'fit-content',
          p: 1,
          py: 0.5,
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        Vous adoptez {animal.name} !
      </Box>
      <Typography variant="body1" sx={{ color: 'white' }}>
        Pour commencer, vous disposez d&apos;un délai légal de 8 jours pour signer le certificat d&apos;engagement et de
        connaissance.
      </Typography>
      <Typography variant="body1" sx={{ color: 'white' }}>
        <span style={{ fontWeight: 700, fontSize: '19px' }}>Ce tutoriel résume ce document.</span>
        <br />
        Il va vous guider à travers les points essentiels pour adopter en toute sérénité.
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', fontWeight: 700, fontSize: 22 }}>
        Prêt ?
      </Typography>
    </Box>
  )
}
