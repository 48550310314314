import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripePaymentElementOptions } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { useAccountsStore } from '../../store/AccountsStore'
import { useBasketStore } from '../../store/BasketStore'

interface Props {
  price: number
  fromBasket?: boolean
  redirectionLink?: string
  adoptionPayment?: boolean
  buttonFontSize?: string
}

export const CheckoutForm: React.FC<Props> = ({
  price,
  fromBasket,
  redirectionLink,
  adoptionPayment,
  buttonFontSize,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const basketStore = useBasketStore()
  const accountsStore = useAccountsStore()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = accountsStore.connectedAccount!

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret')

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Votre paiement a été effectué avec succès.')
          break
        case 'processing':
          setMessage('Votre paiement est en cours de traitement.')
          break
        case 'requires_payment_method':
          setMessage('Votre paiement a échoué. Veuillez réessayer.')
          break
        default:
          setMessage('Une erreur inattendue est survenue.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    if (fromBasket) basketStore.clearBasket()
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: redirectionLink ?? 'https://adoptant.petso.fr/mes-animaux?payment=success',
        receipt_email: user?.email,
        shipping: {
          name: `${user?.firstName} ${user?.lastName}`,
          address: {
            line1: user.address || '',
            postal_code: user.postalCode || '',
            city: user.city || '',
            country: user.country || '',
          },
        },
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      if (error.message) setMessage(error.message)
    } else {
      setMessage('Une erreur inattendue est survenue.')
    }

    setIsLoading(false)
  }

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
    business: {
      name: 'Petso SAS',
    },
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', mt: 2 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          loading={isLoading}
          disabled={isLoading || !stripe || !elements}
          sx={{ fontSize: buttonFontSize ?? 'unset' }}
        >
          {`Payer ${price}€ ${adoptionPayment ? `(${price - 5}€ + 5€ de frais de fonctionnement Petso)` : ''}`}
        </LoadingButton>
      </Box>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
