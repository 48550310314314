import { Add, Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, CircularProgress, Grow, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useNavigate } from 'react-router-dom'
import { AdoptionAttemptDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { colors, theme } from '../theme'
import { MetricalpInfo } from './DocumentSignCard'
import { metricalpEvent } from '@metricalp/react'

interface Props {
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
  files: File[]
  onSubmit: (
    data: Partial<AdoptionAttemptDto>,
    type: 'certificate' | 'contract' | 'payment' | 'sterilization'
  ) => Promise<void>
  filetype: 'certificate' | 'contract' | 'sterilization'
  attemptId: string
  metricalpInfo: MetricalpInfo
}

export const ImportDocumentCard: React.FC<Props> = ({
  setFiles,
  files,
  onSubmit,
  filetype,
  attemptId,
  metricalpInfo,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
    },
    onDrop: (acceptedFiles) => {
      setFiles((prev) => [...prev, ...acceptedFiles])
      console.log(acceptedFiles)
    },
    maxFiles: 1,
  })

  const navigate = useNavigate()
  const globalSnackbarStore = useGlobalSnackbarStore()

  const preSubmit = async () => {
    if (!(files && files.length > 0)) return globalSnackbarStore.triggerErrorMessage('Veuillez ajouter un fichier')
    setIsLoading(true)
    await onSubmit({ step: 2 }, filetype)
    metricalpEvent({
      type: `adoption_action`,
      action: `done_${filetype}`,
      adopterId: metricalpInfo.adopterId,
      adopterName: metricalpInfo.adopterName,
      animalName: metricalpInfo.animalName,
      animalId: metricalpInfo.animalId,
    })
    setFiles([])
    navigate(`/adoptions/${attemptId}?congrats=${filetype}`)
    setIsLoading(false)
  }

  const preSubmitEmpty = async () => {
    setIsLoading(true)
    setFiles([])
    await onSubmit({ step: 2 }, filetype)
    metricalpEvent({
      type: `adoption_action`,
      action: `done_${filetype}`,
      adopterId: metricalpInfo.adopterId,
      adopterName: metricalpInfo.adopterName,
      animalName: metricalpInfo.animalName,
      animalId: metricalpInfo.animalId,
    })
    navigate(`/adoptions/${attemptId}?congrats=${filetype}`)
    setIsLoading(false)
  }

  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <CircularProgress />
      </div>
    )

  const translatedFileType =
    filetype === 'certificate' ? 'Certificat' : filetype === 'sterilization' ? 'Certificat de stérilisation' : 'Contrat'
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: 2,
        filter: isDragActive ? 'blur(4px)' : '',
        transition: 'all 100ms ease-in-out',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          fontSize: '22px',
          fontWeight: 700,
          width: 'fit-content',
          p: 1,
          py: 0.5,
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        {translatedFileType} {filetype !== 'sterilization' && 'remis en main propre'}
      </Box>
      {filetype !== 'sterilization' && (
        <Typography variant="body1" sx={{ mb: 2 }}>
          L&apos;association indique vous avoir remis le document en mains propres.
        </Typography>
      )}

      <Typography variant="body1" sx={{ mb: 2, fontSize: 19, color: theme.palette.secondary.main }}>
        {filetype === 'sterilization' ? 'Importez votre certificat de stérilisation' : 'Importez votre document signé'}
      </Typography>
      {files.map((file, index) => (
        <Box
          key={file.name}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
            {files[0].type !== 'application/pdf' ? `Page ${index + 1}:` : ``}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              flexGrow: 0,
              maxWidth: '70%',
              overflow: 'hidden',
            }}
          >
            {file.name}
          </Typography>
          <IconButton
            onClick={() => {
              setFiles((prev) => prev.filter((f) => f !== file))
            }}
            sx={{ p: 0, m: 0 }}
          >
            <Close />
          </IconButton>
        </Box>
      ))}
      {!(files[0] && files[0].type === 'application/pdf') && (
        <Grow in={true}>
          <Box sx={{ color: 'black' }}>
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                border: `1px dashed ${colors.blue}`,
                borderRadius: 4,
                padding: 2,
                backgroundColor: isDragActive ? colors.blue : 'transparent',
                textTransform: 'none', // Ensure text is not uppercase
              }}
              fullWidth
              {...getRootProps()}
            >
              {/* Input field now inside the button */}
              <input {...getInputProps()} style={{ display: 'none' }} />
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Add />
                <Typography variant="body1" sx={{ fontSize: '16px' }}>
                  {`${files[0] ? 'Ajouter une page' : 'Ajouter un document'}`}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Grow>
      )}
      <Grow in={true} timeout={1000}>
        <LoadingButton
          variant="contained"
          onClick={preSubmit}
          loading={isLoading}
          disabled={files.length < 1}
          sx={{ mt: 2, fontSize: 18 }}
          fullWidth
        >
          {files.length > 1 ? `Importer le document (${files.length} pages)` : `Importer le document`}
        </LoadingButton>
      </Grow>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'left' }}>
        <hr style={{ width: '50%', margin: 0 }} />
        OU
        <hr style={{ width: '50%', margin: 0 }} />
      </Box>
      <Grow in={true} timeout={1000}>
        <Box sx={{ width: '100%', color: 'black' }}>
          <LoadingButton
            variant="outlined"
            color="primary"
            onClick={preSubmitEmpty}
            loading={isLoading}
            sx={{ fontSize: 18 }}
            fullWidth
          >
            Remise en main propre
          </LoadingButton>
        </Box>
      </Grow>
    </Box>
  )
}
