import React from 'react'
import { useMutation } from 'react-query'
import { AnimalProfilesClient } from '../../../../interactors/clients/AnimalProfilesClient'
import { AnimalProfileDto } from '../../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../../store/GlobalSnackBarStore'
import { Categories } from '../AnimalHealthTab'
import { EditEnvironmentQuestions } from '../EditEnvironmentQuestions'
import { EnvironmentInitialQuestions } from '../EnvironmentInitialQuestions'

interface Props {
  animalProfile: AnimalProfileDto
  setAnimalProfile: (animalProfile: AnimalProfileDto) => void
  setSelectedCategory: (category: Categories) => void
}

export const Environment: React.FC<Props> = ({ animalProfile, setAnimalProfile, setSelectedCategory }) => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const mutation = useMutation(
    (animal: AnimalProfileDto) => {
      return AnimalProfilesClient.editAnimalProfile(animal)
    },
    {
      onSuccess: (animal) => {
        setAnimalProfile(animal)
        globalSnackbarStore.triggerSuccessMessage("Profil de l'animal mis à jour")
        setSelectedCategory(Categories.Score)
      },
      onError: (error) => {
        console.error('Error updating animal profile', error)
        globalSnackbarStore.triggerErrorMessage("Une erreur est survenue lors de la mise à jour du profil de l'animal")
      },
    }
  )
  if (animalProfile.health.score?.environmentQuestions && animalProfile.health.score?.environmentQuestions.length > 0) {
    return <EditEnvironmentQuestions animalProfile={animalProfile} editMutation={mutation} />
  } else {
    return <EnvironmentInitialQuestions animalProfile={animalProfile} editMutation={mutation} />
  }
}
