import axios, { AxiosError } from 'axios'

export function readableAxiosError(error: any): string[] {
  const typedError: Error | AxiosError = error
  if (axios.isAxiosError(typedError)) {
    if (typedError.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return [
        JSON.stringify(typedError.response.data),
        `${typedError.response.status} ${typedError.config?.method?.toUpperCase()} ${typedError.config?.url}`,
      ]
    } else if (typedError.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return [JSON.stringify(typedError.request)]
    } else {
      return ['Something happened in setting up the request', typedError.message]
    }
  } else {
    return [JSON.stringify(typedError)]
  }
}
