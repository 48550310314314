import { AxiosRequestConfig } from 'axios'
import { ServiceLocationDto, ServiceLocationWithProvidersNamesDto } from '../gen/backendClient'
import { backendClient } from './client'

export class ServiceLocationsClient {
  public static async getAllLocations({
    page,
    limit,
    coordinates,
    type,
    query,
  }: {
    page: number
    limit: number
    coordinates?: number[]
    type: ServiceLocationDto['type']
    query?: string
  }): Promise<ServiceLocationWithProvidersNamesDto[]> {
    let url = `/adopters-platform/service-locations?page=${page}&limit=${limit}&type=${type}`

    // Append coordinates to the URL if they are provided
    if (coordinates) {
      url += `&coordinates=${coordinates.join(',')}`
    }

    // Append query to the URL if it is provided
    if (query) {
      url += `&query=${encodeURIComponent(query)}`
    }

    const config: AxiosRequestConfig = {
      method: 'GET',
      url: url,
    }

    const response = await backendClient<ServiceLocationWithProvidersNamesDto[]>(config)

    return response.data
  }

  public static async getLocation(id: string): Promise<ServiceLocationWithProvidersNamesDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/adopters-platform/service-locations/${id}`,
    }

    const response = await backendClient<ServiceLocationWithProvidersNamesDto>(config)

    return response.data
  }
}
