import { Box, Button, Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'
import { theme } from '../theme'
import { ReactComponent as CheckIcon } from '../icons/CheckIcon.svg'

interface Props {
  name: string
  price: string
  included: boolean
  picture: React.ReactNode
  description: string
  quantity: number
  referenceNumber: string
  onClick: () => void
  addToCart: (referenceNumber: string, quantity: number) => void
}

export const ItemCard: React.FC<Props> = ({
  name,
  price,
  included,
  picture,
  description,
  quantity,
  referenceNumber,
  onClick,
  addToCart,
}) => {
  return (
    <Card sx={{ maxWidth: '49%', width: '1000px', cursor: 'pointer' }} onClick={onClick}>
      <CardHeader title={name} sx={{ p: 0, pb: 1 }} />
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            {picture}
            <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16.8px', color: '#696B6D' }}>
              {description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="body2"
              sx={{ fontSize: '12px', lineHeight: '14.4px', color: 'rgba(51, 51, 51, 1)', fontWeight: 700 }}
            >
              {quantity ? `Qté x ${quantity}` : 'Qté x 0'}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: '12px', lineHeight: '14.4px', color: 'rgba(51, 51, 51, 1)', fontWeight: 700 }}
            >
              {quantity === 0 ? `Prix unitaire ${price}€` : `Prix total ${(Number(price) * quantity).toFixed(2)}€`}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                px: '20px',
                py: '11px',
                borderRadius: '200px',
                color: theme.palette.secondary.main,
                fontSize: '16px',
                lineHeight: '19.2px',
                fontWeight: 600,
                boxShadow: 'none',
                border: '2px solid',
                boxSizing: 'border-box',
                WebkitBoxSizing: 'border-box',
                MozBoxSizing: 'border-box',
                borderColor: theme.palette.primary.main,
                '&:hover': {
                  border: '2px solid',
                  borderColor: theme.palette.primary.main,
                },
                '&:disabled': {
                  py: '13px',
                  border: 'none',
                  backgroundColor: 'rgba(204, 204, 204, 1)',
                  color: 'rgba(32, 56, 100, 1)',
                },
              }}
              disabled={included ? true : quantity > 0 ? true : false}
              onClick={(e) => {
                e.stopPropagation()
                addToCart(referenceNumber, quantity + 1)
              }}
            >
              {included ? 'Inclus' : quantity > 0 ? <CheckIcon /> : 'Ajouter'}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
