import { Box, Button, Drawer, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import {
  AnimalHealthOperationDtoType,
  AnimalProfileDto,
  AnimalProfileHealthObservationsDto,
  AnimalProfileHealthOperationDto,
} from '../../interactors/gen/backendClient'
import { AnimalHealthOperationItem } from './AnimalHealthOperationItem'
import { useNavigate } from 'react-router-dom'
import { AnimalHealthObservationItem } from './AnimalHealthObservationItem'
import { AnimalHealthDewormingItem, Deworming } from './AnimalHealthDewormingItem'
import { GetShareLink } from './GetShareLink'

interface Props {
  animal: AnimalProfileDto
  displayOnly?: boolean
}

export const AnimalHealthOperationsTab: React.FC<Props> = ({ animal, displayOnly = false }) => {
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const combinedEntries = [
    ...(
      animal.health.operations?.filter((operation) => dayjs(operation.date, 'DD/MM/YYYY').isBefore(dayjs())) || []
    ).map((obs) => ({
      ...obs,
      opType: 'Operation', // Assuming you have a type field to distinguish in your component
    })),
    ...(animal.health.observations || []).map((obs) => ({
      ...obs,
      opType: 'Observation', // Assuming you have a type field to distinguish in your component
    })),
    ...(animal.health.deworming?.dates || []).map((obs) => ({
      date: obs,
      id: 'deworming',
      type: AnimalHealthOperationDtoType.Deworming,
      opType: 'Deworming', // Assuming you have a type field to distinguish in your component
    })),
    ...(animal.health.fleaControl?.dates || []).map((obs) => ({
      date: obs,
      id: 'fleaControl',
      type: AnimalHealthOperationDtoType.FleaControl,
      opType: 'FleaControl', // Assuming you have a type field to distinguish in your component
    })),
  ]

  const pastOperations = combinedEntries.sort((a, b) =>
    dayjs(b.date, b.opType === 'Operation' ? 'DD/MM/YYYY' : undefined).diff(
      dayjs(a.date, a.opType === 'Operation' ? 'DD/MM/YYYY' : undefined)
    )
  )

  const futureOperations = animal.health.operations
    ?.filter((operation) => dayjs(operation.date, 'DD/MM/YYYY').isAfter(dayjs()))
    .sort((a, b) => dayjs(a.date, 'DD/MM/YYYY').diff(dayjs(b.date, 'DD/MM/YYYY')))

  const nextDewormingDate = animal.health?.operations?.find(
    (operation) => operation.type.includes('deworming') && dayjs(operation.date).isAfter(dayjs())
  )?.date

  const nextFleaControlDate = animal.health?.operations?.find(
    (operation) => operation.type.includes('flea-control') && dayjs(operation.date).isAfter(dayjs())
  )?.date

  const nextVaccineDate = animal.health?.operations?.find(
    (operation) =>
      (operation.type.includes('annual-reminder') ||
        operation.type.includes('second-injection') ||
        operation.type.includes('third-injection')) &&
      dayjs(operation.date).isAfter(dayjs())
  )?.date

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Typography
        variant="body1"
        sx={{
          fontSize: '19px',
          lineHeight: '19.2px',
          fontWeight: 700,
          textDecoration: 'underline',
          fontStyle: 'italic',
        }}
      >
        Rappels & échéances à venir :
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1" fontStyle="italic" fontSize={13} color="rgba(87, 87, 87, 1)">
            Vermifuge
          </Typography>
          <Typography variant="body1" fontWeight={700} fontSize={15}>
            {nextDewormingDate ? dayjs(nextDewormingDate).format('DD/MM/YYYY') : 'N/A'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1" fontStyle="italic" fontSize={13} color="rgba(87, 87, 87, 1)">
            Antiparasitaire
          </Typography>
          <Typography variant="body1" fontWeight={700} fontSize={15}>
            {nextFleaControlDate ? dayjs(nextFleaControlDate).format('DD/MM/YYYY') : 'N/A'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1" fontStyle="italic" fontSize={13} color="rgba(87, 87, 87, 1)">
            Vaccin annuel
          </Typography>
          <Typography variant="body1" fontWeight={700} fontSize={15}>
            {nextVaccineDate ? dayjs(nextVaccineDate).format('DD/MM/YYYY') : 'N/A'}
          </Typography>
        </Box>
      </Box>
      {!displayOnly && (
        <>
          <Button variant="contained" color="secondary" onClick={() => setDrawerOpen(true)}>
            Ajouter
          </Button>
          <Typography
            variant="h2"
            component="h2"
            sx={{ fontSize: '24px', lineHeight: '28.8px', fontWeight: 700, color: '#333333' }}
          >
            À venir
          </Typography>

          {futureOperations?.map((operation) => (
            <AnimalHealthOperationItem key={operation.date} operation={operation} />
          ))}
        </>
      )}
      <Typography
        variant="h2"
        component="h2"
        sx={{ fontSize: '24px', lineHeight: '28.8px', fontWeight: 700, color: '#333333' }}
      >
        {displayOnly ? `Suivi de santé` : `Passés`}
      </Typography>
      {pastOperations?.map((operation) =>
        operation.opType === 'Deworming' || operation.opType === 'FleaControl' ? (
          <AnimalHealthDewormingItem
            key={`${operation.date}-${operation.id}`}
            deworming={operation as Deworming}
            animalId={animal.id}
            displayOnly={displayOnly}
          />
        ) : operation.opType === 'Observation' ? (
          <AnimalHealthObservationItem
            key={operation.date}
            observation={operation as AnimalProfileHealthObservationsDto}
          />
        ) : (
          <AnimalHealthOperationItem key={operation.date} operation={operation as AnimalProfileHealthOperationDto} />
        )
      )}

      {!displayOnly && (
        <Box sx={{ position: 'fixed', bottom: '48px', right: '24px' }}>
          <GetShareLink animalId={animal.id} animalName={animal.name} />
        </Box>
      )}

      <Drawer anchor={'bottom'} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', p: 2 }}>
          <Typography variant="h2" component="h2" sx={{ fontSize: '22px', lineHeight: '24.8px', fontWeight: 700 }}>
            Que souhaitez vous ajouter ?
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(`./ajouter-une-operation`)
              setDrawerOpen(false)
            }}
          >
            Un rendez-vous
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(`./ajouter-une-observation`)
              setDrawerOpen(false)
            }}
            sx={{ position: 'relative' }}
            disabled={!['cat', 'dog'].includes(animal.species)}
          >
            Une observation
            {!['cat', 'dog'].includes(animal.species) && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '50%',
                  left: '50%',
                  transform: 'rotate(-15deg) translateX(-50%) translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 20,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'black',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Prochainement !
                </Typography>
              </Box>
            )}
          </Button>
        </Box>
      </Drawer>
    </Box>
  )
}
