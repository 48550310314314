import { Box, Typography } from '@mui/material'
import React from 'react'

interface FieldProps {
  label: string
  children: React.ReactNode
  width?: string
  required?: boolean
}

export const Field: React.FC<FieldProps> = ({ label, children, width = '1000px', required = false }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: '8px',
      width: '100%',
      maxWidth: width,
    }}
  >
    <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '21.82px', color: '#2A5067' }}>
      {label} {required && <span style={{ color: 'red' }}>*</span>}
    </Typography>
    {children}
  </Box>
)
