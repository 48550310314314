import { Autocomplete, BaseTextFieldProps, Chip, SxProps, TextField } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { isColorDark } from '../../utils/isColorDark'
import { setOpacity } from '../../utils/setOpacity'

interface Option {
  label: string
  value: string | number
  color: string
}

interface Options {
  title: string
  options: Option[]
}

type Props<T extends FieldValues> = {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Options[]
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  color?: string
  sx?: SxProps
}

export function ControlledMultipleAutoCompleteWithCategories<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  options,
  size,
  disabled,
  sx,
}: PropsWithChildren<Props<T>>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={[] as any}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          sx={sx}
          onChange={(_, selectedOptions) => onChange(selectedOptions.map((option) => option.value))}
          freeSolo={false}
          id={fieldName}
          options={options.flatMap((option) => option.options)}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
          value={options.flatMap((option) => option.options).filter((option) => value.includes(option.value))}
          size={size}
          disabled={disabled}
          groupBy={(option) => {
            const category = options.find((category) => category.options.includes(option))
            return category?.title || ''
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, ...restTagProps } = getTagProps({ index })
              return (
                <Chip
                  key={option.value}
                  label={option.label}
                  {...restTagProps}
                  style={{
                    backgroundColor: setOpacity(option.color, 0.8),
                    color: isColorDark(option.color) ? 'white' : 'black',
                  }}
                />
              )
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={label}
              error={!!error}
              helperText={error?.message}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '100%',
                },
              }}
            />
          )}
        />
      )}
    />
  )
}
