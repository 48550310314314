import { Box, Button, Grow, InputAdornment, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { omit } from 'lodash'
import React, { useEffect } from 'react'
import { UseMutationResult } from 'react-query'
import { getReadableAdopterAdoptionFormYesNo } from '../../../domain/Adopters/AccountDisplay'
import {
  getReadableAnimalActivity,
  getReadableAnimalCorpulence,
  getReadableAnimalSterilizedOrNeutered,
} from '../../../domain/Animal/AnimalDisplay'
import {
  getQuestionsForFeeding,
  getReadableCatAgeQuestions,
  getReadableCatLifestyleQuestions,
  getReadableCatMealFrequencyQuestions,
} from '../../../domain/AnimalProfileDisplay/HealthQuestionsDisplay'
import {
  AnimalFormAndActivityDtoActivity,
  AnimalFormAndActivityDtoCorpulence,
  AnimalHealthDtoSterilizedOrNeutered,
  AnimalProfileDto,
  EatsGoodAmountDtoAge,
  EatsGoodAmountDtoLifestyle,
  EnvironmentQuestionsDtoHasAccessToSecuredWindows,
  FeedingQuestionsDtoMealFrequency,
} from '../../../interactors/gen/backendClient'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { ControlledMultipleToggleButton } from '../../common/ControlledMutipleToggleButton'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { Field } from '../../common/FormField'
import { FeedingQuestionsWithSterilizedAndBreed } from './NutritionInitialQuestions'

interface Props {
  animalProfile: AnimalProfileDto
  editMutation: UseMutationResult<AnimalProfileDto, unknown, AnimalProfileDto, unknown>
}

export const EditFeedingQuestions: React.FC<Props> = ({ animalProfile, editMutation }) => {
  const mostRecentFeedingQuestions = animalProfile.health.score?.feedingQuestions?.sort((a, b) =>
    dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
  )[0]
  if (!mostRecentFeedingQuestions) return null

  const [selectedQuestion, setSelectedQuestion] = React.useState<string | null>(null)

  const initialAnimalWeight =
    animalProfile.formAndActivity && animalProfile.formAndActivity?.weight
      ? animalProfile.formAndActivity?.weight[animalProfile.formAndActivity?.weight.length - 1]?.weightInKgs
      : undefined

  const getDefaultValues = (feedingQuestions: FeedingQuestionsWithSterilizedAndBreed) => {
    return {
      ...feedingQuestions,
      sterilizedOrNeutered: animalProfile.health.sterilizedOrNeutered,
      weightInKgs: initialAnimalWeight || '',
      date: dayjs().toISOString(),
    }
  }

  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormExtended(mostRecentFeedingQuestions as FeedingQuestionsWithSterilizedAndBreed, getDefaultValues)

  useEffect(() => {
    const scrollDown = () => {
      const chatContainer = document.querySelector('.scrollable-content') as HTMLElement
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
    if (selectedQuestion === null && isDirty) scrollDown()
  }, [selectedQuestion])

  const onSubmit = (data: FeedingQuestionsWithSterilizedAndBreed) => {
    const feedingQuestionsArray = animalProfile.health.score?.feedingQuestions || []
    const inputDataDate = dayjs(data.date).format('YYYY-MM-DD') // Normalize input date to 'YYYY-MM-DD'

    // Find index based on date comparison (ignoring time)
    const index = feedingQuestionsArray.findIndex(
      (question) => dayjs(question.date).format('YYYY-MM-DD') === inputDataDate
    )

    if (index !== -1) {
      // If found, replace the existing entry
      feedingQuestionsArray[index] = omit(data, 'sterilizedOrNeutered', 'breed', 'weightInKgs')
    } else {
      // If not found, push the new data
      feedingQuestionsArray.push(omit(data, 'sterilizedOrNeutered', 'breed', 'weightInKgs'))
    }

    const newAnimalProfile: AnimalProfileDto = {
      ...animalProfile,
      health: {
        ...animalProfile.health,
        sterilizedOrNeutered: data.sterilizedOrNeutered,
        score: {
          ...animalProfile.health.score,
          healthScore: animalProfile.health.score?.healthScore || 0,
          feedingQuestions: feedingQuestionsArray,
        },
      },
      formAndActivity: {
        ...animalProfile.formAndActivity,
        weight: [
          ...(animalProfile.formAndActivity?.weight || []),
          {
            weightInKgs: data.weightInKgs,
            dateOfWeightIn: data.date,
          },
        ],
      },
    }
    editMutation.mutate(newAnimalProfile) // Assuming editMutation is a mutation hook from react-query
  }

  const displaySelectedQuestions = () => {
    switch (selectedQuestion) {
      case 'mealFrequency':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForFeeding('mealFrequency')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.mealFrequency}
                  fieldName="mealFrequency"
                  options={Object.values(FeedingQuestionsDtoMealFrequency).map((value) => ({
                    label: getReadableCatMealFrequencyQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'catHydration':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForFeeding('catHydration')}>
                <ControlledToggleButton
                  control={control}
                  error={errors.catHydration}
                  fieldName="catHydration"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'hasWaterFountain':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForFeeding('hasWaterFountain')}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasWaterFountain}
                  fieldName="hasWaterFountain"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'eatsGoodAmount':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForFeeding('eatsGoodAmount')}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2, width: '100%' }}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sterilizedOrNeutered"
                    error={errors.sterilizedOrNeutered}
                    requiredRule={undefined}
                    options={Object.values(AnimalHealthDtoSterilizedOrNeutered).map(
                      (status: AnimalHealthDtoSterilizedOrNeutered) => ({
                        label: getReadableAnimalSterilizedOrNeutered(status),
                        value: status,
                      })
                    )}
                    label="Stérilisé(e) ou castré(e)"
                    size="small"
                  />
                  <TextField
                    label="Poids de l'animal"
                    type="number"
                    inputProps={{
                      step: '0.01',
                      inputMode: 'decimal',
                      pattern: '[0-9]*',
                    }}
                    fullWidth
                    {...register('weightInKgs')}
                    hiddenLabel
                    error={undefined}
                    helperText={"Si modifié, poids précédent sera conservé dans l'historique"}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                  />
                  <ControlledMultipleToggleButton
                    control={control}
                    label="Quel est le stade de vie de votre chat ?"
                    error={errors.eatsGoodAmount?.age}
                    fieldName="eatsGoodAmount.age"
                    options={Object.values(EatsGoodAmountDtoAge).map((value) => ({
                      label: getReadableCatAgeQuestions(value),
                      value,
                    }))}
                    requiredRule="Ce champ est obligatoire"
                    fullWidth
                  />
                  <ControlledMultipleToggleButton
                    control={control}
                    label="Où est ce que votre chat passe la plupart de son temps ?"
                    error={errors.eatsGoodAmount?.lifestyle}
                    fieldName="eatsGoodAmount.lifestyle"
                    options={Object.values(EatsGoodAmountDtoLifestyle).map((value) => ({
                      label: getReadableCatLifestyleQuestions(value),
                      value,
                    }))}
                    requiredRule="Ce champ est obligatoire"
                    fullWidth
                  />
                  <ControlledMultipleToggleButton
                    control={control}
                    label="Quel est le niveau d'activité de votre chat ?"
                    error={errors.eatsGoodAmount?.activityLevel}
                    fieldName="eatsGoodAmount.activityLevel"
                    options={Object.values(AnimalFormAndActivityDtoActivity).map((value) => ({
                      label: getReadableAnimalActivity(value),
                      value,
                    }))}
                    requiredRule="Ce champ est obligatoire"
                    fullWidth
                  />
                  <ControlledMultipleToggleButton
                    control={control}
                    label="Quelle est la condition physique de votre chat ?"
                    error={errors.eatsGoodAmount?.corpulence}
                    fieldName="eatsGoodAmount.corpulence"
                    options={Object.values(AnimalFormAndActivityDtoCorpulence).map((value) => ({
                      label: getReadableAnimalCorpulence(value),
                      value,
                    }))}
                    requiredRule="Ce champ est obligatoire"
                    fullWidth
                  />
                  <Button variant="contained" type="button" onClick={() => setSelectedQuestion(null)}>
                    Valider
                  </Button>
                </Box>
              </Field>
            </Box>
          </Grow>
        )
      case 'nutritionalIntake':
        return (
          <Grow in={true}>
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2, width: '100%' }}>
                <Field label="Quelle est la portion journalière de nourriture que vous donnez à votre chat ?">
                  <TextField
                    {...register('nutritionalIntake.dailyPortion')}
                    aria-label="Quelle est la portion journalière de nourriture que vous donnez à votre chat ?"
                    fullWidth
                    type="number"
                    helperText="Indiquez la portion en grammes"
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" sx={{ color: 'black' }}>
                          grammes
                        </Typography>
                      ),
                    }}
                  />
                </Field>
                <Field label="Apport nutritionnel /100g de son alimentation (au dos du paquet )">
                  <TextField
                    {...register('nutritionalIntake.metabolicEnergy')}
                    aria-label="Apport nutritionnel /100g de son alimentation (au dos du paquet )"
                    fullWidth
                    type="number"
                    helperText="Indiquez l'apport nutritionel en kcal/100g"
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" sx={{ color: 'black' }}>
                          kcal/100g
                        </Typography>
                      ),
                    }}
                  />
                </Field>
                <Button variant="contained" type="button" onClick={() => handleSubmit(onSubmit)()}>
                  Valider
                </Button>
              </Box>
            </Box>
          </Grow>
        )
      default:
        return null
    }
  }

  if (selectedQuestion) return displaySelectedQuestions()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('mealFrequency')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForFeeding('mealFrequency')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableCatMealFrequencyQuestions(watch('mealFrequency'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('catHydration')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForFeeding('catHydration')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableAdopterAdoptionFormYesNo(watch('catHydration'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('hasWaterFountain')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForFeeding('hasWaterFountain')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableAdopterAdoptionFormYesNo(watch('hasWaterFountain'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('eatsGoodAmount')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForFeeding('eatsGoodAmount')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Consulter vos réponses et les modifier</Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('nutritionalIntake')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForFeeding('nutritionalIntake')}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Consulter vos réponses et les modifier</Typography>
      </Box>
      {isDirty && (
        <Button variant="contained" onClick={handleSubmit(onSubmit)} fullWidth>
          Enregistrer mes modifications
        </Button>
      )}
    </Box>
  )
}
