import create from 'zustand'
import { AdopterDto, CustomerDto } from '../interactors/gen/backendClient'

interface AccountsStore {
  changeConnectedAccount: (account: AdopterDto) => void
  connectedAccount: AdopterDto | null
  isLoading: boolean
  connectedCustomer: CustomerDto | null
  changeConnectedCustomer: (customer: CustomerDto) => void
}

export const useAccountsStore = create<AccountsStore>()((set) => ({
  isLoading: false,
  connectedAccount: null,
  changeConnectedAccount: (account: AdopterDto) => {
    set({ connectedAccount: account })
  },
  connectedCustomer: null,
  changeConnectedCustomer: (customer: CustomerDto) => {
    set({ connectedCustomer: customer })
  },
}))
