import { Box } from '@mui/material'
import React from 'react'
import {
  getReadableAdopterAdoptionFormCounter,
  getReadableAdopterAdoptionFormHadAnimalBefore,
} from '../../../domain/Adopters/AccountDisplay'
import {
  AdopterDtoAdoptionFormHadAnimalBefore,
  AdopterDtoAlreadyHasAnimalOrChildBaby,
} from '../../../interactors/gen/backendClient'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { Field } from '../../common/FormField'
import { FormParams } from '../AdoptionFormScreen'
import { StepsProps } from './StepOne'

export const StepThree: React.FC<StepsProps> = ({ control, errors }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'start', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'start', width: '50%' }}>
          <Field label="Avez-vous déjà eu un animal ?" required>
            <ControlledSelectField
              control={control}
              fieldName="hadAnimalBefore"
              error={errors.hadAnimalBefore}
              requiredRule="Vous devez répondre à la question"
              options={Object.values(AdopterDtoAdoptionFormHadAnimalBefore).map(
                (sterilizedOrNeutered: AdopterDtoAdoptionFormHadAnimalBefore) => ({
                  label: getReadableAdopterAdoptionFormHadAnimalBefore(sterilizedOrNeutered),
                  value: sterilizedOrNeutered,
                })
              )}
              size="small"
            />
          </Field>
          <Field label="Combien de chien(s) avez-vous ?" required>
            <ControlledSelectField
              control={control}
              fieldName="dog"
              error={errors.dog}
              requiredRule="Vous devez choisir une option"
              options={Object.values(AdopterDtoAlreadyHasAnimalOrChildBaby).map(
                (option: AdopterDtoAlreadyHasAnimalOrChildBaby) => ({
                  label: getReadableAdopterAdoptionFormCounter(option),
                  value: option,
                })
              )}
              size="small"
            />
          </Field>
          <Field label="Combien d’animaux autre que chien et chat avez-vous ?" required>
            <ControlledSelectField
              control={control}
              fieldName="other"
              error={errors.other}
              requiredRule="Vous devez choisir une option"
              options={Object.values(AdopterDtoAlreadyHasAnimalOrChildBaby).map(
                (option: AdopterDtoAlreadyHasAnimalOrChildBaby) => ({
                  label: getReadableAdopterAdoptionFormCounter(option),
                  value: option,
                })
              )}
              size="small"
            />
          </Field>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            justifyContent: { xs: 'space-between', md: 'start' },
            width: '50%',
          }}
        >
          <Box sx={{ height: '80.81px', display: { xs: 'none', md: 'block' } }}></Box>
          <Field label="Combien de chat(s) avez-vous ?" required>
            <ControlledSelectField
              control={control}
              fieldName="cat"
              error={errors.cat}
              requiredRule="Vous devez choisir une option"
              options={Object.values(AdopterDtoAlreadyHasAnimalOrChildBaby).map(
                (option: AdopterDtoAlreadyHasAnimalOrChildBaby) => ({
                  label: getReadableAdopterAdoptionFormCounter(option),
                  value: option,
                })
              )}
              size="small"
            />
          </Field>
          <Field label="Vos animaux ont-ils l'habitude des autres animaux ?" required>
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="ownAnimalUsedToAnimals"
              error={errors.ownAnimalUsedToAnimals}
              requiredRule="Vous devez choisir si vous avez d'autres animaux"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'yes'}
              secondOptionValue={'no'}
              fullWidth
            />
          </Field>
        </Box>
      </Box>
    </Box>
  )
}
