import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as CrossIcon } from '../icons/CrossIcon.svg'
import { theme } from '../theme'
import { AdoptionInfoBox } from '../Adoptions/AdoptionInfoBox'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { useForm } from 'react-hook-form'
import ReactImageMagnify from 'react-image-magnify'
import { getProductImageSrc } from '../../utils/S3-links'

interface Props {
  popIn: ItemPopIn | null
  isModalOpened: boolean
  quantity: number
  onCancel: () => void
  addToCart: (referenceNumber: string, quantity: number) => void
}

export interface ItemPopIn {
  ReferenceNumber: string
  Name: string
  Images: string[]
  Description: string
  Price: string
  Included: boolean
  Composition: string
  Dimensions: string
  EducationPoint: string
  USP?: string | undefined
}

export const ItemPopIn: React.FC<Props> = ({ popIn, isModalOpened, quantity, onCancel, addToCart }) => {
  const primaryPicture = popIn?.Images[0] ? getProductImageSrc(popIn?.Images[0]) : undefined
  const [selectedPicture, setSelectedPicture] = React.useState<string | undefined>(
    popIn?.Images[0] ? getProductImageSrc(popIn?.Images[0]) : undefined
  )
  const { control, watch } = useForm({
    defaultValues: {
      quantity: quantity && quantity !== 0 ? quantity : 1,
    },
  })
  const watchQuantity = watch('quantity')
  return (
    <Modal open={isModalOpened} onClose={onCancel}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          padding: '24px',
          borderRadius: '8px',
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          '&:focus': {
            outline: 'none',
          },
          '&:focus-visible': {
            outline: 'none',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 700, marginBottom: '16px', color: 'black' }}
          >
            {popIn?.Name}
          </Typography>
          <CrossIcon style={{ cursor: 'pointer' }} onClick={onCancel} />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '16px', gap: '16px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '50%' }}>
            {primaryPicture && (
              <ReactImageMagnify
                enlargedImageContainerDimensions={{ width: '100%', height: '100%' }}
                enlargedImageContainerStyle={{ zIndex: 10, objectFit: 'contain' }}
                imageStyle={{ objectFit: 'contain', overflow: 'hidden', maxHeight: '300px', height: '300px' }}
                {...{
                  smallImage: {
                    alt: `Photo du produit ${popIn?.Name}`,
                    isFluidWidth: true,
                    src: selectedPicture || primaryPicture,
                    height: 248,
                    width: 320,
                  },
                  largeImage: {
                    src: selectedPicture || primaryPicture,
                    width: 1200,
                    height: 1800,
                  },
                }}
              />
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '16px' }}>
              {popIn?.Images.map((image) => (
                <img
                  key={image}
                  src={getProductImageSrc(image)}
                  height="60px"
                  width="55px"
                  style={{
                    objectFit: 'cover',
                    cursor: 'pointer',
                    border: selectedPicture === getProductImageSrc(image) ? '2px solid #FFC107' : 'none',
                  }}
                  onClick={() => setSelectedPicture(getProductImageSrc(image))}
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '50%' }}>
            <Typography sx={{ fontSize: '14px', lineHeight: '16.8px', fontWeight: 400, color: 'black' }}>
              {popIn?.Description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
            {popIn?.Composition && (
              <Typography sx={{ fontSize: '14px', lineHeight: '16.8px', fontWeight: 400, color: 'black' }}>
                <b>Composition: </b>
                {popIn?.Composition}
              </Typography>
            )}
            {popIn?.Dimensions && (
              <Typography sx={{ fontSize: '14px', lineHeight: '16.8px', fontWeight: 400, color: 'black' }}>
                <b>Dimensions: </b>
                {popIn?.Dimensions}
              </Typography>
            )}
            {popIn?.Included && <AdoptionInfoBox message="Cet article est inclus dans le welcome pack" />}
            <Typography sx={{ fontSize: '14px', lineHeight: '16.8px', fontWeight: 400, color: 'black' }}>
              Prix unitaire : {popIn?.Price}€
            </Typography>
            <ControlledSelectField
              control={control}
              fieldName="quantity"
              error={undefined}
              label="Quantité"
              options={[
                { value: 1, label: popIn?.Included ? 'Qté 1 : inclus dans le welcome pack' : 'Qté 1' },
                { value: 2, label: 'Qté 2' },
                { value: 3, label: 'Qté 3' },
                { value: 4, label: 'Qté 4' },
                { value: 5, label: 'Qté 5' },
              ]}
              requiredRule=""
              size="small"
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {popIn?.ReferenceNumber && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                px: '20px',
                py: '13px',
                borderRadius: '200px',
                color: theme.palette.secondary.main,
                fontSize: '16px',
                lineHeight: '19.2px',
                fontWeight: 600,
                boxShadow: 'none',
              }}
              disabled={(popIn?.Included && watchQuantity === 1) || quantity === watchQuantity}
              onClick={() => {
                addToCart(popIn.ReferenceNumber, watchQuantity)
                onCancel()
              }}
            >
              {quantity > 0 ? 'Mettre à jour' : `Ajouter au panier`}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
