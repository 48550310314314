import { AxiosRequestConfig } from 'axios'
import { PromoCodeDto } from '../gen/backendClient'
import { backendClient } from './client'

export class PromoCodesClient {
  public static async checkPromoCode(code: string): Promise<PromoCodeDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/adopters-platform/promocode/check/${code}`,
    }

    const response = await backendClient<PromoCodeDto>(config)

    return response.data
  }
}
