import { Box, Grow } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useForm } from 'react-hook-form'
import { UseMutationResult } from 'react-query'
import {
  getQuestionsForBehavior,
  getReadableCatBehaviorQuestions,
  getReadableOftenQuestions,
} from '../../../domain/AnimalProfileDisplay/HealthQuestionsDisplay'
import {
  AnimalProfileDto,
  BehaviorQuestionsDto,
  BehaviorQuestionsDtoCatSocialization,
  BehaviorQuestionsDtoInappropriateElimination,
} from '../../../interactors/gen/backendClient'
import { ControlledMultipleToggleButton } from '../../common/ControlledMutipleToggleButton'
import { Field } from '../../common/FormField'

interface Props {
  animalProfile: AnimalProfileDto
  editMutation: UseMutationResult<AnimalProfileDto, unknown, AnimalProfileDto, unknown>
}

export const BehaviorInitialQuestions: React.FC<Props> = ({ animalProfile, editMutation }) => {
  const [step, setStep] = React.useState(0)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BehaviorQuestionsDto>({
    defaultValues: {
      date: dayjs().toISOString(),
    },
  })

  const onSubmit = (data: BehaviorQuestionsDto) => {
    const behaviorQuestionsArray = animalProfile.health.score?.behaviorQuestions || []
    behaviorQuestionsArray.push(data)
    const newAnimalProfile: AnimalProfileDto = {
      ...animalProfile,
      health: {
        ...animalProfile.health,
        score: {
          ...animalProfile.health.score,
          healthScore: animalProfile.health.score?.healthScore || 0,
          behaviorQuestions: behaviorQuestionsArray,
        },
      },
    }
    editMutation.mutate(newAnimalProfile)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Box>
        {step === 0 && (
          <Grow in={step === 0}>
            <Box>
              <Field label={getQuestionsForBehavior('inappropriateElimination')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.inappropriateElimination}
                  fieldName="inappropriateElimination"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 1 && (
          <Grow in={step === 1}>
            <Box>
              <Field label={getQuestionsForBehavior('nocturnalVocalization')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.nocturnalVocalization}
                  fieldName="nocturnalVocalization"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 2 && (
          <Grow in={step === 2}>
            <Box>
              <Field label={getQuestionsForBehavior('fearfulBehavior')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.fearfulBehavior}
                  fieldName="fearfulBehavior"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 3 && (
          <Grow in={step === 3}>
            <Box>
              <Field label={getQuestionsForBehavior('humanInteraction')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.humanInteraction}
                  fieldName="humanInteraction"
                  options={Object.values(BehaviorQuestionsDtoCatSocialization).map((value) => ({
                    label: getReadableCatBehaviorQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 4 && (
          <Grow in={step === 4}>
            <Box>
              <Field label={getQuestionsForBehavior('catSocialization')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.catSocialization}
                  fieldName="catSocialization"
                  options={Object.values(BehaviorQuestionsDtoCatSocialization).map((value) => ({
                    label: getReadableCatBehaviorQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 5 && (
          <Grow in={step === 5}>
            <Box>
              <Field label={getQuestionsForBehavior('otherAnimalsSocialization')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.otherAnimalsSocialization}
                  fieldName="otherAnimalsSocialization"
                  options={Object.values(BehaviorQuestionsDtoCatSocialization).map((value) => ({
                    label: getReadableCatBehaviorQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 6 && (
          <Grow in={step === 6}>
            <Box>
              <Field label={getQuestionsForBehavior('playBehavior')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.playBehavior}
                  fieldName="playBehavior"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 7 && (
          <Grow in={step === 7}>
            <Box>
              <Field label={getQuestionsForBehavior('inappropriateScratching')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.inappropriateScratching}
                  fieldName="inappropriateScratching"
                  options={Object.values(BehaviorQuestionsDtoInappropriateElimination).map((value) => ({
                    label: getReadableOftenQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => handleSubmit(onSubmit)()}
                />
              </Field>
            </Box>
          </Grow>
        )}
      </Box>
    </form>
  )
}
