import { Box } from '@mui/material'
import React from 'react'
import { getReadableAdopterAdoptionFormCounter } from '../../../domain/Adopters/AccountDisplay'
import { AdopterDtoAlreadyHasAnimalOrChildBaby } from '../../../interactors/gen/backendClient'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { Field } from '../../common/FormField'
import { FormParams } from '../AdoptionFormScreen'
import { StepsProps } from './StepOne'

export const StepFour: React.FC<StepsProps> = ({ control, errors }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'start', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'start', width: '50%' }}>
          <Field label="Avez-vous des enfants entre 0 et 12 mois ?" required>
            <ControlledSelectField
              control={control}
              fieldName="baby"
              error={errors.baby}
              requiredRule="Vous devez choisir une option"
              options={Object.values(AdopterDtoAlreadyHasAnimalOrChildBaby).map(
                (option: AdopterDtoAlreadyHasAnimalOrChildBaby) => ({
                  label: getReadableAdopterAdoptionFormCounter(option),
                  value: option,
                })
              )}
              size="small"
            />
          </Field>
          <Field label="Avez-vous des enfants entre 12 et 17 ans ?" required>
            <ControlledSelectField
              control={control}
              fieldName="teenager"
              error={errors.teenager}
              requiredRule="Vous devez choisir une option"
              options={Object.values(AdopterDtoAlreadyHasAnimalOrChildBaby).map(
                (option: AdopterDtoAlreadyHasAnimalOrChildBaby) => ({
                  label: getReadableAdopterAdoptionFormCounter(option),
                  value: option,
                })
              )}
              size="small"
            />
          </Field>
          <Field label="Sont-ils d’accord pour adopter un animal ?" required>
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="allHouseMembersAgree"
              error={errors.allHouseMembersAgree}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'yes'}
              secondOptionValue={'no'}
              fullWidth
            />
          </Field>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'start', width: '50%' }}>
          <Field label="Avez-vous des enfants entre 1 et 12 ans ?" required>
            <ControlledSelectField
              control={control}
              fieldName="child"
              error={errors.child}
              requiredRule="Vous devez choisir une option"
              options={Object.values(AdopterDtoAlreadyHasAnimalOrChildBaby).map(
                (option: AdopterDtoAlreadyHasAnimalOrChildBaby) => ({
                  label: getReadableAdopterAdoptionFormCounter(option),
                  value: option,
                })
              )}
              size="small"
            />
          </Field>
          <Field label="Combien d'adultes dans votre foyer ?" required>
            <ControlledSelectField
              control={control}
              fieldName="adult"
              error={errors.adult}
              requiredRule="Vous devez choisir une option"
              options={Object.values(AdopterDtoAlreadyHasAnimalOrChildBaby).map(
                (option: AdopterDtoAlreadyHasAnimalOrChildBaby) => ({
                  label: getReadableAdopterAdoptionFormCounter(option),
                  value: option,
                })
              )}
              size="small"
            />
          </Field>
          <Field label="Y a t’il des personnes allergiques aux animaux ?" required>
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="allergiesInHousehold"
              error={errors.allergiesInHousehold}
              requiredRule="Vous devez choisir si il y a des personnes allergiques aux animaux"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'yes'}
              secondOptionValue={'no'}
              fullWidth
            />
          </Field>
        </Box>
      </Box>
    </Box>
  )
}
