import React from 'react'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { DocumentItem } from './DocumentItem'
import { Box, Theme, useMediaQuery } from '@mui/material'
import { SoberCollapsible } from '../common/SoberCollapsible'

type Props = {
  animal: AnimalProfileDto
}

export const Documents: React.FC<Props> = ({ animal }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return (
    <SoberCollapsible title="Documents importants" noLeftPadding sx={{ mt: '24px' }} defaultExpanded={!isMobile}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        {animal.documents.map((document) => (
          <DocumentItem key={document.id} document={document} />
        ))}
      </Box>
    </SoberCollapsible>
  )
}
