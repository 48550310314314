import { Box, Button, Grow, Modal, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { getEmojiAnimalSex } from '../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../domain/Animal/calculateAge'
import { Upload } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'

interface Props {
  animal: AnimalProfileDto | null
  isModalOpened: boolean
  setIsModalOpened: (isModalOpened: boolean) => void
}

export const AddAnimalPhotoModal: React.FC<Props> = ({ animal, isModalOpened, setIsModalOpened }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [growCongrats, setGrowCongrats] = React.useState(false)
  const [growSad, setGrowSad] = React.useState(false)
  const [growUpload, setGrowUpload] = React.useState(false)
  const [growCancel, setGrowCancel] = React.useState(false)
  const [growWow, setGrowWow] = React.useState(false)
  const [growSubmit, setGrowSubmit] = React.useState(false)

  const navigate = useNavigate()

  const onCancel = () => {
    navigate(`/mes-animaux/${animal?.id}`)
    setIsModalOpened(false)
  }

  const onDrop = () => {
    setTimeout(() => {
      setGrowWow(true)
    }, 500)
    setTimeout(() => {
      setGrowSubmit(true)
    }, 1000)
  }

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
  })

  const onSubmit = async () => {
    if (!acceptedFiles[0] || !animal) return
    setIsLoading(true)

    await AnimalProfilesClient.uploadProfileImage(animal.id, acceptedFiles[0])

    navigate(`/mes-animaux/${animal?.id}`)
    setIsLoading(false)
    setIsModalOpened(false)
  }

  useEffect(() => {
    if (!isModalOpened) return
    const delayGrows = setTimeout(() => {
      setGrowCongrats(true)
    }, 200)

    const delaySad = setTimeout(() => {
      setGrowSad(true)
    }, 600)

    const delayUpload = setTimeout(() => {
      setGrowUpload(true)
    }, 1200)

    const delayCancel = setTimeout(() => {
      setGrowCancel(true)
    }, 1600)

    return () => {
      clearTimeout(delayGrows)
      clearTimeout(delaySad)
      clearTimeout(delayUpload)
      clearTimeout(delayCancel)
    }
  }, [isModalOpened])

  if (!animal) return null

  return (
    <Modal open={isModalOpened} onClose={onCancel}>
      <Box
        sx={{
          maxWidth: '100%',
          width: '400px',
          maxHeight: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          padding: '24px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          overflowY: 'auto',
        }}
      >
        <Grow in={growCongrats} timeout={500}>
          <Typography variant="h2" component="h2">
            {`Bravo ! ${animal.name} a été créé${animal.sex === 'female' ? 'e' : ''} avec succès !`}
          </Typography>
        </Grow>
        <Grow in={growSad} timeout={500}>
          <Typography variant="body1" component="p">
            {`Malheureusement on ne sait toujours pas à quoi ${animal.sex === 'female' ? 'elle' : 'il'} ressemble 😞`}
          </Typography>
        </Grow>
        <Grow in={growUpload} timeout={1200}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {acceptedFiles[0] ? (
              <img
                style={{
                  width: '300px',
                  maxWidth: '100%',
                  height: '330px',
                  objectFit: 'cover',
                  borderRadius: '11.25px',
                }}
                src={acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0])}
              />
            ) : (
              <Box
                {...getRootProps()}
                sx={{
                  width: '300px',
                  maxWidth: '100%',
                  height: '330px',
                  objectFit: 'cover',
                  borderRadius: '11.25px',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'lightgrey',
                }}
              >
                <input {...getInputProps()} />
                <Typography variant="body1" component="p">
                  Ajouter une photo <Upload />
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: '4px',
                width: '300px',
                maxWidth: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Typography variant="h1" component="p">
                  {animal.name}
                </Typography>
              </Box>
              <Typography
                variant="h1"
                component="p"
                color={animal.sex === 'female' ? 'pink' : 'blue'}
                fontWeight={700}
                fontSize={22}
              >
                {animal.sex ? getEmojiAnimalSex(animal.sex) : ''}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Typography
                  variant="body2"
                  component="p"
                  fontSize={18}
                  fontWeight={500}
                  fontStyle="italic"
                  lineHeight={1.6}
                >
                  {animal?.birthday?.date ? calculateAge(animal.birthday.date) : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grow>
        <Grow in={growWow} timeout={500}>
          <Typography variant="body1" component="p">
            Wow ! {animal.name} est vraiment super {animal.sex === 'female' ? 'mignonne' : 'mignon'} 😍
          </Typography>
        </Grow>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '8px' }}>
          <Grow in={growCancel} timeout={800}>
            <Button variant="contained" color="primary" onClick={onCancel}>
              Plus tard
            </Button>
          </Grow>
          <Grow in={growSubmit} timeout={500}>
            <LoadingButton variant="contained" color="primary" loading={isLoading} onClick={onSubmit}>
              Enregistrer
            </LoadingButton>
          </Grow>
        </Box>
      </Box>
    </Modal>
  )
}
