import React, { useEffect } from 'react'
import { AdopterDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useDashboardLoaderStore } from '../../store/DashboardLoaderStore'
import { useAccountsStore } from '../../store/AccountsStore'
import { useMutation } from 'react-query'
import { readableAxiosError } from '../../utils/axios'
import axios, { AxiosError } from 'axios'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { Box, FormControl, TextField } from '@mui/material'
import { Field } from '../common/FormField'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { Controller } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { ControlledToggleButton } from '../common/ControlledToggleButton'
import { useNavigate } from 'react-router-dom'
import { ControlledVeterinariesAutocomplete } from '../common/ControlledVeterinariesAutocomplete'
import { ServiceProvidersClient } from '../../interactors/clients/ServiceProvidersClient'
import { colors } from '../theme'

interface FormParams {
  firstName: AdopterDto['firstName']
  lastName: AdopterDto['lastName']
  phoneNumber: AdopterDto['phoneNumber']
  city: AdopterDto['city']
  address: AdopterDto['address']
  postalCode: AdopterDto['postalCode']
  country: AdopterDto['country']
  displayPublicly: boolean
  defaultVeterinary: string
}

export const ProfileDetailsScreen: React.FC = () => {
  const globalSnackBarStore = useGlobalSnackbarStore()
  const { setIsLoading } = useDashboardLoaderStore()
  const accountsStore = useAccountsStore()
  const queryParams = new URLSearchParams(window.location.search)
  const fromTutorial = queryParams.get('fromTutorial') === 'true'
  const redirect = queryParams.get('redirect')
  const navigate = useNavigate()
  const account = accountsStore.connectedAccount

  const [defaultVeterinaryName, setDefaultVeterinaryName] = React.useState<string | undefined>(undefined)

  useEffect(() => {
    if (!account || !account?.defaultVeterinary) return
    const fetchDefaultVeterinary = async () => {
      if (account.defaultVeterinary) {
        const veterinary = await ServiceProvidersClient.getProvider(account.defaultVeterinary.id)
        setDefaultVeterinaryName(`${veterinary.firstName} ${veterinary.lastName}`)
      }
    }
    fetchDefaultVeterinary()
  }, [account])

  //Mutation to edit the adopter
  const editAdopterMutation = useMutation(
    async (data: AdopterDto) => {
      const response = await AdoptersClient.editAccount(data)
      return response
    },
    {
      onSuccess: (account) => {
        globalSnackBarStore.triggerSuccessMessage(`Votre profil a bien été modifié.`)
        accountsStore.changeConnectedAccount(account)
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          setIsLoading(false)
          globalSnackBarStore.triggerErrorMessage(`Une erreur est survenue lors de la modification du profil.`)
          return
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
        setIsLoading(false)
      },
      onSettled: () => {
        setIsLoading(false)
        navigate(redirect ?? '/mes-animaux')
      },
    }
  )

  const getDefaultValues = (account: AdopterDto | null): FormParams => ({
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    phoneNumber: account?.phoneNumber,
    address: account?.address,
    city: account?.city,
    postalCode: account?.postalCode,
    country: account?.country,
    displayPublicly: account?.displayPublicly === false ? false : true,
    defaultVeterinary:
      account?.defaultVeterinary?.id && account?.defaultVeterinary?.locationId
        ? `${account.defaultVeterinary.id}_${account.defaultVeterinary.locationId}`
        : '',
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useFormExtended(account, getDefaultValues)

  const onSubmit = (data: FormParams) => {
    if (!account) {
      return globalSnackBarStore.triggerErrorMessage('Un problème est survenu, veuillez réessayer plus tard')
    }
    if (!isDirty) return navigate(redirect ?? '/mes-animaux')

    const veterinaryAndLocation = data.defaultVeterinary ? data.defaultVeterinary.split('_') : [undefined, undefined]
    const [veterinaryId, locationId] = veterinaryAndLocation
    const adopter = {
      ...account!,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: ['+33', ''].includes(data.phoneNumber?.trim() || '') ? undefined : data.phoneNumber,
      address: data.address,
      city: data.city,
      postalCode: data.postalCode,
      country: data.country,
      displayPublicly: data.displayPublicly,
      defaultVeterinary: {
        id: veterinaryId || '',
        locationId: locationId || '',
      },
    }
    editAdopterMutation.mutate(adopter)
  }

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%' }}>
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {fromTutorial && (
          <Box
            sx={{
              backgroundColor: colors.blueSky,
              color: 'white',
              fontSize: '19px',
              fontWeight: 700,
              width: 'fit-content',
              p: 1,
              py: 0.5,
              borderRadius: '11px',
              transform: 'rotate(-1deg)',
            }}
          >
            Tout d&#39;abord, validez vos infos !
          </Box>
        )}
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Field label={`Prénom`} required>
            <TextField
              aria-label="Prénom"
              type="text"
              placeholder="Michel"
              {...register('firstName')}
              fullWidth
              hiddenLabel
              variant="outlined"
              required
              disabled={account?.createdWithGoogle}
              helperText={
                account?.createdWithGoogle
                  ? 'Compte synchronisé avec Google: Vous ne pouvez pas modifier votre prénom'
                  : ''
              }
            />
          </Field>
          <Field label={`Nom`} required>
            <TextField
              aria-label="Nom"
              type="text"
              placeholder="Bernard"
              {...register('lastName')}
              fullWidth
              hiddenLabel
              variant="outlined"
              required
              disabled={account?.createdWithGoogle}
              helperText={
                account?.createdWithGoogle
                  ? 'Compte synchronisé avec Google: Vous ne pouvez pas modifier votre nom'
                  : ''
              }
            />
          </Field>
          <Field label="Téléphone">
            <FormControl variant="outlined" sx={{ width: '100%' }}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  validate: (value: string | undefined) => {
                    console.log(value)
                    return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                      ? true
                      : 'Tel is invalid'
                  },
                }}
                render={({ field, fieldState }) => (
                  <MuiTelInput
                    {...field}
                    aria-label="Numéro de Téléphone"
                    defaultCountry="FR"
                    helperText={fieldState.error ? "Le numéro de téléphone n'est pas valide" : ''}
                    error={!!fieldState.error}
                    hiddenLabel
                  />
                )}
              />
            </FormControl>
          </Field>
          <Field label="Adresse" required>
            <TextField
              aria-label="Adresse"
              placeholder="Votre adresse"
              {...register('address')}
              hiddenLabel
              required
              fullWidth
            />
          </Field>
          <Field label="Code Postal" required>
            <TextField
              aria-label="Code Postal"
              placeholder="Votre code postal"
              {...register('postalCode')}
              hiddenLabel
              required
              fullWidth
            />
          </Field>
          <Field label="Ville" required>
            <TextField
              aria-label="Ville"
              placeholder="Votre ville"
              {...register('city')}
              hiddenLabel
              required
              fullWidth
            />
          </Field>
          <Field label="Pays" required>
            <TextField
              aria-label="Pays"
              placeholder="Votre pays"
              {...register('country')}
              hiddenLabel
              required
              fullWidth
            />
          </Field>
          {!fromTutorial && (
            <>
              <Field label="Profil visible auprès des autres membres" required>
                <ControlledToggleButton<FormParams>
                  control={control}
                  fieldName="displayPublicly"
                  error={errors.displayPublicly}
                  requiredRule="Vous devez choisir une option"
                  firstOptionLabel="Non"
                  secondOptionLabel="Oui"
                  firstOptionValue={false}
                  secondOptionValue={true}
                  fullWidth
                />
              </Field>
              <Field label="Vétérinaire référent">
                <ControlledVeterinariesAutocomplete
                  control={control}
                  fieldName="defaultVeterinary"
                  placeholder={defaultVeterinaryName || 'Vétérinaire référent'}
                />
              </Field>
            </>
          )}
          {!fromTutorial && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 2 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={editAdopterMutation.isLoading}
                disabled={editAdopterMutation.isLoading}
                sx={{ width: '100%', maxWidth: { xs: '100%', md: '300px' } }}
              >
                {`Mettre à jour`}
              </LoadingButton>
            </Box>
          )}
          {fromTutorial && (
            <>
              <div style={{ height: '69px' }}></div>
              <Box
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  left: { xs: 0, md: '240px' },
                  right: 0,
                  p: 2,
                  px: 3,
                  pt: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  alignItems: 'center',
                  borderTop: '1px solid #E0E0E0',
                  boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.10)',
                  zIndex: 2,
                  backgroundColor: 'white',
                }}
              >
                <LoadingButton
                  variant="contained"
                  sx={{ fontSize: '18px' }}
                  type="submit"
                  fullWidth
                  loading={editAdopterMutation.isLoading}
                  disabled={editAdopterMutation.isLoading}
                >
                  Valider mes informations
                </LoadingButton>
              </Box>
            </>
          )}
        </form>
      </Box>
    </Box>
  )
}
