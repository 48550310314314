import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { AnimalItem } from './AnimalItem'
import { AddNewAnimal } from './AddNewAnimal'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { ExpandableCard } from '../Shopping/ExpandableCard'
import { useNavigate } from 'react-router-dom'
import { CircularProgressPanel } from '../common/CircularProgressPanel'

export const AnimalsListScreen: React.FC = () => {
  const [animalProfiles, setAnimalProfiles] = useState<AnimalProfileDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const queryParams = new URLSearchParams(window.location.search)
  const paymentParam = queryParams.get('payment')
  const globalSnackbarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (paymentParam === 'success') {
      globalSnackbarStore.triggerSuccessMessage('Paiement effectué avec succès')
    }
  }, [])

  useEffect(() => {
    const fetchAnimalProfiles = async () => {
      const animalProfiles = await AnimalProfilesClient.getAllAnimalProfilesByAdopterId()
      setAnimalProfiles(animalProfiles)
      setIsLoading(false)
    }

    fetchAnimalProfiles()
  }, [])

  if (isLoading) {
    return <CircularProgressPanel />
  }

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 1, p: 4 }}>
        <Typography variant="h1">{animalProfiles ? 'Mes animaux' : "Petso c'est quoi ?"}</Typography>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {animalProfiles ? (
            <>
              {animalProfiles.map((animalProfile) => (
                <AnimalItem key={animalProfile.id} animalProfile={animalProfile} />
              ))}
              <AddNewAnimal />
            </>
          ) : (
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography variant="h2">
                Petso est une application concue pour les adoptants et les propriétaires pour favoriser le bien-être
                animal
              </Typography>
              <ExpandableCard title="Suivi Préventif">
                <ul style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <li>
                    <span style={{ textDecoration: 'underline' }}>Enregistrement facile :</span> Gardez une trace des
                    documents et des activités quotidiennes, et notifiez tout comportement inhabituel.
                  </li>
                  <li>
                    <span style={{ textDecoration: 'underline' }}>Partage avec votre vétérinaire :</span> transmettez
                    facilement les informations clés à votre vétérinaire pour un suivi de santé optimal.
                  </li>
                </ul>
              </ExpandableCard>
              <ExpandableCard title="Conseils et recommandations personnalisés">
                <ul style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <li>
                    Recevez des conseils sur mesure, adaptés aux besoins spécifiques de votre animal, qu’il s’agisse de
                    nutrition, de soins ou de comportement.
                  </li>
                </ul>
              </ExpandableCard>
              <ExpandableCard title="Communauté">
                <ul style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <li>
                    <span style={{ textDecoration: 'underline' }}>Partage d’Humeur :</span> Exprimez comment se sent
                    votre animal et découvrez les humeurs des compagnons des autres membres.
                  </li>
                  <li>
                    <span style={{ textDecoration: 'underline' }}>Réseau d’Entraide :</span> Connectez-vous avec
                    d’autres propriétaires pour échanger des conseils, trouver des solutions de garde occasionnelle, et
                    plus encore
                  </li>
                </ul>
              </ExpandableCard>
              <Button
                onClick={() => navigate('./ajouter-un-animal')}
                variant="contained"
                sx={{
                  position: 'fixed',
                  bottom: '80px',
                  right: '50%',
                  transform: 'translateX(50%)',
                  fontSize: 16,
                  boxShadow: 4,
                }}
              >
                Ajouter un animal
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
