import { Box, Typography } from '@mui/material'
import React from 'react'
import { lightBlue } from '../theme'

interface Props {
  icon: React.ReactNode
  title: string
  text: string[]
}

export const AdoptionFurtherInfoChipsItem: React.FC<Props> = ({ icon, title, text }) => {
  return (
    <Box sx={{ display: 'flex', py: '8px', px: '16px', borderRadius: '5px', gap: '14px', backgroundColor: lightBlue }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          height: '40px',
          p: '8px',
        }}
      >
        {icon}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Typography
          variant="body1"
          component="p"
          sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
        >
          {title}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: '8px' }}>
          {text.map((item) => (
            <Box
              key={item}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '18px',
                px: '16px',
                py: '2px',
                fontSize: '14px',
                lineHeight: '16.8px',
                color: 'white',
                backgroundColor: '#333333',
              }}
            >
              {item}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
