import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { getEmojiSpecies } from '../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../domain/Animal/calculateAge'
import { AdopterWithAnimalsReducedDto, AnimalReducedDto } from '../../interactors/gen/backendClient'
import { findGoodAnimalProfileURL } from '../../utils/findGoodAnimalProfilURL'

interface Props {
  animal: AnimalReducedDto
  adopter: Omit<AdopterWithAnimalsReducedDto, 'animals'>
}

export const PetParentAnimalItem: React.FC<Props> = ({ animal }) => {
  const profileImageKey = animal?.images?.profileImageKey
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '48px',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '8px',
        p: '8px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: 1, width: '100%' }}>
        <Avatar
          src={profileImageKey ? findGoodAnimalProfileURL(profileImageKey) : ''}
          alt={animal?.name}
          sx={{ height: '32px', width: '32px' }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '4px',
              width: { xs: '100%', md: '33%' },
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}
              >
                {animal?.name} {getEmojiSpecies(animal.species)}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: '15px', lineHeight: '19.2px', fontWeight: 400, fontStyle: 'italic' }}
              >
                {`${animal?.birthday?.date ? calculateAge(animal.birthday.date) : 'âge inconnu'}`}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontSize: '15px', lineHeight: '19.2px', fontWeight: 400, fontStyle: 'italic' }}
            >
              {`${animal.breed?.breed ?? ''}`}
            </Typography>
          </Box>
        </Box>
        {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}></Box> */}
      </Box>
    </Box>
  )
}
