import { backendClient } from './client'
import { AxiosRequestConfig } from 'axios'

export class ErrorsClient {
  public static async notifyError(error: any): Promise<void> {
    console.log(error)
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/errors',
      data: JSON.stringify(error),
    }

    await backendClient(config)
  }
}
