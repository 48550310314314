import { Box, Typography } from '@mui/material'
import { Component, ErrorInfo, ReactNode } from 'react'
import { ErrorsClient } from './interactors/clients/ErrorClient'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
    await ErrorsClient.notifyError({
      currentUrl: window.location.href,
      error: error.message,
      stack: error.stack,
      errorInfo,
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ padding: 6 }}>
          <Typography variant="h4">
            {`🚨 Désolé, il semble qu'une erreur soit survenue sur notre site. Veuillez nous contacter à l'adresse e-mail contact@petso.fr pour signaler le problème et obtenir de l'aide pour le résoudre. Nous nous excusons pour tout inconvénient que cela pourrait causer et nous nous engageons à résoudre ce problème dès que possible.`}
          </Typography>
        </Box>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
