import { AnimalHealthOperationDtoType } from '../interactors/gen/backendClient'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import HealingIcon from '@mui/icons-material/Healing'
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy'
import ScienceIcon from '@mui/icons-material/Science'
import PetsIcon from '@mui/icons-material/Pets'

export const getIconForOperationType = (operationType: AnimalHealthOperationDtoType) => {
  switch (operationType) {
    case AnimalHealthOperationDtoType.FirstInjection:
    case AnimalHealthOperationDtoType.SecondInjection:
    case AnimalHealthOperationDtoType.ThirdInjection:
    case AnimalHealthOperationDtoType.AnnualReminder:
      return <VaccinesIcon />
    case AnimalHealthOperationDtoType.IdentificationChip:
    case AnimalHealthOperationDtoType.Tatoo:
      return <FingerprintIcon />
    case AnimalHealthOperationDtoType.SterilizedOrNeutered:
    case AnimalHealthOperationDtoType.Implant:
    case AnimalHealthOperationDtoType.Surgery:
      return <HealingIcon />
    case AnimalHealthOperationDtoType.FleaControl:
    case AnimalHealthOperationDtoType.Deworming:
      return <LocalPharmacyIcon />
    case AnimalHealthOperationDtoType.FeLv:
    case AnimalHealthOperationDtoType.Fiv:
    case AnimalHealthOperationDtoType.GoodHealthCertificate:
      return <ScienceIcon />
    case AnimalHealthOperationDtoType.Others:
    case AnimalHealthOperationDtoType.Identification:
      return <PetsIcon />
    default:
      return null // Or a default icon
  }
}
