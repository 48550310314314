import { Add, Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Grow, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { getReadableAnimalDocumentType } from '../../domain/Animal/AnimalDisplay'
import { backendClient } from '../../interactors/clients/client'
import { AnimalDocumentDtoType, UploadAnimalProfileDocumentDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { ControlledAutoCompleteWithCustomValue } from '../common/ControlledAutocompleteWithCustomValue'
import { Field } from '../common/FormField'
import { colors } from '../theme'

interface FormData {
  type: AnimalDocumentDtoType
  name?: string
}

export const AddAnimalDocumentScreen: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [files, setFiles] = React.useState<File[]>([])
  const { animalId } = useParams() as { animalId: string }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
    },
    onDrop: (acceptedFiles) => {
      setFiles((prev) => [...prev, ...acceptedFiles])
    },
    maxFiles: 1,
  })
  const navigate = useNavigate()
  const globalSnackbar = useGlobalSnackbarStore()

  const mutation = useMutation(
    async ({ data, files }: { data: UploadAnimalProfileDocumentDto; files: File[] }) => {
      const formData = new FormData()
      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file)
      })
      if (data.type !== undefined) {
        formData.append('type', data.type)
      }

      if (data.name !== undefined) {
        formData.append('name', data.name)
      }
      return await backendClient.post(`adopters-platform/animalprofiles/${animalId}/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    {
      onSuccess: () => {
        globalSnackbar.triggerSuccessMessage(`Votre document a bien été ajouté !`)
      },
    }
  )

  const onSubmit = async (data: FormData) => {
    if (!animalId) return
    if (!data.type) {
      globalSnackbar.triggerErrorMessage('Veuillez sélectionner un type de document')
      return
    }
    setIsLoading(true)

    if (!files[0]) return globalSnackbar.triggerErrorMessage('Veuillez sélectionner un fichier')

    await mutation.mutateAsync({ data: { type: data.type, name: data.name }, files })

    navigate(`/mes-animaux/${animalId}/?tab=documents`)
    setIsLoading(false)
  }

  const { register, control, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      type: undefined,
      name: '',
    },
  })

  /* const watchType = watch('type') */

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 1, p: 4, pb: 10 }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Grow in>
            <Box>
              <Field label="Quel type de document souhaitez-vous enregistrer ?" required>
                <ControlledAutoCompleteWithCustomValue<FormData>
                  control={control}
                  error={undefined}
                  defaultValue=""
                  fieldName="type"
                  size="small"
                  label="Type de document"
                  options={Object.values(AnimalDocumentDtoType).map((type) => ({
                    label: getReadableAnimalDocumentType(type),
                    value: type,
                  }))}
                  requiredRule={'Le type de document est requis'}
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={true}>
            <Box>
              <Field label={`Un nom pour le document ?`}>
                <TextField
                  aria-label="Nom du document"
                  type="text"
                  placeholder="Facture de vaccin, ordonnance, etc."
                  {...register('name')}
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                />
              </Field>
            </Box>
          </Grow>
          {files.map((file, index) => (
            <Box
              key={file.name}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>{`Page ${index + 1}:`}</Typography>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  flexGrow: 0,
                  maxWidth: '70%',
                  overflow: 'hidden',
                }}
              >
                {file.name}
              </Typography>
              <IconButton
                onClick={() => {
                  setFiles((prev) => prev.filter((f) => f !== file))
                }}
                sx={{ p: 0, m: 0 }}
              >
                <Close />
              </IconButton>
            </Box>
          ))}
          {!(files[0] && files[0].type === 'application/pdf') && (
            <Grow in={true}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    border: `1px dashed ${colors.blue}`,
                    borderRadius: 4,
                    padding: 2,
                    backgroundColor: isDragActive ? colors.blue : 'transparent',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                    <Add />
                    <Typography variant="body1" sx={{ fontSize: '16px' }}>
                      Ajouter une page
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grow>
          )}
          <Grow in={true} timeout={1000}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={files.length < 1}
              sx={{ mt: 2 }}
            >
              Ajouter le document ({files.length} page{files.length > 1 ? 's' : ''})
            </LoadingButton>
          </Grow>
        </form>
      </Box>
    </Box>
  )
}
