import { IosShare } from '@mui/icons-material'
import { Chip, IconButton } from '@mui/material'
import React from 'react'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { theme } from '../theme'

interface Props {
  animalId: string
  animalName: string
  chip?: boolean
}

export const GetShareLink: React.FC<Props> = ({ animalId, animalName, chip = false }) => {
  const globalSnackbarStore = useGlobalSnackbarStore()

  const getShareLink = async (): Promise<string> => {
    const shareLink = await AnimalProfilesClient.getShareLink(animalId)

    return shareLink
  }

  // if web share API isn't supported, copy the link to clipboard
  const shareShareLink = async () => {
    const shareLink = await getShareLink()

    if (navigator.share) {
      navigator.share({
        title: `Suivi santé de ${animalName}`,
        text: `Consultez le suivi santé de ${animalName} en détails`,
        url: shareLink,
      })
    } else {
      navigator.clipboard.writeText(shareLink)
      globalSnackbarStore.triggerSuccessMessage('Lien copié dans le presse-papiers')
    }
  }

  return chip ? (
    <Chip
      label={`Partager à mon véto`}
      clickable
      sx={{
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        fontSize: '15px',
        boxShadow:
          '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);',
        cursor: 'pointer',
      }}
      onClick={() => shareShareLink()}
    />
  ) : (
    <IconButton
      sx={{ backgroundColor: theme.palette.primary.main, color: 'white', p: '10px' }}
      onClick={() => shareShareLink()}
    >
      <IosShare />
    </IconButton>
  )
}
