import { Box, Typography, Collapse, SxProps } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { theme } from '../theme'
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDownIcon.svg'

interface Props {
  icon?: React.ReactNode
  subtitle?: string
  defaultExpanded?: boolean
  title: string
  children: React.ReactNode
  noLeftPadding?: boolean
  sx?: SxProps
}

export const SoberCollapsible: React.FC<Props> = ({
  defaultExpanded,
  icon,
  title,
  subtitle,
  children,
  noLeftPadding = false,
  sx,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  useEffect(() => {
    setExpanded(defaultExpanded)
  }, [defaultExpanded])
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', borderTop: '1px solid #CCCCCC', py: 1, ...sx }}>
      <Box
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {icon}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Typography variant="h2" component="p" sx={{ color: 'black', fontSize: '18px', fontWeight: '500' }}>
              {title}
            </Typography>
            <Typography variant="body2" component="p">
              {subtitle}
            </Typography>
          </Box>
        </Box>
        <ArrowDownIcon
          style={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
          }}
        />
      </Box>
      <Collapse in={expanded} timeout="auto" sx={{ pt: expanded ? 2 : 0, pl: noLeftPadding ? '0' : '40px' }}>
        {children}
      </Collapse>
    </Box>
  )
}
