import React from 'react'
import { theme } from '../theme'
import { ArrowForwardIos } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AnimalDto, AnimalProfileDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { metricalpEvent } from '@metricalp/react'

interface Props {
  animalProfile: AnimalProfileDto | AnimalDto
  title: string
  description: string
  logoUrl: string
  imgSrc: string
  brandName: string
  catchPhrase?: string
  redirectUrl?: string
}

export const BrandItem: React.FC<Props> = ({
  animalProfile,
  title,
  description,
  logoUrl,
  imgSrc,
  brandName,
  catchPhrase,
  redirectUrl,
}) => {
  const backgroundColor = 'white'
  const textColor = 'black'
  const lines = description.split('\n')

  const navigate = useNavigate()

  const accountsStore = useAccountsStore()
  const account = accountsStore.connectedAccount

  const handleRedirect = () => {
    metricalpEvent({
      type: `click_partner_${title.toLowerCase().replace(' ', '_')}`,
      adopterId: account?.id,
      adopterName: `${account?.firstName} ${account?.lastName}`,
    })
    if (redirectUrl) {
      window.open(redirectUrl, '_blank', 'noopener,noreferrer,noopener,noreferrer')
    } else {
      navigate(`/partenaires/${brandName}?animalName=${animalProfile.name}`)
    }
  }
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor: 'white',
        borderRadius: '12px',
        overflow: 'hidden',
        width: '100%',
        height: 130,
        transition: 'all 0.3s',
        cursor: 'pointer',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,0.25)',
        '&:hover': {
          boxShadow: '2px 8px 20px 0 rgba(0,0,0,0.25)',
          '& img': {
            transform: 'scale(1.05)',
          },
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: '50%',
          zIndex: 1,
          transition: 'opacity 0.3s', // Transition for the opacity
          backgroundImage: `linear-gradient(-80deg, ${backgroundColor} 50%, rgba(255, 255, 255, 0) 50.5%)`,
        },
      }}
      onClick={() => handleRedirect()}
    >
      <Box
        component="img"
        src={imgSrc}
        alt="Cat food"
        sx={{
          objectFit: 'cover',
          objectPosition: 'center',
          width: '40%',
          height: '100%', // make image take full height of the container
          zIndex: 0, // Ensure image is behind the pseudo-element
          imageRendering: '-webkit-optimize-contrast',
          transition: 'transform 0.3s ease-in-out', // smooth transition for the scale effect
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '4px',
          p: 1,
          pl: 0,
          ml: -3.5,
          zIndex: 2,
        }}
      >
        <Typography variant="h5" component="h2" sx={{ color: textColor, fontWeight: 'bold', fontSize: '16px' }}>
          {title}
        </Typography>
        <Typography component="p" sx={{ color: textColor, fontSize: '13px', pr: 4, lineHeight: '22px' }}>
          {lines.map((line, index) => (
            <span key={index} style={{ display: 'block', lineHeight: '22px' }}>
              {line}
            </span>
          ))}
        </Typography>
        <Box sx={{ height: '19.5px' }}></Box>
        {catchPhrase && (
          <Box
            sx={{
              position: 'absolute',
              right: 10,
              bottom: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
              width: '100%',
            }}
          >
            <Typography
              component="p"
              sx={{ color: theme.palette.secondary.main, fontSize: '13px', fontWeight: 'bold' }}
              dangerouslySetInnerHTML={{ __html: catchPhrase }}
            ></Typography>
          </Box>
        )}
      </Box>
      <ArrowForwardIos sx={{ fontSize: '16px', color: theme.palette.secondary.main, position: 'absolute', right: 5 }} />
      <Box
        component="img"
        src={logoUrl}
        alt="Cat food"
        sx={{
          objectFit: 'contain',
          height: '18px', // make image take full height of the container
          zIndex: 2, // Ensure image is behind the pseudo-element
          imageRendering: '-webkit-optimize-contrast',
          position: 'absolute',
          right: 10,
          top: 10,
        }}
      />
    </Box>
  )
}
