import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { useEffect } from 'react'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `petso-push.firebaseapp.com`,
  projectId: 'petso-push',
  storageBucket: `petso-push.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export const useFirebaseMessaging = () => {
  useEffect(() => {
    getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
      .then((currentToken) => {
        if (currentToken) {
          AdoptersClient.updateFCMToken(currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
      })

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload)
      // Customize notification here
    })
  }, [])
}
