import { Box, Typography } from '@mui/material'
import L, { LatLngExpression } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import React, { useEffect } from 'react'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { Link } from 'react-router-dom'
import { ServiceLocationWithProvidersNamesDto } from '../../interactors/gen/backendClient'
import MarkerIconPink from './icons/MarkerIconPink.svg'
import { calculateDistance } from '../../utils/calculateDistance'
import { debounce } from 'lodash'

interface Props {
  veterinaryMarkers: ServiceLocationWithProvidersNamesDto[]
  userLocation: number[] | undefined
  setShowButton: (showButton: boolean) => void
  lastFetchedPosition: number[]
}

const veterinaryIcon = new L.Icon({
  iconUrl: MarkerIconPink,
  iconSize: new L.Point(36, 36),
})

const MapComponent: React.FC<Omit<Props, 'userLocation'>> = ({
  veterinaryMarkers,
  setShowButton,
  lastFetchedPosition,
}) => {
  const queryParams = new URLSearchParams(window.location.search)
  const map = useMap()

  useEffect(() => {
    const debouncedOnMove = debounce(() => {
      const mapCenter = { latitude: map.getCenter().lat, longitude: map.getCenter().lng }
      const distance = calculateDistance(mapCenter, {
        latitude: lastFetchedPosition[1],
        longitude: lastFetchedPosition[0],
      })
      if (distance > 5) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
      queryParams.set('lat', map.getCenter().lat.toString())
      queryParams.set('lng', map.getCenter().lng.toString())
      window.history.replaceState(null, '', '?' + queryParams.toString())
    }, 500) // Debounce for 500 milliseconds

    map.on('move', debouncedOnMove)

    return () => {
      map.off('move', debouncedOnMove)
      debouncedOnMove.cancel() // Cancel the debounce to clean up
    }
  }, [map])

  useEffect(() => {
    const debouncedOnZoom = debounce(() => {
      queryParams.set('zoom', map.getZoom().toString())
      window.history.replaceState(null, '', '?' + queryParams.toString())
    }, 500) // Debounce for 500 milliseconds

    map.on('zoom', debouncedOnZoom)

    return () => {
      map.off('zoom', debouncedOnZoom)
    }
  }, [map])

  useEffect(() => {
    if (queryParams.has('lat') && queryParams.has('lng')) {
      let zoom = 12
      const lat = parseFloat(queryParams.get('lat') as string)
      const lng = parseFloat(queryParams.get('lng') as string)
      if (queryParams.has('zoom')) {
        zoom = parseInt(queryParams.get('zoom') as string)
      }
      map.setView([lat, lng], zoom)
    }
  }, [])

  return (
    <MarkerClusterGroup disableClusteringAtZoom={14}>
      {veterinaryMarkers.map((element) => (
        <Marker
          key={`${element.name}-${element.id}`}
          position={[element.coordinates[1], element.coordinates[0]] as LatLngExpression}
          title={element.name}
          icon={veterinaryIcon}
        >
          <Popup>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="h6" sx={{ fontSize: '18px' }}>
                {element.name}
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '5px' }}>
                <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                  {element.address}
                </Typography>
                <Typography sx={{ m: '0 !important', p: '0 !important', fontSize: '16px' }} variant="body1">
                  {element.postalCode}, {element.city}
                </Typography>
              </Box>

              <Link to={`/services/${element.id}`}>
                <Typography variant="body1">Voir la fiche</Typography>
              </Link>
            </Box>
          </Popup>
        </Marker>
      ))}
    </MarkerClusterGroup>
  )
}

export const MapOfFrance: React.FC<Props> = ({
  veterinaryMarkers,
  userLocation,
  setShowButton,
  lastFetchedPosition,
}) => {
  // Position for map centered on France
  const position: LatLngExpression =
    userLocation && userLocation.length > 1 ? [userLocation[1], userLocation[0]] : [46.71109, 1.7191036]

  const isMobile = window.innerWidth < 600

  if (!userLocation || userLocation.length < 2) return null
  return (
    <MapContainer
      style={{ height: `calc(100% - ${isMobile ? '104' : '80'}px)`, width: '100%' }}
      center={position}
      zoom={12}
      scrollWheelZoom={true}
      maxZoom={18}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      />
      <MapComponent
        veterinaryMarkers={veterinaryMarkers}
        setShowButton={setShowButton}
        lastFetchedPosition={lastFetchedPosition}
      />
    </MapContainer>
  )
}
