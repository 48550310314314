import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Modal } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { getReadableHealthObservationsType } from '../../domain/Animal/AnimalDisplay'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import {
  AnimalProfileDto,
  AnimalProfileHealthObservationsDto,
  AnimalProfileHealthObservationsDtoType,
} from '../../interactors/gen/backendClient'
import { isValidPastDateCoherence } from '../../utils/date/isValidPastDateCoherence'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { ControlledDateField } from '../common/ControlledDateField'
import { ControlledMultipleAutoComplete } from '../common/ControlledMultipleAutoComplete'
import { Field } from '../common/FormField'
import { spacingItem } from '../theme'
import { ControlledVeterinariesAutocomplete } from '../common/ControlledVeterinariesAutocomplete'

interface Props {
  animal: AnimalProfileDto
  setAnimal: (animal: AnimalProfileDto) => void
  observation: AnimalProfileHealthObservationsDto
  setObservation: (observation: AnimalProfileHealthObservationsDto) => void
  veterinaryName?: string
}

interface FormData {
  type: AnimalProfileHealthObservationsDtoType[]
  date: string
  veterinary?: string
  additionalInfo?: string
}

export const EditObservationDetailsModal: React.FC<Props> = ({
  animal,
  setAnimal,
  observation,
  setObservation,
  veterinaryName,
}) => {
  const [isModalOpened, setIsModalOpened] = React.useState(false)

  const onCancel = () => {
    setIsModalOpened(false)
  }

  const onSubmit = async (data: FormData) => {
    const veterinaryAndLocation = data.veterinary ? data.veterinary.split('_') : [undefined, undefined]
    const [veterinaryId, locationId] = veterinaryAndLocation

    const newObservation = {
      ...observation,
      type: data.type,
      date: data.date !== observation.date ? dayjs(data.date).format('DD/MM/YYYY') : observation.date,
      veterinary: {
        id: veterinaryId || observation.veterinary?.id || '',
        locationId: locationId || observation.veterinary?.locationId || '',
      },
    }

    const animalWithUpdatedObservation = {
      ...animal,
      health: {
        ...animal.health,
        observations: animal.health.observations
          ? animal.health.observations.map((op) => (op.id === observation.id ? newObservation : op))
          : [],
      },
    }

    const newAnimal = await AnimalProfilesClient.editAnimalProfile(animalWithUpdatedObservation)

    setAnimal(newAnimal)
    setObservation(newObservation)

    setIsModalOpened(false)
  }

  const getDefaultValues = (observation: AnimalProfileHealthObservationsDto): FormData => ({
    type: observation.type,
    date: observation.date,
    veterinary: `${observation.veterinary?.id}_${observation.veterinary?.locationId}`,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(observation, getDefaultValues)

  const observationsListForDog = [
    AnimalProfileHealthObservationsDtoType.ExcessiveObjectChewing,
    AnimalProfileHealthObservationsDtoType.ExcessiveScratching,
    AnimalProfileHealthObservationsDtoType.UnusualFrequentUrination,
    AnimalProfileHealthObservationsDtoType.AppetiteLoss,
    AnimalProfileHealthObservationsDtoType.ExcessiveLicking,
    AnimalProfileHealthObservationsDtoType.UnexplainedAggressiveness,
    AnimalProfileHealthObservationsDtoType.SuddenWeightChange,
    AnimalProfileHealthObservationsDtoType.VomitingDog,
    AnimalProfileHealthObservationsDtoType.Diarrhea,
    AnimalProfileHealthObservationsDtoType.EscapeAttempts,
    AnimalProfileHealthObservationsDtoType.SleepHabitsChange,
    AnimalProfileHealthObservationsDtoType.ConstantBarking,
    AnimalProfileHealthObservationsDtoType.NoBarking,
    AnimalProfileHealthObservationsDtoType.ConstantRestlessness,
    AnimalProfileHealthObservationsDtoType.Lethargy,
    AnimalProfileHealthObservationsDtoType.RefusalToEat,
    AnimalProfileHealthObservationsDtoType.HydrationRefusal,
    AnimalProfileHealthObservationsDtoType.BadBreath,
    AnimalProfileHealthObservationsDtoType.IncreasedTerritorialMarking,
    AnimalProfileHealthObservationsDtoType.Itching,
    AnimalProfileHealthObservationsDtoType.SelfMutilation,
    AnimalProfileHealthObservationsDtoType.WalkingDifficulty,
    AnimalProfileHealthObservationsDtoType.PawPadsIssues,
    AnimalProfileHealthObservationsDtoType.SkinProblems,
    AnimalProfileHealthObservationsDtoType.RedEyes,
    AnimalProfileHealthObservationsDtoType.Drooling,
    AnimalProfileHealthObservationsDtoType.SkinIrritation,
  ]

  const observationsListForCat = [
    AnimalProfileHealthObservationsDtoType.DiarrheaAndStoolIssues,
    AnimalProfileHealthObservationsDtoType.Vomiting,
    AnimalProfileHealthObservationsDtoType.MeowingInLitter,
    AnimalProfileHealthObservationsDtoType.AppetiteChange,
    AnimalProfileHealthObservationsDtoType.ExcessiveWeightGain,
    AnimalProfileHealthObservationsDtoType.IncreasedWaterConsumption,
    AnimalProfileHealthObservationsDtoType.SkinCrusts,
    AnimalProfileHealthObservationsDtoType.RedAndSwollenGums,
    AnimalProfileHealthObservationsDtoType.Panting,
    AnimalProfileHealthObservationsDtoType.Sneezing,
    AnimalProfileHealthObservationsDtoType.NoseBleeding,
    AnimalProfileHealthObservationsDtoType.ExcessiveEyeBlinking,
    AnimalProfileHealthObservationsDtoType.MobilityIssues,
    AnimalProfileHealthObservationsDtoType.InappropriateScratching,
    AnimalProfileHealthObservationsDtoType.NocturnalMeowingOrAgitation,
    AnimalProfileHealthObservationsDtoType.InappropriateElimination,
    AnimalProfileHealthObservationsDtoType.FearfulBehavior,
  ]

  return (
    <>
      <IconButton onClick={() => setIsModalOpened(true)} color="secondary">
        <Edit />
      </IconButton>
      <Modal open={isModalOpened} onClose={onCancel}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              maxWidth: '100%',
              width: '400px',
              maxHeight: '100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              padding: '24px',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              overflowY: 'auto',
            }}
          >
            <Field label="Quel est le type de votre observation ?" required>
              <ControlledMultipleAutoComplete
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={undefined}
                fieldName="type"
                size="small"
                label="Type d'observation"
                options={(animal.species === 'dog' ? observationsListForDog : observationsListForCat).map((type) => ({
                  label: getReadableHealthObservationsType(type),
                  value: type,
                }))}
                requiredRule={"Le type d'observation est requis"}
              />
            </Field>
            <Field label={`A quelle date l'avez vous observé ?`} required>
              <ControlledDateField
                control={control}
                fieldName="date"
                error={errors.date}
                requiredRule={"La date de l'observation est requise"}
                validate={(value) => isValidPastDateCoherence(value) || value === null}
                size="small"
                label="Date de l'observation"
              />
            </Field>
            <Field label="Vétérinaire sélectionné">
              <ControlledVeterinariesAutocomplete
                control={control}
                fieldName="veterinary"
                placeholder={veterinaryName}
              />
            </Field>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="text" onClick={onCancel}>
                Annuler
              </Button>
              <LoadingButton type="submit" variant="contained" loading={false}>
                Enregistrer
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  )
}
