import create from 'zustand'

interface GlobalLoaderStore {
  isLoading: boolean
  setIsLoading: (newValue: boolean) => void
}

export const useGlobalLoaderStore = create<GlobalLoaderStore>()((set) => ({
  isLoading: true,
  setIsLoading: (newValue: boolean) => {
    set({ isLoading: newValue })
  },
}))
