import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PATHS } from './PATHS'

import logo from './logo.svg'

import { ArrowBack, GetAppOutlined, Login, Menu as MenuIcon, ShoppingBasket } from '@mui/icons-material'
import {
  Badge,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery,
} from '@mui/material'
/* import { ReactComponent as BriefcaseIcon } from './icons/BriefcaseIcon.svg' */
import { useBasketStore } from '../store/BasketStore'
import { DownloadAppDrawer } from './DownloadAppDrawer'
import { ReactComponent as PawIcon } from './icons/PawIcon.svg'
import { blue } from './theme'

export const ResponsiveAppBarNotConnected: React.FC = () => {
  const navigate = useNavigate()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [drawerOpen, setDrawerOpen] = React.useState(!isMobile)
  const [appDownloadDrawerOpen, setAppDownloadDrawerOpen] = React.useState(false)

  const { totalItems } = useBasketStore()
  const [basketQuantity, setBasketQuantity] = useState(totalItems())
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (totalItems() > basketQuantity) {
      setAnimate(true)
      setTimeout(() => setAnimate(false), 500) // Reset animation state after 500ms
    }
    setBasketQuantity(totalItems())
  }, [totalItems(), basketQuantity])

  const pages = [{ label: 'Produits', path: PATHS.produits.absolute, icon: <PawIcon /> }]

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  // Usage of toggleDrawer remains the same

  const handleNavigation = (path: string) => {
    if (path === 'null') return
    navigate(path)
    setDrawerOpen(false)
  }

  const handleBasketClick = () => {
    if (location.pathname.includes('panier')) {
      return navigate(`${PATHS.panier.absolute}?step=1`)
    }
    return navigate(PATHS.panier.absolute)
  }

  const handleBackArrowClick = () => {
    navigate(-1)
  }

  const drawerWidth = 240

  const location = useLocation()

  getBasePathFromPath(PATHS.animaux.absolute)

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          background:
            location.pathname.includes('actualites') && !location.pathname.includes('ajouter')
              ? 'rgba(255, 255, 255, 0.15)'
              : 'white',
          transition: 'background .5s .25s ease-out',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: '0px 4px 16px -2px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Box sx={{ flexGrow: 0, display: 'flex', width: '90px' }}>
                <IconButton onClick={() => handleBackArrowClick()} sx={{ color: 'black' }}>
                  <ArrowBack />
                </IconButton>
              </Box>

              <Link to="/" style={{ textDecoration: 'none' }}>
                <Box
                  sx={{
                    mx: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >
                  <Icon sx={{ width: '60px', height: '60px' }}>
                    <img src={logo} style={{ width: '100%', height: '100%' }} />
                  </Icon>
                  <Typography variant="h6" noWrap fontSize={26}>
                    Petso
                  </Typography>
                </Box>
              </Link>
              <Box sx={{ flexGrow: 0, display: 'flex', gap: '10px', width: '90px' }}>
                <IconButton
                  sx={{ color: blue, animation: animate ? 'basketBump 0.5s ease' : 'none' }}
                  onClick={() => handleBasketClick()}
                >
                  <Badge
                    badgeContent={basketQuantity ?? ''}
                    color="primary"
                    invisible={!basketQuantity || basketQuantity === 0}
                  >
                    <ShoppingBasket />
                  </Badge>
                </IconButton>

                {isMobile && (
                  <IconButton onClick={() => toggleDrawer()} sx={{ color: 'black' }}>
                    <MenuIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? drawerOpen : true}
        onClose={() => setDrawerOpen(false)}
        elevation={1}
        anchor={isMobile ? 'right' : 'left'}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'info.main',
            color: 'white',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {pages.map((page) => (
              <ListItem key={page.label} disablePadding>
                {/* We set the backgroundColor depending on whether the item is selected. */}

                <ListItemButton
                  disabled={page.path === 'null'}
                  onClick={() => handleNavigation(page.path)}
                  sx={{
                    backgroundColor: location.pathname.includes(page.path || '') ? 'rgba(99, 123, 148, 1)' : 'inherit',
                    fontWeight: 700,
                    color: 'white',
                    my: '8px',
                    mx: '16px',
                    p: '8px',
                    gap: '8px',
                    borderRadius: '8px',
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '24px' }}>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.label} />
                </ListItemButton>
              </ListItem>
            ))}

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setAppDownloadDrawerOpen(true)}
                sx={{
                  fontWeight: 700,
                  color: 'white',
                  my: '8px',
                  mx: '16px',
                  p: '8px',
                  gap: '8px',
                  borderRadius: '8px',
                }}
              >
                <ListItemIcon sx={{ minWidth: '24px', color: 'white' }}>
                  <GetAppOutlined />
                </ListItemIcon>
                <ListItemText primary="Installer l'app" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => navigate(`${PATHS.login.absolute}?from=${location.pathname.replace('/', '')}`)}
                sx={{
                  fontWeight: 700,
                  color: 'white',
                  my: '8px',
                  mx: '16px',
                  p: '8px',
                  gap: '8px',
                  borderRadius: '8px',
                }}
              >
                <ListItemIcon sx={{ minWidth: '24px', color: 'white' }}>
                  <Login />
                </ListItemIcon>
                <ListItemText primary="Se connecter" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <DownloadAppDrawer drawerOpen={appDownloadDrawerOpen} setDrawerOpen={setAppDownloadDrawerOpen} />
    </>
  )
}

function getBasePathFromPath(path: string) {
  const pathParts = path.split('/')
  return pathParts.slice(0, pathParts.length - 1).join('/')
}
