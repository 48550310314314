import { Box, Button, Grow, InputAdornment, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useForm } from 'react-hook-form'
import { UseMutationResult } from 'react-query'
import {
  getQuestionsForFeeding,
  getReadableCatAgeQuestions,
  getReadableCatLifestyleQuestions,
  getReadableCatMealFrequencyQuestions,
} from '../../../domain/AnimalProfileDisplay/HealthQuestionsDisplay'
import {
  AnimalFormAndActivityDtoActivity,
  AnimalFormAndActivityDtoCorpulence,
  AnimalHealthDtoSterilizedOrNeutered,
  AnimalProfileDto,
  EatsGoodAmountDtoAge,
  EatsGoodAmountDtoLifestyle,
  EnvironmentQuestionsDtoHasAccessToSecuredWindows,
  FeedingQuestionsDto,
  FeedingQuestionsDtoMealFrequency,
} from '../../../interactors/gen/backendClient'
import { ControlledMultipleToggleButton } from '../../common/ControlledMutipleToggleButton'
import { Field } from '../../common/FormField'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import {
  getReadableAnimalActivity,
  getReadableAnimalCorpulence,
  getReadableAnimalSterilizedOrNeutered,
} from '../../../domain/Animal/AnimalDisplay'
import { omit } from 'lodash'
import { ControlledSelectField } from '../../common/ControlledSelectField'

interface Props {
  animalProfile: AnimalProfileDto
  editMutation: UseMutationResult<AnimalProfileDto, unknown, AnimalProfileDto, unknown>
}

interface SterilizedAndBreed {
  sterilizedOrNeutered: AnimalProfileDto['health']['sterilizedOrNeutered']
  breed: string
  weightInKgs: string
}

export type FeedingQuestionsWithSterilizedAndBreed = FeedingQuestionsDto & SterilizedAndBreed

export const FeedingInitialQuestions: React.FC<Props> = ({ animalProfile, editMutation }) => {
  const [step, setStep] = React.useState(0)

  const initialAnimalWeight =
    animalProfile.formAndActivity && animalProfile.formAndActivity?.weight
      ? animalProfile.formAndActivity?.weight[animalProfile.formAndActivity?.weight.length - 1]?.weightInKgs
      : undefined

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<FeedingQuestionsWithSterilizedAndBreed>({
    defaultValues: {
      eatsGoodAmount: {
        activityLevel: animalProfile.formAndActivity?.activity,
        corpulence: animalProfile.formAndActivity?.corpulence,
      },
      sterilizedOrNeutered: animalProfile.health.sterilizedOrNeutered,
      weightInKgs: initialAnimalWeight || '',
      date: dayjs().toISOString(),
    },
  })

  const onSubmit = (data: FeedingQuestionsWithSterilizedAndBreed) => {
    const feedingQuestionsArray = animalProfile.health.score?.feedingQuestions || []
    feedingQuestionsArray.push(omit(data, 'sterilizedOrNeutered', 'breed', 'weightInKgs'))
    const newAnimalProfile: AnimalProfileDto = {
      ...animalProfile,
      breed: {
        ...animalProfile.breed,
        breedTitle: animalProfile.breed?.breedTitle || 'breed-type',
        breed: data.breed,
      },
      health: {
        ...animalProfile.health,
        sterilizedOrNeutered: data.sterilizedOrNeutered,
        score: {
          ...animalProfile.health.score,
          healthScore: animalProfile.health.score?.healthScore || 0,
          feedingQuestions: feedingQuestionsArray,
        },
      },
      formAndActivity: {
        ...animalProfile.formAndActivity,
        weight: animalProfile.formAndActivity?.weight || [],
      },
    }
    if (data.weightInKgs && data.weightInKgs !== initialAnimalWeight) {
      newAnimalProfile.formAndActivity?.weight?.push({
        dateOfWeightIn: data.date,
        weightInKgs: data.weightInKgs,
      })
    }
    editMutation.mutate(newAnimalProfile)
  }

  return (
    <form onSubmit={() => handleSubmit(onSubmit)()} style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Box>
        {step === 0 && (
          <Grow in={step === 0}>
            <Box>
              <Field label={getQuestionsForFeeding('mealFrequency')}>
                <ControlledMultipleToggleButton
                  control={control}
                  error={errors.mealFrequency}
                  fieldName="mealFrequency"
                  options={Object.values(FeedingQuestionsDtoMealFrequency).map((value) => ({
                    label: getReadableCatMealFrequencyQuestions(value),
                    value,
                  }))}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 1 && (
          <Grow in={step === 1}>
            <Box>
              <Field label={getQuestionsForFeeding('catHydration')}>
                <ControlledToggleButton
                  control={control}
                  error={errors.catHydration}
                  fieldName="catHydration"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 2 && (
          <Grow in={step === 2}>
            <Box>
              <Field label={getQuestionsForFeeding('hasWaterFountain')}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasWaterFountain}
                  fieldName="hasWaterFountain"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 3 && (
          <Grow in={step === 3}>
            <Box>
              <Field label={getQuestionsForFeeding('eatsGoodAmount')}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2, width: '100%' }}>
                  <ControlledSelectField
                    control={control}
                    fieldName="sterilizedOrNeutered"
                    error={errors.sterilizedOrNeutered}
                    requiredRule={undefined}
                    options={Object.values(AnimalHealthDtoSterilizedOrNeutered).map(
                      (status: AnimalHealthDtoSterilizedOrNeutered) => ({
                        label: getReadableAnimalSterilizedOrNeutered(status),
                        value: status,
                      })
                    )}
                    label="Stérilisé(e) ou castré(e)"
                    size="small"
                  />
                  <TextField
                    label="Poids de l'animal"
                    type="number"
                    inputProps={{
                      step: '0.01',
                      inputMode: 'decimal',
                      pattern: '[0-9]*',
                    }}
                    fullWidth
                    {...register('weightInKgs')}
                    hiddenLabel
                    error={undefined}
                    helperText={"Si modifié, poids précédent sera conservé dans l'historique"}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                  />
                  <ControlledMultipleToggleButton
                    control={control}
                    label="Quel est le stade de vie de votre chat ?"
                    error={errors.eatsGoodAmount?.age}
                    fieldName="eatsGoodAmount.age"
                    options={Object.values(EatsGoodAmountDtoAge).map((value) => ({
                      label: getReadableCatAgeQuestions(value),
                      value,
                    }))}
                    requiredRule="Ce champ est obligatoire"
                    fullWidth
                  />
                  <ControlledMultipleToggleButton
                    control={control}
                    label="Où est ce que votre chat passe la plupart de son temps ?"
                    error={errors.eatsGoodAmount?.lifestyle}
                    fieldName="eatsGoodAmount.lifestyle"
                    options={Object.values(EatsGoodAmountDtoLifestyle).map((value) => ({
                      label: getReadableCatLifestyleQuestions(value),
                      value,
                    }))}
                    requiredRule="Ce champ est obligatoire"
                    fullWidth
                  />
                  <ControlledMultipleToggleButton
                    control={control}
                    label="Quel est le niveau d'activité de votre chat ?"
                    error={errors.eatsGoodAmount?.activityLevel}
                    fieldName="eatsGoodAmount.activityLevel"
                    options={Object.values(AnimalFormAndActivityDtoActivity).map((value) => ({
                      label: getReadableAnimalActivity(value),
                      value,
                    }))}
                    requiredRule="Ce champ est obligatoire"
                    fullWidth
                  />
                  <ControlledMultipleToggleButton
                    control={control}
                    label="Quelle est la condition physique de votre chat ?"
                    error={errors.eatsGoodAmount?.corpulence}
                    fieldName="eatsGoodAmount.corpulence"
                    options={Object.values(AnimalFormAndActivityDtoCorpulence).map((value) => ({
                      label: getReadableAnimalCorpulence(value),
                      value,
                    }))}
                    requiredRule="Ce champ est obligatoire"
                    fullWidth
                  />
                  <Button variant="contained" type="button" onClick={() => setStep((prev) => prev + 1)}>
                    Valider
                  </Button>
                </Box>
              </Field>
            </Box>
          </Grow>
        )}
        {step === 4 && (
          <Grow in={step === 4}>
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2, width: '100%' }}>
                <Field label="Quelle est la portion journalière de nourriture que vous donnez à votre chat ?">
                  <TextField
                    {...register('nutritionalIntake.dailyPortion')}
                    aria-label="Quelle est la portion journalière de nourriture que vous donnez à votre chat ?"
                    fullWidth
                    type="number"
                    helperText="Indiquez la portion en grammes"
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" sx={{ color: 'black' }}>
                          grammes
                        </Typography>
                      ),
                    }}
                  />
                </Field>
                <Field label="Apport nutritionnel /100g de son alimentation (au dos du paquet )">
                  <TextField
                    {...register('nutritionalIntake.metabolicEnergy')}
                    aria-label="Apport nutritionnel /100g de son alimentation (au dos du paquet )"
                    fullWidth
                    type="number"
                    helperText="Indiquez l'apport nutritionel en kcal/100g"
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" sx={{ color: 'black' }}>
                          kcal/100g
                        </Typography>
                      ),
                    }}
                  />
                </Field>
                <Button variant="contained" type="button" onClick={() => handleSubmit(onSubmit)()}>
                  Valider
                </Button>
              </Box>
            </Box>
          </Grow>
        )}
      </Box>
    </form>
  )
}
