import React from 'react'
import { CustomerDto } from '../../interactors/gen/backendClient'
import { Box, Typography } from '@mui/material'

interface Props {
  association: CustomerDto
}

export const AdoptionAssociationCard: React.FC<Props> = ({ association }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', borderTop: '1px solid #CCCCCC', mt: 2, pt: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h2" component="p" sx={{ color: '#4f4f4f', fontSize: '15px' }}>
            {association.name}
          </Typography>
          <Typography variant="body2" sx={{ color: '#4f4f4f', fontSize: '13px' }}>
            {association.email}
          </Typography>
        </Box>
        <img
          style={{
            maxWidth: '95px',
            height: '42px',
            objectFit: 'cover',
            borderRadius: '100%',
          }}
          src={`https://logo-customer-images.s3.eu-west-3.amazonaws.com/${association.id}.jpg`}
        />
      </Box>
      <Box sx={{ display: { md: 'flex', xs: 'none' }, flexDirection: 'column', gap: '8px' }}>
        {association.SIRETNumber && (
          <Typography variant="body2" sx={{ color: '#4f4f4f' }}>
            <b>Numéro de SIRET</b> {association.SIRETNumber}
          </Typography>
        )}
        {association.postalCode && (
          <Typography variant="body2" sx={{ color: '#4f4f4f' }}>
            <b>Code Postal</b> {association.postalCode}
          </Typography>
        )}
        {association.phoneNumber && (
          <Typography variant="body2" sx={{ color: '#4f4f4f' }}>
            <b>Téléphone</b> {association.phoneNumber}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
