import { Autocomplete, BaseTextFieldProps, Chip, TextField } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { blueSky } from '../theme'
import { isColorDark } from '../../utils/isColorDark'

interface Option {
  label: string
  value: string | number
  color?: string
}

type Props<T extends FieldValues> = {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Option[]
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  defaultValue: string
  chip?: boolean // Added chip prop here
}

export function ControlledAutoCompleteWithCustomValue<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  options,
  size,
  disabled,
  requiredRule,
  defaultValue,
  chip = false,
}: PropsWithChildren<Props<T>>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue as any}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          sx={{ width: '100%' }}
          onChange={(_, data) => onChange(data ? data.value : null)}
          id={fieldName}
          options={options}
          getOptionLabel={(option) => (chip ? '' : option.label)}
          value={options.find((option) => option.value === value) || { label: '', value: '' }}
          size={size}
          disabled={disabled}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              required={requiredRule ? value === null : false}
              error={!!error}
              // control value based on chip prop
              InputProps={{
                ...params.InputProps,
                startAdornment:
                  chip && value ? (
                    <Chip
                      key={value}
                      label={options.find((option) => option.value === value)?.label || ''}
                      sx={{
                        backgroundColor: options.find((option) => option.value === value)?.color || blueSky,
                        color: isColorDark(options.find((option) => option.value === value)?.color || blueSky)
                          ? 'white'
                          : 'black',
                        marginY: '-4.5px !important',
                        marginTop: '-1.5px',
                        height: '28px',
                        '& .MuiChip-label': {
                          lineHeight: '24px',
                        },
                      }}
                    />
                  ) : undefined,
              }}
            />
          )}
        />
      )}
    />
  )
}
