import { Android, Apple } from '@mui/icons-material'
import { Drawer, Box, Typography, Button } from '@mui/material'
import React from 'react'

interface Props {
  drawerOpen: boolean
  setDrawerOpen: (value: boolean) => void
}

export const DownloadAppDrawer: React.FC<Props> = ({ drawerOpen, setDrawerOpen }) => {
  const [platform, setPlatform] = React.useState<'ios' | 'android' | null>(null)
  return (
    <Drawer anchor={'bottom'} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      {platform === 'ios' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', p: 2 }}>
          <Typography variant="h2" component="h2" sx={{ fontSize: '22px', lineHeight: '24.8px', fontWeight: 700 }}>
            Comment installer l&#39;application sur iOS ?
          </Typography>
          <iframe
            width="315"
            height="560"
            src="https://www.youtube.com/embed/iMSXKaHuMqE"
            title="Le tuto vidéo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setPlatform(null)
              setDrawerOpen(false)
            }}
          >
            Fermer
          </Button>
        </Box>
      )}
      {platform === 'android' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', p: 2 }}>
          <Typography variant="h2" component="h2" sx={{ fontSize: '22px', lineHeight: '24.8px', fontWeight: 700 }}>
            Comment installer l&#39;application sur Android avec Chrome?
          </Typography>
          {/* <Typography>Etape 1 : Cliquez sur les 3 points en haut à droite de votre navigateur</Typography>
          <Typography>{`Etape 2 : Cliquez sur "Installer l'application" ou "Ajouter à l'écran d'accueil"`}</Typography>
          <Typography>{`Etape 3 : Cliquez sur "Installer"`}</Typography> */}
          <iframe
            width="315"
            height="560"
            src="https://www.youtube.com/embed/LJj-EMeBC1g"
            title="Le tuto vidéo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setPlatform(null)
              setDrawerOpen(false)
            }}
          >
            Fermer
          </Button>
        </Box>
      )}
      {platform === null && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', p: 2 }}>
          <Typography variant="h2" component="h2" sx={{ fontSize: '22px', lineHeight: '24.8px', fontWeight: 700 }}>
            Quelle est votre plateforme ?
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setPlatform('ios')
            }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}
          >
            <Apple />
            <Typography>iOS</Typography>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setPlatform('android')
            }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' }}
          >
            <Android />
            <Typography>Android</Typography>
          </Button>
        </Box>
      )}
    </Drawer>
  )
}
