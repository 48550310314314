import { Box, Typography } from '@mui/material'
import React from 'react'
import { theme } from '../../../theme'
import { LoadingBar } from '../LoadingBar'
import { AnimalDtoSpecies } from '../../../../interactors/gen/backendClient'

interface StepsProps {
  step: number
  species: AnimalDtoSpecies
}

export const Three: React.FC<StepsProps> = ({ step, species }) => {
  if (species === 'dog') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 2, height: '100%' }}>
        <LoadingBar step={step} />
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Santé et soins courants
        </Box>
        <Typography variant="body1" sx={{ color: 'black', fontSize: '18px' }}>
          <span style={{ fontWeight: 700, color: theme.palette.secondary.main }}>
            Prévoyez entre 100 à 300 € par an pour un chien en bonne santé
          </span>{' '}
          pour assurer les traitements incontournables, tels que les vaccins, les antiparasitaires et les vermifuges.
        </Typography>
        <ul style={{ paddingLeft: 20 }}>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            <b>Un suivi vétérinaire annuel est essentiel,</b> ainsi que des soins réguliers : coupe des griffes,
            nettoyage des yeux et oreilles, détartrage et suivi du poids.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            Le toilettage peut aussi représenter un coût, selon la race et le mode de vie.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            La stérilisation, bien que non obligatoire, est fortement recommandée pour{' '}
            <b>protéger votre chien et éviter les portées</b> non désirées. Coût : entre 100 et 600 €.
          </Typography>
        </ul>
      </Box>
    )
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 2, height: '100%' }}>
        <LoadingBar step={step} />
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Santé et soins courants
        </Box>
        <Typography variant="body1" sx={{ color: 'black', fontSize: '18px' }}>
          <span style={{ fontWeight: 700, color: theme.palette.secondary.main }}>
            Prévoyez entre 100 à 300 € par an pour un chat en bonne santé
          </span>{' '}
          pour assurer les traitements incontournables, tels que les vaccins, les antiparasitaires et les vermifuges.
        </Typography>
        <ul style={{ paddingLeft: 20 }}>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            <b>Un suivi vétérinaire annuel est essentiel,</b> ainsi que des soins réguliers : coupe des griffes,
            nettoyage des yeux et oreilles, détartrage et suivi du poids.
          </Typography>
          <Typography
            component="li"
            variant="body1"
            sx={{ fontWeight: 700, color: theme.palette.secondary.main, mb: 2 }}
          >
            Observez attentivement tout changement de comportement ou d&apos;appétit.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            La stérilisation, bien que non obligatoire, est <b>fortement recommandée</b> et peut être exigée par
            l’association lors de l’adoption. Coût : entre 100 et 250 €.
          </Typography>
        </ul>
      </Box>
    )
  }
}
