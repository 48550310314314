import { ArrowBack } from '@mui/icons-material'
import { AppBar, Box, Button, IconButton, Paper, TextField, Toolbar, Typography } from '@mui/material'
import Pusher from 'pusher-js'
import React, { useEffect, useState } from 'react'
import { LocalStorageToken } from '../../interactors/clients/AuthClient'
import { backendClient, getBackendBaseUrl } from '../../interactors/clients/client'
import { useAccountsStore } from '../../store/AccountsStore'
import { ChatMessageDto, ConversationDto, CreateChatMessageDto } from '../../interactors/gen/backendClient'
import dayjs from 'dayjs'
import { getDayLabel } from '../../utils/date/getDayLabel'
import { lightBlue } from '../theme'

interface GroupedMessages {
  [date: string]: ChatMessageDto[]
}

interface Props {
  conversation: ConversationDto
  handleBackClick: () => void
}

export const ChatView: React.FC<Props> = ({ conversation, handleBackClick }) => {
  const accountsStore = useAccountsStore()
  const currentUserId = accountsStore.connectedAccount?.id
  const receiverIndex = conversation.participants.findIndex((participant) => participant !== currentUserId)
  const [messages, setMessages] = useState<ChatMessageDto[]>([])
  const [newMessage, setNewMessage] = useState('')

  useEffect(() => {
    const fetchMessages = async () => {
      const fetchedMessages = await backendClient.get(`/adopters-platform/messages/${conversation.id}`)
      setMessages(fetchedMessages.data)
    }
    fetchMessages()
  }, [conversation])

  useEffect(() => {
    const appKey = process.env.REACT_APP_PUSHER_KEY
    if (!appKey) {
      throw new Error('REACT_APP_PUSHER_KEY is not defined')
    }
    const pusher = new Pusher(appKey, {
      cluster: 'eu',
      userAuthentication: {
        endpoint: `${getBackendBaseUrl()}/v1/pusher/auth`,
        transport: 'ajax',
        params: { currentUserId },
        headers: { Authorization: `Bearer ${LocalStorageToken.getCurrentToken()}` },
      },
      channelAuthorization: {
        endpoint: `${getBackendBaseUrl()}/v1/pusher/channel-auth`,
        transport: 'ajax',
        params: { currentUserId },
        headers: { Authorization: `Bearer ${LocalStorageToken.getCurrentToken()}` },
      },
      forceTLS: false,
    })
    const conversationId = `private-presence-conversation-${conversation.id}`
    const channel = pusher.subscribe(conversationId)

    channel.bind('new-message', (data: ChatMessageDto) => {
      console.log('user', data._id)
      backendClient.get(`/adopters-platform/messages/read/${data._id}`)
      setMessages((prevMessages) => [...prevMessages, data])
    })

    return () => {
      channel.unbind_all()
      channel.unsubscribe()
      pusher.disconnect()
    }
  }, [currentUserId, conversation])

  useEffect(() => {
    const scrollDown = () => {
      const chatContainer = document.querySelector('.scrollable-content') as HTMLElement
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
    scrollDown()
  }, [messages])

  const handleSendMessage = () => {
    // Implement sending message logic here
    const newMessageToSend: CreateChatMessageDto = {
      message: newMessage,
      conversationId: conversation.id,
    }
    backendClient.post('/adopters-platform/messages', newMessageToSend)
    setNewMessage('') // Clear input after sending
  }

  const groupMessagesByDate = (messages: ChatMessageDto[]) => {
    return messages.reduce<GroupedMessages>((acc, message) => {
      // Format the message date to a simple YYYY-MM-DD format for comparison
      const date = dayjs(message.createdAt).format('YYYY-MM-DD')
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(message)
      return acc
    }, {})
  }

  const groupedMessages = groupMessagesByDate(messages) // Your messages array

  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => handleBackClick()}
            sx={{ position: 'absolute', left: 20 }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="h1" sx={{ fontSize: '18px', fontWeight: 700 }}>
            {receiverIndex !== -1 && conversation.participantNames
              ? conversation.participantNames[receiverIndex]
              : 'Nom du destinataire inconnu'}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ flex: 1, overflowY: 'auto', padding: 2 }}>
        {Object.entries(groupedMessages).map(([date, messages]) => (
          <React.Fragment key={date}>
            <Typography variant="h6" sx={{ textAlign: 'center', margin: 2 }}>
              {getDayLabel(date)}
            </Typography>
            {messages.map((message, index) => (
              <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 1,
                    margin: 1,
                    maxWidth: { md: '48%', xs: '80%' },
                    marginLeft: message.senderId === currentUserId ? 'auto' : 0,
                    marginRight: message.senderId === currentUserId ? 0 : 'auto',
                    backgroundColor: message.senderId === currentUserId ? '#f0f0f0' : lightBlue,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    gap: 2,
                  }}
                >
                  <Typography>{message.message}</Typography>
                  <Typography sx={{ fontSize: '12px' }}>{`${dayjs(message.createdAt).hour()}:${
                    dayjs(message.createdAt).minute() < 10
                      ? `0${dayjs(message.createdAt).minute()}`
                      : dayjs(message.createdAt).minute()
                  }`}</Typography>
                </Paper>
              </Box>
            ))}
          </React.Fragment>
        ))}
      </Box>
      <Box
        sx={{
          padding: 2,
          borderTop: '1px solid #ccc',
          backgroundColor: 'white',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Entrez votre message ici..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleSendMessage} sx={{ marginTop: 1 }} variant="contained" color="primary" type="button">
            Envoyer
          </Button>
        </Box>
      </Box>
    </>
  )
}
