import React, { useState } from 'react'
import { AdoptionAttemptDto } from '../../interactors/gen/backendClient'
import { Box, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { colors } from '../theme'
import { backendClient } from '../../interactors/clients/client'
import { LoadingButton } from '@mui/lab'
import { Close } from '@mui/icons-material'

interface Props {
  attempt: AdoptionAttemptDto
  animalName: string
  fullySetAdoptionAttempt: (attempt: AdoptionAttemptDto) => void
}

export const FoodSampleCard: React.FC<Props> = ({ attempt, animalName, fullySetAdoptionAttempt }) => {
  const [selectedValue, setSelectedValue] = useState<'fish' | 'chicken'>('fish')
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)

  const handleToggle = (_: React.MouseEvent<HTMLElement>, newValue: 'fish' | 'chicken' | null) => {
    if (newValue !== null) {
      setSelectedValue(newValue)
    }
  }

  const onSubmit = async (refused?: boolean) => {
    setLoading(true)
    const updatedAttempt = await backendClient.post(`/adopters-platform/accounts/food-sample/${attempt.id}`, {
      choice: refused ? 'refused' : selectedValue,
    })
    fullySetAdoptionAttempt(updatedAttempt.data)
    setStep(2)
    setLoading(false)
  }

  return step === 2 ? (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        mt: 4,
        p: 3,
        border: '1px solid #ddd',
        borderRadius: 2,
        maxWidth: 400,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <IconButton
        onClick={() => setStep(3)}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'grey.600',
        }}
        aria-label="close"
      >
        <Close />
      </IconButton>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        <b>Commande envoyée ! 🎉</b>
        <br />
        Vous recevrez par email un lien de suivi
        <br />
        Livraison en 72h !
      </Typography>
    </Box>
  ) : attempt.hasGottenFoodSample ? null : (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', mt: 4 }}>
      <Box
        sx={{
          backgroundColor: colors.blueSky,
          color: 'white',
          fontSize: '22px',
          fontWeight: 700,
          width: 'fit-content',
          padding: '8px',
          pr: '50px',
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        La pâtée de bienvenue 🎁
      </Box>
      <Typography variant="body1" sx={{ textAlign: 'center', maxWidth: 400 }}>
        Pour l&apos;adoption de {animalName}, sa première friandise vous est offerte !
        <br />
        <b>Faites le bon choix !</b>
      </Typography>
      <ToggleButtonGroup
        value={selectedValue}
        exclusive
        onChange={handleToggle}
        sx={{
          display: 'flex',
          gap: 4,
          justifyContent: 'center',
          mt: 2,
        }}
      >
        {/* Fish Toggle */}
        <ToggleButton
          value="fish"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 160,
            height: 220,
            borderRadius: '16px !important',
            border: selectedValue === 'fish' ? '2px solid #ffcc80 !important' : '1px solid #ddd !important',
            backgroundColor: selectedValue === 'fish' ? '#fff9e6' : 'white',
            boxShadow:
              selectedValue === 'fish' ? '0px 4px 12px rgba(255, 193, 7, 0.3)' : '0px 2px 6px rgba(0, 0, 0, 0.1)',
            padding: 2,
            '&:hover': {
              backgroundColor: '#fff3e0',
            },
          }}
        >
          <Box
            component="img"
            src="https://i.imgur.com/VMTzlfX.png"
            alt="Thon du pacifique"
            sx={{
              width: '100%',
              height: 'auto',
              mb: 1,
              borderRadius: 1,
            }}
          />
          <Typography
            variant="subtitle1"
            color="text.primary"
            sx={{ fontWeight: 'bold', textAlign: 'center', mb: 0.5 }}
          >
            Thon du pacifique
          </Typography>
          <Typography variant="h5">🐟🐠</Typography>
        </ToggleButton>

        {/* Chicken Toggle */}
        <ToggleButton
          value="chicken"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 160,
            height: 220,
            borderRadius: '16px !important',
            border: selectedValue === 'chicken' ? '2px solid #ffcc80 !important' : '1px solid #ddd !important',
            backgroundColor: selectedValue === 'chicken' ? '#fff9e6' : 'white',
            boxShadow:
              selectedValue === 'chicken' ? '0px 4px 12px rgba(255, 193, 7, 0.3)' : '0px 2px 6px rgba(0, 0, 0, 0.1)',
            padding: 2,
            '&:hover': {
              backgroundColor: '#fff3e0',
            },
          }}
        >
          <Box
            component="img"
            src="https://i.imgur.com/raX1ik6.png"
            alt="Poulet & Citrouille"
            sx={{
              width: '100%',
              height: 'auto',
              mb: 1,
              borderRadius: 1,
            }}
          />
          <Typography
            variant="subtitle1"
            color="text.primary"
            sx={{ fontWeight: 'bold', textAlign: 'center', mb: 0.5 }}
          >
            Poulet & Citrouille
          </Typography>
          <Typography variant="h5">🍗🎃</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 360, mt: 2 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          sx={{ fontSize: '18px', mb: 1 }}
          onClick={() => onSubmit()}
          loading={loading}
        >
          Recevoir maintenant
        </LoadingButton>
        <LoadingButton
          variant="text"
          sx={{
            color: '#6b7990',
            textTransform: 'none',
            fontSize: '18px',
            textDecoration: 'underline',
            ':hover': {
              backgroundColor: '#eef2f7',
            },
          }}
          onClick={() => onSubmit(true)}
          loading={loading}
        >
          Ne pas en bénéficier
        </LoadingButton>
      </Box>
    </Box>
  )
}
