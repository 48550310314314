import {
  List,
  LocalHospital,
  LocalHospitalOutlined,
  Map,
  PetsOutlined,
  Psychology,
  PsychologyOutlined,
} from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { ServiceLocationsClient } from '../../interactors/clients/ServiceLocationsClient'
import { ServiceLocationDtoType, ServiceLocationWithProvidersNamesDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { lightGrey, theme } from '../theme'
import { MapOfFrance } from './MapOfFrance'
import { ServiceLocationItem } from './ServiceLocationItem'

export const ServicesMapScreen: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [locations, setLocations] = React.useState<ServiceLocationWithProvidersNamesDto[]>([])
  const [userLocation, setUserLocation] = React.useState<number[]>([])
  const [showButton, setShowButton] = React.useState(false)
  const [lastFetchedPosition, setLastFetchedPosition] = React.useState<number[]>([0, 0])

  const accountsStore = useAccountsStore()
  const user = accountsStore.connectedAccount

  const queryParams = new URLSearchParams(window.location.search)
  const [isListOpen, setIsListOpen] = React.useState<boolean>(queryParams.get('list') === 'true')
  const [tab, setTab] = React.useState<ServiceLocationDtoType>(
    (queryParams.get('tab') as ServiceLocationDtoType) || 'veterinary'
  )

  useEffect(() => {
    const fetchLocations = async (coordinates: number[]) => {
      const tab = (queryParams.get('tab') as ServiceLocationDtoType) || 'veterinary'
      const locations = await ServiceLocationsClient.getAllLocations({
        page: 1,
        limit: 100,
        coordinates,
        type: tab,
      })
      setLastFetchedPosition(coordinates)
      if (tab === 'behaviorist') {
        setIsListOpen(true)
      } else {
        const listState = queryParams.get('list') === 'true'
        setIsListOpen(listState)
      }
      setLocations(locations)
    }
    getUserLocation()
      .then((position) => {
        console.log('User location:', position)
        const coordinates: number[] = [
          position.coords.longitude ?? user?.coordinates?.lng,
          position.coords.latitude ?? user?.coordinates?.lat,
        ]
        setUserLocation(coordinates)
        fetchLocations(coordinates)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error getting user location:', error)
        const coordinates: number[] = [user?.coordinates?.lng ?? 2.349014, user?.coordinates?.lat ?? 48.864716]
        setUserLocation(coordinates)
        fetchLocations(coordinates)
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    const fetchLocations = async (coordinates: number[]) => {
      setIsLoading(true)
      const locations = await ServiceLocationsClient.getAllLocations({
        page: 1,
        limit: 100,
        coordinates,
        type: tab,
      })
      setLastFetchedPosition(coordinates)
      setLocations(locations)
      setIsLoading(false)
    }
    fetchLocations(userLocation)
    if (tab === 'veterinary' && userLocation.length === 2) {
      setIsListOpen(false)
      queryParams.set('lat', userLocation[1].toString())
      queryParams.set('lng', userLocation[0].toString())
      queryParams.delete('zoom')
    }
    queryParams.set('tab', tab)
    window.history.replaceState(null, '', '?' + queryParams.toString())
  }, [tab])

  useEffect(() => {
    queryParams.set('list', isListOpen.toString())
    window.history.replaceState(null, '', '?' + queryParams.toString())
  }, [isListOpen])

  const handleSearchInThisArea = async () => {
    // Ensure the most current queryParams are used
    const currentQueryParams = new URLSearchParams(window.location.search)
    const coordinates: number[] = [Number(currentQueryParams.get('lng')), Number(currentQueryParams.get('lat'))]

    const locations = await ServiceLocationsClient.getAllLocations({
      page: 1,
      limit: 100,
      coordinates,
      type: tab, // Assuming 'tab' is correctly reflecting the current state
    })
    setLastFetchedPosition(coordinates)
    setShowButton(false)
    setLocations(locations)
  }

  if (!locations || !userLocation || isLoading) return <CircularProgressPanel />

  return (
    <Box
      sx={{
        padding: { xs: 0, md: 0 },
        minHeight: '100%',
        maxHeight: { xs: 'calc(100% - 60px)', md: 'calc(100% - 64px)' },
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: '100%',
          marginX: 'auto',
          mb: 0,
          p: 0,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isListOpen ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              backgroundColor: { xs: lightGrey, md: 'white' },
              flexGrow: 1,
              maxWidth: { xs: '100%', md: '1200px' },
              mx: { xs: '0', md: 'auto' },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                color: theme.palette.secondary.main,
                backgroundColor: 'white',
                padding: '16px',
              }}
            >
              {translateCategory(tab)}
            </Typography>

            {locations.map((location) => (
              <ServiceLocationItem key={location.id} location={location} userLocation={userLocation} />
            ))}
            <Box sx={{ height: { xs: '104px', md: '80px' } }} />
          </Box>
        ) : (
          <MapOfFrance
            key={lastFetchedPosition.join('-')}
            veterinaryMarkers={locations}
            userLocation={userLocation}
            setShowButton={setShowButton}
            lastFetchedPosition={lastFetchedPosition}
          />
        )}
        {!isListOpen && (
          <Box
            sx={{
              position: 'fixed',
              top: '90px',
              left: '50%',
              transform: `translateX(-50%) ${showButton ? 'translateY(0)' : 'translateY(-20px)'}`, // Combine transformations
              zIndex: 1000,
              backgroundColor: 'white',
              borderRadius: '9999px',
              opacity: showButton ? 1 : 0,
              transition: 'opacity 0.5s ease-out, transform 0.5s ease-out', // Apply transition
            }}
          >
            <Button
              onClick={() => handleSearchInThisArea()}
              sx={{
                color: theme.palette.secondary.main,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)',
                borderRadius: '9999px',
              }}
              disabled={tab === 'behaviorist'}
            >
              Rechercher dans cette zone
            </Button>
          </Box>
        )}
        <Box
          sx={{
            position: 'fixed',
            bottom: '116px',
            left: { xs: '16px', md: '256px' },
            zIndex: 1000,
            backgroundColor: 'white',
            borderRadius: '9999px',
          }}
        >
          <IconButton
            onClick={() => setIsListOpen(!isListOpen)}
            sx={{
              color: theme.palette.secondary.main,
            }}
            disabled={tab === 'behaviorist'}
          >
            {isListOpen ? <Map sx={{ fontSize: '32px' }} /> : <List sx={{ fontSize: '32px' }} />}
          </IconButton>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: { xs: 0, md: '240px' },
            right: 0,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderTop: '1px solid #E0E0E0',
            boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.10)',
            pb: { xs: '24px', md: '0px' },
          }}
        >
          <Button
            variant="text"
            sx={{
              borderTop: tab === 'veterinary' ? '2px solid pink' : 'none',
              borderRadius: '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '8px',
              transition: 'colors 0.3s ease-in-out',
              px: '8px',
            }}
            onClick={() => {
              setIsListOpen(false)
              setTab('veterinary')
            }}
          >
            {tab === 'veterinary' ? (
              <LocalHospital sx={{ fontSize: '36px', color: 'pink' }} />
            ) : (
              <LocalHospitalOutlined sx={{ fontSize: '36px', color: 'pink' }} />
            )}
            <Typography sx={{ fontSize: '14px', fontWeight: tab === 'veterinary' ? 'bold' : '400', color: 'black' }}>
              Vétérinaires
            </Typography>
          </Button>
          <Button
            variant="text"
            sx={{
              borderTop: tab === 'behaviorist' ? '2px solid orange' : 'none',
              borderRadius: '0px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              transition: 'colors 0.3s ease-in-out',
              px: '8px',
            }}
            onClick={() => {
              setIsListOpen(true)
              setTab('behaviorist')
            }}
          >
            {tab === 'behaviorist' ? (
              <Psychology sx={{ fontSize: '36px', color: 'orange' }} />
            ) : (
              <PsychologyOutlined sx={{ fontSize: '36px', color: 'orange' }} />
            )}
            <Typography sx={{ fontSize: '14px', fontWeight: tab === 'behaviorist' ? 'bold' : '400', color: 'black' }}>
              Comportementalistes
            </Typography>
          </Button>
          <Button
            variant="text"
            sx={{
              position: 'relative',
              borderTop: 'none',
              borderRadius: '0px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              transition: 'colors 0.3s ease-in-out',
              px: '8px',
            }}
            disabled
          >
            <PetsOutlined sx={{ fontSize: '36px', color: 'green' }} />
            <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: '400' }}>
              Petsitters
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                top: 30,
                transform: 'rotate(-15deg)',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: 'black',
                }}
              >
                Bientôt !
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

function getUserLocation(): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser.'))
    } else {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    }
  })
}

function translateCategory(category: string): string {
  switch (category) {
    case 'veterinary':
      return 'Vétérinaires'
    case 'behaviorist':
      return 'Comportementalistes'
    default:
      return category
  }
}
