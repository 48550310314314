import { Favorite } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { BeRealsClient } from '../../interactors/clients/BeRealsClient'
import { SimplifiedBeRealDto } from '../../interactors/gen/backendClient'
import { getBeRealPhotoSrc } from '../../utils/S3-links'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { lightBlue } from '../theme'
import { SelectAnimalForBeRealModal } from './SelectAnimalForBeRealModal'

const demoBeReal: SimplifiedBeRealDto = {
  _id: '1',
  animalProfileId: '1',
  adopterId: '1',
  animalName: 'Bobby',
  description: 'Un chat très mignon',
  imageKey: 'B-1386-E-1000-C3_zh7vsNAWOnwpelRbFj..jpeg',
  likedByCurrentUser: false,
  likesCount: 112,
  createdAt: new Date().toISOString(),
  tags: [{ Malice: '40%' }, { Curiosité: '70%' }, { Fierté: '100%' }],
}

export const TikTokView: React.FC = () => {
  const [bereals, setBereals] = useState<SimplifiedBeRealDto[]>([demoBeReal])
  const [userAlreadyPostedToday, setUserAlreadyPostedToday] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loading, setLoading] = useState(true)

  // Preload the next image
  const preloadImage = (url: string) => {
    const img = new Image()
    img.src = url
  }

  const nextImage = () => {
    const nextIndex = (currentIndex + 1) % bereals.length
    setCurrentIndex(nextIndex)

    // Preload the image after the next one
    const nextNextIndex = (nextIndex + 1) % bereals.length
    preloadImage(getBeRealPhotoSrc(bereals[nextNextIndex].imageKey))
  }

  const prevImage = () => {
    const prevIndex = (currentIndex - 1 + bereals.length) % bereals.length
    setCurrentIndex(prevIndex)
  }

  // Preload the first and second bereals when the component mounts
  useEffect(() => {
    const fetchBereals = async () => {
      const userHasPostedBeReal = await BeRealsClient.checkIfUserHasPostedBeReal()
      setUserAlreadyPostedToday(userHasPostedBeReal)
      if (!userHasPostedBeReal) return setLoading(false)
      const bereals = await BeRealsClient.getAllBeReals()
      setBereals(bereals)
      if (bereals.length > 1) {
        preloadImage(getBeRealPhotoSrc(bereals[1].imageKey)) // Preload the second image
      }
      setLoading(false)
    }
    fetchBereals()
  }, []) // Empty dependency array means this runs once on mount

  const handlers = useSwipeable({
    onSwipedUp: () => nextImage(),
    onSwipedDown: () => prevImage(),
    trackMouse: true,
    preventScrollOnSwipe: true,
  })

  const toggleLike = (index: number) => {
    const beReal = bereals[index]
    beReal.likedByCurrentUser = !beReal.likedByCurrentUser
    beReal.likesCount += beReal.likedByCurrentUser ? 1 : -1
    setBereals([...bereals])
    BeRealsClient.toggleLike(beReal._id)
  }

  if (loading) return <CircularProgressPanel />

  if (bereals.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          flexGrow: 1,
          p: 2,
          mt: '64px',
          pb: 0,
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">{"Aucun BeReal n'a été posté aujourd'hui"}</Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        flexGrow: 1,
        pb: 0,
        alignItems: 'center',
        backgroundColor: 'black',
      }}
    >
      <Box
        {...handlers}
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: '1200px',
          background: `linear-gradient(rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.7) 100%), url(${getBeRealPhotoSrc(
            bereals[currentIndex].imageKey
          )}) center / cover no-repeat`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          height: '100%',
          flexGrow: 1,
          p: 2,
          pb: { xs: 6, md: 10 },
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ width: '100%' }}>
          <Stack direction="column" spacing={0.6}>
            {bereals[currentIndex].tags &&
              bereals[currentIndex].tags.map((tag) => (
                <Typography
                  key={`${Object.keys(tag)[0]}-${Object.values(tag)[0]}`}
                  variant="body1"
                  sx={{
                    width: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '18px',
                    px: '10px',
                    py: '5px',
                    fontSize: '12px',
                    lineHeight: '16.8px',
                    backgroundColor: lightBlue,
                  }}
                >
                  {Object.keys(tag)[0]}: {Object.values(tag)[0]}
                </Typography>
              ))}
          </Stack>
          <SelectAnimalForBeRealModal icon />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ width: '100%' }}>
          <Typography variant="body1" color="white" fontWeight={700} fontSize={19}>
            {bereals[currentIndex].animalName}
          </Typography>
          <Stack direction="column" spacing={0}>
            <IconButton onClick={() => toggleLike(currentIndex)} color="error" sx={{ p: 0 }}>
              {bereals[currentIndex].likedByCurrentUser ? (
                <Favorite sx={{ fontSize: 32 }} />
              ) : (
                <Favorite sx={{ color: 'white', fontSize: 32 }} />
              )}
            </IconButton>
            <Typography variant="body1" color="white" sx={{ textAlign: 'center' }}>
              {bereals[currentIndex].likesCount}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          variant="caption"
          color="white"
          sx={{
            alignSelf: 'flex-start',
            maxWidth: '80%',
            fontSize: '0.8rem',
          }}
        >
          {bereals[currentIndex].description}
        </Typography>
      </Box>
      {!userAlreadyPostedToday && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            background: 'rgba(255, 255, 255, 0.3)',
            backdropFilter: 'blur(10px)',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
            gap: 2,
          }}
        >
          <Typography variant="h2" color="black" sx={{ fontSize: '20px', textAlign: 'center' }}>
            {"Vous n'avez pas encore posté de PetReal aujourd'hui"}
          </Typography>
          <SelectAnimalForBeRealModal />
        </Box>
      )}
    </Box>
  )
}
