import { ArrowForwardIos } from '@mui/icons-material'
import { Box, Chip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getReadableHealthObservationsType } from '../../domain/Animal/AnimalDisplay'
import { AnimalProfileHealthObservationsDto } from '../../interactors/gen/backendClient'
import { fromDayNumberToName } from '../../utils/date/fromDayNumbeToName'
import { fromMonthNumberToName } from '../../utils/date/fromMonthNumberToName'
import { getIconForObservationType } from '../../utils/getIconForObservation'
import { colors, lightYellow, theme } from '../theme'

interface Props {
  observation: AnimalProfileHealthObservationsDto
}

export const AnimalHealthObservationItem: React.FC<Props> = ({ observation }) => {
  /* const [veterinaryName, setVeterinaryName] = React.useState<string>('') */
  /* useEffect(() => {
    const getVeterinaryName = async () => {
      // Check if observation.veterinary exists
      if (observation.veterinary && observation.veterinary.id) {
        try {
          if (observation.veterinary.id.startsWith('A-')) {
            // Fetch veterinary by ID if it starts with 'A-'
            const veterinary = await VeterinaryClient.getVeterinaryById(observation.veterinary.id)
            setVeterinaryName(`👨‍⚕️ ${veterinary.firstName} ${veterinary.lastName}`)
          } else if (observation.veterinary.locationId.startsWith('L-')) {
            // Assuming this function exists and fetches data based on some criteria
            const clinicExport = await ServiceLocationsClient.getLocation(observation.veterinary.locationId)
            const veterinary = clinicExport.serviceProvidersNames.find((vet) => vet.id === observation.veterinary?.id)

            if (veterinary) {
              setVeterinaryName(`👨‍⚕️ ${veterinary.firstName} ${veterinary.lastName}`)
            } else {
              // If no veterinary found in clinicExport
              setVeterinaryName('Vétérinaire inconnu(e)')
            }
          }
        } catch (error) {
          console.error('Error fetching veterinary information:', error)
          setVeterinaryName('Vétérinaire inconnu(e)') // Fallback to unknown if any fetch fails
        }
      } else {
        // If observation.veterinary doesn't exist
        setVeterinaryName('Vétérinaire inconnu(e)')
      }
    }

    getVeterinaryName()
  }, [observation.veterinary]) // Dependency array ensures this effect runs whenever observation.veterinary changes */

  const navigate = useNavigate()

  return (
    <Box
      onClick={() => navigate(`./observation/${observation.id}`)}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: '8px',
        px: '16px',
        borderRadius: '5px',
        gap: '10px',
        border: '1px solid',
        borderColor: colors.lightGrey,
        backgroundColor: lightYellow,
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
          backgroundColor: '#e3ddc8', // Lighter shade for hover state
          borderColor: '#D0D3D8', // Slightly darker border on hover
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            color: theme.palette.secondary.main,
          }}
        >
          {getIconForObservationType(observation.type[0])}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
              color: colors.black,
            }}
          >
            <Chip
              sx={{ backgroundColor: colors.lightGrey }}
              label={getReadableHealthObservationsType(observation.type[0])}
            />
            <Typography>{observation.type.length > 1 ? `+ ${observation.type.length - 1}` : ''}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '18px',
            color: colors.black,
          }}
        >
          <Typography>{`${fromDayNumberToName(dayjs(observation.date).day())} ${dayjs(observation.date)
            .format('DD/MM/YYYY')
            .split('/', 1)} ${fromMonthNumberToName(dayjs(observation.date).month())} ${
            dayjs(observation.date).format('DD/MM/YYYY').split('/', 3)[2]
          }`}</Typography>
        </Box>
        {/*         <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '12px',
            color: colors.black,
          }}
        >
          <Typography>{veterinaryName}</Typography>
        </Box> */}
      </Box>
      <ArrowForwardIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
    </Box>
  )
}
