import { AxiosRequestConfig } from 'axios'
import { CustomerDto } from '../gen/backendClient'
import { backendClient } from './client'

export class CustomersClient {
  public static async getCurrentCustomer(): Promise<CustomerDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: 'adopters-platform/customers',
    }
    const response = await backendClient<CustomerDto>(config)

    return response.data
  }
}
