import { Box } from '@mui/material'
import React from 'react'
import { AnimalDto, AnimalDtoSpecies, AnimalProfileDto } from '../../../interactors/gen/backendClient'
import { ProductItem } from '../../Adoptions/ProductItem'
import data from '../data/products.json'

export interface CategoryProps {
  animalProfile?: AnimalProfileDto | AnimalDto
  specie?: AnimalDtoSpecies
  alreadyAdoptedAnimal?: boolean
}

export const FoodCategory: React.FC<CategoryProps> = ({ animalProfile, specie }) => {
  const species = animalProfile?.species || specie
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1 }}>
        {data.products
          .filter((product) => product.category.includes('alimentation'))
          .filter(
            (product) =>
              (!animalProfile && !specie) ||
              (species && product.species.includes(species)) ||
              product.species.length === 0
          )
          .map((product) => (
            <ProductItem
              key={product.reference}
              product={product}
              animalId={animalProfile?.id}
              animalName={animalProfile?.name}
            />
          ))}
      </Box>
    </>
  )
}
