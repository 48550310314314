import { Snackbar, Alert } from '@mui/material'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'

export const GlobalSnackBar = () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  return (
    <Snackbar
      open={globalSnackbarStore.isSnackbarOpen}
      autoHideDuration={globalSnackbarStore.autoHideDuration}
      onClose={globalSnackbarStore.closeSnackbar}
      anchorOrigin={globalSnackbarStore.position}
      sx={
        globalSnackbarStore.position && {
          '&.MuiSnackbar-root': { top: '120px' },
        }
      }
    >
      <Alert
        onClose={globalSnackbarStore.closeSnackbar}
        severity={globalSnackbarStore.severity}
        action={globalSnackbarStore.action}
        sx={{ width: '100%' }}
      >
        {globalSnackbarStore.message}
      </Alert>
    </Snackbar>
  )
}
