import AssignmentIcon from '@mui/icons-material/Assignment'
import PersonIcon from '@mui/icons-material/Person'
import { Box, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../PATHS'

export const ProfilScreen: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        padding: { xs: 2 },
        minHeight: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2, // Space between buttons
      }}
    >
      {/* Button for "Mon profil" */}
      <Button
        variant="contained"
        onClick={() => navigate(PATHS.monProfil.relative)}
        sx={{
          width: 150, // Adjust for square shape
          height: 150, // Adjust for square shape
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PersonIcon sx={{ fontSize: 60, marginBottom: 1 }} /> {/* Larger icon */}
        Mon Profil
      </Button>
      {/* Button for "Mon formulaire de pré-adoption" */}
      <Button
        variant="contained"
        onClick={() => navigate(PATHS.formulaire.relative)}
        sx={{
          width: 150, // Adjust for square shape
          height: 150, // Adjust for square shape
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AssignmentIcon sx={{ fontSize: 60, marginBottom: 1 }} /> {/* Larger icon */}
        Mon formulaire de pré-adoption
      </Button>
    </Box>
  )
}
