import { FormControl, SxProps, Theme, BaseTextFieldProps } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { frFR } from '@mui/x-date-pickers/locales'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import { toDayJSFromAll } from '../../utils/date/DateOnly'

// The type of the variable in the hook form is string but under the hood, this component uses dayjs.
// So we need to convert the string to dayjs and vice versa.

type Props<T extends FieldValues> = {
  sx?: SxProps<Theme>
  control: Control<T>
  validate: (date: dayjs.Dayjs | null) => boolean
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  minDate?: dayjs.Dayjs
  maxDate?: dayjs.Dayjs
}

dayjs.locale('fr')

export function ControlledDateField<T extends FieldValues>({
  sx,
  control,
  validate,
  error,
  fieldName,
  requiredRule,
  size,
  disabled,
  minDate,
  maxDate,
}: PropsWithChildren<Props<T>>) {
  return (
    <FormControl fullWidth variant="outlined" sx={sx} size={size}>
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: requiredRule,
          validate: (date: string | null | undefined) => {
            const formattedDate = toDayJSFromAll(date || null)
            const isValid = validate(formattedDate)
            return isValid ? true : `La date n'est pas valide.`
          },
        }}
        render={({ field: { onChange, value } }) => {
          const formattedValue = toDayJSFromAll(value)

          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <DatePicker
                  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
                  value={formattedValue || null}
                  onChange={(newValue) => {
                    console.log({ newValue })
                    onChange(newValue)
                  }}
                  format="DD/MM/YYYY"
                  disabled={disabled}
                  minDate={minDate}
                  maxDate={maxDate}
                  slotProps={{
                    textField: {
                      error: !!error,
                      helperText: error?.message,
                      size,
                    },
                  }}
                />
              </LocalizationProvider>
            </>
          )
        }}
      />
    </FormControl>
  )
}
