import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'
import { theme } from '../theme'

interface AdoptionModalProps {
  open: boolean
  handleClose: () => void
  animalPrice: number
  petsoFee: number
}

export const AdoptionPriceDetailsModal: React.FC<AdoptionModalProps> = ({
  open,
  handleClose,
  animalPrice,
  petsoFee,
}) => {
  const totalPrice = animalPrice + petsoFee

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="adoption-modal-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%', // Ensures it takes up the full width
          maxHeight: '100%', // Prevents overflow
          margin: 0, // Removes any default margin
        },
      }}
    >
      {/* Dialog Content */}
      <DialogContent
        sx={{
          padding: '16px', // Reduced padding
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1, // Ensures the content stretches
        }}
      >
        {/* Header Box */}
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            padding: '8px',
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
            marginBottom: '16px',
          }}
        >
          Le montant de l&apos;adoption
        </Box>

        {/* Text Content */}
        <Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Il s&apos;agit d&apos;une{' '}
            <span style={{ fontWeight: 700, color: theme.palette.secondary.main }}>
              compensation pour les frais de santé
            </span>
            , permettant de rembourser à l&apos;association les différentes interventions que l&apos;animal a subies ou
            dont il a bénéficié. Ce montant est donc variable et dépend des antécédents de santé de l&apos;animal, de
            son statut vaccinal, ainsi que de sa stérilisation ou non.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            La compensation de frais de santé pour cet animal est de{' '}
            <span style={{ fontWeight: 700, color: theme.palette.secondary.main }}>{animalPrice} euros</span>.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            À cela s&apos;ajoute la{' '}
            <span style={{ fontWeight: 700, color: theme.palette.secondary.main }}>
              participation aux frais d&apos;adoption
            </span>
            , qui englobe les frais de signature électronique, de paiement et d&apos;accompagnement, assurés par Petso
            pour le compte des associations. Cette participation est de{' '}
            <span style={{ fontWeight: 700, color: theme.palette.secondary.main }}>{petsoFee} €</span>.
          </Typography>

          <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold' }}>
            Le montant total de l&apos;adoption est de {totalPrice}€
          </Typography>
        </Box>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions sx={{ padding: '8px' }}>
        {' '}
        {/* Reduced padding in the actions area */}
        <Button onClick={handleClose} variant="contained" color="primary" fullWidth sx={{ fontSize: '18px' }}>
          J&apos;ai compris
        </Button>
      </DialogActions>
    </Dialog>
  )
}
