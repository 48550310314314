import dayjs from 'dayjs'
import { fromDayNumberToName } from './fromDayNumbeToName'

export const getDayLabel = (unParsedDate: string) => {
  const date = dayjs(unParsedDate).format('YYYY-MM-DD')
  const today = dayjs().format('YYYY-MM-DD')
  const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD')

  if (date === today) {
    return "aujourd'hui"
  } else if (date === yesterday) {
    return 'hier'
  } else if (dayjs().diff(date, 'day') < 7) {
    return fromDayNumberToName(dayjs(date).day())
  } else {
    return dayjs(date).format('DD/MM/YYYY') // Or any other format you prefer
  }
}
