import create from 'zustand'

interface DashboardLoaderStore {
  isLoading: boolean
  setIsLoading: (newValue: boolean) => void
}

export const useDashboardLoaderStore = create<DashboardLoaderStore>()((set) => ({
  isLoading: false,
  setIsLoading: (newValue: boolean) => {
    set({ isLoading: newValue })
  },
}))
