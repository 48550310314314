import create from 'zustand'
import { BasketItem } from '../utils/types/BasketItem'

interface BasketStore {
  basket: BasketItem[]
  addOrUpdateItem: (item: BasketItem) => void
  reduceItemQuantity: (reference: string, amount: number, animalId: string, color: string | undefined) => void
  removeItem: (item: BasketItem) => void
  clearBasket: () => void
  totalItems: () => number
  totalPrice: () => number
}

export const useBasketStore = create<BasketStore>((set, get) => ({
  basket: JSON.parse(localStorage.getItem('basket') || '[]'),

  addOrUpdateItem: (item) => {
    const updatedBasket = get().basket
    const itemIndex = updatedBasket.findIndex(
      (basketItem) =>
        basketItem.reference === item.reference &&
        basketItem.animalId === item.animalId &&
        basketItem.color === item.color
    )

    if (itemIndex > -1) {
      updatedBasket[itemIndex].quantity += item.quantity // Update quantity
    } else {
      updatedBasket.push(item) // Add new item
    }

    localStorage.setItem('basket', JSON.stringify(updatedBasket))
    set({ basket: updatedBasket })
  },

  reduceItemQuantity: (reference, amount, animalId, color) => {
    const updatedBasket = get()
      .basket.map((basketItem) => {
        if (basketItem.reference === reference && basketItem.animalId === animalId && basketItem.color === color) {
          return { ...basketItem, quantity: Math.max(0, basketItem.quantity - amount) }
        }
        return basketItem
      })
      .filter((basketItem) => basketItem.quantity > 0) // Remove the item if the quantity is 0

    localStorage.setItem('basket', JSON.stringify(updatedBasket))
    set({ basket: updatedBasket })
  },

  removeItem: (item) => {
    const updatedBasket = get().basket.filter((basketItem) => {
      // Ensure reference matches exactly
      const isSameReference = basketItem.reference === item.reference

      // Handle undefined or empty values for animalId and color
      const isSameAnimalId = (basketItem.animalId || '') === (item.animalId || '')
      const isSameColor = (basketItem.color || '') === (item.color || '')

      // Remove the item only if all three conditions match
      return !(isSameReference && isSameAnimalId && isSameColor)
    })

    set({ basket: updatedBasket })

    // Update localStorage
    localStorage.setItem('basket', JSON.stringify(updatedBasket))
  },

  clearBasket: () => {
    localStorage.removeItem('basket')
    set({ basket: [] })
  },

  totalItems: () => {
    return get().basket.reduce((acc, item) => acc + item.quantity, 0)
  },

  totalPrice: () => {
    return get().basket.reduce((acc, item) => acc + item.quantity * item.price, 0)
  },
}))
