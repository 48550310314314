import { CircularProgress, Box } from '@mui/material'
import { CSSProperties, FC } from 'react'

export type Props = {
  processing?: boolean
  padding?: boolean
  alignItems?: CSSProperties['alignItems']
  absolute?: boolean
}

export const CircularProgressPanel: FC<Props> = ({
  processing = true,
  padding = true,
  alignItems = 'flex-start',
  absolute = true,
}) => {
  return processing ? (
    <Box
      sx={{
        background: 'white',
        position: absolute ? 'absolute' : 'initial',
        zIndex: 100,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems,
        justifyContent: 'center',
        paddingTop: padding ? 10 : 0,
      }}
    >
      <CircularProgress />
    </Box>
  ) : null
}
