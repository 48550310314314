import { InsuranceEstimationBody } from '../../domain/Adopters/InsuranceEstimationType'
import { AdopterDto, AdopterWithAnimalsReducedDto, AdoptionAttemptDto, AnimalProfileDto } from '../gen/backendClient'
import { backendClient } from './client'
import { AxiosRequestConfig } from 'axios'

export class AdoptersClient {
  public static async getAllAdopters(params?: {
    page: number
    pageSize: number
  }): Promise<Array<AdopterWithAnimalsReducedDto>> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/adopters-platform/accounts/with-animals?page=${params?.page || 1}&pageSize=${params?.pageSize || 10}`,
    }

    const response = await backendClient<Array<AdopterWithAnimalsReducedDto>>(config)

    return response.data
  }

  public static async editAccount(params: AdopterDto): Promise<AdopterDto> {
    const config: AxiosRequestConfig<AdopterDto> = {
      method: 'PUT',
      url: '/adopters-platform/accounts',
      data: {
        ...params,
      },
    }

    const response = await backendClient<AdopterDto>(config)

    return response.data
  }

  public static async editForm(params: AdopterDto): Promise<AdopterDto> {
    const config: AxiosRequestConfig<AdopterDto> = {
      method: 'PUT',
      url: '/adopters-platform/accounts/form',
      data: {
        ...params,
      },
    }

    const response = await backendClient<AdopterDto>(config)

    return response.data
  }

  /* public static async updateAdoptionAttempt(params: AdoptionAttemptDto): Promise<AdoptionAttemptDto> {
    const config: AxiosRequestConfig<AdoptionAttemptDto> = {
      method: 'PUT',
      url: `/adopters-platform/accounts/adoption-attempts/${params.id}`,
      data: {
        ...params,
      },
    }

    const response = await backendClient<AdoptionAttemptDto>(config)

    return response.data
  } */

  public static async updateAdoptionAttempt(data: {
    adoptionAttempt: FormData
    id: string
    type: 'certificate' | 'contract' | 'payment' | 'sterilization'
  }): Promise<AdoptionAttemptDto> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/adopters-platform/accounts/adoption-attempts/${data.id}?type=${data.type}`,
      data: data.adoptionAttempt,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const response = await backendClient<AdoptionAttemptDto>(config)

    return response.data
  }

  public static async updateAdoptionAttemptDummyInfos(data: {
    adoptionAttempt: AdoptionAttemptDto
    id: string
  }): Promise<AdoptionAttemptDto> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/adopters-platform/accounts/adoption-attempts/dummy/${data.id}`,
      data: data.adoptionAttempt,
    }

    const response = await backendClient<AdoptionAttemptDto>(config)

    return response.data
  }

  public static async insuranceEstimation(params: InsuranceEstimationBody): Promise<InsuranceEstimationBody> {
    const config: AxiosRequestConfig<InsuranceEstimationBody> = {
      method: 'POST',
      url: '/adopters-platform/accounts/insurance-estimation',
      data: {
        ...params,
      },
    }

    const response = await backendClient<InsuranceEstimationBody>(config)

    return response.data
  }

  public static async updateFCMToken(token: string): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/adopters-platform/accounts/fcm-tokens`,
      data: {
        token: token,
      },
    }

    await backendClient(config)
  }

  public static async updateTutorialStep(step: number): Promise<AdopterDto> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/adopters-platform/accounts/tutorial`,
      data: {
        step: step,
      },
    }

    const response = await backendClient<AdopterDto>(config)

    return response.data
  }

  public static async updateAdoptionAttemptTutorialStep(step: number, attemptId: string): Promise<AdoptionAttemptDto> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/adopters-platform/accounts/adoption-attempts/tutorial/${attemptId}`,
      data: {
        step: step,
      },
    }

    const response = await backendClient<AdoptionAttemptDto>(config)

    return response.data
  }

  public static async sendInsuranceEstimate(animalId: string): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/adopters-platform/accounts/insurance-estimate/${animalId}`,
    }

    await backendClient<void>(config)
  }

  public static async createAnimalProfileFromAdoptedAnimal(animalId: string): Promise<AnimalProfileDto> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/adopters-platform/animalprofiles/transfer`,
      data: {
        animalId,
      },
    }

    const response = await backendClient<AnimalProfileDto>(config)

    return response.data
  }
}
