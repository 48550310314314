import { AxiosRequestConfig } from 'axios'
import { CreateBeRealDto, SimplifiedBeRealDto } from '../gen/backendClient'
import { backendClient } from './client'
import { forEach } from 'lodash'

export class BeRealsClient {
  public static async getAllBeReals(): Promise<SimplifiedBeRealDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/bereals',
    }

    const response = await backendClient<SimplifiedBeRealDto[]>(config)

    return response.data
  }

  public static async createBeReal(beReal: CreateBeRealDto, image: File): Promise<void> {
    const formData = new FormData()

    formData.append('image', image)

    forEach(beReal, (value, key) => {
      if (value) {
        formData.append(key, value)
      }
    })

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/adopters-platform/bereals',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'image/',
      },
    }

    await backendClient(config)
  }

  public static async checkIfBeRealExists(animalProfileId: string): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/adopters-platform/bereals/check/${animalProfileId}`,
    }

    const response = await backendClient<boolean>(config)

    return response.data
  }

  public static async toggleLike(beRealId: string): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/adopters-platform/bereals/${beRealId}/like`,
    }

    await backendClient(config)
  }

  public static async checkIfUserHasPostedBeReal(): Promise<boolean> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/adopters-platform/bereals/check-user`,
    }

    const response = await backendClient<boolean>(config)

    return response.data
  }
}
