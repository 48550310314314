import React from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  styled,
  stepConnectorClasses,
  StepIconProps,
  stepLabelClasses,
} from '@mui/material'

interface Props {
  activeStep: number
}

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  // Position the label on top
  [`&.${stepLabelClasses.label}`]: {
    marginTop: theme.spacing(2),
  },
  // Position the icon below
  [`& .${stepLabelClasses.iconContainer}`]: {
    marginBottom: theme.spacing(1),
  },
}))

const AdoptionStepIconRoot = styled('div')<{ ownerState: { active?: boolean; completed?: boolean } }>(
  ({ theme, ownerState }) => ({
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffe7ad',
    color: theme.palette.secondary.main,
    height: 16,
    width: 16,
    zIndex: 1,
    ...(ownerState.active && {
      backgroundColor: theme.palette.primary.main,
    }),
    ...(ownerState.completed && {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    }),
  })
)

function AdoptionStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <AdoptionStepIconRoot ownerState={{ active, completed }} className={className}>
      {active && <span style={{ width: '8px', height: '8px', backgroundColor: 'white', borderRadius: '100%' }}></span>}
    </AdoptionStepIconRoot>
  )
}

export const FormStepper: React.FC<Props> = ({ activeStep }) => {
  const Connector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.root}`]: {
      left: 'calc(-50% + 5px)',
      right: 'calc(50% + 5px)',
      top: 46,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 4,
      border: 0,
      backgroundColor: '#ffe7ad',
      borderRadius: 1,
    },
  }))
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<Connector />}>
      <Step key="Informations personnelles">
        <StyledStepLabel StepIconComponent={AdoptionStepIcon}>Informations personnelles</StyledStepLabel>
      </Step>
      <Step key="Vous recherchez">
        <StepLabel StepIconComponent={AdoptionStepIcon}>Vous recherchez</StepLabel>
      </Step>
      <Step key="Vos animaux">
        <StepLabel StepIconComponent={AdoptionStepIcon}>Vos animaux</StepLabel>
      </Step>
      <Step key="Votre foyer">
        <StepLabel StepIconComponent={AdoptionStepIcon}>Votre foyer</StepLabel>
      </Step>
      <Step key="Votre lieu de vie">
        <StepLabel StepIconComponent={AdoptionStepIcon}>Votre lieu de vie</StepLabel>
      </Step>
      <Step key="Votre travail et mode de vie">
        <StepLabel StepIconComponent={AdoptionStepIcon}>Votre travail et mode de vie</StepLabel>
      </Step>
    </Stepper>
  )
}
