import { ArrowForwardIos } from '@mui/icons-material'
import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ServiceLocationWithProvidersNamesDto } from '../../interactors/gen/backendClient'
import { getPartnerLogoSrc } from '../../utils/S3-links'
import { calculateDistanceInKms } from '../../utils/calculateDistance'
import { theme } from '../theme'

interface Props {
  location: ServiceLocationWithProvidersNamesDto
  userLocation: number[]
}

export const ServiceLocationItem: React.FC<Props> = ({ location, userLocation }) => {
  const navigate = useNavigate()
  return (
    <Box
      key={location.id}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: 'white',
        p: '10px',
      }}
      onClick={() => navigate(`/services/${location.id}`)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
          width: '100%',
        }}
      >
        <Avatar
          sx={{ width: '50px', height: '50px' }}
          src={location.logo ? getPartnerLogoSrc(location.logo) : ''}
          alt={location.name}
          title={location.name}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>{location.name}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '5px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {location.type === 'behaviorist' ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '18px',
                  px: '12px',
                  py: '6px',
                  fontSize: '12px',
                  lineHeight: '14.8px',
                  color: 'white',
                  backgroundColor: '#333333',
                }}
              >
                Visio possible
              </Box>
            ) : (
              <div></div>
            )}
            <Typography sx={{ fontStyle: 'italic' }}>
              {location.city} -{' '}
              {calculateDistanceInKms(
                { longitude: userLocation[0], latitude: userLocation[1] },
                { longitude: location.coordinates[0], latitude: location.coordinates[1] }
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <ArrowForwardIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
    </Box>
  )
}
