import { Box, Checkbox, FormControlLabel, FormGroup, TextField, Theme, Typography, useMediaQuery } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import {
  getReadableAdopterAdoptionFormPlanForPetDuringRelocation,
  getReadableAdopterAdoptionFormWorkflow,
} from '../../../domain/Adopters/AccountDisplay'
import {
  AdopterDtoAdoptionFormPlanForPetDuringRelocation,
  AdopterDtoAdoptionFormWorkflow,
} from '../../../interactors/gen/backendClient'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { Field } from '../../common/FormField'
import { blue } from '../../theme'
import { FormParams } from '../AdoptionFormScreen'

interface StepSixProps {
  control: Control<FormParams>
  register: UseFormRegister<FormParams>
  errors: FieldErrors<FormParams>
  checkbox: boolean
  setCheckbox: (value: boolean) => void
}

export const StepSix: React.FC<StepSixProps> = ({ control, register, errors, checkbox, setCheckbox }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'start', width: '100%' }}>
      {isMobile && (
        <Field label="Quel est votre mode de travail ?" required>
          <ControlledSelectField
            control={control}
            fieldName="workflow"
            error={errors.workflow}
            requiredRule="Vous devez choisir une option"
            options={Object.values(AdopterDtoAdoptionFormWorkflow).map((option: AdopterDtoAdoptionFormWorkflow) => ({
              label: getReadableAdopterAdoptionFormWorkflow(option),
              value: option,
            }))}
            size="small"
          />
        </Field>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            justifyContent: { xs: 'space-between', md: 'start' },
            width: '50%',
          }}
        >
          {!isMobile && (
            <Field label="Quel est votre mode de travail ?" required>
              <ControlledSelectField
                control={control}
                fieldName="workflow"
                error={errors.workflow}
                requiredRule="Vous devez choisir une option"
                options={Object.values(AdopterDtoAdoptionFormWorkflow).map(
                  (option: AdopterDtoAdoptionFormWorkflow) => ({
                    label: getReadableAdopterAdoptionFormWorkflow(option),
                    value: option,
                  })
                )}
                size="small"
              />
            </Field>
          )}
          <Field
            label="Êtes-vous conscient que l’arrivée d’un animal est couteux et peut vous amener à réaménager votre habitat ?"
            required
          >
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="consciousOfAnimalNeeds"
              error={errors.consciousOfAnimalNeeds}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'yes'}
              secondOptionValue={'no'}
              fullWidth
            />
          </Field>
          <Field label="Avez-vous conscience que l’adoption d’un animal doit être un acte murement réfléchi ?" required>
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="consciousOfAnimalAdoption"
              error={errors.consciousOfAnimalAdoption}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'yes'}
              secondOptionValue={'no'}
              fullWidth
            />
          </Field>
          {!isMobile && (
            <Field label="En cas de déménagement (France ou étranger), que ferez-vous de votre animal ?" required>
              <ControlledSelectField
                control={control}
                fieldName="planForPetDuringRelocation"
                error={errors.planForPetDuringRelocation}
                requiredRule="Vous devez répondre à la question"
                options={Object.values(AdopterDtoAdoptionFormPlanForPetDuringRelocation).map(
                  (option: AdopterDtoAdoptionFormPlanForPetDuringRelocation) => ({
                    label: getReadableAdopterAdoptionFormPlanForPetDuringRelocation(option),
                    value: option,
                  })
                )}
                size="small"
              />
            </Field>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            justifyContent: { xs: 'space-between', md: 'start' },
            width: '50%',
          }}
        >
          <Box sx={{ height: '80.81px', display: { xs: 'none', md: 'block' } }}></Box>
          <Field
            label="Êtes-vous prêt à vous rendre chez le vétérinaire, à assumer les frais de santé et garantir l’hygiène et le bien-être de votre animal ?"
            required
          >
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="consciousOfAnimalCost"
              error={errors.consciousOfAnimalCost}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'yes'}
              secondOptionValue={'no'}
              fullWidth
            />
          </Field>
          <Field
            label="Savez-vous à qui vous pouvez le confier lors de vos absences ? (déplacement professionnel, week-end, vacances...)"
            required
          >
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="caretakerDuringAbsence"
              error={errors.caretakerDuringAbsence}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'yes'}
              secondOptionValue={'no'}
              fullWidth
            />
          </Field>
          {!isMobile && (
            <Field
              label="Votre futur animal est-il destiné à vous même ou à être offert en cadeau à une autre personne ?"
              required
            >
              <ControlledToggleButton<FormParams>
                control={control}
                fieldName="petIntendedForSelfOrGift"
                error={errors.petIntendedForSelfOrGift}
                requiredRule="Vous devez répondre à la question"
                firstOptionLabel="Moi-même"
                secondOptionLabel="Cadeau"
                firstOptionValue={'self'}
                secondOptionValue={'gift'}
                fullWidth
              />
            </Field>
          )}
        </Box>
      </Box>
      {isMobile && (
        <>
          <Field label="En cas de déménagement (France ou étranger), que ferez-vous de votre animal ?" required>
            <ControlledSelectField
              control={control}
              fieldName="planForPetDuringRelocation"
              error={errors.planForPetDuringRelocation}
              requiredRule="Vous devez répondre à la question"
              options={Object.values(AdopterDtoAdoptionFormPlanForPetDuringRelocation).map(
                (option: AdopterDtoAdoptionFormPlanForPetDuringRelocation) => ({
                  label: getReadableAdopterAdoptionFormPlanForPetDuringRelocation(option),
                  value: option,
                })
              )}
              size="small"
            />
          </Field>
          <Field
            label="Votre futur animal est-il destiné à vous même ou à être offert en cadeau à une autre personne ?"
            required
          >
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="petIntendedForSelfOrGift"
              error={errors.petIntendedForSelfOrGift}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Moi-même"
              secondOptionLabel="Cadeau"
              firstOptionValue={'self'}
              secondOptionValue={'gift'}
              fullWidth
            />
          </Field>
        </>
      )}
      <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '21.82px', color: '#2A5067' }}>
        Dites-nous en plus sur l’animal que vous recherchez (e.g. taille, caractère, couleur du poil...) et autres
        commentaires que vous souhaiteriez nous partager
      </Typography>
      <TextField
        aria-label="Description de l'animal recherché"
        placeholder="Renseignez plus d'informations ici..."
        {...register('desiredPetCharacteristics')}
        hiddenLabel
        multiline
        fullWidth
      />
      <FormGroup sx={{ mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) => {
                event.stopPropagation()
                setCheckbox(event.target.checked)
              }}
              sx={{
                mt: -0.2,
                p: 1,
                color: grey[600],
                '&.Mui-checked': {
                  color: blue[600],
                },
              }}
            />
          }
          label="Je certifie que mes informations sont correctes"
          sx={{ display: 'flex', alignItems: 'center', color: checkbox ? 'black' : grey[600] }}
          checked={checkbox}
          required
        />
      </FormGroup>
    </Box>
  )
}
