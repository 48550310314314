export const getAnimalProfilePhotoSrc = (profileImageKey: string) => {
  return `https://animals-photos.s3.amazonaws.com/${profileImageKey}`
}

export const getAnimalProfileProfilePhotoSrc = (profileImageKey: string) => {
  return `https://animals-profile-photos.s3.eu-west-3.amazonaws.com/${profileImageKey}`
}

export const getAccountProfilePhotoSrc = (profileImageKey: string) => {
  return `https://accounts-photos.s3.amazonaws.com/${profileImageKey}`
}

export const getAnimalDocumentsPhotoSrc = (profileImageKey: string) => {
  if (!profileImageKey) {
    return ''
  }

  return `https://animals-documents.s3.amazonaws.com/${profileImageKey}`
}

export const getAdoptionAttemptsDocumentsSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://petso-adoption-attempts-documents.s3.amazonaws.com/${documentKey}`
}

export const getProductImageSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://petso-products-images.s3.eu-west-3.amazonaws.com/${documentKey}`
}

export const getPartnerLogoSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://petso-partner-logos.s3.eu-west-3.amazonaws.com/${documentKey}`
}

export const getBeRealPhotoSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://bereals-photos.s3.eu-west-3.amazonaws.com/${documentKey}`
}

export const getObservationPhotoOrVideoSrc = (documentKey: string) => {
  if (!documentKey) {
    return ''
  }

  return `https://observations-photos-videos.s3.eu-west-3.amazonaws.com/${documentKey}`
}
