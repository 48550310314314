import { Upload } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, Grow, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { forEach } from 'lodash'
import { nanoid } from 'nanoid'
import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { getReadableHealthObservationsType } from '../../domain/Animal/AnimalDisplay'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { AnimalProfileDto, AnimalProfileHealthObservationsDtoType } from '../../interactors/gen/backendClient'
import { isValidPastDateCoherence } from '../../utils/date/isValidPastDateCoherence'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { ControlledDateField } from '../common/ControlledDateField'
import { ControlledMultipleAutoComplete } from '../common/ControlledMultipleAutoComplete'
import { Field } from '../common/FormField'
import { spacingItem } from '../theme'
import { useAccountsStore } from '../../store/AccountsStore'
import { ServiceProvidersClient } from '../../interactors/clients/ServiceProvidersClient'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { ControlledVeterinariesAutocomplete } from '../common/ControlledVeterinariesAutocomplete'

interface FormData {
  type: AnimalProfileHealthObservationsDtoType[]
  date: string
  veterinary?: string
  additionalInfo?: string
}

export const AddAnimalObservationScreen: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [animal, setAnimal] = React.useState<AnimalProfileDto | null>(null)
  const [observationsList, setObservationsList] = React.useState<AnimalProfileHealthObservationsDtoType[] | null>(null)
  const { animalId } = useParams() as { animalId: string }

  const navigate = useNavigate()

  const accountsStore = useAccountsStore()
  const account = accountsStore.connectedAccount

  const [defaultVeterinaryName, setDefaultVeterinaryName] = React.useState<string | undefined>(undefined)
  const [shouldSetDefaultVeterinary, setShouldSetDefaultVeterinary] = React.useState<boolean>(false)

  const observationsListForDog = [
    AnimalProfileHealthObservationsDtoType.ExcessiveObjectChewing,
    AnimalProfileHealthObservationsDtoType.ExcessiveScratching,
    AnimalProfileHealthObservationsDtoType.UnusualFrequentUrination,
    AnimalProfileHealthObservationsDtoType.AppetiteLoss,
    AnimalProfileHealthObservationsDtoType.ExcessiveLicking,
    AnimalProfileHealthObservationsDtoType.UnexplainedAggressiveness,
    AnimalProfileHealthObservationsDtoType.SuddenWeightChange,
    AnimalProfileHealthObservationsDtoType.VomitingDog,
    AnimalProfileHealthObservationsDtoType.Diarrhea,
    AnimalProfileHealthObservationsDtoType.EscapeAttempts,
    AnimalProfileHealthObservationsDtoType.SleepHabitsChange,
    AnimalProfileHealthObservationsDtoType.ConstantBarking,
    AnimalProfileHealthObservationsDtoType.NoBarking,
    AnimalProfileHealthObservationsDtoType.ConstantRestlessness,
    AnimalProfileHealthObservationsDtoType.Lethargy,
    AnimalProfileHealthObservationsDtoType.RefusalToEat,
    AnimalProfileHealthObservationsDtoType.HydrationRefusal,
    AnimalProfileHealthObservationsDtoType.BadBreath,
    AnimalProfileHealthObservationsDtoType.IncreasedTerritorialMarking,
    AnimalProfileHealthObservationsDtoType.Itching,
    AnimalProfileHealthObservationsDtoType.SelfMutilation,
    AnimalProfileHealthObservationsDtoType.WalkingDifficulty,
    AnimalProfileHealthObservationsDtoType.PawPadsIssues,
    AnimalProfileHealthObservationsDtoType.SkinProblems,
    AnimalProfileHealthObservationsDtoType.RedEyes,
    AnimalProfileHealthObservationsDtoType.Drooling,
    AnimalProfileHealthObservationsDtoType.SkinIrritation,
  ]

  const observationsListForCat = [
    AnimalProfileHealthObservationsDtoType.DiarrheaAndStoolIssues,
    AnimalProfileHealthObservationsDtoType.Vomiting,
    AnimalProfileHealthObservationsDtoType.MeowingInLitter,
    AnimalProfileHealthObservationsDtoType.AppetiteChange,
    AnimalProfileHealthObservationsDtoType.ExcessiveWeightGain,
    AnimalProfileHealthObservationsDtoType.IncreasedWaterConsumption,
    AnimalProfileHealthObservationsDtoType.SkinCrusts,
    AnimalProfileHealthObservationsDtoType.RedAndSwollenGums,
    AnimalProfileHealthObservationsDtoType.Panting,
    AnimalProfileHealthObservationsDtoType.Sneezing,
    AnimalProfileHealthObservationsDtoType.NoseBleeding,
    AnimalProfileHealthObservationsDtoType.ExcessiveEyeBlinking,
    AnimalProfileHealthObservationsDtoType.MobilityIssues,
    AnimalProfileHealthObservationsDtoType.InappropriateScratching,
    AnimalProfileHealthObservationsDtoType.NocturnalMeowingOrAgitation,
    AnimalProfileHealthObservationsDtoType.InappropriateElimination,
    AnimalProfileHealthObservationsDtoType.FearfulBehavior,
  ]

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone()

  useEffect(() => {
    const fetchDefaultVeterinary = async () => {
      if (!account || !account?.defaultVeterinary) return
      if (account.defaultVeterinary) {
        const veterinary = await ServiceProvidersClient.getProvider(account.defaultVeterinary.id)
        setDefaultVeterinaryName(`${veterinary.firstName} ${veterinary.lastName}`)
      }
    }
    const fetchAnimal = async () => {
      if (!animalId) return
      const animal = await AnimalProfilesClient.getAnimalById(animalId)
      setAnimal(animal)
      setObservationsList(animal.species === 'dog' ? observationsListForDog : observationsListForCat)
    }

    fetchDefaultVeterinary()
    fetchAnimal()
  }, [animalId, account])

  const onSubmit = async (data: FormData) => {
    if (!animalId) return

    setIsLoading(true)

    try {
      const veterinaryAndLocation = data.veterinary ? data.veterinary.split('_') : [undefined, undefined]
      const [veterinaryId, locationId] = veterinaryAndLocation

      if (
        account &&
        shouldSetDefaultVeterinary &&
        account?.defaultVeterinary?.id !== veterinaryId &&
        account?.defaultVeterinary?.locationId !== locationId
      ) {
        account.defaultVeterinary = {
          id: veterinaryId || '',
          locationId: locationId || '',
        }
        await AdoptersClient.editAccount(account)
      }

      const newObservation = {
        id: nanoid(),
        date: dayjs(data.date).toISOString(),
        additionalInfo: data.additionalInfo,
      }

      const formData = new FormData()
      if (acceptedFiles[0]) {
        formData.append('file', acceptedFiles[0])
      }

      data.type.forEach((type) => {
        formData.append('type[]', type)
      })

      formData.append('veterinary.id', veterinaryId || '')
      formData.append('veterinary.locationId', locationId || '')

      forEach(newObservation, (value, key) => {
        if (value === undefined) return
        formData.append(key, value)
      })

      await AnimalProfilesClient.addObservation(animalId, formData)
      navigate(`../${animalId}/observation/${newObservation.id}`)
    } catch (error) {
      console.error('Error submitting observation:', error)
      // Handle error, perhaps set an error message in state
    } finally {
      setIsLoading(false)
    }
  }

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      type: [],
      date: dayjs().toISOString(),
      veterinary: account?.defaultVeterinary
        ? `${account.defaultVeterinary.id}_${account.defaultVeterinary.locationId}`
        : '',
      additionalInfo: '',
    },
  })

  const defaultVeterinaryAsString = account?.defaultVeterinary
    ? `${account.defaultVeterinary.id}_${account.defaultVeterinary.locationId}`
    : ''
  const watchVeterinary = watch('veterinary')

  useEffect(() => {
    if (
      defaultVeterinaryAsString &&
      (watchVeterinary === '' || watchVeterinary === undefined || watchVeterinary === null)
    ) {
      setValue('veterinary', defaultVeterinaryAsString)
    }
    if (watchVeterinary === defaultVeterinaryAsString || !defaultVeterinaryAsString) {
      setShouldSetDefaultVeterinary(true)
    } else {
      setShouldSetDefaultVeterinary(false)
    }
  }, [watchVeterinary, defaultVeterinaryAsString])

  if (!animal || !observationsList) return <CircularProgressPanel />

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 1, p: 4, pb: 10 }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Grow in>
            <Box>
              <Field label="Quel est le type de votre observation ?" required>
                <ControlledMultipleAutoComplete
                  sx={{ ...spacingItem, width: '100%' }}
                  control={control}
                  error={undefined}
                  fieldName="type"
                  size="small"
                  label="Type d'observation"
                  options={observationsList.map((type) => ({
                    label: getReadableHealthObservationsType(type),
                    value: type,
                  }))}
                  requiredRule={"Le type d'observation est requis"}
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={true}>
            <Box>
              <Field label={`A quelle date l'avez vous observé ?`} required>
                <ControlledDateField
                  control={control}
                  fieldName="date"
                  error={errors.date}
                  requiredRule={"La date de l'observation est requise"}
                  validate={(value) => isValidPastDateCoherence(value) || value === null}
                  size="small"
                  label="Date de l'observation"
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={true} timeout={500}>
            <Box>
              <Field label="Avec quel vétérinaire ?">
                <>
                  <ControlledVeterinariesAutocomplete
                    control={control}
                    fieldName="veterinary"
                    placeholder={defaultVeterinaryName || undefined}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      checked={shouldSetDefaultVeterinary}
                      onChange={() => setShouldSetDefaultVeterinary(!shouldSetDefaultVeterinary)}
                    />
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Enregistrer comme véto par défaut
                    </Typography>
                  </Box>
                </>
              </Field>
            </Box>
          </Grow>
          <Grow in={true} timeout={500}>
            <Box>
              <Field label={`Infos complémentaires`}>
                <TextField
                  aria-label="Infos complémentaires"
                  type="text"
                  placeholder="(Notes sur l'animal, etc.)"
                  {...register('additionalInfo')}
                  multiline
                  rows={4}
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '100%',
                    },
                  }}
                />
              </Field>
            </Box>
          </Grow>

          <Grow in={true} timeout={500}>
            <Box>
              {acceptedFiles[0] ? (
                acceptedFiles[0].type.startsWith('video') ? (
                  <video
                    style={{
                      width: '100%',
                      height: '330px',
                      objectFit: 'contain',
                      borderRadius: '11.25px',
                    }}
                    src={acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0])}
                    controls
                  />
                ) : (
                  <img
                    style={{
                      width: '100%',
                      height: '330px',
                      objectFit: 'contain',
                      borderRadius: '11.25px',
                    }}
                    src={acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0])}
                  />
                )
              ) : (
                <Box
                  {...getRootProps()}
                  sx={{
                    width: '100%',
                    height: '330px',
                    objectFit: 'cover',
                    borderRadius: '11.25px',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'lightgrey',
                  }}
                >
                  <input {...getInputProps()} />
                  <Typography variant="body1" component="p">
                    Ajouter une photo/vidéo <Upload />
                  </Typography>
                </Box>
              )}
            </Box>
          </Grow>

          <Grow in={true} timeout={1000}>
            <LoadingButton type="submit" variant="contained" loading={isLoading} sx={{ mt: 2 }}>
              Ajouter l&#39;observation
            </LoadingButton>
          </Grow>
        </form>
      </Box>
    </Box>
  )
}
