import axios from 'axios'

export const getBackendBaseUrl = (): string => {
  const currentFrontendUrl = window.location.href

  if (currentFrontendUrl.includes('.netlify.app')) {
    return 'https://petso-backend.herokuapp.com'
  }

  return 'https://petso-backend.herokuapp.com'

  // return 'http://localhost:3007'
}

export const backendClient = axios.create({
  baseURL: `${getBackendBaseUrl()}/v1`,
})
