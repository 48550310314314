import { Box, Button, Grow, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { UseMutationResult } from 'react-query'
import { getReadableAdopterAdoptionFormYesNo } from '../../../domain/Adopters/AccountDisplay'
import {
  getQuestionsForEnvironment,
  getReadableHomeType,
} from '../../../domain/AnimalProfileDisplay/HealthQuestionsDisplay'
import {
  AnimalProfileDto,
  EnvironmentQuestionsDto,
  EnvironmentQuestionsDtoHasAccessToSecuredWindows,
  EnvironmentQuestionsDtoHasAnAdaptedHidingPlace,
  EnvironmentQuestionsDtoHomeType,
} from '../../../interactors/gen/backendClient'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { Field } from '../../common/FormField'

interface Props {
  animalProfile: AnimalProfileDto
  editMutation: UseMutationResult<AnimalProfileDto, unknown, AnimalProfileDto, unknown>
}

export const EditEnvironmentQuestions: React.FC<Props> = ({ animalProfile, editMutation }) => {
  const mostRecentEnvironmentQuestions = animalProfile.health.score?.environmentQuestions?.sort((a, b) =>
    dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
  )[0]
  if (!mostRecentEnvironmentQuestions) return null

  const [selectedQuestion, setSelectedQuestion] = React.useState<string | null>(null)

  const getDefaultValues = (environmentQuestions: EnvironmentQuestionsDto) => {
    return {
      homeType: environmentQuestions.homeType,
      hasAccessToSecuredWindows: environmentQuestions.hasAccessToSecuredWindows,
      hasAnAdaptedHidingPlace: environmentQuestions.hasAnAdaptedHidingPlace,
      hasPerches: environmentQuestions.hasPerches,
      hasToys: environmentQuestions.hasToys,
      hasScratchingPosts: environmentQuestions.hasScratchingPosts,
      hasOneMoreLitterBoxThanNumberOfCats: environmentQuestions.hasOneMoreLitterBoxThanNumberOfCats,
      LitterIsFarFromFood: environmentQuestions.LitterIsFarFromFood,
      date: dayjs().toISOString(),
    }
  }

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormExtended(mostRecentEnvironmentQuestions, getDefaultValues)

  useEffect(() => {
    const scrollDown = () => {
      const chatContainer = document.querySelector('.scrollable-content') as HTMLElement
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
    if (selectedQuestion === null && isDirty) scrollDown()
  }, [selectedQuestion])

  const onSubmit = (data: EnvironmentQuestionsDto) => {
    const environmentQuestionsArray = animalProfile.health.score?.environmentQuestions || []
    const inputDataDate = dayjs(data.date).format('YYYY-MM-DD') // Normalize input date to 'YYYY-MM-DD'

    // Find index based on date comparison (ignoring time)
    const index = environmentQuestionsArray.findIndex(
      (question) => dayjs(question.date).format('YYYY-MM-DD') === inputDataDate
    )

    if (index !== -1) {
      // If found, replace the existing entry
      environmentQuestionsArray[index] = data
    } else {
      // If not found, push the new data
      environmentQuestionsArray.push(data)
    }

    const newAnimalProfile: AnimalProfileDto = {
      ...animalProfile,
      health: {
        ...animalProfile.health,
        score: {
          ...animalProfile.health.score,
          healthScore: animalProfile.health.score?.healthScore || 0,
          environmentQuestions: environmentQuestionsArray,
        },
      },
    }
    editMutation.mutate(newAnimalProfile) // Assuming editMutation is a mutation hook from react-query
  }

  const displaySelectedQuestions = () => {
    switch (selectedQuestion) {
      case 'homeType':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForEnvironment('homeType', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.homeType}
                  fieldName="homeType"
                  firstOptionLabel="Appartement"
                  secondOptionLabel="Maison"
                  firstOptionValue={EnvironmentQuestionsDtoHomeType.Apartment}
                  secondOptionValue={EnvironmentQuestionsDtoHomeType.House}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'hasAccessToSecuredWindows':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasAccessToSecuredWindows', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasAccessToSecuredWindows}
                  fieldName="hasAccessToSecuredWindows"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'hasAnAdaptedHidingPlace':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasAnAdaptedHidingPlace', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasAnAdaptedHidingPlace}
                  fieldName="hasAnAdaptedHidingPlace"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'hasPerches':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasPerches', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasPerches}
                  fieldName="hasPerches"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAnAdaptedHidingPlace.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAnAdaptedHidingPlace.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'hasToys':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasToys', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasToys}
                  fieldName="hasToys"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'hasScratchingPosts':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasScratchingPosts', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasScratchingPosts}
                  fieldName="hasScratchingPosts"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'hasOneMoreLitterBoxThanNumberOfCats':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasOneMoreLitterBoxThanNumberOfCats', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasOneMoreLitterBoxThanNumberOfCats}
                  fieldName="hasOneMoreLitterBoxThanNumberOfCats"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      case 'LitterIsFarFromFood':
        return (
          <Grow in={true}>
            <Box>
              <Field label={getQuestionsForEnvironment('LitterIsFarFromFood', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.LitterIsFarFromFood}
                  fieldName="LitterIsFarFromFood"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setSelectedQuestion(null)}
                />
              </Field>
            </Box>
          </Grow>
        )
      default:
        return null
    }
  }

  if (selectedQuestion) return displaySelectedQuestions()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('homeType')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForEnvironment('homeType', animalProfile.name)}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableHomeType(watch('homeType'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('hasAccessToSecuredWindows')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForEnvironment('hasAccessToSecuredWindows', animalProfile.name)}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableAdopterAdoptionFormYesNo(watch('hasAccessToSecuredWindows'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('hasAnAdaptedHidingPlace')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForEnvironment('hasAnAdaptedHidingPlace', animalProfile.name)}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableAdopterAdoptionFormYesNo(watch('hasAnAdaptedHidingPlace'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('hasPerches')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForEnvironment('hasPerches', animalProfile.name)}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableAdopterAdoptionFormYesNo(watch('hasPerches'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('hasToys')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForEnvironment('hasToys', animalProfile.name)}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableAdopterAdoptionFormYesNo(watch('hasToys'))}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('hasScratchingPosts')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForEnvironment('hasScratchingPosts', animalProfile.name)}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableAdopterAdoptionFormYesNo(watch('hasScratchingPosts'))}
        </Typography>
      </Box>
      {mostRecentEnvironmentQuestions.hasOneMoreLitterBoxThanNumberOfCats && (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
          onClick={() => setSelectedQuestion('hasOneMoreLitterBoxThanNumberOfCats')}
        >
          <Typography variant="h6" sx={{ fontSize: '16px' }}>
            {getQuestionsForEnvironment('hasOneMoreLitterBoxThanNumberOfCats', animalProfile.name)}
          </Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
            Votre réponse:{' '}
            {getReadableAdopterAdoptionFormYesNo(
              watch('hasOneMoreLitterBoxThanNumberOfCats') ||
                mostRecentEnvironmentQuestions.hasOneMoreLitterBoxThanNumberOfCats
            )}
          </Typography>
        </Box>
      )}
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', borderTop: '1px solid #CCCCCC', py: 1 }}
        onClick={() => setSelectedQuestion('LitterIsFarFromFood')}
      >
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          {getQuestionsForEnvironment('LitterIsFarFromFood', animalProfile.name)}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Votre réponse: {getReadableAdopterAdoptionFormYesNo(watch('LitterIsFarFromFood'))}
        </Typography>
      </Box>
      {isDirty && (
        <Button variant="contained" onClick={handleSubmit(onSubmit)} fullWidth>
          Enregistrer mes modifications
        </Button>
      )}
    </Box>
  )
}
