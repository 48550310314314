import { Box, Icon, Typography } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import logo from '../logo.svg'

export const Layout: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'hidden',
      }}
    >
      <Box
        sx={{
          mx: 'auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          // fontFamily: 'monospace',
          fontWeight: 700,
          color: 'black',
          textDecoration: 'none',
        }}
      >
        <Icon sx={{ width: '60px', height: '60px' }}>
          <img src={logo} style={{ width: '100%', height: '100%' }} />
        </Icon>
        <Typography variant="h6" noWrap fontSize={26}>
          Petso
        </Typography>
      </Box>
      <Outlet />
    </Box>
  )
}
