import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Modal } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { getColorForOperationType, getReadableOperationType } from '../../domain/Animal/AnimalDisplay'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import {
  AnimalHealthOperationDtoType,
  AnimalProfileDto,
  AnimalProfileHealthOperationDto,
} from '../../interactors/gen/backendClient'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { ControlledDateField } from '../common/ControlledDateField'
import { ControlledMultipleAutoCompleteWithCategories } from '../common/ControlledMultipleAutocompleteWithCategories'
import { ControlledVeterinariesAutocomplete } from '../common/ControlledVeterinariesAutocomplete'
import { Field } from '../common/FormField'
import { spacingItem } from '../theme'

interface Props {
  animal: AnimalProfileDto
  setAnimal: (animal: AnimalProfileDto) => void
  operation: AnimalProfileHealthOperationDto
  setOperation: (operation: AnimalProfileHealthOperationDto) => void
  veterinaryName?: string
}

interface FormData {
  type: AnimalHealthOperationDtoType[]
  date: string
  veterinary?: string
  additionalInfo?: string
}

export const EditOperationDetailsModal: React.FC<Props> = ({
  animal,
  setAnimal,
  operation,
  setOperation,
  veterinaryName,
}) => {
  const [isModalOpened, setIsModalOpened] = React.useState(false)

  const onCancel = () => {
    setIsModalOpened(false)
  }

  const onSubmit = async (data: FormData) => {
    const veterinaryAndLocation = data.veterinary ? data.veterinary.split('_') : [undefined, undefined]
    const [veterinaryId, locationId] = veterinaryAndLocation

    const newOperation = {
      ...operation,
      type: data.type,
      date: data.date !== operation.date ? dayjs(data.date).format('DD/MM/YYYY') : operation.date,
      veterinary: {
        id: veterinaryId || operation.veterinary?.id || '',
        locationId: locationId || operation.veterinary?.locationId || '',
      },
    }

    const animalWithUpdatedOperation = {
      ...animal,
      health: {
        ...animal.health,
        operations: animal.health.operations.map((op) => (op.id === operation.id ? newOperation : op)),
      },
    }

    const newAnimal = await AnimalProfilesClient.editAnimalProfile(animalWithUpdatedOperation)

    setAnimal(newAnimal)
    setOperation(newOperation)

    setIsModalOpened(false)
  }

  const getDefaultValues = (operation: AnimalProfileHealthOperationDto): FormData => ({
    type: operation.type,
    date: operation.date,
    veterinary: `${operation.veterinary?.id}_${operation.veterinary?.locationId}`,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(operation, getDefaultValues)

  const optionsWithCategories = [
    {
      title: 'Primovaccination et rappel',
      options: Object.values(AnimalHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.FirstInjection,
              AnimalHealthOperationDtoType.SecondInjection,
              AnimalHealthOperationDtoType.ThirdInjection,
              AnimalHealthOperationDtoType.AnnualReminder,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
    {
      title: 'Identification',
      options: Object.values(AnimalHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.IdentificationChip,
              /* AnimalHealthOperationDtoType.EarMarking, */
              AnimalHealthOperationDtoType.Tatoo,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
    {
      title: 'Opérations',
      options: Object.values(AnimalHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.SterilizedOrNeutered,
              AnimalHealthOperationDtoType.Implant,
              AnimalHealthOperationDtoType.Surgery,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
    /* {
      title: 'Soins courants',
      options: Object.values(AnimalHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.FleaControl,
              AnimalHealthOperationDtoType.Deworming,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    }, */
    {
      title: 'Tests/Diagnostiques',
      options: Object.values(AnimalHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.FeLv,
              AnimalHealthOperationDtoType.Fiv,
              AnimalHealthOperationDtoType.GoodHealthCertificate,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
    {
      title: 'Autres visites vétérinaires',
      options: Object.values(AnimalHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalHealthOperationDtoType.Others,
              AnimalHealthOperationDtoType.Identification,
            ] as AnimalHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
  ]

  return (
    <>
      <IconButton onClick={() => setIsModalOpened(true)} color="secondary">
        <Edit />
      </IconButton>
      <Modal open={isModalOpened} onClose={onCancel}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              maxWidth: '100%',
              width: '400px',
              maxHeight: '100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              padding: '24px',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              overflowY: 'auto',
            }}
          >
            <Field label="Type(s) de rendez-vous" required>
              <ControlledMultipleAutoCompleteWithCategories
                sx={{ ...spacingItem, width: '100%' }}
                control={control}
                error={undefined}
                fieldName="type"
                size="small"
                label="Type de rendez-vous"
                options={optionsWithCategories}
                requiredRule={'Le type de rendez-vous est requis'}
              />
            </Field>
            <Field label={`Date du rendez-vous`} required>
              <ControlledDateField
                control={control}
                fieldName="date"
                error={errors.date}
                requiredRule={'La date du rendez-vous est requise'}
                validate={(value) => value !== null || value === null}
                size="small"
                label="Date du rendez-vous"
              />
            </Field>
            <Field label="Vétérinaire sélectionné">
              <ControlledVeterinariesAutocomplete
                control={control}
                fieldName="veterinary"
                placeholder={veterinaryName}
              />
            </Field>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="text" onClick={onCancel}>
                Annuler
              </Button>
              <LoadingButton type="submit" variant="contained" loading={false}>
                Enregistrer
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  )
}
