import { Box, Typography } from '@mui/material'
import React from 'react'
import { lightBlue } from '../theme'

interface Props {
  icon: React.ReactNode
  title: string
  text: { key: string; value: string }[]
}

export const AdoptionFurtherInfoItem: React.FC<Props> = ({ icon, title, text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        py: '8px',
        px: '16px',
        borderRadius: '5px',
        gap: '14px',
        backgroundColor: lightBlue,
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          height: '40px',
          p: '8px',
        }}
      >
        {icon}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Typography
          variant="body1"
          component="p"
          sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
        >
          {title}
        </Typography>
        {text.map((item) => (
          <Typography
            key={item.key}
            variant="body1"
            component="p"
            sx={{ fontSize: '14px', lineHeight: '16.8px', fontWeight: 400, color: '#4f4f4f' }}
          >
            {item.key} : {item.value}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}
