import { zodResolver } from '@hookform/resolvers/zod'
import { Box, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { LoadingButton } from '@mui/lab'
import {
  AnimalProfileDto,
  AnimalProfileHealthObservationsDto,
  AnimalProfileHealthOperationDto,
} from '../../interactors/gen/backendClient'

// Define the form's validation schema using Zod
const schema = z.object({
  name: z.string().min(1, { message: 'Le nom est requis' }),
  comment: z.string().min(1, { message: 'Le commentaire ne peut pas être vide' }),
})

type FormData = z.infer<typeof schema>

interface Props {
  type: 'operation' | 'observation'
  animalId: string
  id: string
  setAnimal: React.Dispatch<React.SetStateAction<AnimalProfileDto | null>>
  setObservation?: React.Dispatch<React.SetStateAction<AnimalProfileHealthObservationsDto | null>>
  setOperation?: React.Dispatch<React.SetStateAction<AnimalProfileHealthOperationDto | null>>
}

export const AddComment: React.FC<Props> = ({ type, animalId, id, setAnimal, setObservation, setOperation }) => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setLoading(true)
    const updatedAnimal = await AnimalProfilesClient.addCommentToObservationOrOperation(animalId, id, type, data)
    setAnimal(updatedAnimal)
    if (setObservation && type === 'observation') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setObservation(updatedAnimal.health.observations!.find((o) => o.id === id)!)
    }
    if (setOperation && type === 'operation') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setOperation(updatedAnimal.health.operations.find((o) => o.id === id)!)
    }
    setLoading(false)
    reset() // Reset form fields after submission
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
      <Typography variant="h6" gutterBottom>
        Ajouter un compte rendu
      </Typography>
      <TextField
        margin="normal"
        fullWidth
        label="Nom"
        autoComplete="name"
        autoFocus
        {...register('name')}
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
      />
      <TextField
        margin="normal"
        fullWidth
        label="Compte rendu"
        multiline
        rows={4}
        {...register('comment')}
        error={Boolean(errors.comment)}
        helperText={errors.comment?.message}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '100%',
          },
        }}
      />
      <Box sx={{ mt: 2, position: 'relative' }}>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
          loading={loading}
          sx={{ py: 1.5 }}
        >
          Ajouter un compte rendu
        </LoadingButton>
      </Box>
    </Box>
  )
}
