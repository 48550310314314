import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { AdoptersClient } from './interactors/clients/AdoptersClient'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY
if (!apiKey) throw new Error('API Key undefined')

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `petso-push.firebaseapp.com`,
  projectId: 'petso-push',
  storageBucket: `petso-push.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Messaging
const messaging = getMessaging(app)

export const requestFirebaseMessagingToken = () => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        AdoptersClient.updateFCMToken(currentToken)
        return currentToken
      } else {
        console.log('No registration token available. Request permission to generate one.')
        // ...
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // ...
    })
}

// Foreground message listener
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload)
  // Customize notification here
})
