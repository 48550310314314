import { Upload } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Grow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Navigate, useNavigate } from 'react-router-dom'
import { getEmojiAnimalSex } from '../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../domain/Animal/calculateAge'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { BeRealsClient } from '../../interactors/clients/BeRealsClient'
import { AnimalProfileDto, CreateBeRealDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'

export const SendMyBeReal: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const [isLoading, setIsLoading] = React.useState(false)
  const [growCongrats, setGrowCongrats] = React.useState(false)
  const [growSad, setGrowSad] = React.useState(false)
  const [growUpload, setGrowUpload] = React.useState(false)
  const [growWow, setGrowWow] = React.useState(false)
  const [growSubmit, setGrowSubmit] = React.useState(false)

  const [animal, setAnimal] = React.useState<AnimalProfileDto | null>(null)
  const [alreadyPosted, setAlreadyPosted] = React.useState<boolean | null>(null)

  const onDrop = () => {
    setTimeout(() => {
      setGrowWow(true)
    }, 500)
    setTimeout(() => {
      setGrowSubmit(true)
    }, 1000)
  }

  const navigate = useNavigate()
  const globalSnackbarStore = useGlobalSnackbarStore()

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
  })

  const onSubmit = async () => {
    if (!acceptedFiles[0] || !animal) return
    setIsLoading(true)

    const bereal: CreateBeRealDto = {
      adopterId: animal.adopterId,
      animalProfileId: animal.id,
    }

    await BeRealsClient.createBeReal(bereal, acceptedFiles[0])

    navigate(`/actualites`)
    setIsLoading(false)
  }

  useEffect(() => {
    const animalId = queryParams.get('animalId')
    if (!animalId) return
    const fetchAnimal = async (): Promise<void | boolean> => {
      const isPosted = await BeRealsClient.checkIfBeRealExists(animalId)
      setAlreadyPosted(isPosted)
      if (isPosted) return
      const animal = await AnimalProfilesClient.getAnimalById(animalId)
      setAnimal(animal)
    }
    fetchAnimal()

    const delayGrows = setTimeout(() => {
      setGrowCongrats(true)
    }, 200)

    const delaySad = setTimeout(() => {
      setGrowSad(true)
    }, 600)

    const delayUpload = setTimeout(() => {
      setGrowUpload(true)
    }, 1200)

    return () => {
      clearTimeout(delayGrows)
      clearTimeout(delaySad)
      clearTimeout(delayUpload)
    }
  }, [])

  if (alreadyPosted === true) {
    globalSnackbarStore.triggerErrorMessage(
      `Vous avez déjà posté un PetReal aujourd'hui pour cet animal. Revenez demain !`
    )
    return <Navigate to="/actualites" />
  }

  if (!animal || alreadyPosted === undefined) return null

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Grow in={growCongrats} timeout={500}>
        <Typography variant="h2" component="h2">
          {`Quelle tête à ${animal.name} en ce moment 🫣`}
        </Typography>
      </Grow>
      <Grow in={growSad} timeout={500}>
        <Typography variant="body1" component="p">
          {`Partage nous une photo de ${animal.sex === 'male' ? 'lui' : 'elle'} !`}
        </Typography>
      </Grow>
      <Grow in={growUpload} timeout={1200}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {acceptedFiles[0] ? (
            <img
              style={{
                width: '300px',
                maxWidth: '100%',
                height: '330px',
                objectFit: 'cover',
                borderRadius: '11.25px',
              }}
              src={acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0])}
            />
          ) : (
            <Box
              {...getRootProps()}
              sx={{
                width: '300px',
                maxWidth: '100%',
                height: '330px',
                objectFit: 'cover',
                borderRadius: '11.25px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'lightgrey',
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1" component="p">
                Ajouter une photo <Upload />
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              gap: '4px',
              width: '300px',
              maxWidth: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography variant="h1" component="p">
                {animal.name}
              </Typography>
            </Box>
            <Typography
              variant="h1"
              component="p"
              color={animal.sex === 'female' ? 'pink' : 'blue'}
              fontWeight={700}
              fontSize={22}
            >
              {animal.sex ? getEmojiAnimalSex(animal.sex) : ''}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography
                variant="body2"
                component="p"
                fontSize={18}
                fontWeight={500}
                fontStyle="italic"
                lineHeight={1.6}
              >
                {animal?.birthday?.date ? calculateAge(animal.birthday.date) : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grow>
      <Grow in={growWow} timeout={500}>
        <Typography variant="body1" component="p">
          Wow ! {animal.name} est vraiment super {animal.sex === 'female' ? 'mignonne' : 'mignon'} 😍
        </Typography>
      </Grow>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '8px' }}>
        <Grow in={growSubmit} timeout={500}>
          <LoadingButton variant="contained" color="primary" loading={isLoading} onClick={onSubmit}>
            Envoyer mon PetReal
          </LoadingButton>
        </Grow>
      </Box>
    </Box>
  )
}
