import React from 'react'
import { AdoptionAttemptDtoStatus } from '../../interactors/gen/backendClient'
import { Box, Typography } from '@mui/material'
import { getReadableAdoptionStatus } from '../../domain/Adoptions/AdoptionDisplay'

interface Props {
  status: AdoptionAttemptDtoStatus
  textToDisplay?: string
  displaySterilizationStatus?: boolean
}

export const AdoptionStatusIndicator: React.FC<Props> = ({ status, textToDisplay, displaySterilizationStatus }) => {
  const determineBGColor = (status: AdoptionAttemptDtoStatus) => {
    if (displaySterilizationStatus) {
      return '#D0E8FF'
    }
    switch (status) {
      case AdoptionAttemptDtoStatus.PendingCustomer:
        return '#f3f3f5'
      case AdoptionAttemptDtoStatus.PendingUser:
        return '#FFE5CD'
      case AdoptionAttemptDtoStatus.SuspendedByCustomer:
        return '#ffc3bf'
      case AdoptionAttemptDtoStatus.SuspendedByUser:
        return '#ffc3bf'
      default:
        return '#f6fff9'
    }
  }

  const determinePrincipalColor = (status: AdoptionAttemptDtoStatus) => {
    if (displaySterilizationStatus) {
      return '#0056b3'
    }
    switch (status) {
      case AdoptionAttemptDtoStatus.PendingCustomer:
        return '#333333'
      case AdoptionAttemptDtoStatus.PendingUser:
        return '#FF7A00'
      case AdoptionAttemptDtoStatus.SuspendedByCustomer:
        return '#d93025'
      case AdoptionAttemptDtoStatus.SuspendedByUser:
        return '#d93025'
      default:
        return '#2f7d32'
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '160px',
        height: '36px',
        borderRadius: '4px',
        border: '1px solid',
        p: '8px',
        borderColor: determinePrincipalColor(status),
        backgroundColor: determineBGColor(status),
        color: determinePrincipalColor(status),
      }}
    >
      <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '20.11px', fontWeight: 400 }}>
        {textToDisplay ?? getReadableAdoptionStatus(status)}
      </Typography>
    </Box>
  )
}
