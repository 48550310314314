import { Opaque } from '../Opaque'
import { isString } from '../isString'
import dayjs from 'dayjs'

export type DateOnly = Opaque<string, 'DateOnly'>

export const DATE_ONLY_FORMAT = 'DD/MM/YYYY'

export const dayJSFromDateOnly = (dateOnly: DateOnly): dayjs.Dayjs => {
  return dayjs(dateOnly, DATE_ONLY_FORMAT)
}

export const toDateOnly = (date: DateOnly | dayjs.Dayjs | string): DateOnly => {
  if (isString(date)) {
    return date as DateOnly
  }

  return dayjs(date).format(DATE_ONLY_FORMAT) as DateOnly
}

export const toDayJSFromAll = (date: DateOnly | dayjs.Dayjs | '' | null | string): dayjs.Dayjs | null => {
  if (!date) return null
  if (isString(date)) {
    const parsedDate = dayjs(date)
    if (parsedDate.isValid()) {
      return parsedDate
    } else {
      return dayJSFromDateOnly(date as DateOnly)
    }
  }

  return dayjs(date)
}
