import { Box, Button, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React from 'react'

export const LovysMainContent: React.FC = () => {
  const [step, setStep] = React.useState(1)
  const [toggle, setToggle] = React.useState<'tattoo' | 'id'>('id')
  return step < 2 ? (
    <>
      <Box
        component="img"
        src="https://i.imgur.com/QCqFKsv.png"
        alt={`Illustration de Lovys`}
        sx={{
          objectFit: 'contain',
          maxWidth: '100%',
          maxHeight: '400px', // make image take full height of the container
          imageRendering: '-webkit-optimize-contrast',
        }}
      />
      <Button variant="contained" color="secondary" onClick={() => setStep((prev) => prev + 1)}>
        Devis gratuit
      </Button>
    </>
  ) : (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: '10px', border: 'solid 2px', borderRadius: '10px', p: 2 }}
    >
      <Typography sx={{ fontWeight: 700 }}>Vérifiez les infos de votre animal</Typography>
      <TextField label="Nom" variant="outlined" value="Roselyn" />
      <TextField label="Date de naissance" variant="outlined" value="09/02/2019" />
      <TextField label="Race" variant="outlined" value="Jack Russell" />
      <ToggleButtonGroup
        value={toggle}
        exclusive
        onChange={(_, newValue) => setToggle(newValue)}
        size="medium"
        fullWidth
        sx={{ '& .MuiToggleButton-root': { height: '48px' } }} // Set height of buttons
      >
        <ToggleButton value={'id'} key="firstOption">
          Puce électronique
        </ToggleButton>
        <ToggleButton value={'tattoo'} key="secondOption">
          Tatouage
        </ToggleButton>
      </ToggleButtonGroup>
      <TextField
        label={toggle === 'id' ? 'Numéro de Puce' : 'Numéro de Tatouage'}
        variant="outlined"
        value={toggle === 'id' ? '250 26 99 031225337' : 'PE'}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '12px', mt: 2 }}>
        <Button variant="contained" color="primary" onClick={() => setStep((prev) => prev - 1)}>
          Précédent
        </Button>
        <Button variant="contained" color="secondary">
          Valider
        </Button>
      </Box>
    </Box>
  )
}
