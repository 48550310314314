import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { FormControl, FormHelperText, ToggleButtonGroup, ToggleButton, SxProps, Theme, Typography } from '@mui/material'

interface Option {
  label: string
  value: string | boolean | number
}

type Props<T extends FieldValues> = {
  sx?: SxProps<Theme>
  label?: string
  control: Control<T>
  error?: FieldError
  fieldName: Path<T>
  options: Option[]
  requiredRule?: string
  disabled?: boolean
  fullWidth?: boolean
  onKeyUp?: () => void
  onChangeFromParent?: (value: string | boolean | number | undefined | null) => void // Now passes value to parent handler
}

export function ControlledMultipleToggleButton<T extends FieldValues>({
  sx,
  label,
  control,
  error,
  fieldName,
  options,
  requiredRule,
  disabled,
  onKeyUp,
  onChangeFromParent,
  fullWidth = false,
}: Props<T>) {
  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{ width: '100%', ...sx }}
      required={Boolean(requiredRule)}
      component="fieldset"
    >
      <Controller
        name={fieldName}
        control={control}
        defaultValue={undefined}
        rules={{
          validate: (value) => (requiredRule ? value !== undefined : true),
        }}
        render={({ field: { onChange, value } }) => (
          <>
            {label && (
              <Typography variant="body1" sx={{ mb: 1 }}>
                {label}
              </Typography>
            )}
            <ToggleButtonGroup
              value={value}
              aria-label={label}
              exclusive
              onChange={(_, newValue: string | boolean | number | null) => {
                if (newValue !== null) {
                  // Only update if newValue is not null
                  onChange(newValue)
                }
                if (onChangeFromParent) {
                  onChangeFromParent(newValue)
                }
              }}
              size="medium"
              disabled={disabled}
              onKeyUp={onKeyUp}
              orientation="vertical"
              fullWidth={fullWidth}
              sx={{ '& .MuiToggleButton-root': { height: '48px' } }} // Custom height for buttons
            >
              {options.map((option: Option) => (
                <ToggleButton value={option.value} key={option.label}>
                  {option.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </>
        )}
      />
      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
    </FormControl>
  )
}
