import { CheckCircleOutline, DoDisturb, ShoppingCart } from '@mui/icons-material'
import { Box, Button, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AdoptionAttemptDto, AnimalDto } from '../../interactors/gen/backendClient'
import { useBasketStore } from '../../store/BasketStore'
import { colors } from '../theme'
import { AccessoriesTab } from './AccessoriesTab'
import { AdoptionPriceDetailsModal } from './AdoptionPriceDetailsModal'
import data from '../Shopping/data/products.json'
import { backendClient } from '../../interactors/clients/client'
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { CheckoutForm } from '../common/StripeCheckoutForm'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { useNavigate } from 'react-router-dom'
import { PaymentMarketStepModal } from './PaymentMarketStepModal'
import { MetricalpInfo } from './DocumentSignCard'
import { metricalpEvent } from '@metricalp/react'

interface Props {
  attempt: AdoptionAttemptDto
  animal: AnimalDto
  onSubmit: (
    data: Partial<AdoptionAttemptDto>,
    type: 'certificate' | 'contract' | 'payment',
    noUpdate?: boolean
  ) => Promise<void>
  metricalpInfo: MetricalpInfo
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

export const AdoptionPaymentPath: React.FC<Props> = ({ animal, attempt, onSubmit, metricalpInfo }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const initialStep = queryParams.get('step')
  const navigate = useNavigate()
  const basketStore = useBasketStore()
  const basket = basketStore.basket
  const PETSO_FEE = 12
  const ADOPTION_PRICE = (Number(animal.adoption.priceInEuros) || 0) + PETSO_FEE
  const [marketModalOpened, setMarketModalOpened] = useState<boolean>(true)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [donation, setDonation] = useState<number>(5)
  const [alreadyPaidAdoptionFees, setAlreadyPaidAdoptionFees] = useState<boolean>(false)

  const onClose = () => {
    metricalpEvent({
      type: `adoption_action`,
      action: `access_discount`,
      adopterId: metricalpInfo.adopterId,
      adopterName: metricalpInfo.adopterName,
      animalName: metricalpInfo.animalName,
      animalId: metricalpInfo.animalId,
    })
    setMarketModalOpened(false)
  }

  const fdp = basketStore.totalItems() > 0 ? (basketStore.totalPrice() > 30 ? 0 : 5.99) : 0

  const total =
    Math.round(
      (basketStore.totalPrice() + fdp + donation + (alreadyPaidAdoptionFees ? 0 : ADOPTION_PRICE) + Number.EPSILON) *
        100
    ) / 100

  const [step, setStep] = useState<number>(initialStep ? Number(initialStep) : 1)

  const handleStepForward = () => {
    if (step === 2) {
      if (total === 0) {
        if (alreadyPaidAdoptionFees) {
          onSubmit({ step: 4 }, 'payment')
          metricalpEvent({
            type: `adoption_action`,
            action: `done_payment`,
            adopterId: metricalpInfo.adopterId,
            adopterName: metricalpInfo.adopterName,
            animalName: metricalpInfo.animalName,
            animalId: metricalpInfo.animalId,
          })
        }
        return navigate(`/adoptions/${attempt.id}`)
      }
    }
    if (step === 3) {
      if (alreadyPaidAdoptionFees) {
        onSubmit({ step: 4 }, 'payment', true)
        metricalpEvent({
          type: `adoption_action`,
          action: `done_payment`,
          adopterId: metricalpInfo.adopterId,
          adopterName: metricalpInfo.adopterName,
          animalName: metricalpInfo.animalName,
          animalId: metricalpInfo.animalId,
        })
      }
      createPaymentIntent()
    }
    setStep(step + 1)
    navigate(`/adoptions/${attempt.id}?page=action&step=${step + 1}`)
  }

  useEffect(() => {
    setStep(initialStep ? Number(initialStep) : 1)
  }, [queryParams])

  const [modalOpened, setModalOpened] = useState<boolean>(false)

  const handleDonationChange = (_: React.MouseEvent<HTMLElement>, newDonation: number | null) => {
    if (newDonation !== null) {
      setDonation(newDonation)
    }
  }

  const createPaymentIntent = async () => {
    const response = await backendClient.post(
      `/adopters-platform/stripe/create-payment-intent?attemptId=${attempt.id}`,
      {
        items: [
          ...basket.map((item) => ({
            ...item,
            price: item.price * 100,
          })),
          ...(alreadyPaidAdoptionFees ? [] : [{ reference: 'ADOPTION', quantity: 1, price: ADOPTION_PRICE * 100 }]),
          { reference: 'DONATION', quantity: 1, price: donation * 100 },
        ],
      }
    )
    setClientSecret(response.data)
  }

  const appearance: Appearance = {
    theme: 'stripe',
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  const StyledToggleButton = styled(ToggleButton)(() => ({
    borderRadius: '8px !important', // Rounded corners
    margin: '0 8px', // Spacing between buttons
    border: 'none', // No border
    textTransform: 'none', // Disable uppercase text
    fontSize: '16px', // Custom font size
    fontWeight: 'bold', // Bold font
    backgroundColor: 'rgba(217, 217, 217, 1)', // Background color
    color: 'rgba(87, 87, 87, 1)', // Text color

    '&:hover': {
      backgroundColor: colors.blueSky, // Background color on hover
    },

    '&:active': {
      backgroundColor: colors.blueSky, // Background color when active
    },

    '&.Mui-selected': {
      backgroundColor: colors.blueSky + ' !important', // Background color when selected
      color: 'white', // Text color when selected
    },
  }))

  const Step1 = () => (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'start', justifyContent: 'start', py: 4 }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'start', justifyContent: 'start' }}>
          <Box
            sx={{
              backgroundColor: colors.blueSky,
              color: 'white',
              fontSize: '20px',
              fontWeight: 700,
              width: 'fit-content',
              p: 1,
              py: 0.5,
              borderRadius: '11px',
              transform: 'rotate(-1deg)',
            }}
          >
            Les frais d&apos;adoption:
          </Box>
          <Typography variant="body1" sx={{ color: 'rgba(87, 87, 87, 1)', fontWeight: 700, px: 1 }}>
            Les frais pour l&apos;adoption de {animal.name} sont de {ADOPTION_PRICE}€{' '}
            <span
              style={{ textDecoration: 'underline', color: colors.blueSky, fontStyle: 'italic', cursor: 'pointer' }}
              onClick={() => setModalOpened(true)}
            >
              voir le détail
            </span>
          </Typography>
          <Box sx={{ px: 1, width: '100%' }}>
            {alreadyPaidAdoptionFees ? (
              <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 1, mt: 1, width: '100%' }}
              >
                <CheckCircleOutline sx={{ color: colors.seaGreen, fontSize: '26px' }} />
                <Typography variant="body1" color={colors.seaGreen} fontWeight={700} fontSize={14}>
                  Vous affirmez avoir déjà réglé. L’association validera votre contribution.{' '}
                  <Button
                    variant="text"
                    color="inherit"
                    sx={{ fontStyle: 'italic', fontWeight: 700, p: '2px' }}
                    onClick={() => setAlreadyPaidAdoptionFees(false)}
                  >
                    Modifier
                  </Button>
                </Typography>
              </Box>
            ) : (
              <Button variant="outlined" color="primary" fullWidth onClick={() => setAlreadyPaidAdoptionFees(true)}>
                {`J'ai déjà effectué le réglement`}
              </Button>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'start', justifyContent: 'start' }}>
          <Box
            sx={{
              backgroundColor: colors.blueSky,
              color: 'white',
              fontSize: '20px',
              fontWeight: 700,
              width: 'fit-content',
              p: 1,
              py: 0.5,
              borderRadius: '11px',
              transform: 'rotate(-1deg)',
            }}
          >
            Soutenez l&apos;adoption responsable
          </Box>
          <Typography variant="body1" sx={{ color: 'rgba(87, 87, 87, 1)', px: 1 }}>
            Pour lutter contre l’abandon, Petso apporte un service aux associations.{' '}
            <b>Nous ne pouvons exister sans votre soutien !</b>
          </Typography>
          <ToggleButtonGroup size="large" value={donation} onChange={handleDonationChange} exclusive fullWidth>
            <StyledToggleButton value={0} key="0">
              <DoDisturb />
            </StyledToggleButton>
            <StyledToggleButton value={5} key="5">
              5€
            </StyledToggleButton>
            <StyledToggleButton value={10} key="10">
              10€
            </StyledToggleButton>
            <StyledToggleButton value={20} key="20">
              20€
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Button variant="contained" color="primary" fullWidth sx={{ fontSize: '18px' }} onClick={handleStepForward}>
          {`Étape suivante`}
        </Button>
      </Box>
      <AdoptionPriceDetailsModal
        open={modalOpened}
        handleClose={() => setModalOpened(false)}
        animalPrice={Number(animal.adoption.priceInEuros)}
        petsoFee={Number(PETSO_FEE)}
      />
    </>
  )

  const Step2 = () => (
    <>
      <PaymentMarketStepModal
        handleClose={onClose}
        open={marketModalOpened}
        handleStepForward={handleStepForward}
        animalName={animal.name}
        profileImageKey={animal?.images?.profileImageKey || ''}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, mb: 3, mt: 1 }}>
        <CheckCircleOutline sx={{ color: colors.seaGreen, fontSize: '36px' }} />
        <Typography variant="body1" color={colors.seaGreen} fontWeight={700} fontSize={15}>
          En adoptant auprès de l’association, Bénéficiez de réduction exclusive de 10%
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 3,
          backgroundColor: colors.blueSky,
          color: 'white',
          fontSize: '20px',
          fontWeight: 700,
          width: 'fit-content',
          p: 1,
          py: 0.5,
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        {`Pour préparer l'arrivée de ${animal.name}`}
      </Box>
      <AccessoriesTab animalProfile={animal} />
      <Button
        variant="contained"
        color="primary"
        sx={{ fontSize: '18px', position: 'fixed', bottom: 40, left: { xs: 20, md: '260px' }, right: 20 }}
        onClick={handleStepForward}
      >
        {basketStore.totalPrice() > 0
          ? `Ajouter (${Math.round((basketStore.totalPrice() + Number.EPSILON) * 100) / 100} €)`
          : `Passer l'étape`}
      </Button>
    </>
  )

  const Step3 = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', py: 4 }}>
      <Box
        sx={{
          mb: 3,
          backgroundColor: colors.blueSky,
          color: 'white',
          fontSize: '20px',
          fontWeight: 700,
          width: 'fit-content',
          p: 1,
          py: 0.5,
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        {`Récapitulatif`}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'start',
            justifyContent: 'start',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 1, mt: 1, width: '100%' }}>
            <CheckCircleOutline sx={{ color: colors.seaGreen, fontSize: '26px' }} />
            <Typography variant="body1" color={colors.seaGreen} fontWeight={700} fontSize={14}>
              Le code promo a bien été appliqué
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mb: 2,
              p: 2,
              borderRadius: 2,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: 'white',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <ShoppingCart color="primary" />
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Récapitulatif de votre commande</Typography>
            </Box>
            {basket.map((item) => (
              <Box
                key={item.reference}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #E0E0E0',
                  pb: 1,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                  {data.products.find((i) => item.reference === i.reference)?.title} x {item.quantity}
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                  {(item.price * item.quantity).toFixed(2)}€
                </Typography>
              </Box>
            ))}
            {!alreadyPaidAdoptionFees && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #E0E0E0',
                  pb: 1,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Frais d&apos;adoption</Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>{ADOPTION_PRICE}€</Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #E0E0E0',
                pb: 1,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Donation</Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>{donation}€</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: 2,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#888' }}>Frais de port</Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#888' }}>
                {fdp > 0 ? `${fdp}€` : 'Offerts'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: 2,
              }}
            >
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>Total</Typography>
              <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>{total}€</Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ fontSize: '18px', mt: 1 }}
            onClick={handleStepForward}
          >
            {`Payer ${total}€`}
          </Button>
        </Box>
      </Box>
    </Box>
  )

  const Step4 = () => {
    if (!clientSecret) {
      return <CircularProgressPanel absolute={false} />
    }
    return (
      <Box
        sx={{
          py: 4,
        }}
      >
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            price={total}
            redirectionLink={`https://adoptant.petso.fr/adoptions/${attempt.id}`}
            buttonFontSize="18px"
          />
        </Elements>
      </Box>
    )
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 />
      case 2:
        return <Step2 />
      case 3:
        return <Step3 />
      case 4:
        return <Step4 />
      default:
        return null
    }
  }

  return renderStep()
}
