import {
  AccountDto,
  AccountDtoPermission,
  AccountDtoPreferenceSeniority,
  AccountDtoRoles,
  AccountDtoStatus,
} from '../../interactors/gen/backendClient'

export const fullName = (account: { firstName?: string; lastName?: string }) => {
  return [account.firstName, account.lastName].filter((field) => field).join(' ')
}

export const fullAddress = (addressObject: { address?: string; postalCode?: string; country?: string }): string => {
  return [addressObject.address, addressObject.postalCode, addressObject.country].filter((field) => field).join(', ')
}

type AccountPropertyWithLabel = Exclude<
  keyof AccountDto,
  'customerId' | 'isDeleted' | 'images' | 'status' | 'city' | 'preferences'
>
// type AccountPropertyWithLabel = keyof AccountDto

export const getReadableAccountFieldLabel = (field: AccountPropertyWithLabel): string => {
  const map: Record<AccountPropertyWithLabel, string> = {
    id: 'ID',
    firstName: 'Prénom',
    lastName: 'Nom',
    roles: 'Rôles',
    email: 'Email',
    permission: 'Permission',
    address: 'Addresse',
    phoneNumber: 'Numéro de téléphone',
    postalCode: 'Code postal',
    country: 'Pays',
    titles: 'Postes',
    home: 'Domicile',
    availability: 'Disponibilité',
    hasACACED: 'ACACED',
    documents: 'Documents',
    comments: 'Commentaires',
    hasAcceptedTerms: "A accepté les conditions d'utilisation",
    veterinary: 'Vétérinaire',
    visibility: 'Visibilité',
    memberNumber: 'Numéro de membre',
    coordinates: 'Coordonnées',
    restrainedData: 'Données restreintes',
    validated: 'Validé',
  }

  return map[field]
}

export const getReadableAccountRole = (field: AccountDtoRoles): string => {
  const map: Record<AccountDtoRoles, string> = {
    [AccountDtoRoles.Member]: "Membre de l'association",
    [AccountDtoRoles.HostFamily]: "Famille d'accueil",
    [AccountDtoRoles.Veterinary]: 'Vétérinaire',
  }

  return map[field]
}

export const getReadableAccountPermission = (field: AccountDtoPermission): string => {
  const map: Record<AccountDtoPermission, string> = {
    [AccountDtoPermission.Administrator]: 'Admin',
    [AccountDtoPermission.Editor]: 'Éditeur',
    [AccountDtoPermission.Read]: 'Lecteur',
    [AccountDtoPermission.Silent]: "Pas d'accès",
  }

  return map[field]
}

export const getReadableAccountPreferenceSeniority = (field: AccountDtoPreferenceSeniority): string => {
  const map: Record<AccountDtoPreferenceSeniority, string> = {
    [AccountDtoPreferenceSeniority.Junior]: 'Junior',
    [AccountDtoPreferenceSeniority.Senior]: 'Sénior',
    [AccountDtoPreferenceSeniority.Adult]: 'Adulte',
  }

  return map[field]
}

export const getReadableAccountStatus = (field: AccountDtoStatus): string => {
  const map: Record<AccountDtoStatus, string> = {
    [AccountDtoStatus.Active]: 'Disponible',
    [AccountDtoStatus.Inactive]: 'Indisponible',
  }

  return map[field]
}
