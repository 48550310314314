import { Box, Theme, useMediaQuery } from '@mui/material'
import React from 'react'
import { getReadableAdopterAdoptionFormMainHomeType } from '../../../domain/Adopters/AccountDisplay'
import { AdopterDtoAdoptionFormMainHomeType } from '../../../interactors/gen/backendClient'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { Field } from '../../common/FormField'
import { FormParams } from '../AdoptionFormScreen'
import { StepsProps } from './StepOne'

export const StepFive: React.FC<StepsProps> = ({ control, errors }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'start', width: '100%' }}>
      {isMobile && (
        <Field label="Vous vivez principalement:" width="1000px" required>
          <ControlledSelectField
            control={control}
            fieldName="mainHomeType"
            error={errors.mainHomeType}
            requiredRule="Vous devez répondre à la question"
            options={Object.values(AdopterDtoAdoptionFormMainHomeType).map(
              (option: AdopterDtoAdoptionFormMainHomeType) => ({
                label: getReadableAdopterAdoptionFormMainHomeType(option),
                value: option,
              })
            )}
            size="small"
          />
        </Field>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            justifyContent: { xs: 'space-between', md: 'start' },
            width: '50%',
          }}
        >
          {!isMobile && (
            <Field label="Vous vivez principalement:" width="1000px" required>
              <ControlledSelectField
                control={control}
                fieldName="mainHomeType"
                error={errors.mainHomeType}
                requiredRule="Vous devez répondre à la question"
                options={Object.values(AdopterDtoAdoptionFormMainHomeType).map(
                  (option: AdopterDtoAdoptionFormMainHomeType) => ({
                    label: getReadableAdopterAdoptionFormMainHomeType(option),
                    value: option,
                  })
                )}
                size="small"
              />
            </Field>
          )}
          <Field label="Avez-vous une cour ?" required>
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="courtyard"
              error={errors.courtyard}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'one'}
              secondOptionValue={'none'}
              fullWidth
            />
          </Field>
          <Field label="Avez-vous une terrasse ?" required>
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="terrace"
              error={errors.terrace}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'one'}
              secondOptionValue={'none'}
              fullWidth
            />
          </Field>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            justifyContent: { xs: 'space-between', md: 'start' },
            width: '50%',
          }}
        >
          <Box sx={{ height: '80.81px', display: { xs: 'none', md: 'block' } }}></Box>
          <Field label="Avez-vous un balcon ?" required>
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="balcony"
              error={errors.balcony}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'one'}
              secondOptionValue={'none'}
              fullWidth
            />
          </Field>
          <Field label="Avez-vous un jardin ?" required>
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="garden"
              error={errors.garden}
              requiredRule="Vous devez répondre à la question"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={'one'}
              secondOptionValue={'none'}
              fullWidth
            />
          </Field>
        </Box>
      </Box>
    </Box>
  )
}
