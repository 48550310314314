import { Dialog, DialogContent, DialogActions, Button, Box, Typography, Avatar } from '@mui/material'
import React from 'react'
import { colors } from '../theme'
import { getAnimalProfilePhotoSrc } from '../../utils/S3-links'

interface Props {
  open: boolean
  handleClose: () => void
  handleStepForward: () => void
  animalName: string
  profileImageKey: string
}

export const PaymentMarketStepModal: React.FC<Props> = ({
  open = true,
  handleClose,
  animalName,
  profileImageKey,
  handleStepForward,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="adoption-modal-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%', // Ensures it takes up the full width
          height: '100%', // Ensures it takes up the full height
          maxHeight: '100%', // Prevents overflow
          margin: 0, // Removes any default margin
        },
      }}
    >
      {/* Dialog Content */}
      <DialogContent
        sx={{
          padding: '16px', // Reduced padding
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '6vh',
          flexGrow: 1, // Ensures the content stretches
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '24px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Bonne nouvelle !
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar
            src={profileImageKey ? getAnimalProfilePhotoSrc(profileImageKey) : ''}
            alt={animalName}
            sx={{ height: '120px', width: '120px' }}
          />
        </Box>

        <Typography variant="body1" color="rgba(87, 87, 87, 1)" fontWeight={700} fontSize={20} textAlign="center">
          Petso pense à vous avec des tarifs négociés pour l&apos;arrivée de {animalName} !
        </Typography>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions
        sx={{
          padding: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          color: 'rgba(0, 0, 0, 1)',
        }}
      >
        <Button onClick={handleClose} variant="contained" color="primary" fullWidth sx={{ fontSize: '18px' }}>
          Bénéficiez de 10%
        </Button>
        <Button
          onClick={handleStepForward}
          variant="text"
          color="inherit"
          fullWidth
          sx={{ fontSize: '18px', ml: '0px !important' }}
        >
          Passer au paiement
        </Button>
      </DialogActions>
    </Dialog>
  )
}
