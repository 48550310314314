import { Box, Chip, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AccessoriesTab } from '../Adoptions/AccessoriesTab'
import { theme } from '../theme'
import { FoodCategory } from './Categories/FoodCategory'
import { AnimalDtoSpecies } from '../../interactors/gen/backendClient'

export enum Categories {
  Fidelami = 'fidelami',
  Food = 'food',
}

export const ShoppingTabs: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = React.useState<Categories>(Categories.Fidelami)
  const [specie, setSpecie] = React.useState<AnimalDtoSpecies>(AnimalDtoSpecies.Cat)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search)

  const handleSpecieChange = (_: React.MouseEvent<HTMLElement>, newSpecie: string | null) => {
    if (newSpecie === 'dog') {
      handleCategoryChange(Categories.Fidelami)
    }
    setSpecie(newSpecie as AnimalDtoSpecies)
  }

  const handleCategoryChange = (category: Categories) => {
    setSelectedCategory(category)
    navigate(`${location.pathname}?${`shoppingCategory=${category}`}`)
  }

  useEffect(() => {
    const tab = queryParams.get('shoppingCategory') as Categories
    if (tab) {
      setSelectedCategory(tab)
    } else {
      setSelectedCategory(Categories.Fidelami)
    }
  }, [queryParams])

  const renderCategory = () => {
    switch (selectedCategory) {
      case Categories.Food:
        return <FoodCategory specie={specie} />
      case Categories.Fidelami:
        return <AccessoriesTab specie={specie} />
      default:
        return <Box />
    }
  }

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', mb: 10, p: 2 }}>
        <Typography variant="h1" sx={{ fontSize: '24px', fontWeight: 'bold', mb: 2 }}>
          La boutique Petso pour :
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 3, mt: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              overflowX: 'auto',
              mx: { xs: -2, md: 0 },
              pr: { xs: 1, md: 0 },
              pl: { xs: 1, md: 0 },
            }}
          >
            <ToggleButtonGroup
              value={specie}
              exclusive
              onChange={handleSpecieChange}
              aria-label="Produits pour :"
              sx={{ width: '100%' }}
            >
              <ToggleButton
                value="cat"
                aria-label="Chat"
                sx={{ width: '100%', fontSize: '26px', textTransform: 'none', gap: '4px' }}
              >
                <Typography sx={{ display: { xs: 'none', md: 'inline-block' } }}>Chat</Typography>🐱
              </ToggleButton>
              <ToggleButton
                value="dog"
                aria-label="Chien"
                sx={{ width: '100%', fontSize: '26px', textTransform: 'none', gap: '4px' }}
              >
                <Typography sx={{ display: { xs: 'none', md: 'inline-block' } }}>Chien</Typography>
                🐶
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {specie === 'cat' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                overflowX: 'auto',
                mx: { xs: -2, md: 0 },
                pr: { xs: 1, md: 0 },
                pl: { xs: 2, md: 0 },
              }}
            >
              {Object.values(Categories).map((category) => (
                <Chip
                  key={category}
                  label={translateCategories(category)}
                  clickable
                  onClick={() => handleCategoryChange(category)}
                  sx={{
                    backgroundColor: selectedCategory === category ? theme.palette.secondary.main : 'transparent',
                    border: `1px solid ${theme.palette.secondary.main}`,
                    color: selectedCategory === category ? 'white' : theme.palette.secondary.main,
                    fontSize: '14px',
                    px: '12px',
                    py: '20px',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    '&:focus': {
                      backgroundColor: theme.palette.secondary.main,
                      color: 'white',
                    },
                  }}
                />
              ))}
            </Box>
          )}

          {renderCategory()}
        </Box>
      </Box>
    </Box>
  )
}

function translateCategories(category: Categories): string {
  switch (category) {
    case Categories.Food:
      return 'Alimentation 🍗'
    case Categories.Fidelami:
      return 'Accessoires 👇'
    default:
      return ''
  }
}
