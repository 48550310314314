import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Avatar, Box, Card, CardContent, Chip, IconButton, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { getEmojiAnimalSex } from '../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../domain/Animal/calculateAge'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { getAnimalProfilePhotoSrc, getAnimalProfileProfilePhotoSrc } from '../../utils/S3-links'
import { findGoodAnimalProfileURL } from '../../utils/findGoodAnimalProfilURL'
import { ReactComponent as CrossIcon } from '../icons/CrossIcon.svg'
import { lightBlue } from '../theme'

interface Props {
  animal: AnimalProfileDto
}

export const SmallAnimalCard: React.FC<Props> = ({ animal }) => {
  const [open, setOpen] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const profileImageKey = animal.images?.profileImageKey

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const pictures = [profileImageKey, ...(animal.images?.otherImagesKeys || [])].filter(Boolean)

  const nextImage = () => {
    console.log(pictures.length)
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % pictures.length)
  }

  const previousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + pictures.length) % pictures.length)
  }

  return (
    <Card sx={{ p: { xs: 1, md: 2 } }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'row' }, gap: '24px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Box
              sx={{
                position: 'relative',
                height: { md: '330px', xs: '100px' },
                maxWidth: '100%',
                width: { xs: '100px', md: '300px' },
              }}
            >
              {profileImageKey ? (
                <img
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '11.25px',
                    cursor: 'pointer',
                  }}
                  key={profileImageKey}
                  src={profileImageKey ? findGoodAnimalProfileURL(profileImageKey) : ''}
                  onClick={() => handleImageClick(0)}
                />
              ) : (
                <Avatar
                  alt={animal.name}
                  sx={{
                    width: '300px',
                    maxWidth: '100%',
                    height: '330px',
                    objectFit: 'cover',
                    borderRadius: '11.25px',
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '8px' }}>
              {pictures?.slice(1, 4).map((picture, index) => (
                <img
                  key={picture}
                  style={{
                    width: '300px',
                    maxWidth: '20%',
                    height: '63px',
                    objectFit: 'cover',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  src={
                    animal.fromCustomerAnimal?.animalId
                      ? getAnimalProfilePhotoSrc(picture || '')
                      : getAnimalProfileProfilePhotoSrc(picture || '')
                  }
                  onClick={() => handleImageClick(index + 1)}
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="h1" component="p" sx={{ fontSize: { md: '22px', xs: '20px' } }}>
                    {animal.name}
                  </Typography>
                </Box>
                <Typography
                  variant="h1"
                  component="p"
                  color={animal.sex === 'female' ? 'pink' : 'blue'}
                  sx={{ fontSize: { md: '22px', xs: '20px' } }}
                >
                  {animal.sex ? getEmojiAnimalSex(animal.sex) : ''}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography
                    variant="body2"
                    component="p"
                    fontSize={{ md: 18, xs: 16 }}
                    fontWeight={500}
                    fontStyle="italic"
                    lineHeight={1.6}
                  >
                    {animal?.birthday?.date ? calculateAge(animal.birthday.date) : ''}
                  </Typography>
                </Box>
              </Box>
              {animal.species === 'cat' && (
                <Chip
                  label={
                    <Typography>
                      Indicateur bien-être <b>{animal.health?.score?.healthScore}%</b>
                    </Typography>
                  }
                  sx={{
                    backgroundColor: lightBlue,
                    fontSize: 18,
                    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.25)',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    ml: { md: '-16px', xs: '-32px' },
                    pl: { md: '16px', xs: '16px' },
                    '& span': {
                      p: 0,
                      pr: { md: '24px', xs: '45px' },
                      textOverflow: 'inherit',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              borderRadius: '8px',
              p: '32px',
              gap: '24px',
              border: 'none',
              position: 'relative',
            }}
          >
            <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '0px', right: '0px' }}>
              <CrossIcon />
            </IconButton>
            <IconButton
              onClick={previousImage}
              sx={{
                position: 'absolute',
                left: '0px',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }} />
            </IconButton>
            <IconButton
              onClick={nextImage}
              sx={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)' }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <img
              style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
              src={
                animal.fromCustomerAnimal?.animalId
                  ? getAnimalProfilePhotoSrc(pictures[selectedImageIndex] || '')
                  : getAnimalProfileProfilePhotoSrc(pictures[selectedImageIndex] || '')
              }
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  )
}
