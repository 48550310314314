import { Typography, Box } from '@mui/material'
import React from 'react'
import { ProductItem } from '../ProductItem'
import { CategoryProps } from './WellbeingCategory'
import data from '../data/products.json'

export const AccessoriesCategory: React.FC<CategoryProps> = ({ animalProfile }) => {
  const species = animalProfile.species
  return (
    <>
      <Typography variant="h2" sx={{ fontWeight: 700, textAlign: 'center' }}>
        Nos équipements et accessoires
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1 }}>
        {data.products
          .filter((product) => product.category.includes('equipment'))
          .filter((product) => product.species.includes(species) || product.species.length === 0)
          .map((product) => (
            <ProductItem key={product.reference} product={product} animalId={animalProfile.id} />
          ))}
      </Box>
    </>
  )
}
