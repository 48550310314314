import {
  BehaviorQuestionsDto,
  BehaviorQuestionsDtoCatSocialization,
  BehaviorQuestionsDtoInappropriateElimination,
  EatsGoodAmountDtoAge,
  EatsGoodAmountDtoLifestyle,
  EnvironmentQuestionsDto,
  EnvironmentQuestionsDtoHomeType,
  FeedingQuestionsDto,
  FeedingQuestionsDtoMealFrequency,
} from '../../interactors/gen/backendClient'

export type EnvironmentQuestionsPropertyWithLabel = Exclude<keyof EnvironmentQuestionsDto, 'date'>
export type BehaviorQuestionsPropertyWithLabel = Exclude<keyof BehaviorQuestionsDto, 'date'>
export type FeedingQuestionsPropertyWithLabel = Exclude<keyof FeedingQuestionsDto, 'date'>

export const getQuestionsForEnvironment = (
  field: EnvironmentQuestionsPropertyWithLabel,
  animalName: string
): string => {
  const map: Record<EnvironmentQuestionsPropertyWithLabel, string> = {
    hasToys: `${animalName} dispose-t-il de jouets ?`,
    hasAccessToSecuredWindows: "Votre chat a-t-il accès à des fenêtres lui permettant d'observer l'extérieur ?",
    hasAnAdaptedHidingPlace: `Votre chat dispose-t-il d'une cachette où il aime se reposer ?`,
    hasOneMoreLitterBoxThanNumberOfCats: `Disposez-vous d'une litière supplémentaire par rapport au nombre de chats que vous avez ?`,
    hasPerches: `${animalName} a-t-il accès à un perchoir ou à une zone en hauteur où il aime se tenir ?`,
    hasScratchingPosts: `${animalName} a-t-il un griffoir à sa disposition ?`,
    homeType: `Dans quel type d'habitation vivez-vous ?`,
    LitterIsFarFromFood: `La litière de votre chat est-elle située loin de son espace d'alimentation ?`,
  }

  return map[field]
}

export const getQuestionsForBehavior = (field: BehaviorQuestionsPropertyWithLabel): string => {
  const map: Record<BehaviorQuestionsPropertyWithLabel, string> = {
    catSocialization: `Comment votre chat se comporte t-il avec les autres chats ?`,
    fearfulBehavior: `Votre chat montre-t-il des signes de peur ou un comportement craintif ?`,
    humanInteraction: `Comment votre chat se comporte t-il avec les humains ?`,
    inappropriateElimination: `Votre chat marque-t-il son territoire ou élimine-t-il hors de sa litière ?`,
    inappropriateScratching: `Votre chat fait-il ses griffes sur des endroits non appropriés ?`,
    nocturnalVocalization: `Est-ce que votre chat miaule de manière excessive ou manifeste de l'agitation nocturne ?`,
    otherAnimalsSocialization: `Comment votre chat se comporte t-il avec les autres animaux ?`,
    playBehavior: `Votre chat joue-t-il souvent ?`,
  }

  return map[field]
}

export const getQuestionsForFeeding = (field: FeedingQuestionsPropertyWithLabel): string => {
  const map: Record<FeedingQuestionsPropertyWithLabel, string> = {
    catHydration: `Votre chat boit-il régulièrement de l'eau chaque jour ?`,
    eatsGoodAmount: `Calculez le besoin énergétique de votre chat !`,
    hasWaterFountain: `Disposez-vous d'une fontaine à eau pour votre chat ou veillez-vous à ce que sa gamelle d'eau reste propre ?`,
    mealFrequency: `À quelle fréquence votre chat prend-il ses repas ?`,
    nutritionalIntake: `La quantité de nourriture que vous donnez à votre chat est t-elle adéquate ?`,
    energeticalNeeds: `Besoin énergétique de votre chat`,
  }

  return map[field]
}

export const getReadableHomeType = (homeType: EnvironmentQuestionsDtoHomeType): string => {
  const map: Record<EnvironmentQuestionsDtoHomeType, string> = {
    apartment: 'Appartement',
    house: 'Maison',
  }

  return map[homeType]
}

export const getReadableOftenQuestions = (field: BehaviorQuestionsDtoInappropriateElimination): string => {
  const map: Record<BehaviorQuestionsDtoInappropriateElimination, string> = {
    always: 'Toujours',
    never: 'Jamais',
    often: 'Souvent',
    sometimes: 'Parfois',
    rarely: 'Rarement',
  }

  return map[field]
}

export const getReadableCatBehaviorQuestions = (field: BehaviorQuestionsDtoCatSocialization): string => {
  const map: Record<BehaviorQuestionsDtoCatSocialization, string> = {
    aggressive: 'Agressif',
    curious: 'Curieux',
    emotional: 'Affectif',
    indifferent: 'Indifférent',
    shy: 'Timide',
  }

  return map[field]
}

export const getReadableCatMealFrequencyQuestions = (field: FeedingQuestionsDtoMealFrequency): string => {
  const map: Record<FeedingQuestionsDtoMealFrequency, string> = {
    'four-times-a-day': 'Régulier (4+ repas/jour)',
    'three-times-a-day': 'Régulier (3 repas/jour)',
    'twice-a-day': 'Régulier (2 repas/jour)',
    'once-a-day': 'Régulier (1 repas/jour)',
    unlimited: 'Libre service',
  }

  return map[field]
}

export const getReadableCatAgeQuestions = (field: EatsGoodAmountDtoAge): string => {
  const map: Record<EatsGoodAmountDtoAge, string> = {
    adult: 'Adulte',
    puppy: 'Chaton',
    'new-born': 'Nouveau-né',
    young: 'Adolescent',
    senior: 'Sénior',
  }

  return map[field]
}

export const getReadableCatLifestyleQuestions = (field: EatsGoodAmountDtoLifestyle): string => {
  const map: Record<EatsGoodAmountDtoLifestyle, string> = {
    'inside-and-outside': 'Mixte',
    inside: 'Intérieur',
    outside: 'Surtout extérieur',
  }

  return map[field]
}
