import { ArrowForwardIos, Edit, Upload } from '@mui/icons-material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { LoadingButton } from '@mui/lab'
import { Avatar, Box, Button, Card, CardContent, Chip, Grow, IconButton, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useNavigate } from 'react-router-dom'
import { getEmojiAnimalSex } from '../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../domain/Animal/calculateAge'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { getAnimalProfilePhotoSrc, getAnimalProfileProfilePhotoSrc } from '../../utils/S3-links'
import { findGoodAnimalProfileURL } from '../../utils/findGoodAnimalProfilURL'
import { ReactComponent as CrossIcon } from '../icons/CrossIcon.svg'
import { lightBlue, theme } from '../theme'
import { EditAnimalGeneralInfoModal } from './EditAnimalGeneralInfoModal'

interface Props {
  animal: AnimalProfileDto
  setAnimal: (animal: AnimalProfileDto) => void
  beRealAlreadyDone: boolean
}

export const AnimalCard: React.FC<Props> = ({ animal, setAnimal, beRealAlreadyDone }) => {
  const [open, setOpen] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [profileImageKey, setProfileImageKey] = useState<string | undefined>(animal.images?.profileImageKey)

  const navigate = useNavigate()
  const globalSnackbarStore = useGlobalSnackbarStore()

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({})

  const changeProfileImage = async () => {
    if (!acceptedFiles[0] || !animal) return
    setIsLoading(true)

    const newImageKey = await AnimalProfilesClient.uploadProfileImage(animal.id, acceptedFiles[0])
    setProfileImageKey(newImageKey.split('/').reverse()[0])
    setIsEditMode(false)
    setIsLoading(false)
  }

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const pictures = [profileImageKey, ...(animal.images?.otherImagesKeys || [])].filter(Boolean)

  const nextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % pictures.length)
  }

  const previousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + pictures.length) % pictures.length)
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Box sx={{ position: 'relative', height: '330px', maxWidth: '100%', width: { xs: '400px', md: '300px' } }}>
              {isEditMode ? (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {acceptedFiles[0] ? (
                    <img
                      style={{
                        width: '300px',
                        maxWidth: '100%',
                        height: '330px',
                        objectFit: 'cover',
                        borderRadius: '11.25px',
                      }}
                      src={acceptedFiles[0] && URL.createObjectURL(acceptedFiles[0])}
                    />
                  ) : (
                    <Box
                      {...getRootProps()}
                      sx={{
                        width: '300px',
                        maxWidth: '100%',
                        height: '330px',
                        objectFit: 'cover',
                        borderRadius: '11.25px',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'lightgrey',
                      }}
                    >
                      <input {...getInputProps()} />
                      <Typography variant="body1" component="p">
                        Ajouter une photo <Upload />
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : profileImageKey ? (
                <img
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    height: '330px',
                    objectFit: 'cover',
                    borderRadius: '11.25px',
                    cursor: 'pointer',
                  }}
                  key={profileImageKey}
                  src={profileImageKey ? findGoodAnimalProfileURL(profileImageKey) : ''}
                  onClick={() => handleImageClick(0)}
                />
              ) : (
                <Avatar
                  alt={animal.name}
                  sx={{
                    width: '300px',
                    maxWidth: '100%',
                    height: '330px',
                    objectFit: 'cover',
                    borderRadius: '11.25px',
                    cursor: 'pointer',
                  }}
                />
              )}
              {!isEditMode && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '0px',
                    right: '0px',
                    bgcolor: 'rgba(255, 255, 255, 0.4)',
                    p: '4px',
                    pl: '32px',
                    pt: '32px',
                    borderBottomRightRadius: '11.25px',
                    clipPath: 'polygon(0% 100%, 100% 0%, 100% 100%)',
                  }}
                >
                  <IconButton onClick={() => setIsEditMode(true)}>
                    <Edit />
                  </IconButton>
                </Box>
              )}
              {!isEditMode && !beRealAlreadyDone && (
                <Grow in={true} timeout={300}>
                  <Box
                    onClick={() => {
                      navigate(`/actualites/ajouter-une-actualite?animalId=${animal.id}`)
                      globalSnackbarStore.closeSnackbar()
                    }}
                    sx={{
                      position: 'absolute',
                      top: '-5px',
                      left: '0px',
                      right: '0px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor: lightBlue,
                      borderRadius: '6px',
                      p: '10px',
                      gap: '8px',
                      width: '100%',
                      border: `1px solid ${theme.palette.secondary.main}`,
                      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.25)',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#E3E6F0', // Lighter shade for hover state
                        borderColor: '#D0D3D8', // Slightly darker border on hover
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        sx={{ fontSize: '14px', fontWeight: 600 }}
                      >{`Comment va ${animal.name} aujourd’hui ?`}</Typography>
                      <Typography
                        sx={{ fontSize: '13px', fontWeight: 400 }}
                      >{`Partagez son humeur dans un PetReal !`}</Typography>
                    </Box>
                    <ArrowForwardIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
                  </Box>
                </Grow>
              )}
            </Box>
            {isEditMode && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: { xs: 'space-between', md: 'flex-start' },
                  gap: '8px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    acceptedFiles.pop()
                    setIsEditMode(false)
                  }}
                >
                  Annuler
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isLoading}
                  onClick={() => changeProfileImage()}
                >
                  Enregistrer
                </LoadingButton>
              </Box>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '8px' }}>
              {pictures?.slice(1, 4).map((picture, index) => (
                <img
                  key={picture}
                  style={{
                    width: '300px',
                    maxWidth: '20%',
                    height: '63px',
                    objectFit: 'cover',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  src={
                    animal.fromCustomerAnimal?.animalId
                      ? getAnimalProfilePhotoSrc(picture || '')
                      : getAnimalProfileProfilePhotoSrc(picture || '')
                  }
                  onClick={() => handleImageClick(index + 1)}
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="h1" component="p">
                    {animal.name}
                  </Typography>
                </Box>
                <Typography
                  variant="h1"
                  component="p"
                  color={animal.sex === 'female' ? 'pink' : 'blue'}
                  fontWeight={700}
                  fontSize={22}
                >
                  {animal.sex ? getEmojiAnimalSex(animal.sex) : ''}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography
                    variant="body2"
                    component="p"
                    fontSize={18}
                    fontWeight={500}
                    fontStyle="italic"
                    lineHeight={1.6}
                  >
                    {animal?.birthday?.date ? calculateAge(animal.birthday.date) : ''}
                  </Typography>
                </Box>
              </Box>
              {animal.species === 'cat' && (
                <Chip
                  label={
                    <Typography>
                      Indicateur bien-être <b>{animal.health?.score?.healthScore}%</b>
                    </Typography>
                  }
                  clickable
                  component="a"
                  href={`/mes-animaux/${animal.id}?tab=shopping&category=sante`}
                  sx={{
                    backgroundColor: lightBlue,
                    fontSize: 18,
                    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.25)',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    ml: '-16px',
                    pl: '16px',
                    '& span': {
                      p: 0,
                      pr: '24px',
                      textOverflow: 'inherit',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              )}
            </Box>
            <EditAnimalGeneralInfoModal animal={animal} setAnimal={setAnimal} />
          </Box>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              borderRadius: '8px',
              p: '32px',
              gap: '24px',
              border: 'none',
              position: 'relative',
            }}
          >
            <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '0px', right: '0px' }}>
              <CrossIcon />
            </IconButton>
            <IconButton
              onClick={previousImage}
              sx={{
                position: 'absolute',
                left: '0px',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }} />
            </IconButton>
            <IconButton
              onClick={nextImage}
              sx={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)' }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <img
              style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
              src={findGoodAnimalProfileURL(pictures[selectedImageIndex] as string)}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  )
}
