import React from 'react'
import { Card, CardContent, Typography, Box } from '@mui/material'
import dayjs from 'dayjs'
import { ObservationCommentDto } from '../../interactors/gen/backendClient'

type CommentProps = {
  comment: ObservationCommentDto
}

export const Comment: React.FC<CommentProps> = ({ comment }) => {
  const { date, name, comment: content } = comment
  const formattedDate = dayjs(date).format('DD MMM YYYY') // Format date as needed

  return (
    <Card sx={{ maxWidth: 345, m: 1, backgroundColor: '#f7f7f7' }}>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formattedDate}
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">{content}</Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
