import { Box, Typography } from '@mui/material'
import React from 'react'
import image from './bienvenueSurPetso.png'

export const One: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3, height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={image} width={300} alt="Image d'illustration entre une femme et son animal" />
      </Box>
      <Typography variant="h1" sx={{ color: 'white', fontSize: '22px', lineHeight: '23.36px' }}>
        Bienvenue sur Petso !
      </Typography>
      <Typography variant="body1" sx={{ color: 'white' }}>
        En partenariat avec l’association cet espace vous est dédié afin de vous accompagner tout au long de votre
        démarche d’adoption
      </Typography>
    </Box>
  )
}
