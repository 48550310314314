import { Box } from '@mui/material'
import React from 'react'

interface Props {
  color: string
  starting?: boolean
}

export const LoadingBlock: React.FC<Props> = ({ color, starting = false }) => {
  return (
    <Box
      sx={{ height: '18px', width: '20%', backgroundColor: color, borderRadius: starting ? '5px 0px 0px 5px' : '0px' }}
    ></Box>
  )
}
