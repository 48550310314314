import { AxiosRequestConfig } from 'axios'
import { AnimalDto } from '../gen/backendClient'
import { backendClient } from './client'

export class AnimalsClient {
  public static async getAnimalById(animalId: string): Promise<AnimalDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters-platform/animals/' + animalId,
    }

    const response = await backendClient<AnimalDto>(config)

    return response.data
  }
}
