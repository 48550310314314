export type LatLng = {
  latitude: number
  longitude: number
}

export function calculateDistanceInKms(coord1: LatLng, coord2: LatLng): string {
  const R = 6371 // Radius of the Earth in kilometers
  const lat1 = (coord1.latitude * Math.PI) / 180 // Convert degrees to radians
  const lat2 = (coord2.latitude * Math.PI) / 180
  const deltaLat = ((coord2.latitude - coord1.latitude) * Math.PI) / 180
  const deltaLon = ((coord2.longitude - coord1.longitude) * Math.PI) / 180

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distance = R * c // Distance in kilometers

  // Format distance with "France format" - using comma as decimal separator
  return distance.toFixed(2).replace('.', ',') + ' km'
}

export function calculateDistance(coord1: LatLng, coord2: LatLng): number {
  const R = 6371 // Radius of the Earth in kilometers
  const lat1 = (coord1.latitude * Math.PI) / 180 // Convert degrees to radians
  const lat2 = (coord2.latitude * Math.PI) / 180
  const deltaLat = ((coord2.latitude - coord1.latitude) * Math.PI) / 180
  const deltaLon = ((coord2.longitude - coord1.longitude) * Math.PI) / 180

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distance = R * c // Distance in kilometers

  // Format distance with "France format" - using comma as decimal separator
  return distance
}
