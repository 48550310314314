import { Box, Button, Theme, Typography, useMediaQuery } from '@mui/material'
import axios, { AxiosError } from 'axios'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { fullName } from '../../domain/Account/AccountDisplay'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import {
  AdopterDto,
  AdopterDtoAdoptionForm,
  AdopterDtoAdoptionFormHadAnimalBefore,
  AdopterDtoAdoptionFormMainHomeType,
  AdopterDtoAdoptionFormOwnAnimalUsedToAnimals,
  AdopterDtoAdoptionFormPetIntendedForSelfOrGift,
  AdopterDtoAdoptionFormPlanForPetDuringRelocation,
  AdopterDtoAdoptionFormSex,
  AdopterDtoAdoptionFormSpecies,
  AdopterDtoAdoptionFormSterilizedOrNeutered,
  AdopterDtoAdoptionFormWorkflow,
  AdopterDtoAlreadyHasAnimalOrChildBaby,
} from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { readableAxiosError } from '../../utils/axios'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { blue, theme } from '../theme'
import { FormStepper } from './FormStepper'
import { StepFive } from './Steps/StepFive'
import { StepFour } from './Steps/StepFour'
import { StepOne } from './Steps/StepOne'
import { StepSix } from './Steps/StepSix'
import { StepThree } from './Steps/StepThree'
import { StepTwo } from './Steps/StepTwo'
// ... import other steps

export interface FormParams {
  firstName: AdopterDto['firstName']
  lastName: AdopterDto['lastName']
  phoneNumber: AdopterDto['phoneNumber']
  city: AdopterDto['city']
  address: AdopterDto['address']
  postalCode: AdopterDto['postalCode']
  country: AdopterDto['country']
  species: AdopterDtoAdoptionFormSpecies[]
  sex: AdopterDtoAdoptionFormSex | undefined
  sterilizedOrNeutered: AdopterDtoAdoptionFormSterilizedOrNeutered | undefined
  hadAnimalBefore: AdopterDtoAdoptionFormHadAnimalBefore | undefined
  baby: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  child: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  teenager: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  adult: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  dog: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  cat: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  other: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  ownAnimalUsedToAnimals: AdopterDtoAdoptionFormOwnAnimalUsedToAnimals | undefined
  allHouseMembersAgree: AdopterDtoAdoptionFormOwnAnimalUsedToAnimals | undefined
  allergiesInHousehold: AdopterDtoAdoptionFormOwnAnimalUsedToAnimals | undefined
  mainHomeType: AdopterDtoAdoptionFormMainHomeType | undefined
  courtyard: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  balcony: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  terrace: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  garden: AdopterDtoAlreadyHasAnimalOrChildBaby | undefined
  workflow: AdopterDtoAdoptionFormWorkflow | undefined
  consciousOfAnimalNeeds: AdopterDtoAdoptionFormOwnAnimalUsedToAnimals | undefined
  consciousOfAnimalCost: AdopterDtoAdoptionFormOwnAnimalUsedToAnimals | undefined
  consciousOfAnimalAdoption: AdopterDtoAdoptionFormOwnAnimalUsedToAnimals | undefined
  caretakerDuringAbsence: AdopterDtoAdoptionFormOwnAnimalUsedToAnimals | undefined
  planForPetDuringRelocation: AdopterDtoAdoptionFormPlanForPetDuringRelocation | undefined
  petIntendedForSelfOrGift: AdopterDtoAdoptionFormPetIntendedForSelfOrGift | undefined
  desiredPetCharacteristics: string | undefined
  userConsentForInsuranceEstimate: boolean
}

export const AdoptionFormScreen: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState<number>(1)
  const accountsStore = useAccountsStore()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const account = accountsStore.connectedAccount
  const [checkbox, setCheckbox] = React.useState(account?.adoptionForm?.finishedFillingForm ? true : false)
  const fieldSx = { width: '100%' }

  useEffect(() => {
    if (account?.adoptionForm?.step && account.adoptionForm.step !== currentStep) {
      setCurrentStep(account.adoptionForm.step)
      setCheckbox(true)
    }
    console.log(currentStep)
  }, [account])

  //Mutation to edit the adopter
  const editAdopterMutation = useMutation(
    async (data: AdopterDto) => {
      const response = await AdoptersClient.editForm(data)
      return response
    },
    {
      onSuccess: (adopter) => {
        if (adopter.adoptionForm?.finishedFillingForm) {
          globalSnackBarStore.triggerSuccessMessage(
            `Le formulaire a bien été envoyé à l'association, elle vous recontactera dans les plus brefs délais.`
          )
        }
        accountsStore.changeConnectedAccount(adopter)
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          globalSnackBarStore.triggerErrorMessage(`Une erreur est survenue lors de l'envoi du formulaire`)
          return
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  const getDefaultValues = (account: AdopterDto | null): FormParams => ({
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    phoneNumber: account?.phoneNumber,
    address: account?.address,
    city: account?.city,
    postalCode: account?.postalCode,
    country: account?.country,
    species: account?.adoptionForm?.species || [],
    sex: account?.adoptionForm?.sex,
    sterilizedOrNeutered: account?.adoptionForm?.sterilizedOrNeutered,
    hadAnimalBefore: account?.adoptionForm?.hadAnimalBefore,
    baby: account?.adoptionForm?.alreadyHasAnimalOrChild?.baby || 'none',
    child: account?.adoptionForm?.alreadyHasAnimalOrChild?.child || 'none',
    teenager: account?.adoptionForm?.alreadyHasAnimalOrChild?.teenager || 'none',
    adult: account?.adoptionForm?.alreadyHasAnimalOrChild?.adult || 'none',
    dog: account?.adoptionForm?.alreadyHasAnimalOrChild?.dog || 'none',
    cat: account?.adoptionForm?.alreadyHasAnimalOrChild?.cat || 'none',
    other: account?.adoptionForm?.alreadyHasAnimalOrChild?.other || 'none',
    ownAnimalUsedToAnimals: account?.adoptionForm?.ownAnimalUsedToAnimals,
    allHouseMembersAgree: account?.adoptionForm?.allHouseMembersAgree,
    allergiesInHousehold: account?.adoptionForm?.allergiesInHousehold,
    mainHomeType: account?.adoptionForm?.mainHomeType,
    courtyard: account?.adoptionForm?.hasExteriorAccess?.courtyard || 'none',
    balcony: account?.adoptionForm?.hasExteriorAccess?.balcony || 'none',
    terrace: account?.adoptionForm?.hasExteriorAccess?.terrace || 'none',
    garden: account?.adoptionForm?.hasExteriorAccess?.garden || 'none',
    workflow: account?.adoptionForm?.workflow,
    consciousOfAnimalNeeds: account?.adoptionForm?.consciousOfAnimalNeeds,
    consciousOfAnimalCost: account?.adoptionForm?.consciousOfAnimalCost,
    consciousOfAnimalAdoption: account?.adoptionForm?.consciousOfAnimalAdoption,
    caretakerDuringAbsence: account?.adoptionForm?.caretakerDuringAbsence,
    planForPetDuringRelocation: account?.adoptionForm?.planForPetDuringRelocation,
    petIntendedForSelfOrGift: account?.adoptionForm?.petIntendedForSelfOrGift,
    desiredPetCharacteristics: account?.adoptionForm?.desiredPetCharacteristics,
    userConsentForInsuranceEstimate: account?.adoptionForm?.userConsentForInsuranceEstimate || false,
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormExtended(account, getDefaultValues)

  const onSubmit = (data: FormParams) => {
    if (!account || (currentStep === 6 && !checkbox)) {
      return globalSnackBarStore.triggerErrorMessage('Un problème est survenu, veuillez réssayer')
    }
    // Formatting data to send to the API
    const adoptionForm: AdopterDtoAdoptionForm = {
      species: data.species,
      sex: data.sex,
      sterilizedOrNeutered: data.sterilizedOrNeutered,
      hadAnimalBefore: data.hadAnimalBefore,
      alreadyHasAnimalOrChild: {
        baby: data.baby,
        child: data.child,
        teenager: data.teenager,
        adult: data.adult,
        dog: data.dog,
        cat: data.cat,
        other: data.other,
      },
      ownAnimalUsedToAnimals: data.ownAnimalUsedToAnimals,
      allHouseMembersAgree: data.allHouseMembersAgree,
      allergiesInHousehold: data.allergiesInHousehold,
      mainHomeType: data.mainHomeType,
      hasExteriorAccess: {
        courtyard: data.courtyard,
        balcony: data.balcony,
        terrace: data.terrace,
        garden: data.garden,
      },
      workflow: data.workflow,
      consciousOfAnimalNeeds: data.consciousOfAnimalNeeds,
      consciousOfAnimalCost: data.consciousOfAnimalCost,
      consciousOfAnimalAdoption: data.consciousOfAnimalAdoption,
      caretakerDuringAbsence: data.caretakerDuringAbsence,
      planForPetDuringRelocation: data.planForPetDuringRelocation,
      petIntendedForSelfOrGift: data.petIntendedForSelfOrGift,
      desiredPetCharacteristics: data.desiredPetCharacteristics,
      userConsentForInsuranceEstimate: data.userConsentForInsuranceEstimate,
      finishedFillingForm: account.adoptionForm?.finishedFillingForm === true || currentStep === 6 ? true : false,
      step: account.adoptionForm?.finishedFillingForm === true || currentStep === 6 ? 6 : currentStep + 1,
    }
    const adopter = {
      ...account!,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: ['+33', ''].includes(data.phoneNumber?.trim() || '') ? undefined : data.phoneNumber,
      address: data.address,
      city: data.city,
      postalCode: data.postalCode,
      country: data.country,
      adoptionForm: adoptionForm,
    }
    if (isDirty) {
      editAdopterMutation.mutate(adopter)
    }
    setCurrentStep((prev) => (prev < 6 ? prev + 1 : 6))
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const renderStep = () => {
    const stepProps = { control: control, register: register, errors: errors, fieldSx: fieldSx }

    switch (currentStep) {
      case 1:
        return <StepOne {...stepProps} />
      case 2:
        return <StepTwo {...stepProps} />
      case 3:
        return <StepThree {...stepProps} />
      case 4:
        return <StepFour {...stepProps} />
      case 5:
        return <StepFive {...stepProps} />
      case 6:
        return <StepSix {...stepProps} setCheckbox={setCheckbox} checkbox={checkbox} />
      default:
        return null
    }
  }

  const renderStepLabel = () => {
    switch (currentStep) {
      case 1:
        return 'Informations personnelles'
      case 2:
        return 'Vous recherchez'
      case 3:
        return 'Vos animaux'
      case 4:
        return 'Votre foyer'
      case 5:
        return 'Votre lieu de vie'
      case 6:
        return 'Votre travail et mode de vie'
      default:
        return null
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        boxSizing: 'border-box',
        padding: { xs: 2, md: 4 },
        pr: { xs: 2, md: 8 },
        minHeight: '100%',
        pb: { xs: 2, md: 20 },
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          marginY: 1,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            mb: { xs: '16px', md: '48px' },
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '22px', color: '#4F4F4F' }}>
            {account ? fullName(account) : '..'}
          </Typography>
          <Typography variant="h1" sx={{ fontWeight: 900, fontSize: '14px', lineHeight: '18.69px', color: '#BDBDBD' }}>
            Mon formulaire de pré-adoption
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: isMobile ? '85%' : '100%',
            paddingBottom: '30px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'start', width: '100%' }}>
            {!isMobile ? (
              <FormStepper activeStep={currentStep - 1} />
            ) : (
              <Typography
                variant="h2"
                sx={{
                  fontSize: '18px',
                  lineHeight: '22px',
                  fontWeight: '600',
                  color: theme.palette.primary.main,
                  mb: '12px',
                }}
              >
                {renderStepLabel()}
              </Typography>
            )}
            {renderStep()}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: { xs: 1, md: 4 },
              mx: { xs: '-10px', md: 0 },
              pb: 4,
            }}
          >
            {currentStep > 1 ? (
              <Button
                variant="contained"
                onClick={() => setCurrentStep((prev) => prev - 1)}
                type="button"
                sx={{ height: '48px', borderRadius: '264px', padding: '13px 60px', color: blue, fontWeight: 600 }}
              >
                Précédent
              </Button>
            ) : (
              <Box></Box>
            )}
            {currentStep <= 6 && (
              <Button
                variant="contained"
                type="submit"
                disabled={currentStep === 6 && !checkbox}
                sx={{ height: '48px', borderRadius: '264px', padding: '13px 60px', color: blue, fontWeight: 600 }}
              >
                {currentStep === 6 ? 'Envoyer' : 'Suivant'}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  )
}
