import { AxiosRequestConfig } from 'axios'
import { ServiceProviderDto } from '../gen/backendClient'
import { backendClient } from './client'

export class ServiceProvidersClient {
  public static async getProvider(id: string): Promise<ServiceProviderDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/adopters-platform/service-providers/${id}`,
    }

    const response = await backendClient<ServiceProviderDto>(config)

    return response.data
  }
}
