import { ArrowBackIos, Cancel, Check, Edit, GpsFixed, Phone } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Chip, IconButton, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { pdfjs } from 'react-pdf'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getReadableOperationType } from '../../domain/Animal/AnimalDisplay'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { ServiceLocationsClient } from '../../interactors/clients/ServiceLocationsClient'
import { VeterinaryClient } from '../../interactors/clients/VeterinaryClient'
import {
  AnimalProfileDocumentDto,
  AnimalProfileDto,
  AnimalProfileHealthOperationDto,
  ServiceLocationWithProvidersNamesDto,
} from '../../interactors/gen/backendClient'
import { fromDayNumberToName } from '../../utils/date/fromDayNumbeToName'
import { fromMonthNumberToName } from '../../utils/date/fromMonthNumberToName'
import { getIconForOperationType } from '../../utils/getIconForOperation'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { colors, lightBlue, theme } from '../theme'
import { AnimalDocumentsTable } from './AnimalDocumentsTable'
import { EditOperationDetailsModal } from './EditOperationDetailsModal'
import { OperationDocumentCard } from './OperationDocumentCard'
import { backendClient } from '../../interactors/clients/client'
import { GetShareLink } from './GetShareLink'
import { Comment } from '../SuiviVeto/Comment'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

type FormData = {
  additionalInfo?: string
}

export const OperationDetailsScreen: React.FC = () => {
  const [operation, setOperation] = React.useState<AnimalProfileHealthOperationDto | null>(null)
  const [animal, setAnimal] = React.useState<AnimalProfileDto | null>(null)
  const [documents, setDocuments] = React.useState<AnimalProfileDocumentDto[]>([])
  const [selectedDocuments, setSelectedDocuments] = React.useState<string[]>([])
  const [veterinaryName, setVeterinaryName] = React.useState<string>('Vétérinaire inconnu(e)')
  const [externalVeterinary, setExternalVeterinary] = React.useState<ServiceLocationWithProvidersNamesDto | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isDocumentEditMode, setIsDocumentEditMode] = React.useState(false)
  const { operationId, animalId } = useParams<{ operationId: string; animalId: string }>()
  const navigate = useNavigate()

  const getDefaultValues = (operation: AnimalProfileHealthOperationDto | null): FormData => ({
    additionalInfo: operation?.additionalInfo || '',
  })

  const { register, watch, handleSubmit, setValue } = useFormExtended(operation, getDefaultValues)

  const onSubmit = async (data: FormData) => {
    if (!animal || !operation) return

    const newOperation: AnimalProfileHealthOperationDto = {
      ...operation,
      additionalInfo: data.additionalInfo,
    }

    const animalWithUpdatedOperation = {
      ...animal,
      health: {
        ...animal.health,
        operations: animal.health.operations.map((op) => (op.id === operation.id ? newOperation : op)),
      },
    }

    const newAnimal = await AnimalProfilesClient.editAnimalProfile(animalWithUpdatedOperation)

    setAnimal(newAnimal)
    setOperation(newOperation)
  }

  const updateSelectedDocuments = async () => {
    if (!animal || !operation) return

    operation.documentsIds = selectedDocuments

    const animalWithUpdatedOperation = {
      ...animal,
      health: {
        ...animal.health,
        operations: animal.health.operations.map((op) => (op.id === operation.id ? operation : op)),
      },
    }

    const newAnimal = await AnimalProfilesClient.editAnimalProfile(animalWithUpdatedOperation)
    setDocuments(newAnimal.documents.filter((doc) => selectedDocuments.includes(doc.id)))

    setAnimal(newAnimal)
  }

  const handleEditDocumentsClick = () => {
    if (isDocumentEditMode) {
      updateSelectedDocuments()
    }
    setIsDocumentEditMode((prev) => !prev)
  }

  const handlePetsoConversationClick = async () => {
    const newConversation = await backendClient.post('/adopters-platform/conversations', {
      receiverId: 'B-3403',
    })
    const conversationId = newConversation.data.id
    navigate(`/chat?id=${conversationId}`)
  }

  useEffect(() => {
    const fetchVeterinary = async (operation: AnimalProfileHealthOperationDto) => {
      // Ensure operation and veterinary object exist
      if (!operation || !operation.veterinary) return

      const veterinaryId = operation.veterinary.id
      const locationId = operation.veterinary.locationId

      if (!veterinaryId) return

      try {
        // If veterinaryId starts with 'A-', it's a Petso veterinary
        if (veterinaryId && veterinaryId.startsWith('A-')) {
          const veterinary = await VeterinaryClient.getVeterinaryById(veterinaryId)
          setVeterinaryName(`Docteur ${veterinary.firstName} ${veterinary.lastName}`)
        }
        // If locationId is provided, it indicates an external veterinary linked to a service location
        else if (locationId && locationId.startsWith('L-')) {
          const clinicExport = await ServiceLocationsClient.getLocation(locationId)
          // Assuming operation.veterinary.id is the id of the veterinary in the external clinic
          const veterinary = clinicExport.serviceProvidersNames.find((vet) => vet.id === veterinaryId)

          if (veterinary) {
            setVeterinaryName(`Docteur ${veterinary.firstName} ${veterinary.lastName}`)
            setExternalVeterinary(clinicExport)
          }
        }
      } catch (error) {
        console.error('Error fetching veterinary information:', error)
        // Handle errors or set state accordingly if needed
      }
    }

    const fetchOperationAndVeterinary = async () => {
      try {
        if (!animalId || !operationId) return

        const animal = await AnimalProfilesClient.getAnimalById(animalId)
        const operation = animal.health.operations?.find((op) => op.id === operationId)

        if (animal && operation) {
          setAnimal(animal)
          setOperation(operation)
          if (operation.documentsIds && operation.documentsIds.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setDocuments(animal.documents.filter((doc) => operation.documentsIds!.includes(doc.id)))
            setSelectedDocuments(operation.documentsIds)
          }
          await fetchVeterinary(operation) // Ensure this is called after setting the operation
        }
      } catch (error) {
        console.error('Error fetching operation information:', error)
        // Handle errors or set state accordingly if needed
      } finally {
        setIsLoading(false)
      }
    }

    fetchOperationAndVeterinary()
  }, [animalId, operationId, operation?.veterinary?.id])

  const watchAdditionalInfo = watch('additionalInfo')

  if (isLoading || !animal || !operation) return <CircularProgressPanel />

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          mb: 10,
          p: 2,
          pb: 8,
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: '24px',
            color: colors.black,
          }}
        >
          <IconButton onClick={() => navigate(`/mes-animaux/${animalId}/?tab=appointments`)}>
            <ArrowBackIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: '8px',
              px: '16px',
              borderRadius: '8px',
              gap: '10px',
              backgroundColor: colors.lightGrey,
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '16px',
                  color: theme.palette.secondary.main,
                }}
              >
                {getIconForOperationType(operation.type[0])}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '12px',
                    color: colors.black,
                  }}
                >
                  {operation.type.map((type) => (
                    <Chip key={type} sx={{ backgroundColor: lightBlue }} label={getReadableOperationType(type)} />
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '18px',
                  color: colors.black,
                }}
              >
                <Typography>{`${fromDayNumberToName(dayjs(operation.date, 'DD/MM/YYYY').day())} ${operation.date.split(
                  '/',
                  1
                )} ${fromMonthNumberToName(dayjs(operation.date, 'DD/MM/YYYY').month())} ${
                  operation.date.split('/', 3)[2]
                }`}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: '30px',
                borderLeft: '2px solid black',
                display: 'flex',
                borderLeftColor: theme.palette.secondary.main,
                color: theme.palette.secondary.main,
                height: '60px',
                maxHeight: '100%',
              }}
            >
              <EditOperationDetailsModal
                animal={animal}
                operation={operation}
                setAnimal={setAnimal}
                setOperation={setOperation}
                veterinaryName={veterinaryName.split(' ')[1] + ' ' + veterinaryName.split(' ')[2]}
              />
            </Box>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', py: '8px', px: '16px', width: '100%' }}>
            <Typography sx={{ fontSize: '18px' }}>{`Notes`}</Typography>
            <TextField
              multiline
              placeholder="Notes"
              {...register('additionalInfo')}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '100%',
                },
              }}
            />
            {watchAdditionalInfo !== operation.additionalInfo && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setValue('additionalInfo', operation.additionalInfo)
                  }}
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                >
                  <Cancel />
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                >
                  <Check />
                </Button>
              </Box>
            )}
          </Box>
        </form>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            borderRadius: '8px',
            p: '10px',
            backgroundColor: lightBlue,
          }}
        >
          <Typography sx={{ fontSize: '18px' }}>{`Une question ? Nous sommes là pour vous aider`}</Typography>
          {/* <Chip
            label={`${animal.name} peut-il manger avant ce rdv ?`}
            sx={{
              backgroundColor: 'white',
              fontSize: '15px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);',
              cursor: 'pointer',
            }}
          />
          <Chip
            label={`Comment relaxer ${animal.name} avant le rdv ?`}
            sx={{
              backgroundColor: 'white',
              fontSize: '15px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);',
              cursor: 'pointer',
            }}
          /> */}
          <Chip
            label={`Contacter l'équipe Petso`}
            clickable
            sx={{
              backgroundColor: 'white',
              fontSize: '15px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);',
              cursor: 'pointer',
            }}
            onClick={() => handlePetsoConversationClick()}
          />
          <GetShareLink animalId={animal.id} animalName={animal.name} chip />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            py: '8px',
            px: '16px',
            borderRadius: '8px',
            gap: '16px',
            backgroundColor: colors.lightGrey,
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', width: '100%' }}>
            <Typography sx={{ fontSize: '24px' }}>👨‍⚕️</Typography>
            <Typography sx={{ fontSize: '20px' }}>{veterinaryName}</Typography>
          </Box>
          {externalVeterinary && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '18px', width: '100%' }}>
              <Typography sx={{ fontSize: '16px' }}>{externalVeterinary.name}</Typography>
            </Box>
          )}
          {externalVeterinary && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '12px',
                width: '100%',
              }}
            >
              <Typography
                sx={{ fontSize: '16px' }}
              >{`${externalVeterinary.postalCode}, ${externalVeterinary.city}`}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '32px' }}>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                  component="a"
                  href={`tel:${externalVeterinary.phoneNumber}`}
                >
                  <Phone />
                </Button>

                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                  component="a"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${externalVeterinary.postalCode}+${externalVeterinary.city}+${externalVeterinary.address}`}
                  target="_blank" // Opens in a new tab
                >
                  <GpsFixed />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            borderRadius: '8px',
            p: '10px',
            backgroundColor: lightBlue,
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '16px' }}>
            <Typography sx={{ fontSize: '18px' }}>{`Documents liés`}</Typography>
            <LoadingButton
              color="inherit"
              variant="text"
              onClick={() => handleEditDocumentsClick()}
              sx={{
                borderRadius: '10px',
                p: '10px',
                minWidth: '0',
              }}
            >
              {isDocumentEditMode ? (
                <Check sx={{ color: theme.palette.primary.main }} />
              ) : (
                <Edit sx={{ color: theme.palette.secondary.main }} />
              )}
            </LoadingButton>
          </Box>

          {isDocumentEditMode ? (
            <>
              <AnimalDocumentsTable
                documents={animal.documents}
                selectedDocuments={selectedDocuments}
                setSelectedDocuments={setSelectedDocuments}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
                <Typography variant="body2" sx={{ fontSize: '16px' }}>
                  Vous ne trouvez pas votre document ? Ajoutez le directement dans{' '}
                  <Link to={`/mes-animaux/${animalId}/?tab=documents`}>l&#39;onglet &quot;Documents&quot;</Link>
                </Typography>
              </Box>
            </>
          ) : (
            documents.map((document) => <OperationDocumentCard key={document.id} document={document} />)
          )}
          {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '300px' }}>
              <Document file="https://cors-anywhere.herokuapp.com/https://animals-documents.s3.amazonaws.com/P-1042-veterinary-order-forms-1004.pdf">
                <Page
                  pageNumber={1}
                  width={Math.min(window.innerWidth * 0.9, 300)}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
            </div>
          </div> */}
        </Box>
        {operation.comments && operation.comments.length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', py: '8px', width: '100%' }}>
            <Typography sx={{ fontSize: '18px' }}>{`Compte rendu`}</Typography>

            {operation.comments.map((comment, index) => (
              <Comment key={`${comment.name}-${index}`} comment={comment} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}
