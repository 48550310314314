import { Box, Grow } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useForm } from 'react-hook-form'
import { UseMutationResult } from 'react-query'
import { getQuestionsForEnvironment } from '../../../domain/AnimalProfileDisplay/HealthQuestionsDisplay'
import {
  AnimalProfileDto,
  EnvironmentQuestionsDto,
  EnvironmentQuestionsDtoHasAccessToSecuredWindows,
  EnvironmentQuestionsDtoHasAnAdaptedHidingPlace,
  EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats,
  EnvironmentQuestionsDtoHasScratchingPosts,
  EnvironmentQuestionsDtoHasToys,
  EnvironmentQuestionsDtoHomeType,
} from '../../../interactors/gen/backendClient'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { Field } from '../../common/FormField'

interface Props {
  animalProfile: AnimalProfileDto
  editMutation: UseMutationResult<AnimalProfileDto, unknown, AnimalProfileDto, unknown>
}

export const EnvironmentInitialQuestions: React.FC<Props> = ({ animalProfile, editMutation }) => {
  const [step, setStep] = React.useState(0)
  /* const getDefaultValues = (environmentQuestions: EnvironmentQuestionsDto) => {
    return {
      homeType: environmentQuestions.homeType,
      hasAccessToSecuredWindows: environmentQuestions.hasAccessToSecuredWindows,
      hasAnAdaptedHidingPlace: environmentQuestions.hasAnAdaptedHidingPlace,
      hasToys: environmentQuestions.hasToys,
      hasScratchingPosts: environmentQuestions.hasScratchingPosts,
      hasOneMoreLitterBoxThanNumberOfCats: environmentQuestions.hasOneMoreLitterBoxThanNumberOfCats,
      LitterIsFarFromFood: environmentQuestions.LitterIsFarFromFood,
      date: dayjs().toISOString(),
    }
  } */

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EnvironmentQuestionsDto>({
    defaultValues: {
      date: dayjs().toISOString(),
    },
  })

  const onSubmit = (data: EnvironmentQuestionsDto) => {
    const environmentQuestionsArray = animalProfile.health.score?.environmentQuestions || []
    environmentQuestionsArray.push(data)
    const newAnimalProfile: AnimalProfileDto = {
      ...animalProfile,
      health: {
        ...animalProfile.health,
        score: {
          ...animalProfile.health.score,
          healthScore: animalProfile.health.score?.healthScore || 0,
          environmentQuestions: environmentQuestionsArray,
        },
      },
    }
    editMutation.mutate(newAnimalProfile)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Box>
        {step === 0 && (
          <Grow in={step === 0}>
            <Box>
              <Field label={getQuestionsForEnvironment('homeType', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.homeType}
                  fieldName="homeType"
                  firstOptionLabel="Appartement"
                  secondOptionLabel="Maison"
                  firstOptionValue={EnvironmentQuestionsDtoHomeType.Apartment}
                  secondOptionValue={EnvironmentQuestionsDtoHomeType.House}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 1 && (
          <Grow in={step === 1}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasAccessToSecuredWindows', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasAccessToSecuredWindows}
                  fieldName="hasAccessToSecuredWindows"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAccessToSecuredWindows.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 2 && (
          <Grow in={step === 2}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasAnAdaptedHidingPlace', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasAnAdaptedHidingPlace}
                  fieldName="hasAnAdaptedHidingPlace"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAnAdaptedHidingPlace.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAnAdaptedHidingPlace.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 3 && (
          <Grow in={step === 3}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasPerches', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasPerches}
                  fieldName="hasPerches"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasAnAdaptedHidingPlace.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasAnAdaptedHidingPlace.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 4 && (
          <Grow in={step === 4}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasToys', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasToys}
                  fieldName="hasToys"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasToys.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasToys.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 5 && (
          <Grow in={step === 5}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasScratchingPosts', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasScratchingPosts}
                  fieldName="hasScratchingPosts"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasScratchingPosts.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasScratchingPosts.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 6 && (
          <Grow in={step === 6}>
            <Box>
              <Field label={getQuestionsForEnvironment('hasOneMoreLitterBoxThanNumberOfCats', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.hasOneMoreLitterBoxThanNumberOfCats}
                  fieldName="hasOneMoreLitterBoxThanNumberOfCats"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => setStep((prev) => prev + 1)}
                />
              </Field>
            </Box>
          </Grow>
        )}
        {step === 7 && (
          <Grow in={step === 7}>
            <Box>
              <Field label={getQuestionsForEnvironment('LitterIsFarFromFood', animalProfile.name)}>
                <ControlledToggleButton
                  control={control}
                  error={errors.LitterIsFarFromFood}
                  fieldName="LitterIsFarFromFood"
                  firstOptionLabel="Oui"
                  secondOptionLabel="Non"
                  firstOptionValue={EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats.Yes}
                  secondOptionValue={EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats.No}
                  requiredRule="Ce champ est obligatoire"
                  fullWidth
                  onChangeFromParent={() => handleSubmit(onSubmit)()}
                />
              </Field>
            </Box>
          </Grow>
        )}
      </Box>
    </form>
  )
}
