import { Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getProductImageSrc } from '../../utils/S3-links'
import { lightBlue } from '../theme'

interface Props {
  product: {
    title: string
    images: string[]
    reference: string
    price: number
    chips: string[]
  }
  animalId: string
}

export const ProductItem: React.FC<Props> = ({ product, animalId }) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '120px',
        backgroundColor: lightBlue,
        borderRadius: '12px',
        p: '8px',
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/produits/${product.reference}?animalId=${animalId}`)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: 1, width: '100%' }}>
        <img
          src={getProductImageSrc(product.images[0])}
          alt={product.title}
          style={{ height: '100%', width: '90px', objectFit: 'contain', borderRadius: '12px', maxHeight: '104px' }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '10px',
              width: '100%',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}>
              {product.title}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
              {product.chips[0] && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '18px',
                    px: '16px',
                    py: '2px',
                    fontSize: '12px',
                    lineHeight: '16.8px',
                    color: 'black',
                    backgroundColor: '#F9F9F9',
                  }}
                >
                  {product.chips[0]}
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              {product.chips[1] ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '18px',
                    px: '16px',
                    py: '2px',
                    fontSize: '12px',
                    lineHeight: '16.8px',
                    color: 'black',
                    backgroundColor: '#F9F9F9',
                  }}
                >
                  {product.chips[1]}
                </Box>
              ) : (
                <div></div>
              )}
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: '15px', lineHeight: '19.2px', fontWeight: 700 }}
              >
                {product.price}€
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
