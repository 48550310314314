import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

interface Props {
  isOpen: boolean
  onClose: () => void
  promoCode?: string
}

export const LoginNeededPopUp: React.FC<Props> = ({ isOpen, onClose, promoCode }) => {
  const navigate = useNavigate()

  const handleLogin = () => {
    navigate(`/login?mode=login&from=panier?step=2${promoCode ? `-promoCode=${promoCode}` : ''}`)
    onClose()
  }

  const handleRegister = () => {
    navigate(`/login?mode=register&from=panier?step=2${promoCode ? `-promoCode=${promoCode}` : ''}`)
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      sx={{ '.MuiDialog-paper': { padding: 2, borderRadius: 2, maxWidth: 400 } }}
    >
      <DialogTitle id="form-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ShoppingCartIcon color="primary" />
        Connexion requise
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2, fontSize: '1rem' }}>
          Pour valider votre commande, veuillez vous connecter ou créer un compte.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', paddingX: 2 }}>
        <Button onClick={handleLogin} variant="contained" color="primary" sx={{ minWidth: 120 }}>
          Connexion
        </Button>
        <Button onClick={handleRegister} variant="outlined" color="primary" sx={{ minWidth: 120 }}>
          Inscription
        </Button>
      </DialogActions>
    </Dialog>
  )
}
