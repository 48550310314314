import { Box } from '@mui/material'
import React from 'react'
import {
  getReadableAdopterAdoptionFormSex,
  getReadableAdopterAdoptionFormSterilizedOrNeutered,
} from '../../../domain/Adopters/AccountDisplay'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import {
  AdopterDtoAdoptionFormSex,
  AdopterDtoAdoptionFormSterilizedOrNeutered,
  AnimalDtoSpecies,
} from '../../../interactors/gen/backendClient'
import { ControlledMultipleSelectField } from '../../common/ControlledMultipleSelectField'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { Field } from '../../common/FormField'
import { StepsProps } from './StepOne'

export const StepTwo: React.FC<StepsProps> = ({ control, errors, fieldSx }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'start', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'start', width: '50%' }}>
          <Field label="Vous cherchez à adopter" required>
            <ControlledMultipleSelectField
              control={control}
              fieldName="species"
              error={errors.address}
              requiredRule="Vous devez choisir les espèces que vous souhaitez adopter"
              options={Object.values(AnimalDtoSpecies).map((status: AnimalDtoSpecies) => ({
                label: getReadableSpecies(status, { withEmoji: true }),
                value: status,
              }))}
              color="default"
              size="small"
              sx={{ ...fieldSx }}
            />
          </Field>
          <Field label="Stérilisation, vous cherchez de préférence" required>
            <ControlledSelectField
              control={control}
              fieldName="sterilizedOrNeutered"
              error={errors.sterilizedOrNeutered}
              requiredRule="Vous devez choisir une préférence de stérilisation"
              options={Object.values(AdopterDtoAdoptionFormSterilizedOrNeutered).map(
                (sterilizedOrNeutered: AdopterDtoAdoptionFormSterilizedOrNeutered) => ({
                  label: getReadableAdopterAdoptionFormSterilizedOrNeutered(sterilizedOrNeutered),
                  value: sterilizedOrNeutered,
                })
              )}
              size="small"
              fullWidth
            />
          </Field>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'start', width: '50%' }}>
          <Field label="Sexe, vous cherchez de préférence: " required>
            <ControlledSelectField
              control={control}
              fieldName="sex"
              error={errors.sex}
              requiredRule="Vous devez choisir une préférence de sexe"
              options={Object.values(AdopterDtoAdoptionFormSex).map((sexPreference: AdopterDtoAdoptionFormSex) => ({
                label: getReadableAdopterAdoptionFormSex(sexPreference),
                value: sexPreference,
              }))}
              size="small"
              fullWidth
            />
          </Field>
        </Box>
      </Box>
    </Box>
  )
}
