import AirIcon from '@mui/icons-material/Air'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import HealingIcon from '@mui/icons-material/Healing'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import PetsIcon from '@mui/icons-material/Pets'
import PsychologyIcon from '@mui/icons-material/Psychology'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import SneezingIcon from '@mui/icons-material/SyncProblem' // Example, MUI doesn't have a sneezing icon
import TextureIcon from '@mui/icons-material/Texture'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import WarningIcon from '@mui/icons-material/Warning'
import WaterIcon from '@mui/icons-material/Water'
import { AnimalProfileHealthObservationsDtoType } from '../interactors/gen/backendClient'

export const getIconForObservationType = (issue: AnimalProfileHealthObservationsDtoType) => {
  switch (issue) {
    case AnimalProfileHealthObservationsDtoType.DiarrheaAndStoolIssues:
      return <LocalHospitalIcon />
    case AnimalProfileHealthObservationsDtoType.Vomiting:
      return <HealingIcon />
    case AnimalProfileHealthObservationsDtoType.MeowingInLitter:
    case AnimalProfileHealthObservationsDtoType.InappropriateElimination:
      return <PetsIcon />
    case AnimalProfileHealthObservationsDtoType.AppetiteChange:
      return <RestaurantIcon />
    case AnimalProfileHealthObservationsDtoType.ExcessiveWeightGain:
      return <NewReleasesIcon />
    case AnimalProfileHealthObservationsDtoType.IncreasedWaterConsumption:
      return <WaterIcon />
    case AnimalProfileHealthObservationsDtoType.SkinCrusts:
      return <TextureIcon />
    case AnimalProfileHealthObservationsDtoType.RedAndSwollenGums:
      return <ReportProblemIcon />
    case AnimalProfileHealthObservationsDtoType.Panting:
    case AnimalProfileHealthObservationsDtoType.NocturnalMeowingOrAgitation:
      return <AirIcon />
    case AnimalProfileHealthObservationsDtoType.Sneezing:
      return <SneezingIcon /> // Using SyncProblem as a placeholder
    case AnimalProfileHealthObservationsDtoType.NoseBleeding:
      return <VisibilityOffIcon />
    case AnimalProfileHealthObservationsDtoType.ExcessiveEyeBlinking:
      return <ErrorOutlineIcon />
    case AnimalProfileHealthObservationsDtoType.MobilityIssues:
      return <DirectionsWalkIcon />
    case AnimalProfileHealthObservationsDtoType.InappropriateScratching:
      return <WarningIcon />
    case AnimalProfileHealthObservationsDtoType.FearfulBehavior:
      return <PsychologyIcon />
    default:
      return null // Or a default icon
  }
}
