import { Card, CardContent, Box, Typography, Collapse } from '@mui/material'
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDownIcon.svg'
import React, { useEffect, useState } from 'react'
import { theme } from '../theme'

interface Props {
  title: string
  children: React.ReactNode
  defaultExpanded?: boolean
}

export const ExpandableCard: React.FC<Props> = ({ title, children, defaultExpanded }) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  useEffect(() => {
    setExpanded(defaultExpanded)
  }, [defaultExpanded])

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent sx={{ px: { xs: '4px', md: '16px' } }}>
        <Box
          onClick={() => setExpanded(!expanded)}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Typography variant="h2" component="p">
            {title}
          </Typography>
          <ArrowDownIcon
            style={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              marginLeft: 'auto',
              transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
            }}
          />
        </Box>
        <Collapse in={expanded} timeout="auto">
          {children}
        </Collapse>
      </CardContent>
    </Card>
  )
}
