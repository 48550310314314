import { Box, Button, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { frFR } from '@mui/x-date-pickers/locales'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { debounce } from 'lodash'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { AnimalProfilesClient } from '../../../../interactors/clients/AnimalProfilesClient'
import { AnimalProfileDto, AnimalProfileHealthPestControlDto } from '../../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../../store/GlobalSnackBarStore'
import { DateString } from '../../../../utils/date/DateString'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { ControlledSelectField } from '../../../common/ControlledSelectField'
import { Field } from '../../../common/FormField'
import { colors, lightBlue, theme } from '../../../theme'
import { LocalPharmacy } from '@mui/icons-material'

interface Props {
  data: AnimalProfileHealthPestControlDto | undefined
  type: 'deworming' | 'fleaControl'
  animalProfile: AnimalProfileDto
  setAnimalProfile: (animalProfile: AnimalProfileDto) => void
}

export const PestControl: React.FC<Props> = ({ data, type, animalProfile, setAnimalProfile }) => {
  const [clicked, setClicked] = React.useState(false)
  const [initialTreatmentDate, setInitialTreatmentDate] = React.useState<DateString>(
    dayjs().toISOString() as DateString
  )
  const globalSnackbarStore = useGlobalSnackbarStore()
  const getDefaultValues = (data: AnimalProfileHealthPestControlDto | undefined) => {
    return {
      frequency: data?.frequency || 12,
    }
  }
  const { handleSubmit, watch, control } = useFormExtended(data, getDefaultValues)

  const frequency = watch('frequency')

  const mutation = useMutation(
    (animal: AnimalProfileDto) => {
      return AnimalProfilesClient.editAnimalProfile(animal)
    },
    {
      onSuccess: (animal) => {
        globalSnackbarStore.triggerSuccessMessage("Profil de l'animal mis à jour")
        setAnimalProfile(animal)
      },
      onError: (error) => {
        console.error('Error updating animal profile', error)
        globalSnackbarStore.triggerErrorMessage("Une erreur est survenue lors de la mise à jour du profil de l'animal")
      },
    }
  )

  useEffect(() => {
    // Create a debounced function that updates the animal profile
    const debouncedUpdate = debounce((frequency: number) => {
      if (frequency === data?.frequency || frequency === 0) return
      console.log('Auto-saving frequency:', frequency)
      const newAnimalProfile = {
        ...animalProfile,
        health: {
          ...animalProfile.health,
          [type]: { dates: data?.dates || [], frequency: frequency },
        },
      }
      mutation.mutate(newAnimalProfile)
    }, 500) // Wait for 500ms of inactivity before triggering

    // Call the debounced function with the current frequency value
    debouncedUpdate(frequency)

    // Cleanup function to cancel the debounced call if the component unmounts
    return () => {
      debouncedUpdate.cancel()
    }
  }, [frequency])

  const onSubmit = (form: { frequency: number }) => {
    console.log('Submitting...')
    const newAnimalProfile = {
      ...animalProfile,
      health: {
        ...animalProfile.health,
        [type]: { dates: data?.dates || [], frequency: form.frequency },
      },
    }
    mutation.mutate(newAnimalProfile)
  }

  const onDateAdd = (date: DateString) => {
    const newAnimalProfile = {
      ...animalProfile,
      health: {
        ...animalProfile.health,
        [type]: { dates: [...(data?.dates || []), date], frequency: data?.frequency || 0 },
      },
    }
    mutation.mutate(newAnimalProfile)
  }

  const onFirstDateSubmit = () => {
    const newAnimalProfile = {
      ...animalProfile,
      health: {
        ...animalProfile.health,
        [type]: { dates: [initialTreatmentDate], frequency: data?.frequency || 0 },
      },
    }
    mutation.mutate(newAnimalProfile)
  }

  return (
    <>
      <Typography variant="h2" component="h2" sx={{ color: 'black', textAlign: 'center', mt: 2 }}>
        {type === 'deworming' ? 'Vermifuge' : 'Insectifuge'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field label="Fréquence du traitement">
          {/* <TextField
            {...register('frequency')}
            aria-label="Fréquence de traitement"
            fullWidth
            type="number"
            helperText="Indiquez la fréquence du traitement en semaine(s)"
            InputProps={{
              endAdornment: (
                <Typography variant="body2" sx={{ color: 'black' }}>
                  semaine(s)
                </Typography>
              ),
            }}
          /> */}
          <ControlledSelectField<{ frequency: number }>
            control={control}
            fieldName="frequency"
            aria-label="Fréquence du traitement"
            requiredRule="La fréquence du traitement est requise"
            options={[
              { value: 1, label: '1 semaine' },
              { value: 2, label: '2 semaines' },
              { value: 3, label: '3 semaines' },
              { value: 4, label: '1 mois' },
              { value: 8, label: '2 mois' },
              { value: 12, label: '3 mois' },
              { value: 16, label: '4 mois' },
              { value: 24, label: '6 mois' },
            ]}
            error={undefined}
          />
        </Field>
      </form>
      {data && data.dates.length > 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 2,
              mt: 2,
              border: '1px solid #ccc',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <Typography variant="h3" sx={{ color: 'black', textAlign: 'center', fontSize: '20px' }}>
              Prochain traitement à réaliser avant le{' '}
              {data.dates.length > 0 &&
                dayjs(data.dates.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? 1 : -1))[0])
                  .add(data.frequency, 'week')
                  .format('DD/MM/YYYY')}
            </Typography>
            <Button variant="contained" onClick={() => onDateAdd(dayjs().toISOString() as DateString)}>
              Je viens de le faire
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              py: '8px',
              px: '16px',
              mt: 2,
              borderRadius: '5px',
              gap: '10px',
              border: '1px solid',
              borderColor: colors.lightGrey,
              backgroundColor: lightBlue,
            }}
          >
            <Box
              sx={{ display: 'flex', gap: '12px', alignItems: 'center', color: theme.palette.secondary.main, mb: 1 }}
            >
              <LocalPharmacy />
              <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                Historique des traitements
              </Typography>
            </Box>

            {data.dates.map((date) => (
              <Typography key={date}>Date de traitement: {dayjs(date).format('DD/MM/YYYY')}</Typography>
            ))}
          </Box>
        </>
      )}
      {((data && data.dates.length === 0) || !data) && (
        <>
          {clicked ? (
            <>
              <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', mt: 2 }}>
                Date du traitement:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <DatePicker
                  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
                  defaultValue={dayjs()}
                  onChange={(newValue: Dayjs | null) =>
                    newValue && setInitialTreatmentDate(newValue.toISOString() as DateString)
                  }
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </>
          ) : (
            <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', mt: 2 }}>
              Pas de traitement enregistré
            </Typography>
          )}
          <Button variant="contained" onClick={() => (!clicked ? setClicked(true) : onFirstDateSubmit())}>
            Ajouter une première date de traitement
          </Button>
        </>
      )}
    </>
  )
}
