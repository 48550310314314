import React from 'react'
import { AnimalDocumentDtoType, AnimalProfileDocumentDto } from '../../interactors/gen/backendClient'
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { getReadableAnimalDocumentType } from '../../domain/Animal/AnimalDisplay'
import dayjs from 'dayjs'

interface Props {
  documents: AnimalProfileDocumentDto[]
  selectedDocuments: string[] // We'll use document IDs for simplicity
  setSelectedDocuments: (documentIds: string[]) => void
}

export const AnimalDocumentsTable: React.FC<Props> = ({ documents, selectedDocuments, setSelectedDocuments }) => {
  const handleSelectDocument = (id: string) => {
    if (selectedDocuments.includes(id)) {
      setSelectedDocuments(selectedDocuments.filter((docId) => docId !== id))
    } else {
      setSelectedDocuments([...selectedDocuments, id])
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="animal documents table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            <TableCell>Nom</TableCell>
            <TableCell align="right">Catégorie</TableCell>
            <TableCell align="right">Date d&#39;ajout</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document) => (
            <TableRow
              key={document.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              hover
              onClick={() => handleSelectDocument(document.id)}
              role="checkbox"
              selected={selectedDocuments.includes(document.id)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedDocuments.includes(document.id)}
                  inputProps={{ 'aria-labelledby': document.id }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {document.name != null && document.name !== ''
                  ? document.name
                  : getReadableAnimalDocumentType(document.type as AnimalDocumentDtoType)}
              </TableCell>
              <TableCell align="right">
                {getReadableAnimalDocumentType(document.type as AnimalDocumentDtoType)}
              </TableCell>
              <TableCell align="right">{dayjs(document.uploadedAt).format('DD/MM/YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
