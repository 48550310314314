import {
  ChangeOfHostedFamilyHostedFamilyFollowUpDtoType,
  ShiftHostedFamilyFollowUpDtoType,
} from '../../interactors/gen/backendClient'

export const HostedFamilyFollowUpDtoType = {
  ...ChangeOfHostedFamilyHostedFamilyFollowUpDtoType,
  ...ShiftHostedFamilyFollowUpDtoType,
}

export type HostedFamilyFollowUpDtoType = typeof HostedFamilyFollowUpDtoType[keyof typeof HostedFamilyFollowUpDtoType]

/* export const isChangeOfHostedFamilyHostedFamilyFollowUpDto = (
  followUp: AnimalDtoHostedFamilyFollowUpsInner
): followUp is ChangeOfHostedFamilyHostedFamilyFollowUpDto => {
  return followUp.type === HostedFamilyFollowUpDtoType.ChangeOfHostedFamily
} */
