import { Add, Remove } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { BasketItem } from '../../utils/types/BasketItem'
import data from './data/products.json'
import { getProductImageSrc } from '../../utils/S3-links'
import { useBasketStore } from '../../store/BasketStore'
import { useNavigate } from 'react-router-dom'

interface Props {
  item: BasketItem
}

export const BasketItemComponent: React.FC<Props> = ({ item }) => {
  const basketStore = useBasketStore()
  const navigate = useNavigate()
  const product = data.products.find((p) => p.reference === item.reference)

  const handleAddMore = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    basketStore.addOrUpdateItem({
      reference: item.reference,
      quantity: 1,
      price: item.price,
      animalId: item.animalId,
      color: item.color,
    })
  }

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    basketStore.reduceItemQuantity(item.reference, 1, item.animalId, item.color)
  }

  if (!product) return null
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100px',
        p: '8px',
        px: '16px',
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/produits/${product.reference}?animalId=${item.animalId}`)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', gap: 1, width: '100%' }}>
        <img
          src={getProductImageSrc(product.images[0])}
          alt={product.title}
          style={{ height: '100%', maxHeight: '84px', width: '80px', objectFit: 'contain', borderRadius: '12px' }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '10px',
              width: '100%',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="body1" component="p" sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700 }}>
              {product.title}
            </Typography>
            {item.color && (
              <Typography
                variant="body2"
                component="p"
                sx={{ fontSize: '14px', lineHeight: '16.2px', fontWeight: 400, my: -0.5 }}
              >
                {item.color}
              </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: '15px', lineHeight: '19.2px', fontWeight: 400, fontStyle: 'italic' }}
              >
                {`${Math.round((item.quantity * item.price + Number.EPSILON) * 100) / 100}€`}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', backgroundColor: 'white' }}>
                <IconButton onClick={(e) => handleRemove(e)}>
                  <Remove sx={{ width: '20px', height: '20px' }} />
                </IconButton>
                <Typography variant="body1" component="p" sx={{ fontSize: '13px', fontWeight: 400 }}>
                  {item.quantity}
                </Typography>
                <IconButton onClick={(e) => handleAddMore(e)}>
                  <Add sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
