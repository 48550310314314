import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as CrossIcon } from '../icons/CrossIcon.svg'
import { theme } from '../theme'

interface Props {
  popIn: PopIn | null
  isModalOpened: boolean
  onConfirm: (type?: PopIn['type']) => void
  onCancel: () => void
}

export interface PopIn {
  type: 'cancel' | 'confirm'
  title: string
  message: string
  cancelText: string
  confirmText: string
}

export const ConfirmationPopIn: React.FC<Props> = ({ popIn, isModalOpened, onConfirm, onCancel }) => {
  return (
    <Modal open={isModalOpened} onClose={onCancel}>
      <Box
        sx={{
          width: '493px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          padding: '24px',
          borderRadius: '8px',
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 700, marginBottom: '16px', color: 'black' }}
          >
            {popIn?.title}
          </Typography>
          <CrossIcon style={{ cursor: 'pointer' }} onClick={onCancel} />
        </Box>

        <Typography
          sx={{ fontSize: '14px', lineHeight: '16.8px', fontWeight: 400, color: 'black', marginBottom: '32px' }}
        >
          {popIn?.message}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCancel}
            sx={{
              px: '20px',
              py: '13px',
              borderRadius: '200px',
              color: theme.palette.secondary.main,
              fontSize: '16px',
              lineHeight: '19.2px',
              fontWeight: 600,
              boxShadow: 'none',
              border: '2px solid',
              borderColor: theme.palette.primary.main,
              '&:hover': {
                border: '2px solid',
                borderColor: theme.palette.primary.main,
              },
            }}
          >
            {popIn?.cancelText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onConfirm(popIn?.type)}
            sx={{
              px: '20px',
              py: '13px',
              borderRadius: '200px',
              color: theme.palette.secondary.main,
              fontSize: '16px',
              lineHeight: '19.2px',
              fontWeight: 600,
              boxShadow: 'none',
            }}
          >
            {popIn?.confirmText}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
