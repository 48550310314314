import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { lightBlue, theme } from '../theme'
import { PetParentAnimalItem } from './AnimalItem'
import { backendClient } from '../../interactors/clients/client'
import { useNavigate } from 'react-router-dom'
import { ArrowForwardIos } from '@mui/icons-material'

export const PetParentsListScreen: React.FC = () => {
  const adoptersStore = useAdoptersStore()
  const navigate = useNavigate()
  const adopters = adoptersStore.adopters

  const sentinelRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // If the sentinel comes into view, fetch more adopters
        if (entries[0].isIntersecting) {
          adoptersStore.fetchMoreAdopters()
        }
      },
      { threshold: 1.0 } // Trigger when the sentinel is fully visible
    )

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current)
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current)
      }
    }
  }, [adoptersStore])

  const handleNewConversationClick = async (adopterId: string) => {
    const newConversation = await backendClient.post('/adopters-platform/conversations', {
      receiverId: adopterId,
    })
    const conversationId = newConversation.data.id
    navigate(`/chat?id=${conversationId}`)
  }

  if (adoptersStore.isLoading && !adopters) {
    return <CircularProgressPanel />
  }

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20, overflowY: 'scroll' }}>
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          mb: 10,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Box
          onClick={() => handleNewConversationClick('B-3403')}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: lightBlue,
            borderRadius: '12px',
            p: 2,
            gap: '8px',
            width: '100%',
            border: `1px solid ${theme.palette.secondary.main}`,
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#E3E6F0', // Lighter shade for hover state
              borderColor: '#D0D3D8', // Slightly darker border on hover
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{`Une question ?`}</Typography>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 400 }}
            >{`Discutez avec un membre Petso titulaire de l’Acaced`}</Typography>
          </Box>
          <ArrowForwardIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
        </Box>
        {adopters.map((adopter) => (
          <Box
            key={adopter.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderRadius: '12px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: lightBlue,
                borderRadius: '12px',
                p: 1,
                px: 2,
                gap: '8px',
                width: 'min-content',
                mb: -2,
                zIndex: 1,
              }}
            >
              <Typography
                sx={{ fontSize: '16px', fontWeight: 600, whiteSpace: 'nowrap' }}
              >{`${adopter.firstName} ${adopter.lastName[0]}.`}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: lightBlue,
                borderRadius: '12px',
                p: 2,
                gap: '8px',
                width: '100%',
              }}
            >
              {adopter.animals.map((animal) => (
                <PetParentAnimalItem key={animal.id} animal={animal} adopter={adopter} />
              ))}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                  gap: '10px',
                  mt: '8px',
                }}
              >
                <Typography sx={{ fontSize: '12px', fontWeight: 600, whiteSpace: 'nowrap' }}>
                  {adopter.distanceToUser >= 100
                    ? `à plus de 50km de vous`
                    : `à moins de ${adopter.distanceToUser}km de vous`}
                </Typography>
                <Button variant="contained" color="secondary" onClick={() => handleNewConversationClick(adopter.id)}>
                  Entrer en contact
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
        <div ref={sentinelRef} style={{ height: '1px' }}></div>
      </Box>
    </Box>
  )
}
