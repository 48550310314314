import { Autocomplete, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { debounce } from 'lodash'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { ServiceLocationsClient } from '../../interactors/clients/ServiceLocationsClient'

type Option = {
  label: string
  value: string
  locationName: string
}

type Props<T extends FieldValues> = {
  control: Control<T>
  fieldName: Path<T>
  label?: string
  placeholder?: string
}

export function ControlledVeterinariesAutocomplete<T extends FieldValues>({
  control,
  fieldName,
  label,
  placeholder,
}: PropsWithChildren<Props<T>>) {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<Option[]>([])
  const [loading, setLoading] = useState(false)

  const fetchData = async (search: string) => {
    if (!search) {
      return
    }

    setLoading(true)
    try {
      const locations = await ServiceLocationsClient.getAllLocations({
        page: 1,
        limit: 30,
        type: 'veterinary',
        query: search,
      })
      const newOptions = locations.flatMap((location) =>
        location.serviceProvidersNames.map((provider) => ({
          label: `${provider.firstName} ${provider.lastName}`,
          value: `${provider.id}_${location.id}`,
          locationName: location.name,
        }))
      )

      setOptions(newOptions)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  // Use useCallback to create a stable reference of debounced function
  const debouncedFetchData = useCallback(
    debounce((search: string) => fetchData(search), 1000),
    [] // dependencies array is empty, meaning the function is created only once
  )

  const handleInputChange = (_: React.SyntheticEvent<Element, Event>, newInputValue: string, reason: string) => {
    setInputValue(newInputValue)
    if (reason === 'input') {
      debouncedFetchData(newInputValue)
    }
  }

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            value={options.find((option) => option.value === value)}
            onChange={(_, newValue) => {
              onChange(newValue?.value ?? null)
            }}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            loading={loading}
            options={options}
            fullWidth
            noOptionsText={
              inputValue.length > 0
                ? 'Aucun vétérinaire correspondant à votre recherche.'
                : 'Saisissez le nom de votre vétérinaire'
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label || undefined}
                variant="outlined"
                placeholder={placeholder || undefined}
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    color: 'text.primary', // Uses the theme's primary text color for placeholder
                    opacity: 1, // Make it fully opaque like a normal text
                    fontWeight: 'normal', // Optional: Set to normal to mimic the weight of entered text
                  },
                  // Add any additional styles for the TextField here
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      <Typography sx={{ fontSize: '22px' }}>👨‍⚕️</Typography>
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      <Typography variant="body1">{option.label}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {option.locationName}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            }}
          />
        )}
      />
    </>
  )
}
