import create from 'zustand'

interface NotificationModalOpenStore {
  isOpen: boolean
  setIsOpen: (newValue: boolean) => void
}

export const useNotificationModalOpenStore = create<NotificationModalOpenStore>()((set) => ({
  isOpen: false,
  setIsOpen: (newValue: boolean) => {
    set({ isOpen: newValue })
  },
}))
