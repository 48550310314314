import { Assignment } from '@mui/icons-material'
import { Box, Button, Chip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { getReadableAnimalDocumentType } from '../../domain/Animal/AnimalDisplay'
import { AnimalDocumentDtoType, AnimalProfileDocumentDto } from '../../interactors/gen/backendClient'
import { getAnimalDocumentsPhotoSrc } from '../../utils/S3-links'
import { colors, lightBlue, theme } from '../theme'

type Props = {
  document: AnimalProfileDocumentDto
}

export const DocumentItem: React.FC<Props> = ({ document }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: '8px',
        px: '16px',
        borderRadius: '5px',
        gap: '10px',
        border: '1px solid',
        borderColor: colors.lightGrey,
        backgroundColor: colors.lightGrey,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
          color: colors.black,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            color: colors.black,
          }}
        >
          <Assignment sx={{ color: theme.palette.secondary.main }} />
          <Chip
            sx={{ backgroundColor: lightBlue }}
            label={document.type ? getReadableAnimalDocumentType(document.type as AnimalDocumentDtoType) : ''}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Typography sx={{ fontStyle: 'italic', fontSize: '14px' }}>{`${dayjs(document.uploadedAt).format(
          'DD/MM/YY'
        )}`}</Typography>
        {document.name && <Typography>{document.name}</Typography>}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          gap: '16px',
          color: colors.black,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          component="a"
          href={getAnimalDocumentsPhotoSrc(document.documentKey)}
          target="_blank"
        >
          Consulter
        </Button>
      </Box>
    </Box>
  )
}
