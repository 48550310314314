import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { backendClient } from '../../interactors/clients/client'
import { ConversationDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { getDayLabel } from '../../utils/date/getDayLabel'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { theme } from '../theme'
import { ChatView } from './ChatView'
import dayjs from 'dayjs'

export const ConversationsScreen: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const accountsStore = useAccountsStore()
  const currentUserId = accountsStore.connectedAccount?.id
  const [isLoading, setIsLoading] = React.useState(true)
  const [conversations, setConversations] = React.useState<ConversationDto[]>([])
  const [selectedConversation, setSelectedConversation] = React.useState<ConversationDto | null>(null)

  /* const handleNewConversationClick = async () => {
    const newConversation = await backendClient.post('/adopters-platform/conversations', {
      receiverId: 'B-1386',
    })
    setConversations([...conversations, newConversation.data])
  } */

  const handleConversationClick = (conversation: ConversationDto) => {
    queryParams.set('id', conversation.id)
    window.history.pushState({}, '', `${window.location.pathname}?${queryParams.toString()}`)
    setSelectedConversation(conversation)
  }

  const handleBackClick = () => {
    queryParams.delete('id')
    window.history.pushState({}, '', `${window.location.pathname}?${queryParams.toString()}`)
    setSelectedConversation(null)
  }

  useEffect(() => {
    const conversationId = queryParams.get('id')
    if (conversationId) {
      const conversation = conversations.find((conversation) => conversation.id === conversationId)
      if (conversation) {
        setSelectedConversation(conversation)
      }
    }
  }, [conversations])

  useEffect(() => {
    if (selectedConversation) return
    const fetchConversations = async () => {
      const fetchedConversations = await backendClient.get('/adopters-platform/conversations')
      setConversations(fetchedConversations.data)
      setIsLoading(false)
    }
    fetchConversations()
  }, [selectedConversation])

  if (isLoading) return <CircularProgressPanel />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: { xs: 0, md: 0 }, minHeight: '100%', pb: 20 }}>
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          mb: 10,
          p: selectedConversation ? 0 : 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          flexGrow: 1,
        }}
      >
        {!selectedConversation ? (
          conversations.length > 0 ? (
            conversations
              .filter((conversation) => conversation.lastMessage)
              .sort((a, b) => (dayjs(a.lastMessage?.createdAt).isBefore(b.lastMessage?.createdAt) ? 1 : -1))
              .map((conversation, index) => (
                <Card
                  key={index}
                  onClick={() => {
                    handleConversationClick(conversation)
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '64px',
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 600 }}>
                        {conversation.participants.findIndex((participant) => participant !== currentUserId) !== -1 &&
                        conversation.participantNames
                          ? conversation.participantNames[
                              conversation.participants.findIndex((participant) => participant !== currentUserId)
                            ]
                          : 'Nom du destinataire inconnu'}
                      </Typography>
                      <Typography sx={{ fontSize: '14px' }}>
                        {conversation.lastMessage ? getDayLabel(conversation.lastMessage.createdAt) : ''}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontWeight: conversation.unreadCount && conversation.unreadCount > 0 ? 600 : 400,
                        }}
                      >
                        {conversation.lastMessage ? conversation.lastMessage.message : ''}
                      </Typography>
                      {conversation.unreadCount ? (
                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            fontSize: '14px',
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {conversation.unreadCount}
                        </Avatar>
                      ) : null}
                    </Box>
                  </CardContent>
                </Card>
              ))
          ) : (
            <>
              <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>
                Aucune conversation pour le moment
              </Typography>
            </>
          )
        ) : (
          <ChatView conversation={selectedConversation} handleBackClick={handleBackClick} />
        )}
      </Box>
    </Box>
  )
}
