import { Box, Typography } from '@mui/material'
import React from 'react'
import data from '../Shopping/data/products.json'
import { colors, theme } from '../theme'
import { ProductCard } from './ProductCard'
import Slider from 'react-slick'
import { PackCard } from './PackCard'
import { CategoryProps } from '../Shopping/Categories/FoodCategory'

export const AccessoriesTab: React.FC<CategoryProps> = ({ animalProfile, specie, alreadyAdoptedAnimal }) => {
  const species = animalProfile?.species || specie

  const settings = {
    dots: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    initialSlide: 0,
    speed: 200,
    arrows: false,
    customPaging: () => <div className="ft-slick__dots--custom"></div>,
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1 }}>
        {animalProfile && alreadyAdoptedAnimal && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                  fontSize: '24px',
                  textAlign: 'left',
                  color: theme.palette.secondary.dark,
                  mb: 2,
                }}
              >
                {`Les kits par Fidelami`}
              </Typography>
            </Box>

            <Box sx={{ mx: -2 }}>
              <hr
                style={{
                  backgroundColor: colors.silver,
                  height: '20px',
                  width: '100%',
                  border: 'none',
                  margin: '0',
                }}
              />
            </Box>
          </>
        )}
        <Box sx={{ py: 2 }}>
          <Slider {...settings}>
            {data.products
              .filter((product) => product.category.includes('fidelami-pack'))
              .filter(
                (product) =>
                  (!animalProfile && !specie) ||
                  (species && product.species.includes(species)) ||
                  product.species.length === 0
              )
              .map((product) => (
                <PackCard
                  key={product.reference}
                  product={product}
                  animalId={animalProfile?.id}
                  noRedirect={!alreadyAdoptedAnimal}
                />
              ))}
          </Slider>
        </Box>
        <Box sx={{ mx: -2 }}>
          <hr
            style={{
              backgroundColor: colors.silver,
              height: '20px',
              width: '100%',
              border: 'none',
              margin: '0',
            }}
          />
        </Box>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            textAlign: 'left',
            color: theme.palette.secondary.dark,
            px: '12px',
            py: '8px',
          }}
        >
          {`A la carte`}
        </Typography>
        {data.products
          .filter((product) => product.category.includes('fidelami'))
          .filter(
            (product) =>
              (!animalProfile && !specie) ||
              (species && product.species.includes(species)) ||
              product.species.length === 0
          )
          .map((product) => (
            <ProductCard
              key={product.reference}
              product={product}
              animalId={animalProfile?.id}
              noRedirect={!alreadyAdoptedAnimal}
            />
          ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 1,
          px: 2,
          backgroundColor: colors.mintTulip,
          mx: -2,
        }}
      >
        <Typography sx={{ fontSize: '14px' }}>
          {`Sélectionnée par la SPA et Petso, Fidelami est une marque dédiée au bien-être animal et à l'adoption responsable. Leurs produits, conçus avec soin, répondent aux besoin de nos compagnons à quatres pattes.`}{' '}
          <b>{`En achetant Fidelami, vous offrez aussi des repas en refuge.`}</b>
        </Typography>
      </Box>
    </>
  )
}
