import { ArrowForwardIos } from '@mui/icons-material'
import HealingIcon from '@mui/icons-material/Healing'
import { Box, Chip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getReadableOperationType } from '../../domain/Animal/AnimalDisplay'
import { AnimalHealthOperationDtoType } from '../../interactors/gen/backendClient'
import { fromDayNumberToName } from '../../utils/date/fromDayNumbeToName'
import { fromMonthNumberToName } from '../../utils/date/fromMonthNumberToName'
import { colors, lightPink, theme } from '../theme'

export interface Deworming {
  date: string
  id: string
  type: AnimalHealthOperationDtoType
}

interface Props {
  deworming: Deworming
  animalId: string
  displayOnly?: boolean
}

export const AnimalHealthDewormingItem: React.FC<Props> = ({ deworming, animalId, displayOnly = false }) => {
  const navigate = useNavigate()

  return (
    <Box
      onClick={() =>
        !displayOnly
          ? navigate(`/mes-animaux/${animalId}?tab=shopping&category=sante&subCategory=${deworming.id}`)
          : null
      }
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: '8px',
        px: '16px',
        borderRadius: '5px',
        gap: '10px',
        border: '1px solid',
        borderColor: colors.lightGrey,
        backgroundColor: lightPink,
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
          backgroundColor: '#d9bfcd', // Lighter shade for hover state
          borderColor: '#D0D3D8', // Slightly darker border on hover
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            color: theme.palette.secondary.main,
          }}
        >
          <HealingIcon />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
              color: colors.black,
            }}
          >
            <Chip sx={{ backgroundColor: colors.lightGrey }} label={getReadableOperationType(deworming.type)} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '18px',
            color: colors.black,
          }}
        >
          <Typography>{`${fromDayNumberToName(dayjs(deworming.date).day())} ${dayjs(deworming.date)
            .format('DD/MM/YYYY')
            .split('/', 1)} ${fromMonthNumberToName(dayjs(deworming.date).month())} ${
            dayjs(deworming.date).format('DD/MM/YYYY').split('/', 3)[2]
          }`}</Typography>
        </Box>
      </Box>
      {!displayOnly && <ArrowForwardIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />}
    </Box>
  )
}
