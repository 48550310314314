import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { lightBlue } from '../theme'

interface Props {
  icon: React.ReactNode
  title: string
  text: string
}

export const AnimalDescriptionItem: React.FC<Props> = ({ icon, title, text }) => {
  const [isCardExpanded, setIsCardExpanded] = useState(false)

  const truncateLength = 238
  const truncateText = (text: string, length: number) => {
    if (text.length <= length) {
      return text
    }
    return `${text.substring(0, length)}...`
  }

  const toggleCardExpand = () => {
    setIsCardExpanded(!isCardExpanded)
  }

  const description = text || 'Aucune description pour le moment.'
  const displayDescriptionText = !isCardExpanded ? truncateText(description, truncateLength) : description

  return (
    <Box
      sx={{
        display: 'flex',
        py: '8px',
        px: '16px',
        borderRadius: '5px',
        gap: '14px',
        backgroundColor: lightBlue,
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          height: '40px',
          p: '8px',
        }}
      >
        {icon}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Typography
          variant="body1"
          component="p"
          sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{ fontSize: '14px', lineHeight: '16.8px', fontWeight: 400, color: '#4f4f4f' }}
        >
          {displayDescriptionText}
        </Typography>
        {description.length > truncateLength && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              variant="text"
              onClick={() => toggleCardExpand()}
              color="secondary"
              sx={{ fontWeight: 700, fontSize: '16px', lineHeight: '19.2px' }}
            >
              {isCardExpanded ? 'Voir moins' : 'Voir plus'}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}
