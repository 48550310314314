import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, Grow, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { getColorForOperationType, getReadableOperationType } from '../../domain/Animal/AnimalDisplay'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import {
  AnimalProfileHealthOperationDto,
  AnimalProfileHealthOperationDtoType,
  AnimalProfileDto,
} from '../../interactors/gen/backendClient'
import { isValidPastDateCoherence } from '../../utils/date/isValidPastDateCoherence'
import { ControlledDateField } from '../common/ControlledDateField'
import { ControlledMultipleAutoCompleteWithCategories } from '../common/ControlledMultipleAutocompleteWithCategories'
import { Field } from '../common/FormField'
import { spacingItem } from '../theme'
import { nanoid } from 'nanoid'
import { ControlledVeterinariesAutocomplete } from '../common/ControlledVeterinariesAutocomplete'
import { ServiceProvidersClient } from '../../interactors/clients/ServiceProvidersClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'

interface FormData {
  type: AnimalProfileHealthOperationDtoType[]
  date: string
  veterinary?: string
  additionalInfo?: string
}

export const AddAnimalOperationScreen: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [animal, setAnimal] = React.useState<AnimalProfileDto | null>(null)
  const { animalId } = useParams() as { animalId: string }

  const accountsStore = useAccountsStore()
  const account = accountsStore.connectedAccount

  const [defaultVeterinaryName, setDefaultVeterinaryName] = React.useState<string | undefined>(undefined)
  const [shouldSetDefaultVeterinary, setShouldSetDefaultVeterinary] = React.useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchDefaultVeterinary = async () => {
      if (!account || !account?.defaultVeterinary) return
      if (account.defaultVeterinary) {
        const veterinary = await ServiceProvidersClient.getProvider(account.defaultVeterinary.id)
        setDefaultVeterinaryName(`${veterinary.firstName} ${veterinary.lastName}`)
      }
    }
    const fetchAnimal = async () => {
      if (!animalId) return
      const animal = await AnimalProfilesClient.getAnimalById(animalId)
      setAnimal(animal)
    }

    fetchDefaultVeterinary()
    fetchAnimal()
  }, [animalId, account])

  const onSubmit = async (data: FormData) => {
    if (!animal) return
    setIsLoading(true)

    const newAnimal = { ...animal }

    const veterinaryAndLocation = data.veterinary ? data.veterinary.split('_') : [undefined, undefined]
    const [veterinaryId, locationId] = veterinaryAndLocation

    if (
      account &&
      shouldSetDefaultVeterinary &&
      account?.defaultVeterinary?.id !== veterinaryId &&
      account?.defaultVeterinary?.locationId !== locationId
    ) {
      account.defaultVeterinary = {
        id: veterinaryId || '',
        locationId: locationId || '',
      }
      await AdoptersClient.editAccount(account)
    }

    const newOperation: AnimalProfileHealthOperationDto = {
      id: nanoid(),
      type: data.type,
      date: dayjs(data.date).format('DD/MM/YYYY'),
      veterinary: {
        id: veterinaryId || '',
        locationId: locationId || '',
      },
      additionalInfo: data.additionalInfo,
    }

    newAnimal.health.operations = newAnimal.health.operations
      ? [...newAnimal.health.operations, newOperation]
      : [newOperation]

    await AnimalProfilesClient.editAnimalProfile(newAnimal)
    setAnimal(newAnimal)
    navigate(`../${animalId}/operation/${newOperation.id}`)
    setIsLoading(false)
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      type: [],
      date: '',
      veterinary: account?.defaultVeterinary
        ? `${account.defaultVeterinary.id}_${account.defaultVeterinary.locationId}`
        : '',
      additionalInfo: '',
    },
  })

  const defaultVeterinaryAsString = account?.defaultVeterinary
    ? `${account.defaultVeterinary.id}_${account.defaultVeterinary.locationId}`
    : ''
  const watchVeterinary = watch('veterinary')

  useEffect(() => {
    if (
      defaultVeterinaryAsString &&
      (watchVeterinary === '' || watchVeterinary === undefined || watchVeterinary === null)
    ) {
      setValue('veterinary', defaultVeterinaryAsString)
    }
    if (watchVeterinary === defaultVeterinaryAsString || !defaultVeterinaryAsString) {
      setShouldSetDefaultVeterinary(true)
    } else {
      setShouldSetDefaultVeterinary(false)
    }
  }, [watchVeterinary, defaultVeterinaryAsString])

  console.log(watchVeterinary)

  const optionsWithCategories = [
    {
      title: 'Primovaccination et rappel',
      options: Object.values(AnimalProfileHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalProfileHealthOperationDtoType.FirstInjection,
              AnimalProfileHealthOperationDtoType.SecondInjection,
              AnimalProfileHealthOperationDtoType.ThirdInjection,
              AnimalProfileHealthOperationDtoType.AnnualReminder,
            ] as AnimalProfileHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalProfileHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
    {
      title: 'Identification',
      options: Object.values(AnimalProfileHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalProfileHealthOperationDtoType.IdentificationChip,
              /* AnimalProfileHealthOperationDtoType.EarMarking, */
              AnimalProfileHealthOperationDtoType.Tatoo,
            ] as AnimalProfileHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalProfileHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
    {
      title: 'Opérations',
      options: Object.values(AnimalProfileHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalProfileHealthOperationDtoType.SterilizedOrNeutered,
              AnimalProfileHealthOperationDtoType.Implant,
              AnimalProfileHealthOperationDtoType.Surgery,
            ] as AnimalProfileHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalProfileHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
    /* {
      title: 'Soins courants',
      options: Object.values(AnimalProfileHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalProfileHealthOperationDtoType.FleaControl,
              AnimalProfileHealthOperationDtoType.Deworming,
            ] as AnimalProfileHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalProfileHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    }, */
    {
      title: 'Tests/Diagnostiques',
      options: Object.values(AnimalProfileHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalProfileHealthOperationDtoType.FeLv,
              AnimalProfileHealthOperationDtoType.Fiv,
              AnimalProfileHealthOperationDtoType.GoodHealthCertificate,
            ] as AnimalProfileHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalProfileHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
    {
      title: 'Autres visites vétérinaires',
      options: Object.values(AnimalProfileHealthOperationDtoType)
        .filter((type) =>
          (
            [
              AnimalProfileHealthOperationDtoType.Others,
              AnimalProfileHealthOperationDtoType.Identification,
            ] as AnimalProfileHealthOperationDtoType[]
          ).includes(type)
        )
        .map((status: AnimalProfileHealthOperationDtoType) => ({
          label: getReadableOperationType(status),
          value: status,
          color: getColorForOperationType(status),
        })),
    },
  ]

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 1, p: 4, pb: 10 }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Grow in>
            <Box>
              <Field label="Quel type de rdv souhaitez-vous enregistrer ?" required>
                <ControlledMultipleAutoCompleteWithCategories
                  sx={{ ...spacingItem, width: '100%' }}
                  control={control}
                  error={undefined}
                  fieldName="type"
                  size="small"
                  label="Type de rendez-vous"
                  options={optionsWithCategories}
                  requiredRule={'Le type de rendez-vous est requis'}
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={true}>
            <Box>
              <Field label={`A quelle date est/était-il prévu ?`} required>
                <ControlledDateField
                  control={control}
                  fieldName="date"
                  error={errors.date}
                  requiredRule={'La date du rendez-vous est requise'}
                  validate={(value) => isValidPastDateCoherence(value) || value === null}
                  size="small"
                  label="Date du rendez-vous"
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={true} timeout={500}>
            <Box>
              <Field label="Avec quel vétérinaire ?">
                <>
                  <ControlledVeterinariesAutocomplete
                    control={control}
                    fieldName="veterinary"
                    placeholder={defaultVeterinaryName || undefined}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      checked={shouldSetDefaultVeterinary}
                      onChange={() => setShouldSetDefaultVeterinary(!shouldSetDefaultVeterinary)}
                    />
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Enregistrer comme véto par défaut
                    </Typography>
                  </Box>
                </>
              </Field>
            </Box>
          </Grow>
          <Grow in={true} timeout={500}>
            <Box>
              <Field label={`Infos complémentaires`}>
                <TextField
                  aria-label="Infos complémentaires"
                  type="text"
                  placeholder="(Notes pour le rendez-vous, etc.)"
                  {...register('additionalInfo')}
                  multiline
                  rows={4}
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '100%',
                    },
                  }}
                />
              </Field>
            </Box>
          </Grow>
          <Grow in={true} timeout={1000}>
            <LoadingButton type="submit" variant="contained" loading={isLoading} sx={{ mt: 2 }}>
              Ajouter le rendez-vous
            </LoadingButton>
          </Grow>
        </form>
      </Box>
    </Box>
  )
}
