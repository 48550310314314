import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import {
  getReadableAnimalActivity,
  getReadableAnimalCorpulence,
  getReadableAnimalHealthTestStatus,
  getReadableAnimalSize,
  getReadableAnimalSterilizedOrNeutered,
  getReadableTattooLocation,
} from '../../domain/Animal/AnimalDisplay'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import {
  AnimalFormAndActivityDtoActivity,
  AnimalFormAndActivityDtoCorpulence,
  AnimalFormAndActivityDtoSize,
  AnimalHealthDtoFIV,
  AnimalHealthDtoFeLV,
  AnimalHealthDtoSterilizedOrNeutered,
  AnimalProfileDto,
  TattooDtoLocation,
} from '../../interactors/gen/backendClient'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { ControlledMultipleFreeSoloAutoCompleteWithCategories } from '../common/ControlledMultipleFreeSoloAutocompleteWithCategories'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { ControlledToggleButton } from '../common/ControlledToggleButton'
import { Field } from '../common/FormField'
import { lightBlue, theme } from '../theme'
import { ControlledAutoCompleteWithCustomValue } from '../common/ControlledAutocompleteWithCustomValue'

interface Props {
  animal: AnimalProfileDto
  setAnimal: (animal: AnimalProfileDto) => void
}

interface FormData {
  isPedigreeRegistered: boolean
  registrationNumber: string
  numberOfAnimalsInLitter: string
  size: AnimalFormAndActivityDtoSize
  corpulence?: AnimalFormAndActivityDtoCorpulence
  activity?: AnimalFormAndActivityDtoActivity
  weightInKgs: string
  identificationNumber: string
  tattooNumber: string
  tattooLocation?: TattooDtoLocation
  sterilizedOrNeutered: AnimalHealthDtoSterilizedOrNeutered
  FIV: AnimalHealthDtoFIV
  FeLV: AnimalHealthDtoFeLV
  characteristics: string[]
  description: string
  breed: string
  isBreedMixed: boolean
  fatherBreed?: string
  motherBreed?: string
}

const catBreeds = [
  'Abyssinien',
  'African Shorthair',
  'Aladseer',
  'LaPerm',
  'Américain à poil court',
  'Américain à poil dur',
  'Américain Curl',
  'Maine Coon',
  'Ragdoll',
  'Angora turc',
  'Aphrodite Giant Cat',
  'Mau Arabe',
  'Asiatique',
  'Bombay',
  'Burmese',
  'Burmilla',
  "Léopard d'Asie",
  'Chantilly-Tiffany',
  'Balinais',
  'Bengale',
  'Européen',
  'Birman',
  'British Longhair',
  'Siamois',
  'Pixie-bob',
  'Brazillian Shorthair',
  'British Shorthair',
  'Persan',
  'Rex de Cornouailles',
  'California Spangled',
  'Chartreux',
  'Chausie',
  'Cheetoh',
  'Colorpoint à poil court',
  'Bobtail Japonais',
  'Devon Rex',
  'Mau Egyptien',
  'Poil court européen',
  'Exotic Shorthair',
  'Oriental Shorthair',
  'Chat des forêts norvégiennes',
  'Brun de la Havane',
  'Javanais',
  'Keetso',
  'Korat',
  'Lykoi',
  'Manx',
  'Munchkin',
  'Nebelung',
  'Ocicat',
  'Peterbald',
  'Bleu russe',
  'Savannah',
  'Scottish Fold',
  'Selkirk Rex',
  'Serengeti',
  'Sibérien',
  'Singapour',
  'Snowshoe',
  'Somali',
  'Sphynx',
  'Chat chocolaté du Suffolk',
  'Tonkinois',
  'Toyger',
  'Van turc',
  'York Chocolate Cat',
]

const dogBreeds = [
  'Affenpinscher',
  'Afghan',
  'Africain sans poils',
  'Airedale Terrier',
  'Akbash',
  'Akita',
  'Alano Espagnol',
  'Alapaha Blue Blood Bulldog',
  'Américain Mancon',
  'American Bully',
  'American Mastiff',
  'Ariégeois',
  'Aussiedoodle',
  'Autres grands chiens',
  'Bakharwal',
  'Bandogge Mastiff',
  'Barbet',
  'Barzoï',
  'Basenji',
  'Basset Artésien Normand',
  'Basset Bleu De Gascogne',
  'Basset Fauve de Bretagne',
  'Basset Griffon',
  'Basset Hound',
  'Beagle',
  'Beauceron',
  'Bedlington Terrier',
  'Berger Allemand',
  'Berger Australien',
  'Berger Blanc Suisse',
  "Berger d'Anatolie",
  'Berger de Bergame',
  'Berger de Bohème',
  'Berger de Karst',
  'Berger des Carpates',
  "Berger d'Europe de l'Est",
  'Berger Tchécoslovaque',
  'Bichon Caniche',
  'Bichon Frisé',
  'Bichon Havanais',
  'Bichpoo',
  'Blue Heeler',
  'Boerboel',
  'Borador',
  'Border Collie',
  'Border Terrier',
  'Bouche Noir Cur',
  'Bouledogue',
  'Bouledogue Américain',
  'Bouledogue français',
  'Bouvier Australien',
  'Bouvier Bernois',
  'Bouvier des Açores',
  'Bouvier des Flandres',
  'Bouvier Saint Miguel',
  'Boxer',
  'Braque du Bourbonnais',
  'Braque Italien',
  'Briard français',
  'Bug',
  'Buggle',
  'Bull Terrier',
  'Bulldog miniature',
  'Bulldog anglican',
  'Bulldog australien',
  'Bullmastiff',
  'Bullmastiff américain',
  'Bullmastiff des Pyrénées',
  'Bullmastiff français',
  'Bully Kutta',
  'Bully Indien',
  'Cairn Terrier',
  'Can de Palleiro',
  'Canaan',
  'Cane Corsos',
  'Caniche',
  'Caniche nain',
  'Caniche royal',
  'Canin',
  'Cao de Castro Laboreiro',
  'Cao Fila',
  'Carea Leones',
  'Carlin',
  'Cavachon',
  'Cavalier King Charles',
  'Cavapoo',
  'Chesapeake Bay Retriever',
  'Chi-A-Pap',
  'Chien Argentin',
  'Chien Canari',
  'Chien courant',
  'Chien courant bulgare',
  'Chien courant Crétois',
  'Chien courant de Bavière',
  'Chien courant de Halden',
  'Chien courant de Harrier',
  'Chien courant de Lettonie',
  'Chien courant de Prague',
  "Chien courant d'Hamilton",
  "Chien courant d'Ibiza",
  "Chien courant d'Istrie",
  "Chien courant d'Ours de Carélie",
  'Chien courant du Groenland',
  'Chien courant du Jura',
  'Chien courant Polonais',
  'Chien courant Serbe',
  "Chien d'arrêt Allemand",
  "Chien d'arrêt Allemand à poil long",
  "Chien d'arrêt Anglais",
  "Chien d'arrêt Slovaque",
  'Chien de Berger Anglais',
  'Chien de Berger Belge',
  'Chien de Berger Britannique',
  'Chien de Berger Catalan',
  "Chien de Berger d'Anatolie",
  "Chien de Berger d'Argentine",
  "Chien de Berger d'Asie Centrale",
  'Chien de Berger de Bosnie',
  'Chien de Berger de Bucovine',
  'Chien de Berger de Laponie',
  'Chien de Berger de Picardie',
  'Chien de Berger de Travail',
  'Chien de Berger du Caucase',
  'Chien de Berger Grec',
  'Chien de Berger Hollandais',
  'Chien de Berger Hongrois',
  'Chien de Berger Islandais',
  'Chien de Berger Polonais de Tatra',
  'Chien de Berger Portugais',
  'Chien de Berger Roumain',
  'Chien de Berger Roumain de Mioritza',
  'Chien de chasse français',
  'Chien de chasse polonais',
  "Chien de Montagne de l'Entlebucher",
  'Chien de Montagne des Pyrénées',
  'Chien de perdrix de Drentse',
  'Chien de Posavac',
  'Chien de renne de Laponie',
  'Chien de sang',
  'Chien de Tamasquie',
  'Chien de traîneau sibérien de Seppala',
  "Chien d'eau espagnol",
  "Chien d'eau portugais",
  "Chien d'Elkhound Norvégien",
  "Chien d'Estrela",
  'Chien du Brésil',
  'Chien du désert de Libye',
  'Chien du Pharaon',
  'Chien Eskimo Canadien',
  'Chien finlandais de Laponie',
  'Chien français de porcelaine',
  'Chien Léopard de Catahoula',
  'Chien Loup',
  'Chien polonais de plaine',
  'Chien rouge chinois',
  'Chien Suédois de Laponie',
  'Chien-loup de Sarloos',
  'Chien-loup tchécoslovaque',
  'Chiens inuits du Nord',
  'Chihuahua',
  'Chin japonais',
  'Chinois à crête',
  'Chion',
  'Chi-Shi',
  'Chiweenie',
  'Chorkie',
  'Chow Chow',
  'Chug',
  "Cirneco Dell'Etna",
  'Clumber Spaniel',
  'Cockapoo',
  'Cocker Anglais',
  'Cocker Spaniel',
  'Colley',
  'Colley à poil dur',
  'Colley à poil ras',
  'Colley barbu',
  'Colley de Ferme',
  'Collie Irlandais',
  'Coonhound noir et feu',
  'Corgi',
  'Coton de Tuléar',
  'Croix Carea Leones',
  'Croix Kangal Turc',
  'Croix Utonagan',
  'Dachsbracke des Alpes',
  'Dalmatien',
  'Dandie Dinmont',
  'Deerhound écossais',
  'Dingo Australien',
  'Dobermann',
  'Dogue Allemand',
  'Dogue Brésilien',
  'Dogue de Bordeaux',
  'Doxiedoodle',
  'Doxiepoo',
  'Doxihuahua',
  'Drever',
  'Epagneul Allemand',
  'Epagneul Boykin',
  'Epagneul breton',
  'Epagneul de Picardie',
  'Epagneul de Springer',
  'Epagneul de Sussex',
  "Epagneul d'eau américain",
  "Epagneul d'eau Irlandais",
  'Epagneul du Tibet',
  'Epagneul Français',
  'Eurasier',
  'Fell Terrier',
  'Field Spaniel',
  'Fila Brasileiro',
  'Flat Coated Retriever',
  'Fox hound',
  'Fox Terrier',
  'Foxhound Américain',
  'Galgo Espanol',
  'Glen of Imaal Terrier',
  'Golden Retriever',
  'Gonczy Polski',
  'Grand Bouvier Suisse',
  'Grand Danois',
  'Griffon Bleu de Gascogne',
  'Griffon Bruxellois',
  'Griffon de Korthais',
  'Griffon de Nevers',
  'Griffon Fauve de Bretagne',
  'Groupe des chiens de chasse',
  'Harzer Fuchs',
  'Havane',
  'Heeler de Lancashire',
  'Hellenikos Poimenikos',
  'Highland Terrier',
  'Hokkaîdo',
  'Hovawart',
  'Huntaway',
  'Husky',
  "Husky d'Alaska",
  'Hybride de loup',
  "Indien d'Amérique",
  'Irish Wheaten Terrier',
  'Italiano Spinone',
  'Jack Chi',
  'Jack Russell',
  'Jack Russell Terrier',
  'Jackahuahua',
  'Jackapoo',
  'Jamthund',
  'Jindo Coréen',
  'Kangal Turc',
  'Karabash',
  "Karabash d'Anatolie",
  'Karakachan',
  'Keeshond',
  'Kelpie Australien',
  'Kerry Beagle',
  'Kerry Blue Terrier',
  "Kleekai d'Alaska",
  'Kokoni',
  'Kooikerhondje',
  'Kromfohrlander',
  'Kuvasz',
  'Kuvasz Hongrois',
  'Labradoodle',
  'Labradoodle Australien',
  'Labradoodle miniature',
  'Labrador',
  'Lagotto Romagnolo',
  'Laizhou Hong',
  'Lakeland Terrier',
  'Lapsk Vallhund',
  'Laufhund Suisse',
  'Leavitt Bulldog',
  'Leonberger',
  'Lévrier Afghan',
  'Lévrier Espagnol',
  'Lévrier Hongrois',
  'Lévrier Irlandais',
  'Lévrier Polonais',
  'Lévrier de course',
  'Lévrier Grec',
  'Lévrier Greyhound',
  'Lhasa Apso',
  'Liaka Sibérien',
  'Lowchen',
  'Lucas Terrier',
  'Lurcher',
  'Magyar Agar',
  "Malamute d'Alaska",
  'Mal-Shi',
  'Maltais',
  'Maltipoo',
  'Manchester Terrier',
  'Maremma',
  'Mastiff américain de Bandogge',
  'Mastiff Argentin',
  'Mastiff Brésilien',
  'Mastiff du Tibet',
  'Mastiff Espagnol',
  'Mastiff Napolitain',
  'Mastiff Pakistanais',
  'Mastiff Serbe',
  "Mastiff d'Afrique du Sud",
  'Mâtin indien',
  'Mexicain sans poil',
  'Mi-Ki',
  'Mudi Hongrois',
  'Munsterlander',
  'Nova Scotia Duck Tolling Retriever',
  'Olde English Bulldogge',
  'Otterhound',
  'Ovcharka du Caucase',
  'Papchi',
  'Papihuahua',
  'Papillon',
  'Pariah',
  'Patterdale Terrier',
  'Peagle',
  'Pekeapoo',
  'Pékinois',
  'Perro de Presa Canarios',
  'Perro Majorero',
  'Pinscher',
  'Pinscher Allemand',
  'Pit Bull',
  'Pit Bull Mastiff',
  'Pit Bull Terrier',
  'Pit Bull Terrier Américain',
  'Podenco Canario',
  'Podenco Enano',
  'Podengo portugais',
  'Pointer',
  'Pointer à poil dur',
  'Pointer Danois',
  'Pointer de Burgos',
  'Pomapoo',
  'Pomchi',
  'Poméranien',
  'Pomsky',
  'Poochon',
  'Presa Canarios',
  'Pugalier',
  'Pugapoo',
  'Puli Hongrois',
  'Queensland Heeler',
  'Rat Terrier',
  'Ratonero Bodeguero Andaluz',
  'Retriver à poil raide',
  'Rhodesian Ridgeback',
  'Rottweiler',
  'Russkiy Toy',
  'Sabueso Espanol',
  'Saluki',
  'Samoyède',
  'Sarplaninac yougoslave',
  'Schapendoes',
  'Schipperke',
  'Schnauzer',
  'Schnauzer miniature',
  'Schnauzer géant',
  'Schnoodle',
  'Scottish Terrier',
  'Sealyham Terrier',
  'Segugio Italiano',
  'Setter Anglais',
  'Setter Français',
  'Setter Gorden',
  'Setter Irlandais',
  'Setter Irlandais Rouger et Blanc',
  'Setters',
  'Shar Pei',
  'Sheepadoodle',
  'Sheepapoo',
  'Shetland Sheepdog',
  'Shi Chi',
  'Shiba Inu Japonais',
  'Shichon',
  'Shih Tzu',
  'Shih-Poo',
  'Shikoku',
  'Shorkie',
  'Siberian Husky',
  'Shye Terrier',
  'Sloughi',
  'Spaniel Field',
  'Spitz',
  'Spitz Allemand',
  'Spitz Finlandais',
  'Spitz japonais',
  'Springer Spaniel',
  'Springerdoodle',
  'Springerpoo',
  'Sprocker Spaniel',
  'Sproodle',
  'Saint Bernard',
  'Stabyhoun',
  'Staffordshire Bull Terrier',
  'Staffordshire Terrier Américain',
  'Teckel',
  'Teckel miniature',
  'Teckeldoodle',
  'Teckelpoo',
  'Terre-Neuve',
  'Terridoodle',
  'Terrier',
  'Terrier américain sans poil',
  'Terrier Anglais',
  'Terrier Anglais miniature',
  'Terrier Australien',
  'Terrier Belge',
  'Terrier Biewer',
  'Terrier Brésilien',
  'Terrier de Boston',
  'Terrier de Chasse Allemand',
  'Terrier de Norfolk',
  'Terrier de Norwich',
  'Terrier de Nuttall',
  'Terrier de Plummer',
  'Terrier du Tibet',
  'Terrier Irlandais',
  'Terrier soyeux',
  'Terrier Tchèque',
  'Terripoo',
  'Texas Heeler',
  'Thai Ridgeback',
  'Tosa',
  'Tosa Inus',
  'Tosa Japonais',
  'Utonagan',
  'Vallhund Suédois',
  'Vizsla Hongrois',
  'Volpino Italiano',
  'Weirmaraner',
  'Welsh Collie',
  'Welsh Terrier',
  'West Highland Terrier',
  'Wetterhoun',
  'Whippet',
  'Yorkie-Chi',
  'Yorkiehuahua',
  'Yorkiepoo',
  'Yorkshire Terrier (Miniature)',
  'Zuchon',
]

export const EditAnimalFurtherInfoModal: React.FC<Props> = ({ animal, setAnimal }) => {
  const [isModalOpened, setIsModalOpened] = React.useState(false)

  const onCancel = () => {
    setIsModalOpened(false)
  }

  const initialAnimalWeight =
    animal.formAndActivity && animal.formAndActivity?.weight
      ? animal.formAndActivity?.weight[animal.formAndActivity?.weight.length - 1]?.weightInKgs
      : undefined

  const onSubmit = async (data: FormData) => {
    animal.breed = {
      ...animal.breed,
      breed: animal.breed?.breed || '',
      breedTitle: animal.breed?.breedTitle || 'breed-type',
      isPedigreeRegistered: data.isPedigreeRegistered,
      registrationNumber: data.registrationNumber,
      numberOfAnimalsInLitter: Number(data.numberOfAnimalsInLitter),
    }
    animal.formAndActivity = {
      ...animal.formAndActivity,
      size: data.size,
      corpulence: data.corpulence,
      activity: data.activity,
      weight:
        animal.formAndActivity?.weight || data.weightInKgs
          ? [
              ...(animal.formAndActivity?.weight ?? []),
              ...(data.weightInKgs
                ? [{ weightInKgs: data.weightInKgs, dateOfWeightIn: dayjs().format('DD/MM/YYYY') }]
                : []),
            ]
          : [],
    }
    animal.identificationNumber = data.identificationNumber
    animal.tattoo = {
      number: data.tattooNumber,
      location: data.tattooLocation,
    }
    animal.health = {
      ...animal.health,
      sterilizedOrNeutered: data.sterilizedOrNeutered,
      FIV: data.FIV,
      FeLV: data.FeLV,
    }
    animal.breed = {
      ...animal.breed,
      breedTitle: data.isBreedMixed ? 'mixed-breed' : 'breed-type',
      breed: data.isBreedMixed ? `${data.fatherBreed} x ${data.motherBreed}` : data.breed,
      filiation: {
        fatherBreed: data.fatherBreed,
        motherBreed: data.motherBreed,
      },
    }
    animal.characteristics = data.characteristics
    animal.description = data.description

    const newAnimal = await AnimalProfilesClient.editAnimalProfile(animal)

    setAnimal(newAnimal)

    setIsModalOpened(false)
  }

  const getDefaultValues = (animal: AnimalProfileDto): FormData => ({
    isPedigreeRegistered: animal.breed?.isPedigreeRegistered || false,
    registrationNumber: animal.breed?.registrationNumber || '',
    numberOfAnimalsInLitter: animal.breed?.numberOfAnimalsInLitter?.toString() || '',
    size: animal.formAndActivity?.size || 'small',
    corpulence: animal.formAndActivity?.corpulence,
    activity: animal.formAndActivity?.activity,
    weightInKgs: initialAnimalWeight || '',
    identificationNumber: animal.identificationNumber || '',
    tattooNumber: animal.tattoo?.number || '',
    tattooLocation: animal.tattoo?.location,
    sterilizedOrNeutered: animal.health?.sterilizedOrNeutered || 'unknown',
    FIV: animal.health?.FIV || 'unknown',
    FeLV: animal.health?.FeLV || 'unknown',
    characteristics: animal.characteristics || [],
    description: animal.description || '',
    breed: animal.breed?.breed || '',
    isBreedMixed: animal.breed?.breedTitle === 'mixed-breed',
    fatherBreed: animal.breed?.filiation?.fatherBreed,
    motherBreed: animal.breed?.filiation?.motherBreed,
  })

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormExtended(animal, getDefaultValues)

  const options = [
    {
      title: 'Habitat et mode de vie',
      options: [
        'Convient à la vie en appartement',
        'Ne convient pas à la vie en appartement',
        'Convient à la vie en maison',
        'Ne convient pas à la vie en maison',
        'Hypoallergénique',
      ],
    },
    {
      title: 'Vie sociale',
      options: [
        'Entente avec les enfants',
        'Ne s’entend pas avec les enfants',
        'Entente avec les chiens',
        'Ne s’entend pas avec les chiens',
        'Entente avec les chats',
        'Ne s’entend pas avec les chats',
        'Entente avec les autres animaux',
        'Ne s’entend pas avec les autres animaux',
      ],
    },
    {
      title: 'Traits de caractère',
      options: ['Joueur', 'Calin', 'Craintif', 'Peureux', 'Agressif', 'Timide', 'Bruyant', 'Propre'],
    },
  ]

  const breedOptions =
    animal.species === 'cat'
      ? catBreeds.map((breed) => ({ label: breed, value: breed }))
      : dogBreeds.map((breed) => ({ label: breed, value: breed }))

  const watchIsBreedMixed = watch('isBreedMixed')

  return (
    <>
      <IconButton
        onClick={() => setIsModalOpened(true)}
        sx={{ border: '1px solid', backgroundColor: theme.palette.primary.main, color: 'white' }}
      >
        <Edit />
      </IconButton>
      <Modal open={isModalOpened} onClose={onCancel}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              maxWidth: '100%',
              width: '400px',
              maxHeight: '100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              padding: { xs: '10px', md: '24px' },
              borderRadius: { xs: 0, md: '8px' },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                overflowY: 'auto',
                height: '100%',
                width: '100%',
                pb: { xs: '10px', md: '24px' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  py: '8px',
                  px: '16px',
                  borderRadius: '5px',
                  gap: '14px',
                  backgroundColor: lightBlue,
                  height: '100%',
                  width: '100%',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
                  >
                    Description
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', pl: '20px' }}></Box>
                  <Field label="Description">
                    <TextField
                      aria-label="Description"
                      type="text"
                      placeholder="Médor est un super chien, je l'adores"
                      {...register('description')}
                      multiline
                      rows={4}
                      fullWidth
                      hiddenLabel
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: '100%',
                        },
                      }}
                    />
                  </Field>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  py: '8px',
                  px: '16px',
                  borderRadius: '5px',
                  gap: '14px',
                  backgroundColor: lightBlue,
                  height: '100%',
                  width: '100%',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
                  >
                    Race
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', pl: '20px' }}>
                    <Field label={`${animal.name} est il croisé ?`} required>
                      <ControlledToggleButton<FormData>
                        control={control}
                        fieldName="isBreedMixed"
                        error={errors.isBreedMixed}
                        requiredRule={undefined}
                        firstOptionLabel="Non"
                        secondOptionLabel="Oui"
                        firstOptionValue={false}
                        secondOptionValue={true}
                        fullWidth
                      />
                    </Field>
                    {watchIsBreedMixed ? (
                      <>
                        <Field label={`Race du père`}>
                          {animal.species === 'cat' || animal.species === 'dog' ? (
                            <ControlledAutoCompleteWithCustomValue<FormData>
                              control={control}
                              fieldName="fatherBreed"
                              error={errors.fatherBreed}
                              aria-label="Race du père"
                              requiredRule=""
                              options={breedOptions}
                              defaultValue=""
                            />
                          ) : (
                            <TextField
                              type="text"
                              {...register('fatherBreed')}
                              placeholder="Chartreux"
                              error={!!errors.fatherBreed}
                              helperText={errors.fatherBreed?.message}
                              fullWidth
                              hiddenLabel
                            />
                          )}
                        </Field>
                        <Field label={`Race de la mère`}>
                          {animal.species === 'cat' || animal.species === 'dog' ? (
                            <ControlledAutoCompleteWithCustomValue<FormData>
                              control={control}
                              fieldName="motherBreed"
                              error={errors.motherBreed}
                              aria-label="Race de la mère"
                              requiredRule=""
                              options={breedOptions}
                              defaultValue=""
                            />
                          ) : (
                            <TextField
                              type="text"
                              {...register('motherBreed')}
                              placeholder="Chartreux"
                              error={!!errors.motherBreed}
                              helperText={errors.motherBreed?.message}
                              fullWidth
                              hiddenLabel
                            />
                          )}
                        </Field>
                      </>
                    ) : (
                      <Field label={`Race de ${animal.name}`}>
                        {animal.species === 'cat' || animal.species === 'dog' ? (
                          <ControlledAutoCompleteWithCustomValue<FormData>
                            control={control}
                            fieldName="breed"
                            error={errors.breed}
                            aria-label="Race de l'animal"
                            requiredRule=""
                            options={breedOptions}
                            defaultValue=""
                          />
                        ) : (
                          <TextField
                            type="text"
                            {...register('motherBreed')}
                            placeholder="Chartreux"
                            error={!!errors.motherBreed}
                            helperText={errors.motherBreed?.message}
                            fullWidth
                            hiddenLabel
                          />
                        )}
                      </Field>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  py: '8px',
                  px: '16px',
                  borderRadius: '5px',
                  gap: '14px',
                  backgroundColor: lightBlue,
                  height: '100%',
                  width: '100%',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
                  >
                    Identité
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', pl: '20px' }}>
                    <Field label="Numéro de puce">
                      <TextField
                        aria-label="Numéro de puce"
                        type="text"
                        placeholder="250XXXXXXXXXXXX"
                        {...register('identificationNumber')}
                        error={!!errors.identificationNumber}
                        helperText={errors.identificationNumber?.message}
                        fullWidth
                        hiddenLabel
                      />
                    </Field>
                    <Field label="Numéro de tatouage">
                      <TextField
                        aria-label="Numéro de tatouage"
                        type="text"
                        placeholder="XXXXXX"
                        {...register('tattooNumber')}
                        error={!!errors.tattooNumber}
                        helperText={errors.tattooNumber?.message}
                        fullWidth
                        hiddenLabel
                      />
                    </Field>
                    <Field label="Emplacement du tatouage">
                      <ControlledSelectField
                        control={control}
                        fieldName="tattooLocation"
                        error={errors.tattooLocation}
                        requiredRule={undefined}
                        options={Object.values(TattooDtoLocation).map((status: TattooDtoLocation) => ({
                          label: getReadableTattooLocation(status),
                          value: status,
                        }))}
                        size="small"
                      />
                    </Field>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  py: '8px',
                  px: '16px',
                  borderRadius: '5px',
                  gap: '14px',
                  backgroundColor: lightBlue,
                  height: '100%',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
                  >
                    Forme et activité
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', pl: '20px' }}>
                    <Field label="Nouveau poids">
                      <TextField
                        aria-label="Nouveau poids"
                        type="number"
                        inputProps={{
                          step: '0.01',
                          inputMode: 'decimal',
                          pattern: '[0-9]*',
                        }}
                        fullWidth
                        {...register('weightInKgs')}
                        hiddenLabel
                        error={!!errors.tattooNumber}
                        helperText={"Le poids précédent sera conservé dans l'historique"}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                      />
                    </Field>
                    <Field label="Taille">
                      <ControlledSelectField
                        control={control}
                        fieldName="size"
                        error={errors.size}
                        requiredRule={undefined}
                        options={Object.values(AnimalFormAndActivityDtoSize).map(
                          (status: AnimalFormAndActivityDtoSize) => ({
                            label: getReadableAnimalSize(status),
                            value: status,
                          })
                        )}
                        size="small"
                      />
                    </Field>
                    <Field label="Corpulence">
                      <ControlledSelectField
                        control={control}
                        fieldName="corpulence"
                        error={errors.corpulence}
                        requiredRule={undefined}
                        options={Object.values(AnimalFormAndActivityDtoCorpulence).map(
                          (status: AnimalFormAndActivityDtoCorpulence) => ({
                            label: getReadableAnimalCorpulence(status),
                            value: status,
                          })
                        )}
                        size="small"
                      />
                    </Field>
                    <Field label="Activité">
                      <ControlledSelectField
                        control={control}
                        fieldName="activity"
                        error={errors.activity}
                        requiredRule={undefined}
                        options={Object.values(AnimalFormAndActivityDtoActivity).map(
                          (status: AnimalFormAndActivityDtoActivity) => ({
                            label: getReadableAnimalActivity(status),
                            value: status,
                          })
                        )}
                        size="small"
                      />
                    </Field>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  py: '8px',
                  px: '16px',
                  borderRadius: '5px',
                  gap: '14px',
                  backgroundColor: lightBlue,
                  height: '100%',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
                  >
                    Santé
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', pl: '20px' }}>
                    <Field label="Stérilisé/Castré">
                      <ControlledSelectField
                        control={control}
                        fieldName="sterilizedOrNeutered"
                        error={errors.sterilizedOrNeutered}
                        requiredRule={undefined}
                        options={Object.values(AnimalHealthDtoSterilizedOrNeutered).map(
                          (status: AnimalHealthDtoSterilizedOrNeutered) => ({
                            label: getReadableAnimalSterilizedOrNeutered(status),
                            value: status,
                          })
                        )}
                        size="small"
                      />
                    </Field>
                    <Field label="Test FIV">
                      <ControlledSelectField
                        control={control}
                        fieldName="FIV"
                        error={errors.FIV}
                        requiredRule={undefined}
                        options={Object.values(AnimalHealthDtoFIV).map((status: AnimalHealthDtoFIV) => ({
                          label: getReadableAnimalHealthTestStatus(status),
                          value: status,
                        }))}
                        size="small"
                      />
                    </Field>
                    <Field label="Test FeLV">
                      <ControlledSelectField
                        control={control}
                        fieldName="FeLV"
                        error={errors.FeLV}
                        requiredRule={undefined}
                        options={Object.values(AnimalHealthDtoFIV).map((status: AnimalHealthDtoFIV) => ({
                          label: getReadableAnimalHealthTestStatus(status),
                          value: status,
                        }))}
                        size="small"
                      />
                    </Field>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  py: '8px',
                  px: '16px',
                  borderRadius: '5px',
                  gap: '14px',
                  backgroundColor: lightBlue,
                  height: '100%',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
                  >
                    Portée et généalogie
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', pl: '20px' }}>
                    <Field label="Enregistrement LOF/LOOF">
                      <ControlledToggleButton<FormData>
                        control={control}
                        fieldName="isPedigreeRegistered"
                        error={errors.isPedigreeRegistered}
                        firstOptionLabel="Oui"
                        secondOptionLabel="Non"
                        firstOptionValue={true}
                        secondOptionValue={false}
                        requiredRule={undefined}
                        fullWidth
                      />
                    </Field>
                    <Field label="Numéro LOF/LOOF">
                      <TextField
                        aria-label="Numéro LOF/LOOF"
                        type="text"
                        placeholder="XXX XXXXXXXXXXXXX"
                        {...register('registrationNumber')}
                        error={!!errors.registrationNumber}
                        helperText={errors.registrationNumber?.message}
                        fullWidth
                        hiddenLabel
                      />
                    </Field>
                    <Field label="Numéro de l'animal dans la portée">
                      <TextField
                        aria-label="Numéro de l'animal dans la portée"
                        type="number"
                        {...register('numberOfAnimalsInLitter')}
                        error={!!errors.numberOfAnimalsInLitter}
                        helperText={errors.numberOfAnimalsInLitter?.message}
                        fullWidth
                        hiddenLabel
                      />
                    </Field>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  py: '8px',
                  px: '16px',
                  borderRadius: '5px',
                  gap: '14px',
                  backgroundColor: lightBlue,
                  height: '100%',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ fontSize: '16px', lineHeight: '19.2px', fontWeight: 700, color: '#333333' }}
                  >
                    Caractéristiques
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', pl: '20px' }}>
                    <ControlledMultipleFreeSoloAutoCompleteWithCategories
                      control={control}
                      fieldName="characteristics"
                      label="Caractéristiques"
                      options={options}
                      color="#333333"
                      error={undefined}
                      requiredRule={undefined}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: { xs: '10px', md: '24px' },
                mb: { xs: '32px' },
              }}
            >
              <Button variant="text" onClick={onCancel} sx={{ fontSize: '18px' }}>
                Annuler
              </Button>
              <LoadingButton type="submit" variant="contained" loading={false} sx={{ fontSize: '18px' }}>
                Enregistrer
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  )
}
