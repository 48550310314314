import React from 'react'

/**
 * Recursively clone and replace text in React nodes.
 *
 * @param {React.ReactNode} node - The React node to process.
 * @param {string} searchText - The text to search for.
 * @param {string} replaceWith - The text to replace with.
 * @returns {React.ReactNode} The node with replaced text.
 */
export function replaceTextInNode(node: React.ReactNode, searchText: string, replaceWith: string): React.ReactNode {
  if (typeof node === 'string') {
    // Replace text in strings
    return node.replaceAll(searchText, replaceWith)
  }

  if (typeof node === 'number' || typeof node === 'boolean' || node === null) {
    // Return non-string primitives as is
    return node
  }

  if (Array.isArray(node)) {
    // Recursively process array elements
    return node.map((child) => replaceTextInNode(child, searchText, replaceWith))
  }

  if (React.isValidElement(node)) {
    // Recursively process React elements' children
    return React.cloneElement(node, node.props, replaceTextInNode(node.props.children, searchText, replaceWith))
  }

  return node // Return other types unmodified
}
