import { ArrowBackIos, GpsFixed, Phone } from '@mui/icons-material'
import { Box, Button, Chip, IconButton, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { pdfjs } from 'react-pdf'
import { useNavigate, useParams } from 'react-router-dom'
import { getReadableHealthObservationsType } from '../../domain/Animal/AnimalDisplay'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { ServiceLocationsClient } from '../../interactors/clients/ServiceLocationsClient'
import { VeterinaryClient } from '../../interactors/clients/VeterinaryClient'
import {
  AnimalProfileDto,
  AnimalProfileHealthObservationsDto,
  ServiceLocationWithProvidersNamesDto,
} from '../../interactors/gen/backendClient'
import { getObservationPhotoOrVideoSrc } from '../../utils/S3-links'
import { fromDayNumberToName } from '../../utils/date/fromDayNumbeToName'
import { fromMonthNumberToName } from '../../utils/date/fromMonthNumberToName'
import { getIconForObservationType } from '../../utils/getIconForObservation'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { colors, lightYellow, theme } from '../theme'
import { Comment } from './Comment'
import { AddComment } from './AddComment'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

export const SuiviObservationDetailsScreen: React.FC = () => {
  const [observation, setObservation] = React.useState<AnimalProfileHealthObservationsDto | null>(null)
  const [animal, setAnimal] = React.useState<AnimalProfileDto | null>(null)
  const [veterinaryName, setVeterinaryName] = React.useState<string>('Vétérinaire inconnu(e)')
  const [externalVeterinary, setExternalVeterinary] = React.useState<ServiceLocationWithProvidersNamesDto | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const { observationId, id } = useParams<{ observationId: string; id: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchVeterinary = async (observation: AnimalProfileHealthObservationsDto) => {
      // Ensure observation and veterinary object exist
      if (!observation || !observation.veterinary) return

      const veterinaryId = observation.veterinary.id
      const locationId = observation.veterinary.locationId

      if (!veterinaryId) return

      try {
        // If veterinaryId starts with 'A-', it's a Petso veterinary
        if (veterinaryId && veterinaryId.startsWith('A-')) {
          const veterinary = await VeterinaryClient.getVeterinaryById(veterinaryId)
          setVeterinaryName(`Docteur ${veterinary.firstName} ${veterinary.lastName}`)
        }
        // If locationId is provided, it indicates an external veterinary linked to a service location
        else if (locationId && locationId.startsWith('L-')) {
          const clinicExport = await ServiceLocationsClient.getLocation(locationId)
          // Assuming observation.veterinary.id is the id of the veterinary in the external clinic
          const veterinary = clinicExport.serviceProvidersNames.find((vet) => vet.id === veterinaryId)

          if (veterinary) {
            setVeterinaryName(`Docteur ${veterinary.firstName} ${veterinary.lastName}`)
            setExternalVeterinary(clinicExport)
          }
        }
      } catch (error) {
        console.error('Error fetching veterinary information:', error)
        // Handle errors or set state accordingly if needed
      }
    }

    const fetchObservationAndVeterinary = async () => {
      try {
        if (!id || !observationId) return

        const animal = await AnimalProfilesClient.getAnimalByMongoId(id)
        const observation = animal.health.observations?.find((op) => op.id === observationId)

        if (animal && observation) {
          setAnimal(animal)
          setObservation(observation)
          await fetchVeterinary(observation) // Ensure this is called after setting the observation
        }
      } catch (error) {
        console.error('Error fetching observation information:', error)
        // Handle errors or set state accordingly if needed
      } finally {
        setIsLoading(false)
      }
    }

    fetchObservationAndVeterinary()
  }, [id, observationId, observation?.veterinary?.id])

  if (isLoading || !animal || !observation) return <CircularProgressPanel />

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20, overflowY: 'auto' }}>
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          mb: 10,
          p: 2,
          pb: 8,
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: '24px',
            color: colors.black,
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: '8px',
              px: '16px',
              borderRadius: '8px',
              gap: '10px',
              backgroundColor: colors.lightGrey,
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '16px',
                  color: theme.palette.secondary.main,
                }}
              >
                {getIconForObservationType(observation.type[0])}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '12px',
                    color: colors.black,
                  }}
                >
                  {observation.type.map((type) => (
                    <Chip
                      key={type}
                      sx={{ backgroundColor: lightYellow }}
                      label={getReadableHealthObservationsType(type)}
                    />
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '18px',
                  color: colors.black,
                }}
              >
                <Typography>{`${fromDayNumberToName(dayjs(observation.date).day())} ${dayjs(observation.date)
                  .format('DD/MM/YYYY')
                  .split('/', 1)} ${fromMonthNumberToName(dayjs(observation.date).month())} ${
                  dayjs(observation.date).format('DD/MM/YYYY').split('/', 3)[2]
                }`}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', py: '8px', px: '16px', width: '100%' }}>
          <Typography sx={{ fontSize: '18px' }}>{`Notes`}</Typography>
          <TextField
            multiline
            placeholder="Notes"
            value={observation.additionalInfo}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '100%',
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            py: '8px',
            px: '16px',
            borderRadius: '8px',
            gap: '16px',
            backgroundColor: colors.lightGrey,
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', width: '100%' }}>
            <Typography sx={{ fontSize: '24px' }}>👨‍⚕️</Typography>
            <Typography sx={{ fontSize: '20px' }}>{veterinaryName}</Typography>
          </Box>
          {externalVeterinary && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '18px', width: '100%' }}>
              <Typography sx={{ fontSize: '16px' }}>{externalVeterinary.name}</Typography>
            </Box>
          )}
          {externalVeterinary && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '12px',
                width: '100%',
              }}
            >
              <Typography
                sx={{ fontSize: '16px' }}
              >{`${externalVeterinary.postalCode}, ${externalVeterinary.city}`}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '32px' }}>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                  component="a"
                  href={`tel:${externalVeterinary.phoneNumber}`}
                >
                  <Phone />
                </Button>

                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                  component="a"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${externalVeterinary.postalCode}+${externalVeterinary.city}+${externalVeterinary.address}`}
                  target="_blank" // Opens in a new tab
                >
                  <GpsFixed />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
            borderRadius: '8px',
            p: '10px',
            backgroundColor: lightYellow,
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          {observation.documentKey && observation.documentKey.startsWith('image') ? (
            <img
              src={getObservationPhotoOrVideoSrc(observation.documentKey)}
              alt="Photo de l'observation"
              style={{ width: '100%', maxWidth: '600px', height: 'auto' }}
            />
          ) : observation.documentKey && observation.documentKey.startsWith('video') ? (
            <video
              src={getObservationPhotoOrVideoSrc(observation.documentKey)}
              controls
              style={{ width: '100%', maxWidth: '600px', height: 'auto' }}
            />
          ) : (
            <Typography sx={{ fontSize: '16px' }}>{`Aucun document associé`}</Typography>
          )}
        </Box>
        {observation.comments && observation.comments.length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', py: '8px', width: '100%' }}>
            <Typography sx={{ fontSize: '18px' }}>{`Compte rendu`}</Typography>

            {observation.comments.map((comment, index) => (
              <Comment key={`${comment.name}-${index}`} comment={comment} />
            ))}
          </Box>
        )}
        <AddComment
          type="observation"
          animalId={animal.id}
          id={observationId!}
          setAnimal={setAnimal}
          setObservation={setObservation}
        />
      </Box>
    </Box>
  )
}
