import { DocusealForm } from '@docuseal/react'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { backendClient } from '../../interactors/clients/client'
import { AdoptionAttemptDto } from '../../interactors/gen/backendClient'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { useNavigate } from 'react-router-dom'
import { metricalpEvent } from '@metricalp/react'

export interface MetricalpInfo {
  animalName?: string
  animalId?: string
  adopterId?: string
  adopterName?: string
}

interface Props {
  attempt: AdoptionAttemptDto
  email: string | undefined
  type: 'certificate' | 'contract'
  fullySetAdoptionAttempt: (attempt: AdoptionAttemptDto) => void
  metricalpInfo: MetricalpInfo
}

export const DocumentSignCard: React.FC<Props> = ({ attempt, type, email, fullySetAdoptionAttempt, metricalpInfo }) => {
  const navigate = useNavigate()
  const getDocuSealSlug = useMutation(
    (attemptId: string) =>
      backendClient.get(`adopters-platform/accounts/adoption-attempts/docuseal/${attemptId}?type=${type}`),
    {
      onSuccess: (response) => {
        console.log(response.data)
        fullySetAdoptionAttempt(response.data)
      },
    }
  )

  const sendAssignationIdToBackend = useMutation(
    (submissionId: string) =>
      backendClient.post(`adopters-platform/accounts/adoption-attempts/docuseal/${attempt.id}?type=${type}`, {
        submissionId,
      }),
    {
      onSuccess: (response) => {
        console.log(response.data)
        metricalpEvent({
          type: `adoption_action`,
          action: `done_${type}`,
          adopterId: metricalpInfo.adopterId,
          adopterName: metricalpInfo.adopterName,
          animalName: metricalpInfo.animalName,
          animalId: metricalpInfo.animalId,
        })
        if (type === 'contract' && (attempt.paymentSentByUser || attempt.paymentDone)) {
          navigate(`/adoptions/${attempt.id}`)
        } else {
          navigate(`/adoptions/${attempt.id}?congrats=${type}`)
        }

        fullySetAdoptionAttempt(response.data)
      },
    }
  )

  const docuSealSlug = attempt[type]?.docuSealSlug

  useEffect(() => {
    if (!docuSealSlug) {
      getDocuSealSlug.mutate(attempt.id)
    }
  }, [])

  if (getDocuSealSlug.isLoading || sendAssignationIdToBackend.isLoading) {
    return <CircularProgressPanel absolute={false} />
  }

  return docuSealSlug ? (
    <DocusealForm
      src={`https://docuseal.co/d/${docuSealSlug}`}
      email={email}
      logo="/apple-touch-icon.png"
      withTitle={false}
      onComplete={async (data) => {
        await sendAssignationIdToBackend.mutateAsync(data.submission_id)
      }}
      customCss=".label { font-size: 1.2rem; } .border-yellow-200 { display: none; } .bottom-0 { bottom: 10px; } .space-x-1 { display: none } .bg-red-100 { pointer-events: none; } .btn-neutral { background-color: #F3B700; border-color: #F3B700; } .base-button { background-color: #F3B700; border-color: #F3B700; } .whitespace-nowrap { white-space: nowrap; text-overflow: ellipsis; overflow: hidden; width: 100%; }"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        maxHeight: 'calc(100vh - 60px)',
      }}
      withDownloadButton={false}
      withSendCopyButton={false}
    />
  ) : (
    <CircularProgressPanel absolute={false} />
  )
}
