import { Box, FormControl, SxProps, TextField } from '@mui/material'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import React from 'react'
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form'
import { Field } from '../../common/FormField'
import { FormParams } from '../AdoptionFormScreen'

export interface StepsProps {
  control: Control<FormParams>
  register: UseFormRegister<FormParams>
  errors: FieldErrors<FormParams>
  fieldSx?: SxProps
}

export const StepOne: React.FC<StepsProps> = ({ control, register, errors, fieldSx }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'start', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'start', width: '50%' }}>
          <Field label="Prénom" required>
            <TextField
              aria-label="Prénom"
              type="text"
              placeholder="Votre prénom"
              {...register('firstName', { required: 'Le prénom est requis' })}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              required
              hiddenLabel
              sx={fieldSx}
            />
          </Field>

          <Field label="Téléphone" required>
            <FormControl variant="outlined" sx={fieldSx} required>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  validate: (value: string | undefined) => {
                    console.log(value)
                    return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                      ? true
                      : 'Tel is invalid'
                  },
                }}
                render={({ field, fieldState }) => (
                  <MuiTelInput
                    {...field}
                    aria-label="Numero de Téléphone"
                    defaultCountry="FR"
                    helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                    error={!!fieldState.error}
                    hiddenLabel
                    sx={fieldSx}
                    required
                  />
                )}
              />
            </FormControl>
          </Field>
          <Field label="Adresse" required>
            <TextField
              aria-label="Adresse"
              placeholder="Votre adresse"
              {...register('address')}
              hiddenLabel
              sx={fieldSx}
              required
            />
          </Field>

          <Field label="Code Postal" required>
            <TextField
              aria-label="Code Postal"
              placeholder="Votre code postal"
              {...register('postalCode')}
              hiddenLabel
              sx={fieldSx}
              required
            />
          </Field>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'start', width: '50%' }}>
          <Field label="Nom" required>
            <TextField
              aria-label="Nom"
              placeholder="Votre nom"
              {...register('lastName')}
              hiddenLabel
              required
              sx={fieldSx}
            />
          </Field>
          <Box sx={{ height: '77.81px' }}></Box>
          <Field label="Ville" required>
            <TextField
              aria-label="Ville"
              placeholder="Votre ville"
              {...register('city')}
              hiddenLabel
              sx={fieldSx}
              required
            />
          </Field>
          <Field label="Pays" required>
            <TextField
              aria-label="Pays"
              placeholder="Votre pays"
              {...register('country')}
              hiddenLabel
              sx={fieldSx}
              required
            />
          </Field>
        </Box>
      </Box>
    </Box>
  )
}
