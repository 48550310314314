import { AssistantDirection, EventAvailable, Mail, Phone } from '@mui/icons-material'
import { Avatar, Box, Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ServiceLocationsClient } from '../../interactors/clients/ServiceLocationsClient'
import { ServiceProvidersClient } from '../../interactors/clients/ServiceProvidersClient'
import { ServiceLocationWithProvidersNamesDto, ServiceProviderDto } from '../../interactors/gen/backendClient'
import { getPartnerLogoSrc } from '../../utils/S3-links'
import { ExpandableCard } from '../Shopping/ExpandableCard'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { lightGrey } from '../theme'

export const ServiceLocationScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [location, setLocation] = React.useState<ServiceLocationWithProvidersNamesDto | null>(null)
  const [provider, setProvider] = React.useState<ServiceProviderDto | null>(null)

  useEffect(() => {
    const fetchLocation = async () => {
      if (!id) return
      const location = await ServiceLocationsClient.getLocation(id)
      if (location && location.serviceProviders && location.serviceProviders.length > 0) {
        // Assuming `getProvider` function exists and works correctly with the first provider's id
        const provider = await ServiceProvidersClient.getProvider(location.serviceProviders[0])

        // Update state with fetched data
        if (provider) {
          setProvider(provider) // Assuming this sets the state for a provider
        }
      }
      setLocation(location)
    }
    fetchLocation()
  }, [id])

  if (!location) return <CircularProgressPanel />

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          mb: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            backgroundColor: lightGrey,
            py: 4,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
              px: 2,
            }}
          >
            <Avatar
              sx={{ width: '72px', height: '72px' }}
              src={location.logo ? getPartnerLogoSrc(location.logo) : ''}
              alt={location.name}
              title={location.name}
            />
            <Typography
              variant="h1"
              sx={{ fontWeight: 'bold', textAlign: 'center', color: 'secondary.main', fontSize: '28px' }}
            >
              {location.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'space-around',
              width: '100%',
              px: 1,
              mt: 2,
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              sx={{
                borderRadius: '10px',
                p: '10px',
                px: '18px',
                minWidth: '0',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                width: '25%',
              }}
              component="a"
              href={`tel:${location.phoneNumber}`}
              disabled={!location.phoneNumber}
            >
              <Phone />
              <Typography sx={{ fontSize: '12px' }}>Appeler</Typography>
            </Button>

            <Button
              color="secondary"
              variant="contained"
              sx={{
                borderRadius: '10px',
                p: '10px',
                px: '18px',
                minWidth: '0',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                width: '25%',
              }}
              component="a"
              href={`mailto:${location.email}`}
              target="_blank" // Opens in a new tab
              disabled={!location.email}
            >
              <Mail />
              <Typography sx={{ fontSize: '12px' }}>Email</Typography>
            </Button>

            <Button
              color="secondary"
              variant="contained"
              sx={{
                borderRadius: '10px',
                p: '10px',
                px: '18px',
                minWidth: '0',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                width: '25%',
              }}
              component="a"
              href={`https://www.google.com/maps/dir/?api=1&destination=${location.postalCode}+${location.city}+${location.address}`}
              target="_blank" // Opens in a new tab
              disabled={!location.address}
            >
              <AssistantDirection />
              <Typography sx={{ fontSize: '12px' }}>Itinéraire</Typography>
            </Button>

            <Button
              color="secondary"
              variant="contained"
              sx={{
                borderRadius: '10px',
                p: '10px',
                px: '18px',
                minWidth: '0',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                width: '25%',
              }}
              component="a"
              disabled={!location.bookingLink}
              href={`${location.bookingLink}`}
              target="_blank" // Opens in a new tab
            >
              <EventAvailable />
              <Typography sx={{ fontSize: '12px' }}>Réserver</Typography>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            px: 2,
            width: '100%',
          }}
        >
          {((location.address && location.postalCode && location.city) || location.schedules) && (
            <ExpandableCard title="Informations" defaultExpanded>
              {location.schedules && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 2 }}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Horaires</Typography>
                  <Typography>{location.schedules}</Typography>
                </Box>
              )}
              {location.address && location.postalCode && location.city && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 2 }}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Adresse</Typography>
                  <Typography>{location.address}</Typography>
                  <Typography>{`${location.postalCode} ${location.city}`}</Typography>
                </Box>
              )}
            </ExpandableCard>
          )}
          {provider?.presentation && (
            <ExpandableCard title="Présentation">
              <Typography sx={{ mt: 2 }}>
                {provider.presentation.split('\n').map((paragraph) => (
                  <>
                    {paragraph}
                    <br />
                    <br />
                  </>
                ))}
              </Typography>
            </ExpandableCard>
          )}
          {provider?.description && (
            <ExpandableCard title="Méthodes">
              <Typography sx={{ mt: 2 }}>
                {provider.description.split('\n').map((paragraph) => (
                  <>
                    {paragraph}
                    <br />
                    <br />
                  </>
                ))}
              </Typography>
            </ExpandableCard>
          )}
          {provider?.pricing && (
            <ExpandableCard title="Tarifs et services associés">
              <ul>
                {provider.pricing.map((price) => (
                  <li key={price.key + price.value}>
                    <Typography>{`${price.key}${price.value ? ':' : ''} ${price.value}`}</Typography>
                  </li>
                ))}
              </ul>
            </ExpandableCard>
          )}
          {location.serviceProvidersNames && (
            <ExpandableCard title="Membres de l'équipe">
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
                {location.serviceProvidersNames.map((provider) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', width: '100%' }} key={provider.id}>
                    <Typography sx={{ fontSize: '24px' }}>{getEmojiForCategory(location.type)}</Typography>
                    <Typography sx={{ fontSize: '20px' }}>
                      {provider.firstName} {provider.lastName}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </ExpandableCard>
          )}
        </Box>
      </Box>
    </Box>
  )
}

function getEmojiForCategory(category: string): string {
  switch (category) {
    case 'veterinary':
      return '👨‍⚕️'
    case 'behaviorist':
      return '🐾'
    default:
      return category
  }
}
