import { ArrowForwardIos } from '@mui/icons-material'
import { Box, Chip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getReadableOperationType } from '../../domain/Animal/AnimalDisplay'
import { VeterinaryClient } from '../../interactors/clients/VeterinaryClient'
import { AnimalProfileHealthOperationDto } from '../../interactors/gen/backendClient'
import { fromDayNumberToName } from '../../utils/date/fromDayNumbeToName'
import { fromMonthNumberToName } from '../../utils/date/fromMonthNumberToName'
import { getIconForOperationType } from '../../utils/getIconForOperation'
import { colors, lightBlue, theme } from '../theme'
import { ServiceLocationsClient } from '../../interactors/clients/ServiceLocationsClient'

interface Props {
  operation: AnimalProfileHealthOperationDto
}

export const AnimalHealthOperationItem: React.FC<Props> = ({ operation }) => {
  const [veterinaryName, setVeterinaryName] = React.useState<string>('')
  useEffect(() => {
    const getVeterinaryName = async () => {
      // Check if operation.veterinary exists
      if (operation.veterinary && operation.veterinary.id) {
        try {
          if (operation.veterinary.id.startsWith('A-')) {
            // Fetch veterinary by ID if it starts with 'A-'
            const veterinary = await VeterinaryClient.getVeterinaryById(operation.veterinary.id)
            setVeterinaryName(`👨‍⚕️ ${veterinary.firstName} ${veterinary.lastName}`)
          } else if (operation.veterinary.locationId.startsWith('L-')) {
            // Assuming this function exists and fetches data based on some criteria
            const clinicExport = await ServiceLocationsClient.getLocation(operation.veterinary.locationId)
            const veterinary = clinicExport.serviceProvidersNames.find((vet) => vet.id === operation.veterinary?.id)

            if (veterinary) {
              setVeterinaryName(`👨‍⚕️ ${veterinary.firstName} ${veterinary.lastName}`)
            } else {
              // If no veterinary found in clinicExport
              setVeterinaryName('Vétérinaire inconnu(e)')
            }
          }
        } catch (error) {
          console.error('Error fetching veterinary information:', error)
          setVeterinaryName('Vétérinaire inconnu(e)') // Fallback to unknown if any fetch fails
        }
      } else {
        // If operation.veterinary doesn't exist
        setVeterinaryName('Vétérinaire inconnu(e)')
      }
    }

    getVeterinaryName()
  }, [operation.veterinary]) // Dependency array ensures this effect runs whenever operation.veterinary changes

  const navigate = useNavigate()

  return (
    <Box
      onClick={() => navigate(`./operation/${operation.id}`)}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: '8px',
        px: '16px',
        borderRadius: '5px',
        gap: '10px',
        border: '1px solid',
        borderColor: colors.lightGrey,
        backgroundColor: lightBlue,
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
          backgroundColor: '#E3E6F0', // Lighter shade for hover state
          borderColor: '#D0D3D8', // Slightly darker border on hover
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            color: theme.palette.secondary.main,
          }}
        >
          {getIconForOperationType(operation.type[0])}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
              color: colors.black,
            }}
          >
            <Chip sx={{ backgroundColor: colors.lightGrey }} label={getReadableOperationType(operation.type[0])} />
            <Typography>{operation.type.length > 1 ? `+ ${operation.type.length - 1}` : ''}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '18px',
            color: colors.black,
          }}
        >
          <Typography>{`${fromDayNumberToName(dayjs(operation.date, 'DD/MM/YYYY').day())} ${operation.date.split(
            '/',
            1
          )} ${fromMonthNumberToName(dayjs(operation.date, 'DD/MM/YYYY').month())} ${
            operation.date.split('/', 3)[2]
          }`}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '12px',
            color: colors.black,
          }}
        >
          <Typography>{veterinaryName}</Typography>
        </Box>
      </Box>
      <ArrowForwardIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
    </Box>
  )
}
