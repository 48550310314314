import { Box, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as PlusIcon } from '../icons/PlusIcon.svg'
import { useNavigate } from 'react-router-dom'

export const AddNewAnimal: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Box
      onClick={() => navigate('./ajouter-un-animal')}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '98px',
        border: '1px dashed rgba(224, 224, 224, 1)',
        borderRadius: '8px',
        p: '16px',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
        <Box
          sx={{ height: '66px', width: '66px', border: '0.97px dashed rgba(32, 56, 100, 1)', borderRadius: '100%' }}
        ></Box>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          Ajouter mon animal
        </Typography>
      </Box>
      <PlusIcon />
    </Box>
  )
}
