import React, { useEffect, useState } from 'react'
import { useAccountsStore } from '../../store/AccountsStore'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { findGoodAnimalProfileURL } from '../../utils/findGoodAnimalProfilURL'
import { Avatar, Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { getEmojiAnimalSex } from '../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../domain/Animal/calculateAge'
import { useNavigate } from 'react-router-dom'
import { AddCircle } from '@mui/icons-material'

interface Props {
  icon?: boolean
}

export const SelectAnimalForBeRealModal: React.FC<Props> = ({ icon }) => {
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const account = accountsStore.connectedAccount

  const [isModalOpened, setIsModalOpened] = useState(false)

  const [animals, setAnimals] = useState<AnimalProfileDto[]>([])
  const [loading, setLoading] = useState(true)

  const onClose = () => {
    setIsModalOpened(false)
  }

  const onOpen = () => {
    if (animals.length === 1) return navigate('/actualites/ajouter-une-actualite?animalId=' + animals[0].id)
    setIsModalOpened(true)
  }

  useEffect(() => {
    const fetchAnimalProfiles = async () => {
      const animalProfiles = await AnimalProfilesClient.getAllAnimalProfilesByAdopterId()
      setAnimals(animalProfiles)
      setLoading(false)
    }

    fetchAnimalProfiles()
  }, [])

  if (loading) return <CircularProgressPanel />

  if (!account) return null
  return (
    <>
      {icon ? (
        <IconButton onClick={() => onOpen()} sx={{ p: 0 }}>
          <AddCircle sx={{ color: 'white', fontSize: 32 }} />
        </IconButton>
      ) : (
        <Button variant="contained" color="primary" onClick={() => onOpen()}>
          Poster un PetReal
        </Button>
      )}
      <Modal open={isModalOpened} onClose={onClose}>
        <Box
          sx={{
            maxWidth: '100%',
            width: '400px',
            maxHeight: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            padding: '24px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '24px',
            overflowY: 'auto',
          }}
        >
          <Typography variant="h2" sx={{ width: '100%' }}>
            Choisissez un animal pour lequel poster un PetReal:
          </Typography>
          {animals.map((animal) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/actualites/ajouter-une-actualite?animalId=' + animal.id)}
              key={animal.id}
            >
              <Avatar
                src={animal.images.profileImageKey ? findGoodAnimalProfileURL(animal.images.profileImageKey) : ''}
                alt={animal.name}
                sx={{ height: '120px', width: '120px', borderRadius: '11.25px', maxWidth: '120px' }}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  gap: '4px',
                  width: '120px',
                  maxWidth: '100%',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="h1" component="p" fontSize={22}>
                    {animal.name}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant="h1"
                    component="p"
                    color={animal.sex === 'female' ? 'pink' : 'blue'}
                    fontWeight={700}
                    fontSize={22}
                  >
                    {animal.sex ? getEmojiAnimalSex(animal.sex) : ''}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography
                      variant="body2"
                      component="p"
                      fontSize={18}
                      fontWeight={500}
                      fontStyle="italic"
                      lineHeight={1.6}
                    >
                      {animal?.birthday?.date ? calculateAge(animal.birthday.date) : ''}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', mt: 2 }}>
            <Button onClick={onClose}>Annuler</Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
