import { useEffect } from 'react'
import { AuthService } from '../../interactors/services/AuthService'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAccountsStore } from '../../store/AccountsStore'
import { CircularProgressPanel } from '../common/CircularProgressPanel'

const AuthRedirect = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const membersStore = useAccountsStore()

  useEffect(() => {
    const { search } = location

    try {
      AuthService.loginWithGoogle(search).then((account) => {
        if (account) {
          membersStore.changeConnectedAccount(account)
        }
        navigate('/')
      })
    } catch (error) {
      console.error('Error fetching auth token:', error)
      navigate('/')
    }
  }, [])

  return <CircularProgressPanel />
}

export default AuthRedirect
