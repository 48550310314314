import { useEffect } from 'react'
import { useForm as useRHF, FieldValues, DeepPartial, UseFormReturn } from 'react-hook-form'

export function useFormExtended<FormParams extends FieldValues, Entity>(
  entity: Entity,
  getDefaultValues: (entity: Entity) => FormParams
): UseFormReturn<FormParams> {
  const useFormReturn = useRHF<FormParams>({
    defaultValues: getDefaultValues(entity) as DeepPartial<FormParams>,
    mode: 'onBlur',
  })

  useEffect(() => {
    useFormReturn.reset(getDefaultValues(entity))
  }, [entity, useFormReturn.reset])

  return useFormReturn
}
