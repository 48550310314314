import {
  FormControl,
  FormHelperText,
  SxProps,
  Theme,
  InputLabel,
  MenuItem,
  Select,
  BaseTextFieldProps,
  Chip,
  Box,
} from '@mui/material'
import { PropsWithChildren, useState } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'

interface Option {
  label: string
  value: string | number
}

type Props<T extends FieldValues> = {
  sx?: SxProps<Theme>
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Array<Option>
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  color: 'error' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning'
  fullWidth?: boolean
}

export function ControlledMultipleSelectField<T extends FieldValues>({
  sx,
  control,
  options,
  error,
  fieldName,
  label,
  requiredRule,
  size,
  disabled,
  color,
  fullWidth = false,
}: PropsWithChildren<Props<T>>) {
  const [menuOpen, setMenuOpen] = useState(false)
  const labelId = `${fieldName}_label`

  return (
    <FormControl fullWidth={fullWidth} variant="outlined" sx={{ width: '300px', ...sx }}>
      <InputLabel htmlFor={labelId} error={!!error} size={size === 'small' ? 'small' : 'normal'}>
        {label}
      </InputLabel>
      <Controller
        name={fieldName}
        control={control}
        rules={{ required: requiredRule }}
        defaultValue={[] as any} // TODO: check this
        render={({ field: { onChange, value } }) => (
          <Select
            multiple
            value={value}
            onChange={(e) => {
              onChange(e)
              setMenuOpen(false)
            }}
            open={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
            label={label}
            labelId={labelId}
            error={!!error}
            required={Boolean(requiredRule)}
            size={size}
            disabled={disabled}
            style={{ height: '48px' }}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: string) => {
                  // Find the corresponding option object based on the value
                  const option = options.find((option) => option.value === value)
                  // If an option is found, use its label; otherwise, use the value as a fallback
                  const label = option ? option.label : value
                  return <Chip key={value} label={label} color={color} />
                })}
              </Box>
            )}
          >
            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
    </FormControl>
  )
}
