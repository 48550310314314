import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getProductImageSrc } from '../../utils/S3-links'
import { theme } from '../theme'
import { Add, AddShoppingCart, ArrowForwardIos, Remove } from '@mui/icons-material'
import { useBasketStore } from '../../store/BasketStore'

interface Props {
  product: {
    title: string
    images: string[]
    reference: string
    price: number
    brand: string
    deliveryPrice?: number
    bestSale?: boolean
    subscription?: boolean
  }
  animalId?: string
  noRedirect?: boolean
}

export const ProductCard: React.FC<Props> = ({ product, animalId, noRedirect = false }) => {
  const navigate = useNavigate()
  const basketStore = useBasketStore()
  const splittedPrice = product.price.toString().split('.')

  const basket = basketStore.basket
  const item = basket.find((item) => item.reference === product.reference && animalId === item.animalId)

  const handleAddMore = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    basketStore.addOrUpdateItem({
      reference: product.reference,
      quantity: 1,
      price: product.price,
      animalId: animalId || '',
    })
  }

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    basketStore.reduceItemQuantity(product.reference, 1, animalId || '', undefined)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '124px',
        backgroundColor: 'white',
        cursor: 'pointer',
      }}
      onClick={() => {
        if (noRedirect) return
        window.scrollTo(0, 0)
        navigate(`/produits/${product.reference}${animalId ? `?animalId=${animalId}` : ''}`)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          gap: 2,
          width: '100%',
          height: '100%',
        }}
      >
        <img
          src={getProductImageSrc(product.images[0])}
          alt={product.title}
          style={{ height: '124px', width: '115px', maxHeight: '100%', objectFit: 'contain', borderRadius: '11px' }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          {product.bestSale && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 'fit-content',
                clipPath: 'polygon(0% 0%, 88% 0%, 100% 50%, 88% 100%, 0% 100%)',
                backgroundColor: theme.palette.primary.main,
                pr: '22px',
                pl: '6px',
                py: '2px',
                fontSize: '13px',
                fontWeight: 500,
              }}
            >
              #1 Meilleure vente
            </Box>
          )}
          <Typography variant="body1" sx={{ fontWeight: 700, fontSize: '19px', textAlign: 'left', color: 'black' }}>
            {`${splittedPrice[0]}`}
            <span style={{ fontSize: '14px' }}>
              {splittedPrice[1] ? `,${splittedPrice[1].length === 1 ? `${splittedPrice[1]}0` : splittedPrice[1]}` : ''}
            </span>
            {` €`}
          </Typography>
          <Typography variant="h2" sx={{ fontWeight: 700, fontSize: '18px', textAlign: 'left', color: 'black' }}>
            {product.title}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', textAlign: 'left', color: 'black' }}>
            {product.brand}
          </Typography>
        </Box>
        {noRedirect ? (
          item ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column-reverse',
                alignItems: 'center',
                gap: '2px',
                backgroundColor: 'white',
              }}
            >
              <IconButton onClick={(e) => handleRemove(e)}>
                <Remove sx={{ fontSize: 24, borderRadius: '100%', border: '1px solid' }} />
              </IconButton>
              <Typography variant="body1" component="p" sx={{ fontSize: '13px', fontWeight: 700 }}>
                {item?.quantity}
              </Typography>
              <IconButton onClick={(e) => handleAddMore(e)}>
                <Add sx={{ fontSize: 24, borderRadius: '100%', border: '1px solid' }} />
              </IconButton>
            </Box>
          ) : (
            <IconButton color="secondary" onClick={(e) => handleAddMore(e)}>
              <AddShoppingCart sx={{ fontSize: 26 }} />
            </IconButton>
          )
        ) : (
          <ArrowForwardIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
        )}
      </Box>
    </Box>
  )
}
