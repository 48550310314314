import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Fidelami from '../images/fidelami.png'
import { AdoptionInfoBox } from '../Adoptions/AdoptionInfoBox'
import { ItemCard } from './ItemCard'
import { ItemPopIn } from './ItemPopIn'
import { theme } from '../theme'
import products from '../../products.json'
import { getProductImageSrc } from '../../utils/S3-links'

type Item = {
  ReferenceNumber: string
  Name: string
  EAN: string
  Price: string
  Images: string[]
  Included: boolean
  Description: string
  Composition: string
  Dimensions: string
  EducationPoint: string
  USP?: string | undefined
  Color?: string | undefined
}

export type ItemForCart = {
  referenceNumber: string
  quantity: number
}

const INCLUDED_ITEMS_CAT: ItemForCart[] = [
  { referenceNumber: '170053', quantity: 1 },
  { referenceNumber: '170393', quantity: 1 },
  { referenceNumber: '170142', quantity: 1 },
]

export const WelcomePack: React.FC = () => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [selectedItem, setSelectedItem] = useState<Item | null>(null)
  const [step, setStep] = useState(1)
  const [cart, setCart] = useState<ItemForCart[]>(INCLUDED_ITEMS_CAT)
  const navigate = useNavigate()

  console.log(cart)

  const handleReturnClick = () => {
    if (step > 1) return setStep(step - 1)
    navigate(-1)
  }

  const handleAddToCart = (referenceNumber: Item['ReferenceNumber'], quantity: number) => {
    const cartItem = cart.find((cartItem) => cartItem.referenceNumber === referenceNumber)
    if (cartItem) {
      cartItem.quantity = quantity
      setCart([...cart])
    } else {
      setCart([...cart, { referenceNumber: referenceNumber, quantity: quantity }])
    }
  }

  // When user is on step 3, save cart in local storage
  useEffect(() => {
    if (step === 3) {
      localStorage.setItem('cart', JSON.stringify(cart))
      navigate('/panier/recapitulatif')
    }
  }, [step])

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', mb: 6, p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography
            variant="body2"
            sx={{ fontSize: '14px', lineHeight: '16.8px', fontWeight: 400, color: 'black', cursor: 'pointer' }}
            onClick={handleReturnClick}
          >
            {`< Retour`}
          </Typography>
          <Typography variant="h1">Welcome pack</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '40%', my: 2, gap: '8px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
                justifyContent: 'space-between',
              }}
            >
              <img src={Fidelami} height="120px" />
              <Typography variant="body2" sx={{ fontSize: '16px', lineHeight: '19.2px', color: '#696B6D' }}>
                {step === 1
                  ? 'Nous vous proposons une suggestion personnalisée d’équipements indispensables pour favoriser l’arrivée d’alfonzo dans votre famille'
                  : 'Vous pouvez maintenant choisir tous les articles que vous souhaitez ajouter à votre welcome pack'}
              </Typography>
            </Box>
            {step === 1 && <AdoptionInfoBox message="Les articles suivants sont inclus dans le welcome pack." />}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '16px',
              justifyContent: 'space-between',
            }}
          >
            {products.kitChat.Products.filter((product) => product.Included === (step === 1)).map((product) => (
              <ItemCard
                key={product.ReferenceNumber}
                name={product.Name}
                price={product.Price}
                included={product.Included || false}
                picture={<img src={product.Images[0] ? getProductImageSrc(product.Images[0]) : ''} height="120px" />}
                description={product.Description}
                quantity={cart.find((cartItem) => cartItem.referenceNumber === product.ReferenceNumber)?.quantity || 0}
                referenceNumber={product.ReferenceNumber}
                addToCart={handleAddToCart}
                onClick={() => {
                  setSelectedItem(product)
                  setIsModalOpened(true)
                }}
              />
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                px: '20px',
                py: '13px',
                borderRadius: '200px',
                color: theme.palette.secondary.main,
                fontSize: '16px',
                lineHeight: '19.2px',
                fontWeight: 600,
                boxShadow: 'none',
              }}
              onClick={() => setStep(step + 1)}
            >
              {step === 1 ? 'Continuer' : 'Valider'}
            </Button>
          </Box>
        </Box>
        <ItemPopIn
          key={selectedItem?.ReferenceNumber}
          popIn={selectedItem}
          quantity={cart.find((cartItem) => cartItem.referenceNumber === selectedItem?.ReferenceNumber)?.quantity || 0}
          isModalOpened={isModalOpened}
          addToCart={handleAddToCart}
          onCancel={() => {
            setSelectedItem(null)
            setIsModalOpened(false)
          }}
        />
      </Box>
    </Box>
  )
}
