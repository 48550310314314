import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'
import { requestFirebaseMessagingToken } from '../firebase-init'
import { useNotificationModalOpenStore } from '../store/NotificationModalOpenStore'

const NotificationModal: React.FC = () => {
  const notificationModalOpenStore = useNotificationModalOpenStore()
  console.log('test', notificationModalOpenStore.isOpen)
  const handleEnableNotifications = () => {
    requestFirebaseMessagingToken()
    notificationModalOpenStore.setIsOpen(false) // Close modal after enabling notifications
  }

  return (
    <Dialog open={notificationModalOpenStore.isOpen} onClose={() => notificationModalOpenStore.setIsOpen(false)}>
      <DialogTitle>{'Activer les notifications'}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {`Pour améliorer votre expérience et vous tenir informé des dernières mises à jour, nous vous recommandons
          d'activer les notifications.`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => notificationModalOpenStore.setIsOpen(false)} color="primary">
          Plus tard
        </Button>
        <Button onClick={handleEnableNotifications} color="primary" autoFocus>
          Activer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NotificationModal
