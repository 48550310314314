import { ArrowBackIos, Cancel, Check, Delete, GpsFixed, Phone } from '@mui/icons-material'
import { Box, Button, Chip, IconButton, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { pdfjs } from 'react-pdf'
import { useNavigate, useParams } from 'react-router-dom'
import { getReadableHealthObservationsType } from '../../domain/Animal/AnimalDisplay'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { ServiceLocationsClient } from '../../interactors/clients/ServiceLocationsClient'
import { VeterinaryClient } from '../../interactors/clients/VeterinaryClient'
import { backendClient } from '../../interactors/clients/client'
import {
  AnimalProfileDto,
  AnimalProfileHealthObservationsDto,
  ServiceLocationWithProvidersNamesDto,
} from '../../interactors/gen/backendClient'
import { getObservationPhotoOrVideoSrc } from '../../utils/S3-links'
import { fromDayNumberToName } from '../../utils/date/fromDayNumbeToName'
import { fromMonthNumberToName } from '../../utils/date/fromMonthNumberToName'
import { getAdviceForHealthObservationsType } from '../../utils/getAdviceForObservation'
import { getIconForObservationType } from '../../utils/getIconForObservation'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { SoberCollapsible } from '../common/SoberCollapsible'
import { colors, lightYellow, theme } from '../theme'
import { EditObservationDetailsModal } from './EditObservationDetailsModal'
import { AddFileToObservation } from './AddFileToObservation'
import { GetShareLink } from './GetShareLink'
import { Comment } from '../SuiviVeto/Comment'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

type FormData = {
  additionalInfo?: string
}

export const ObservationDetailsScreen: React.FC = () => {
  const [observation, setObservation] = React.useState<AnimalProfileHealthObservationsDto | null>(null)
  const [animal, setAnimal] = React.useState<AnimalProfileDto | null>(null)
  const [veterinaryName, setVeterinaryName] = React.useState<string>('Vétérinaire inconnu(e)')
  const [externalVeterinary, setExternalVeterinary] = React.useState<ServiceLocationWithProvidersNamesDto | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isDeleteMode, setIsDeleteMode] = React.useState(false)
  const { observationId, animalId } = useParams<{ observationId: string; animalId: string }>()
  const navigate = useNavigate()

  const getDefaultValues = (observation: AnimalProfileHealthObservationsDto | null): FormData => ({
    additionalInfo: observation?.additionalInfo || '',
  })

  const { register, watch, handleSubmit, setValue } = useFormExtended(observation, getDefaultValues)

  const onSubmit = async (data: FormData) => {
    if (!animal || !observation) return

    const newObservation: AnimalProfileHealthObservationsDto = {
      ...observation,
      additionalInfo: data.additionalInfo,
    }

    const animalWithUpdatedObservation = {
      ...animal,
      health: {
        ...animal.health,
        observations: animal.health.observations
          ? animal.health.observations.map((op) => (op.id === observation.id ? newObservation : op))
          : [newObservation],
      },
    }

    const newAnimal = await AnimalProfilesClient.editAnimalProfile(animalWithUpdatedObservation)

    setAnimal(newAnimal)
    setObservation(newObservation)
  }

  // Delete documentKey from the actual observation
  const onConfirmDeleteFile = async () => {
    if (!animal || !observation) return

    const newObservation: AnimalProfileHealthObservationsDto = {
      ...observation,
      documentKey: '',
    }

    const animalWithUpdatedObservation = {
      ...animal,
      health: {
        ...animal.health,
        observations: animal.health.observations
          ? animal.health.observations.map((op) => (op.id === observation.id ? newObservation : op))
          : [newObservation],
      },
    }

    const newAnimal = await AnimalProfilesClient.editAnimalProfile(animalWithUpdatedObservation)

    setAnimal(newAnimal)
    setObservation(newObservation)
    setIsDeleteMode(false)
  }

  const handlePetsoConversationClick = async () => {
    const newConversation = await backendClient.post('/adopters-platform/conversations', {
      receiverId: 'B-3403',
    })
    const conversationId = newConversation.data.id
    navigate(`/chat?id=${conversationId}`)
  }

  useEffect(() => {
    const fetchVeterinary = async (observation: AnimalProfileHealthObservationsDto) => {
      // Ensure observation and veterinary object exist
      if (!observation || !observation.veterinary) return

      const veterinaryId = observation.veterinary.id
      const locationId = observation.veterinary.locationId

      if (!veterinaryId) return

      try {
        // If veterinaryId starts with 'A-', it's a Petso veterinary
        if (veterinaryId && veterinaryId.startsWith('A-')) {
          const veterinary = await VeterinaryClient.getVeterinaryById(veterinaryId)
          setVeterinaryName(`Docteur ${veterinary.firstName} ${veterinary.lastName}`)
        }
        // If locationId is provided, it indicates an external veterinary linked to a service location
        else if (locationId && locationId.startsWith('L-')) {
          const clinicExport = await ServiceLocationsClient.getLocation(locationId)
          // Assuming observation.veterinary.id is the id of the veterinary in the external clinic
          const veterinary = clinicExport.serviceProvidersNames.find((vet) => vet.id === veterinaryId)

          if (veterinary) {
            setVeterinaryName(`Docteur ${veterinary.firstName} ${veterinary.lastName}`)
            setExternalVeterinary(clinicExport)
          }
        }
      } catch (error) {
        console.error('Error fetching veterinary information:', error)
        // Handle errors or set state accordingly if needed
      }
    }

    const fetchObservationAndVeterinary = async () => {
      try {
        if (!animalId || !observationId) return

        const animal = await AnimalProfilesClient.getAnimalById(animalId)
        const observation = animal.health.observations?.find((op) => op.id === observationId)

        if (animal && observation) {
          setAnimal(animal)
          setObservation(observation)
          await fetchVeterinary(observation) // Ensure this is called after setting the observation
        }
      } catch (error) {
        console.error('Error fetching observation information:', error)
        // Handle errors or set state accordingly if needed
      } finally {
        setIsLoading(false)
      }
    }

    fetchObservationAndVeterinary()
  }, [animalId, observationId, observation?.veterinary?.id])

  const watchAdditionalInfo = watch('additionalInfo')

  if (isLoading || !animal || !observation) return <CircularProgressPanel />

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          mb: 10,
          p: 2,
          pb: 8,
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: '24px',
            color: colors.black,
          }}
        >
          <IconButton onClick={() => navigate(`/mes-animaux/${animalId}/?tab=appointments`)}>
            <ArrowBackIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: '8px',
              px: '16px',
              borderRadius: '8px',
              gap: '10px',
              backgroundColor: colors.lightGrey,
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '16px',
                  color: theme.palette.secondary.main,
                }}
              >
                {getIconForObservationType(observation.type[0])}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '12px',
                    color: colors.black,
                  }}
                >
                  {observation.type.map((type) => (
                    <Chip
                      key={type}
                      sx={{ backgroundColor: lightYellow }}
                      label={getReadableHealthObservationsType(type)}
                    />
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '18px',
                  color: colors.black,
                }}
              >
                <Typography>{`${fromDayNumberToName(dayjs(observation.date).day())} ${dayjs(observation.date)
                  .format('DD/MM/YYYY')
                  .split('/', 1)} ${fromMonthNumberToName(dayjs(observation.date).month())} ${
                  dayjs(observation.date).format('DD/MM/YYYY').split('/', 3)[2]
                }`}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: '30px',
                borderLeft: '2px solid black',
                display: 'flex',
                borderLeftColor: theme.palette.secondary.main,
                color: theme.palette.secondary.main,
                height: '60px',
                maxHeight: '100%',
              }}
            >
              <EditObservationDetailsModal
                animal={animal}
                observation={observation}
                setAnimal={setAnimal}
                setObservation={setObservation}
                veterinaryName={veterinaryName}
              />
            </Box>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', py: '8px', px: '16px', width: '100%' }}>
            <Typography sx={{ fontSize: '18px' }}>{`Notes`}</Typography>
            <TextField
              multiline
              placeholder="Notes"
              {...register('additionalInfo')}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '100%',
                },
              }}
            />
            {watchAdditionalInfo !== observation.additionalInfo && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setValue('additionalInfo', observation.additionalInfo)
                  }}
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                >
                  <Cancel />
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                >
                  <Check />
                </Button>
              </Box>
            )}
          </Box>
        </form>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            borderRadius: '8px',
            p: '10px',
            backgroundColor: lightYellow,
          }}
        >
          <Typography sx={{ fontSize: '18px' }}>{`Une question ? Nous sommes là pour vous aider`}</Typography>
          <Chip
            label={`Contacter l'équipe Petso`}
            clickable
            sx={{
              backgroundColor: 'white',
              fontSize: '15px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);',
              cursor: 'pointer',
            }}
            onClick={() => handlePetsoConversationClick()}
          />
          <GetShareLink animalId={animal.id} animalName={animal.name} chip />
          {observation.type.map((type) => (
            <SoberCollapsible key={type} noLeftPadding title={getReadableHealthObservationsType(type)}>
              <Typography variant="body2" sx={{ fontSize: '16px' }}>
                {getAdviceForHealthObservationsType(type)}
              </Typography>
            </SoberCollapsible>
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            py: '8px',
            px: '16px',
            borderRadius: '8px',
            gap: '16px',
            backgroundColor: colors.lightGrey,
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', width: '100%' }}>
            <Typography sx={{ fontSize: '24px' }}>👨‍⚕️</Typography>
            <Typography sx={{ fontSize: '20px' }}>{veterinaryName}</Typography>
          </Box>
          {externalVeterinary && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '18px', width: '100%' }}>
              <Typography sx={{ fontSize: '16px' }}>{externalVeterinary.name}</Typography>
            </Box>
          )}
          {externalVeterinary && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '12px',
                width: '100%',
              }}
            >
              <Typography
                sx={{ fontSize: '16px' }}
              >{`${externalVeterinary.postalCode}, ${externalVeterinary.city}`}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '32px' }}>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                  component="a"
                  href={`tel:${externalVeterinary.phoneNumber}`}
                >
                  <Phone />
                </Button>

                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: '10px',
                    p: '10px',
                    minWidth: '0',
                  }}
                  component="a"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${externalVeterinary.postalCode}+${externalVeterinary.city}+${externalVeterinary.address}`}
                  target="_blank" // Opens in a new tab
                >
                  <GpsFixed />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
            borderRadius: '8px',
            p: '10px',
            backgroundColor: lightYellow,
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          {isDeleteMode ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '16px',
                width: '100%',
              }}
            >
              <Typography sx={{ fontSize: '18px' }}>{`Confirmer la suppression ?`}</Typography>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <IconButton color="secondary" onClick={() => setIsDeleteMode(false)}>
                  <Cancel />
                </IconButton>
                <IconButton color="primary" onClick={onConfirmDeleteFile}>
                  <Check />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '16px',
                width: '100%',
              }}
            >
              <Typography sx={{ fontSize: '18px' }}>{`Photo/Vidéo`}</Typography>
              {observation.documentKey &&
              (observation.documentKey.startsWith('image') || observation.documentKey.startsWith('video')) ? (
                <IconButton color="error" onClick={() => setIsDeleteMode(true)}>
                  <Delete />
                </IconButton>
              ) : null}
            </Box>
          )}

          {observation.documentKey && observation.documentKey.startsWith('image') ? (
            <img
              src={getObservationPhotoOrVideoSrc(observation.documentKey)}
              alt="Photo de l'observation"
              style={{ width: '100%', maxWidth: '400px', height: 'auto' }}
            />
          ) : observation.documentKey && observation.documentKey.startsWith('video') ? (
            <video
              src={getObservationPhotoOrVideoSrc(observation.documentKey)}
              controls
              style={{ width: '100%', maxWidth: '400px', height: 'auto' }}
            />
          ) : (
            <AddFileToObservation animalId={animalId!} observationId={observationId!} setObservation={setObservation} />
          )}
        </Box>
        {observation.comments && observation.comments.length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', py: '8px', width: '100%' }}>
            <Typography sx={{ fontSize: '18px' }}>{`Compte rendu`}</Typography>

            {observation.comments.map((comment, index) => (
              <Comment key={`${comment.name}-${index}`} comment={comment} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}
