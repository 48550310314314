import { Box, Typography } from '@mui/material'
import React from 'react'
import { LovysMainContent } from './LovysMainContent'
import { KozooMainContent } from './KozooMainContent'
import { FelomeMainContent } from './FelomeMainContent'
import { AlformeMainContent } from './AlformeMainContent'

export type BrandCollapse = {
  title: string
  content: React.ReactNode
}

export type Brand = {
  name: string
  logoSrc: string
  imgSrc: string
  title: string
  description: React.ReactNode
  mainContent?: React.ReactNode
  collapses?: BrandCollapse[]
}

export const Brands: Brand[] = [
  {
    name: 'kozoo',
    logoSrc: 'https://www.kozoo.eu/wp-content/themes/kozoo/assets/images/kozoo-assurance-chien-chat-digitale.svg',
    imgSrc: 'https://animals-photos.s3.amazonaws.com/2023-04-05+(1).jpg',
    title: "Prévenez les risques avec l'assurance Kozoo",
    description: (
      <Typography
        component="p"
        sx={{ color: 'black', fontSize: '16px' }}
      >{`N’attendez pas que votre animal soit malade pour couvrir ses frais de santé et protéger votre animal pour tous les coups durs. Kozoo couvre les frais vétérinaires liés aux maladies, accidents et hospitalisations : consultations, médicaments, analyses médicales, chirurgie. `}</Typography>
    ),
    mainContent: <KozooMainContent />,
    collapses: [
      {
        title: 'Assurance Kozoo à la carte',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              Configurez votre assurance sur mesure sans franchise et sans frais cachés, en fonction de :
            </Typography>
            <ol style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px' }}>
              <li>La couverture d’assurance de 50% à 100% de prise en charge</li>
              <li>
                Du plafond de remboursement annuel (jusqu’à 3000€ remboursés par an) de vos frais vétérinaires sur les
                maladies et les accidents.
              </li>
            </ol>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              En option un Capital Décès et une Responsabilité Civile sont aussi proposés
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Comment ça marche',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <ol style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px' }}>
              <li>
                Choisissez l’offre qui vous convient le mieux en fonction du niveau de couverture, des plafonds de
                remboursement et des options.
              </li>
              <li>
                Souscrivez facilement en ligne : un RIB et le numéro d’identification de votre animal est simplement
                requis
              </li>
              <li>
                Vous êtes remboursé sur la base des documents/justificatifs transmis en ligne, sans feuille de soins
              </li>
              <li>
                {
                  'Koozo s’engage à vous verser les fonds sur votre compte en 48h (sauf pour les factures > 600€ qui nécessitent un examen approfondi)'
                }
              </li>
            </ol>
          </Box>
        ),
      },
    ],
  },
  {
    name: 'lovys',
    logoSrc: 'https://i.imgur.com/LauKVLb.png',
    imgSrc: 'https://www.lovys.com/fr/wp-content/uploads/sites/2/cache/2022/09/pets_newwebsite_large-1366x768-q80.png',
    title: "Prévenez les risques avec l'assurance Lovys",
    description: (
      <Typography
        component="p"
        sx={{ color: 'black', fontSize: '16px' }}
      >{`N’attendez pas que votre animal soit malade pour couvrir ses frais de santé et protéger votre animal pour tous les coups durs. Lovys couvre les frais vétérinaires liés aux maladies, accidents et hospitalisations : consultations, médicaments, analyses médicales, chirurgie. `}</Typography>
    ),
    mainContent: <LovysMainContent />,
    collapses: [
      {
        title: 'Assurance Lovys à la carte',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              Configurez votre assurance sur mesure sans franchise et sans frais cachés, en fonction de :
            </Typography>
            <ol style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px' }}>
              <li>La couverture d’assurance de 60% à 80% de prise en charge</li>
              <li>
                Du plafond de remboursement annuel (jusqu’à 1500€ remboursés par an) de vos frais vétérinaires sur les
                maladies et les accidents.
              </li>
            </ol>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px', fontWeight: 700 }}>
              En option, vous pouvez aussi souscrire à
            </Typography>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px' }}>
              <li>
                Une garantie obsèque-décès : quand le pire arrive, nous sommes là pour vous aider. Lovys vous décharge
                d’une partie des frais d’obsèques de votre animal.
              </li>
              <li>
                Une garantie alimentation thérapeutique: Lovys rembourse l’alimentation thérapeutique de votre animal
                jusqu’à 200€/an.
              </li>
              <li>
                Une garantie frais de recherche: En cas de perte ou de vol de votre animal, nous participons aux frais
                de recherche à hauteur de 250€.
              </li>
            </ul>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              En option un les soins préventifs peuvent être couvert à hauteur de 100€/an
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Comment ça marche',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <ol style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px' }}>
              <li>
                Choisissez l’offre qui vous convient le mieux en fonction du niveau de couverture, des plafonds de
                remboursement et des options (prise en charge des soins préventif en option jusqu’à 100€/an).
              </li>
              <li>
                Souscrivez facilement en ligne : un RIB et le numéro d’identification de votre animal est simplement
                requis
              </li>
              <li>
                Vous êtes remboursé sur la base des documents/justificatifs transmis en ligne, sans feuille de soins
              </li>
              <li>{'Lovys s’engage à vous verser les fonds sur votre compte en 72h*'}</li>
            </ol>
          </Box>
        ),
      },
    ],
  },
  {
    name: 'felome',
    logoSrc: 'https://www.felome.fr/wp-content/uploads/2023/04/logo-et-nom-compagnie-1.png',
    imgSrc: 'https://i.imgur.com/9B0pSU3.jpeg',
    title: 'Le bilan génétique pour votre animal',
    description: (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
          {`Anticiper ses besoins de santé de votre chat avec le bilan génétique pour votre animal à la maison.`}
        </Typography>
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px', fontStyle: 'italic' }}>
          <li>Dépistage de plus de 70 variants de maladies génétiques,</li>
          <li>Analyse de plus de 30 couleurs et traits,</li>
          <li>Analyse de proximité de plus de 20 races.</li>
        </ul>
        <Typography component="p" sx={{ color: 'black', fontSize: '16px', fontStyle: 'italic' }}>
          Anticipez les risques de maladies de votre chat et assurez lui la meilleure vie !
        </Typography>
        <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
          Profitez de 30€ offert sur l’achat d’un test pendant l’adoption - 120€ au lieu de 150€ !
        </Typography>
      </Box>
    ),
    mainContent: <FelomeMainContent />,
    collapses: [
      {
        title: 'Présentation de Felome',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              Felome est un laboratoire dédié à la santé de votre chat. Avec plus de 15 ans d’expérience en biologie
              moléculaire, leur amour de la science est aussi fort que leur amour pour les chats. Le laboratoire de
              Felome est situé en France avec le soutien du biocluster Genopole. Sa principale motivation est d’offrir
              aux propriétaires d’animaux de compagnie la possibilité de mieux connaître et de prendre soin de leurs
              petits compagnons grâce au séquençage ADN.
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Comment ça marche',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              Felome permet avec un simple échantillon de salive prélevé à la maison de dépister plusieurs risques de
              maladies et traits héréditaires :
            </Typography>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px' }}>
              <li>Maladies rénales et urinaires</li>
              <li>Myocardiopathie</li>
              <li>Maladies oculaires</li>
              <li>Troubles métaboliques et endocriniens</li>
              <li>Troubles sanguins et immunitaires</li>
              <li>Troubles musculosquelettiques</li>
              <li>Groupe sanguin</li>
              <li>Sensibilité médicamenteuse</li>
              <li>Couleur du pelage</li>
              <li>Motif du pelage</li>
              <li>Texture du pelage</li>
              <li>Longueur du pelage</li>
              <li>Morphologie</li>
            </ul>
            <Typography
              component="a"
              href="https://www.felome.fr/wp-content/uploads/2023/12/FELOME-panel-version-08-12-2023.pdf"
              target="_blank"
              sx={{ color: 'black', fontSize: '16px' }}
            >
              En savoir plus sur la liste des marqueurs génétiques testés par Felome
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Comment effectuer le test ?',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px', fontWeight: 700 }}>
              Felome envoie un kit de prélèvement de salive directement à votre domicile !
            </Typography>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              Le kit est composé d’une notice, d’un swab buccal, d’un étui de protection et d’une enveloppe retour
              préaffranchie.
              <br />
              Vous pouvez effectuer le test seul chez vous, vous avez simplement à retourner le kit par courrier et vous
              recevrez les résultats par mail et directement depuis l’onglet Petso document de votre animal.
              <br />
              Le rapport du bilan génétique Felome contient l’ensemble des maladies et traits génétiques recherchés avec
              toutes les explications pour le comprendre !
            </Typography>
          </Box>
        ),
      },
    ],
  },
  {
    name: 'alforme',
    logoSrc: 'https://i.imgur.com/Mc0XzCF.png',
    imgSrc: 'https://i.imgur.com/Mc0XzCF.png',
    title: 'Formation Alforme',
    description: (
      <Typography
        component="p"
        sx={{ color: 'black', fontSize: '16px' }}
      >{`Suivez en toute autonomie la formation en ligne dédiée aux premiers secours canin et félin. D'une durée de 3h en e-learning, elle permet à qui le souhaite de se former rapidement et efficacement au secourisme animalier.  Cette formation est idéale pour toute personne souhaitant acquérir des compétences en premiers secours spécifiques aux chiens et aux chats. Soyez prêt à sauver des vies avec des techniques adaptées et des conseils d'experts.`}</Typography>
    ),
    mainContent: <AlformeMainContent />,
    collapses: [
      {
        title: "Présentation d'Alforme",
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              ALFORME est un organisme de formation et de conseils qui propose diverses formations destinées aux
              vétérinaires, assistants vétérinaires, aux professionnels animaliers (éducateurs canin, éleveurs, maître
              chien, petsitters...) ainsi qu’au grand public. Ces formations couvrent des sujets tels que la
              physiothérapie, le coaching au bien-être canin et le secourisme canin et félin. Alforme combine des
              approches pédagogiques théoriques et pratiques, guidés par l’expertise vétérinaire au centre de toutes ses
              actions.
              <br />
              Alforme est un organisme de formation certifié Qualiopi
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Les Conseils Véto Alforme N°1 - Réaliser un bilan de santé',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/qdY-PHsl8tw?si=Dh6m2ALLDmCtfkZ2"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </Box>
        ),
      },
      {
        title: 'Cibles et pré-requis',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              Cette formation est idéale pour toute personne souhaitant acquérir des compétences en premiers secours
              spécifiques aux chiens et aux chats. Aucun prérequis n’est nécessaire, juste une volonté d’apprendre à
              prendre soin de vos animaux en cas d’urgence.
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Objectifs',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px' }}>
              <li>
                <b>Identifier et reconnaître les urgences vitales :</b> Apprenez à détecter rapidement les situations
                qui menacent la vie de vos chiens et chats.
              </li>
              <li>
                <b>Maîtriser les gestes de premiers secours :</b> Acquérir les connaissances et les réflexes nécessaires
                pour intervenir efficacement lors d’urgences vitales
              </li>
            </ul>
          </Box>
        ),
      },
      {
        title: 'Méthodologie (pédagogie et évaluation)',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px' }}>
              <li>
                La formation se déroule entièrement en ligne, combinant des modules interactifs et des démonstrations
                vidéo pour une expérience d’apprentissage complète. Vous serez guidé à travers chaque module par des
                simulations qui renforcent la théorie par des exemples visuels concrets.
              </li>
              <li>
                <b>Évaluation :</b> Un quiz à choix multiples (QCM) est utilisé pour évaluer vos connaissances tout au
                long de la formation.
              </li>
            </ul>
          </Box>
        ),
      },
      {
        title: 'Au programme de la formation',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '18px' }}>
              <li>L’Animal de Compagnie et le Secourisme</li>
              <li>La Chaîne de Secours</li>
              <li>Secourir : La Gestion des Situations d’Urgence</li>
              <li>La Trousse de 1er Secours</li>
              <li>Auto-évaluation finale</li>
            </ul>
            <a
              href="https://petso-partner-logos.s3.eu-west-3.amazonaws.com/PSCF-4.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Télécharger la plaquette/programme
            </a>
          </Box>
        ),
      },
      {
        title: 'Lieu',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              En ligne.
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Durée et horaires',
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              La durée de la formation est de 3 h 00.
            </Typography>
          </Box>
        ),
      },
      {
        title: "Modalités d'inscription",
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
            <Typography component="p" sx={{ color: 'black', fontSize: '16px' }}>
              Inscrivez-vous et payez via Petso pour bénéficier d’une réduction partenaire. Nous transmettrons ensuite
              vos coordonnées à Alforme, qui créera votre accès à la plateforme d’e-learning dans les 24 heures suivant
              le règlement (jours ouvrés).
            </Typography>
          </Box>
        ),
      },
    ],
  },
]
