import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@mui/material'
import React from 'react'
import { colors, theme } from '../theme'
import { Close } from '@mui/icons-material'

interface AdoptionModalProps {
  open: boolean
  handleClose: () => void
  handleNext: () => void
  type: 'tutorial' | 'certificate' | 'contract'
  animalName: string
  customerName: string
  useVariant?: boolean
}

export const AdoptionCongratulationsModal: React.FC<AdoptionModalProps> = ({
  open,
  handleClose,
  handleNext,
  type,
  animalName,
  useVariant = false,
}) => {
  const Tutorial = (
    <>
      {/* Header Box */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        <span style={{ fontSize: '72px' }}>🎉</span>
        <Typography variant="h5" sx={{ color: 'black', fontWeight: 700, fontSize: 22 }}>
          Tutoriel terminé !
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          Vous avez vu les points clés du certificat d&apos;engagement et de connaissance.
        </Typography>
      </Box>

      {/* Text Content */}

      <Box
        sx={{
          backgroundColor: colors.blueSky,
          color: 'white',
          fontSize: '22px',
          fontWeight: 700,
          width: 'fit-content',
          padding: '8px',
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        À quoi sert-il ?
      </Box>

      <Typography variant="body1" sx={{ fontSize: 19, textAlign: 'center' }}>
        Ce certificat est légalement obligatoire pour sensibiliser les adoptants et lutter contre l&apos;abandon des
        animaux.
      </Typography>

      <Typography
        variant="body1"
        sx={{ textAlign: 'left', fontWeight: 700, color: theme.palette.secondary.main, fontSize: 19 }}
      >
        Vous bénéficiez d&apos;un délai légal de 8 jours pour le signer
      </Typography>
    </>
  )

  const Certificate = (
    <>
      {/* Header Box */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <span style={{ fontSize: '72px' }}>🎉</span>
        <Typography variant="h5" sx={{ color: 'black', fontWeight: 700, textAlign: 'center', fontSize: 22 }}>
          Certificat d&apos;engagement signé !
        </Typography>
      </Box>

      {/* Text Content */}
      <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
        Retrouvez vos documents signés dans le profil de {animalName}
      </Typography>

      <Box
        sx={{
          backgroundColor: colors.blueSky,
          color: 'white',
          fontSize: '22px',
          fontWeight: 700,
          width: 'fit-content',
          padding: '8px',
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        Prochaine étape ?
      </Box>

      <Typography
        variant="body1"
        sx={{ mb: 2, textAlign: 'center', fontWeight: 700, color: theme.palette.secondary.main, fontSize: 19 }}
      >
        Signer le contrat et régler les frais d&apos;adoption de {animalName}.
      </Typography>
    </>
  )

  const CertificateVariant = (
    <>
      {/* Header Box */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <span style={{ fontSize: '72px' }}>🎉</span>
        <Typography variant="h5" sx={{ color: 'black', fontWeight: 700, textAlign: 'center', fontSize: 22 }}>
          Certificat d&apos;engagement signé !
        </Typography>
      </Box>

      {/* Text Content */}
      <Box
        sx={{
          backgroundColor: colors.blueSky,
          color: 'white',
          fontSize: '22px',
          fontWeight: 700,
          width: 'fit-content',
          padding: '8px',
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        Mais attention !
      </Box>

      <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
        L&apos;adoption de {animalName} n&apos;est pas terminée. L&apos;association doit valider ce document avant de
        vous envoyer le contrat d&apos;adoption.
      </Typography>

      <Typography
        variant="body1"
        sx={{ mb: 2, textAlign: 'center', fontWeight: 700, color: theme.palette.secondary.main, fontSize: 19 }}
      >
        Vous recevrez un mail une fois le contrat disponible. Vous pourrez alors le signer et régler les frais
        d&apos;adoption de {animalName}.
      </Typography>
    </>
  )

  const Contract = (
    <>
      {/* Header Box */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <span style={{ fontSize: '72px' }}>🎉</span>
        <Typography variant="h5" sx={{ color: 'black', fontWeight: 700, textAlign: 'center', fontSize: 22 }}>
          Contrat d’adoption signé !
        </Typography>
      </Box>

      {/* Text Content */}

      <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
        Retrouvez vos documents signés dans le profil de {animalName}
      </Typography>

      <Box
        sx={{
          backgroundColor: colors.blueSky,
          color: 'white',
          fontSize: '22px',
          fontWeight: 700,
          width: 'fit-content',
          padding: '8px',
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        Dernière étape ?
      </Box>

      <Typography
        variant="body1"
        sx={{ mb: 2, textAlign: 'center', fontWeight: 700, color: theme.palette.secondary.main, fontSize: 19 }}
      >
        Payer les frais d’adoption. Il s’agit de la compensation des frais de santé de {animalName}
      </Typography>
    </>
  )

  const renderContent = () => {
    switch (type) {
      case 'tutorial':
        return Tutorial
      case 'certificate':
        if (useVariant) return CertificateVariant
        return Certificate
      case 'contract':
        return Contract
      default:
        return null
    }
  }

  const renderTitle = () => {
    switch (type) {
      case 'tutorial':
        return 'Signer le certificat'
      case 'certificate':
        if (useVariant) return "J'ai compris"
        return 'Paiement et contrat'
      case 'contract':
        return "Payer les frais d'adoption"
      default:
        return null
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="adoption-modal-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%', // Ensures it takes up the full width
          height: '100%', // Ensures it takes up the full height
          maxHeight: '100%', // Prevents overflow
          margin: 0, // Removes any default margin
          position: 'relative', // Allows absolute positioning inside
        },
      }}
    >
      {/* IconButton for the Close Icon */}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      {/* Dialog Content */}
      <DialogContent
        sx={{
          padding: '16px',
          py: '4px', // Reduced padding
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '4vh',
          flexGrow: 1, // Ensures the content stretches
        }}
      >
        {renderContent()}
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions sx={{ padding: '8px' }}>
        <Button
          onClick={useVariant ? handleClose : handleNext}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ fontSize: '18px' }}
        >
          {renderTitle()}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
